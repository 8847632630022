import React, {Component} from 'react';
import { CSSTransition } from 'react-transition-group';
import Matomo from '../Matomo.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import GrowDownItem from '../general_questions/components/animations/GrowDownItem.js';
import FadeInItem from '../general_questions/components/animations/FadeInItem.js';

//REQUIRED PROPS: page_title, showpage, next, nextPage, values_customer, values_business, default_state_var, header_question, choices, HandleChangeObjectNext


export class ReviewSelections extends Component {
    constructor (props) {
        super(props);
        this.state = {
            showmodal : false,
            mounted : false,
            err: null,
            input_box_key: null, //key of the input box to render
            dropdown_key: null, //key of the dropdown to render
        }
    }

    componentDidMount = () => {
        const {page_title, showpage, nextpage} = this.props;

        /*-Matomo Analytics-*/
        const title = page_title;
        Matomo(title);
        /*----End Matomo----*/
        
        //Skip to next page if necessary
        console.log('showpage: ' + showpage)
        if(!showpage){
            console.log('skipping page: ' + title)
            this.props.nextPage(nextpage, true);
        }
        this.setState({ mounted: true });
    }

    componentWillUnmount = () => {
        clearTimeout(this.timeoutId);
    }

    getItemText = (item, editable) => {
        const {input_box_key} = this.state;
        const {address_start, address_end} = this.props.values_customer;

    }

    showInputBox = (key) => e => {
        e.preventDefault();
        const {input_box_key} = this.state;
        if (input_box_key === key){
            this.setState({input_box_key: null});
        } else {
            this.setState({input_box_key: key});
        }
    }

    handleItemEdit = (key) => e =>{
        console.log('handleItemEdit')
        console.log(key)
    }

    handleItemDelete = (item) => {
        console.log(item)
    }

    showDropdown = (key) => e => {
        e.preventDefault();
        const {dropdown_key} = this.state;
        if (dropdown_key === key){
            this.setState({dropdown_key: null});
        } else {
            this.setState({dropdown_key: key});
        }
    }


    renderDropdown = (key) => {
        //key is key of the item to change in sequence_items
        //option has value, title, state_var
        const {handleEditInput} = this.props;
        const item = this.props.sequence_items.find(item => item.key === key);
        console.log(item)
        let options;
        if (!item){
            return;
        }
        if(item.title.includes('Turn')){
            options =         
            [
                {
                    value: "Quarter",
                    title: "Quarter Turn on Stairs",
                    state_var: 'turn_type',
                },
                {
                    value: "Full",
                    title: "Full Turn on Stairs",
                    state_var: 'turn_type',
                }

            ]
        }
        return(
            <select className='dropdown' value={item.value} onChange={handleEditInput(key)} onBlur={this.showDropdown(key)}>
                {options.map((option, index) => {
                    return(
                        <option key={index} id={option.key} value={option.value}>{option.title}</option>
                    )
                })}
            </select>
        )
    }

    signalReviewComplete = (final_delay) => {
        // wait for the final delay, then signal that the instructions are complete
        const {reviewed_selections} = this.props;
        if (reviewed_selections){
            return;
        }
        this.timeoutId = setTimeout(() => {
            this.props.signalReviewComplete(true);
        }, final_delay + 1500); // add 1.5 seconds to the final delay (time for last animation to complete)
    }
        
    getAnimationDelay = (index, component_type = 'line') => {
        const { sequence_items } = this.props;
        const line_duration = 250;
        const line_delay = 0;
        const circle_duration = 500;
        const circle_delay = 250;
        const text_duration = 500;
        const text_delay = 500;
        const total_duration = line_duration + circle_duration + text_duration;
        console.log('getAnimationDelay')
        console.log(index)
        console.log(component_type)
        let delay = (index !== 0) ? (index * 1000) : 0;
        //line, then circle, then text
        if (component_type === 'circle'){
            delay += 250;
        } else if (component_type === 'text'){
            delay += 500;
        } else if (component_type === 'control-icon'){ //wait for the very end to show the control icons
            delay = (total_duration * sequence_items.length) + 100;
        }
        if (index === sequence_items.length - 1) {
            this.signalReviewComplete(delay);
        }
        console.log('delay: ' + delay)
        return delay;
    }

    SelectionComponent = (item, first_item=false, index=null) => {
        const {input_box_key, dropdown_key} = this.state;
        const {address_start, address_end} = this.props.values_customer;
        const {handleEditInput, handleDeleteInput, reviewed_selections} = this.props;
        const sequence_items = this.props.sequence_items;
        let line = null;
        let circle = null;
        let editable = true;
        let removable = true;
        console.log(item)
        if (typeof item.value === 'string' && (item.value.toLowerCase().includes('start') || item.value.toLowerCase().includes('destination'))){
            line = <div className='review-selections-line invis'/>
            circle= <div className='review-selections-circle invis'><div className='inner-circle invis'/></div>
        } 
        else if (!first_item){
            circle = <div className='review-selections-circle'><div className='inner-circle'/></div>
            line = <div className='review-selections-line'/>
        } else {
            circle = null;
            line = null;
        }
        const key = item.key;

        let text;
        let text_html;
        let onEditHandler = () => this.showInputBox(key);
        let text_class = 'light';

        if (!item.title){
            return;
        }
        if (item.title === 'Stairs'){
            text = (item.value === 1) ? ' Stair' : ' Stairs';
        } else if (item.title === 'Turn on Stairs'){
            onEditHandler = () => this.showDropdown(key);
            if (item.value === 'Full'){
                text = 'Full Turn on Stairs';
            } else if (item.value.includes('Quarter')){
                text = 'Quarter Turn on Stairs';
            } else {
                text = item.title;
            }
        } else if (item.title === "Tight Turn"){
            text = 'Tight Turn';
            editable = false;
        } else if (item.title.includes('Grass') || item.title.includes('Dirt')){
            text = item.title;
            editable = false;
        } else if (item.value === 'start'){
            text = 'Starting Address: ' + address_start;
            text_class += ' bold';
            editable = false;
            removable = false;
        } else if (item.value === 'destination'){
            text_class += ' bold';
            text = 'Destination Address: ' + address_end;
            editable = false;
            removable = false;
        }
        else{
            text = item.title + ': ' + item.value;
        }

        if (input_box_key === item.key){
            text_html = (
                <p className={text_class}>
                    <input 
                        className={'review-selections-edit-input small no-margin'} 
                        type='text' 
                        value={item.value} 
                        onChange={(e) => handleEditInput(key)(e)}
                        onBlur={this.showInputBox(key)}
                        max={'999'}
                    />
                    {text}
                </p>
            )
        } else if (dropdown_key === item.key){
            console.log('rendering dropdown_key')
            text_html = (
                <p className={text_class}>
                    {this.renderDropdown(item.key)}
                </p>
            )
        }
        
        else {
            text_html = (
                    <p className={text_class}>
                        {editable && text[0] === ' ' && item.value} {/*if the first character is not a space, we don't want to display the value*/}
                        {text}
                    </p>
            )
        }
        
        const handleEdit = (key) => onEditHandler(key);

        let edit_icon;
        let delete_icon;
        if (editable){
            if (!reviewed_selections){
                edit_icon = <FadeInItem item={edit_icon} key={index + 1} animation_delay={this.getAnimationDelay(index, 'control-icon')} fade_duration={500}/>
            } else {
                edit_icon = <FontAwesomeIcon icon={faPenSquare} className='icon' id={key} onClick={(e) => handleEdit(key)(e)}/>
            }
        }
        if (removable){
            if (!reviewed_selections){
                delete_icon = <FadeInItem item={delete_icon} key={index + 1.5} animation_delay={this.getAnimationDelay(index, 'control-icon')} fade_duration={500}/>
            } else {
                delete_icon = <FontAwesomeIcon icon={faTrash} className='icon' id={key} onClick={(e) => handleDeleteInput(key)(e)}/>
            }
        }

        return(
            <div className='review-selections-container' key={index}>
                {(!reviewed_selections) ? <GrowDownItem item={line} key={index} animation_delay={this.getAnimationDelay(index, 'line')} fade_duration={500}/> : line}
                {(!reviewed_selections) ? <FadeInItem item={circle} key={index + .5} animation_delay={this.getAnimationDelay(index, 'circle')} extra_class="align-self-center"/> : circle}
                <div key={item.title} className='review-selections-item'>
                    <div className='light review-selections-text-group'>
                        {(!reviewed_selections) ? <FadeInItem item={text_html} key={index + .75} animation_delay={this.getAnimationDelay(index, 'text')} fade_duration={500}/> : text_html}
                        <div className='control-group'>
                            {edit_icon}
                            {delete_icon}
                        </div>
                    </div>
                </div>
                {/* {index !== sequence_items.length -1 && <div className='review-selections-circle'/>}                                           */}
            </div>
        )
    }


    render() { 
        const { values_customer, gpt_prompts, is_mobile_display, sequence_items} = this.props;
        const start_address = values_customer.address_start;
        const end_address = values_customer.address_end;

        if (this.state.err) throw this.state.err;
        return (
            <div className='surveywrapper'>
                <div className='multiplechoice-transitionbuffer has-continue-footer'>
                {/* {this.state.showmodal ? <GPTModal handleClose={this.hideModal} prompts={modal_gpt_prompts} intro_query={modal_intro_query} displayclass={"modal display-block"} company_name={this.props.values_business.businessname} industry={values_business.industry}/> : null} */}
                    <CSSTransition
                            in={this.state.mounted}
                            timeout={1000}
                            classNames="fade"
                            unmountOnExit 
                            appear
                    >
                        <div className='multiplechoice height-auto no-position-absolute'>
                            
                            <div className='textwrapper'>
                                <h2 className='large'>Please confirm your selections:</h2>
                                <h4 className='small'>This is important! Your quote will only be as accurate as the answers you provide.</h4>
                                {/* {!hide_gpt && <p className='clickable display-flex-row' onClick={() => this.showModal(gpt_prompts)}><img className='assistant-icon' src={AI_Assistant_Icon} alt='chat icon'/>&nbsp;Ask anything about this</p>} */}
                            </div>
                            {sequence_items.map((item, index) => {
                                    return(
                                        this.SelectionComponent(item, (index === 0), index)
                                    )
                                }
                            )}
                            {(!sequence_items || sequence_items.length === 0) && 
                                <div className='review-selections-container'>
                                    <div className='center medium light'>No stairs, tight turns, or grass/dirt sections</div>
                                </div>
                            }
                            {/* <div className='textwrapper'>
                                <h2 className='large invis'>.</h2>
                                <h4 className='small invis'>.</h4>
                                <p className='small btntitle invis'>.</p>
                            </div> */}
                            <div className="fillremainderflex"/>
                            
                        </div>
                    </CSSTransition>
                    {/* <FooterContact values_business={values_business} phone={values_customer.branch.phone}/> */}
                </div>
            </div>
        )
    }
}

export default ReviewSelections;