import React, {Component} from 'react';
import axios from 'axios';
import Header from '../Header';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSyncAlt} from '@fortawesome/free-solid-svg-icons';
import CheckBoxesQuestion from './CheckBoxesQuestion';
import TextInputQuestion from './TextInputQuestion';
import ContinueFooter from '../questions/ContinueFooter';
import MultipleChoiceQuestion from './MultipleChoiceQuestion';
import { hasTwoUniqueValues } from '../../Helpers';
import SingleColumnInputObject from './SingleColumnInputObject';
import SubmitAdditionalInputFooter from './question_childrencomponents/SubmitInputFooter';
import CategoricalCheckBoxesQuestion from './CategoricalCheckBoxesQuestion';
import SingleColumnSingleFieldInputs from './SingleColumnSingleFieldInputs';
import { findIndexByProperty } from '../../Helpers';
import AddonPricingList from './AddonPricingList';
import DragDropOptions from './DragDropOptions';
import PackageCreator from './PackageCreator';


export class ConcreteOnboardingSurvey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //------------------------------//
            //** General State Conditions **//

            //***DEV ONLY!! */
            addons: 
                [
                    {coef: 0, title: 'Addon 1', flat: 0, linear: 0, key: "1", long: null, short: null}, //dummy addon for dev
                    {coef: 0, title: 'Addon 2', flat: 0, linear: 0, key: "2", long: null, short: null}, //dummy addon for dev
                    {coef: 0, title: 'Addon 3', flat: 0, linear: 0, key: "3", long: null, short: null}, //dummy addon for dev
                    {coef: 0, title: 'Addon 4', flat: 0, linear: 0, key: "4", long: null, short: null}, //dummy addon for dev
                    {coef: 0, title: 'Addon 5', flat: 0, linear: 0, key: "5", long: null, short: null}, //dummy addon for dev
                    {coef: 0, title: 'Addon 6', flat: 0, linear: 0, key: "6", long: null, short: null}, //dummy addon for dev
                ],
            servicetype_choices: [
                {
                    title: 'Epoxy Coatings',
                    finishes: [
                        { title: 'Solid Color', coef: null, parent: 'Epoxy Coatings' },
                        { title: 'Flake', coef: null, parent: 'Epoxy Coatings' },
                        { title: 'Polyurea', coef: null, parent: 'Epoxy Coatings' },
                        { title: 'Polyaspartic', coef: null, parent: 'Epoxy Coatings' },
                        { title: 'Quartz', coef: null, parent: 'Epoxy Coatings' },
                        { title: 'Other', coef: null, parent: 'Epoxy Coatings' }
                    ],
                    package_addons: []
                },
            ],
            //pages: ['Company Name'], //array history of pages
            pages: ['Package Creator'], //array history of pages
            ismobile: props.ismobile,
            //** FairQuote Options Information **//
            businessid: props.subdomain, //table ref
            businesslogo: null, //s3 logo key
            
            businessaddress: '', 
            branch_options: [], //array of objects with .name, .phone, .email, .address (in format `${street}, ${city}, ${state} ${zipCode}` for GoogleMapsAPI)
            areatype_options: [{title: 'A'}, {title: 'B'}, {title: 'C'}, {title: 'D'}], //array of objects with .title,(coating) .services[]
            servicetype_options: [ //array of objects with .title,(service) .Finishes[]
                {
                    title: 'Epoxy Coatings',
                    finishes: [
                        { title: 'Solid Color', coef: null, parent: 'Epoxy Coatings' },
                        { title: 'Flake', coef: null, parent: 'Epoxy Coatings' },
                        { title: 'Polyurea', coef: null, parent: 'Epoxy Coatings' },
                        { title: 'Polyaspartic', coef: null, parent: 'Epoxy Coatings' },
                        { title: 'Quartz', coef: null, parent: 'Epoxy Coatings' },
                        { title: 'Other', coef: null, parent: 'Epoxy Coatings' }
                    ],
                    package_addons: {}
                },
                {
                    title: 'Polyaspartic Coatings',
                    finishes: [
                        { title: 'Solid Color', coef: null, parent: 'Polyaspartic Coatings' },
                        { title: 'Flake', coef: null, parent: 'Polyaspartic Coatings' },
                        { title: 'Polyurea', coef: null, parent: 'Polyaspartic Coatings' },
                        { title: 'Polyaspartic', coef: null, parent: 'Polyaspartic Coatings' },
                        { title: 'Other', coef: null, parent: 'Polyaspartic Coatings' }
                    ],
                    package_addons: {}
                },
                {
                    title: 'Decorative Concrete',
                    finishes: [
                        { title: 'Stamping', coef: null, parent: 'Decorative Concrete' },
                        { title: 'Engraving', coef: null, parent: 'Decorative Concrete' },
                        { title: 'Other', coef: null, parent: 'Decorative Concrete' }
                    ],
                    package_addons: {}
                },
                {
                    title: 'Floor Striping',
                    finishes: [
                        { title: 'Solid Color Epoxy', coef: null, parent: 'Floor Striping' },
                        { title: 'Solid Color Paint', coef: null, parent: 'Floor Striping' },
                        { title: 'Other', coef: null, parent: 'Floor Striping' }
                    ],
                    package_addons: {}
                },
                {
                    title: 'Concrete Polishing',
                    finishes: [
                        { title: 'Basic', coef: null, parent: 'Concrete Polishing' },
                        { title: 'Mid-grade', coef: null, parent: 'Concrete Polishing' },
                        { title: 'Advanced', coef: null, parent: 'Concrete Polishing' },
                        { title: 'Other', coef: null, parent: 'Concrete Polishing' }
                    ]
                },
                {
                    title: 'Metallic Coatings',
                    finishes: [], // No specific finishes provided
                    package_addons: {}
                },
                {
                    title: 'Concrete Staining',
                    finishes: [
                        { title: 'Acid colors', coef: null, parent: 'Concrete Staining' },
                        { title: 'Solid colors', coef: null, parent: 'Concrete Staining' },
                        { title: 'Metallic', coef: null, parent: 'Concrete Staining' },
                        { title: 'Other', coef: null, parent: 'Concrete Staining' }
                    ],
                    package_addons: {}
                },
                {
                    title: 'Terrazzo Coatings',
                    finishes: [
                        { title: 'Traditional cement based', coef: null, parent: 'Terrazzo Coatings' },
                        { title: 'Epoxy', coef: null, parent: 'Terrazzo Coatings' },
                        { title: 'Other', coef: null, parent: 'Terrazzo Coatings' }
                    ],
                    package_addons: {}
                },
                {
                    title: 'Concrete Seal',
                    finishes: [
                        { title: 'Acid colors', coef: null, parent: 'Concrete Seal' },
                        { title: 'Solid colors', coef: null, parent: 'Concrete Seal' },
                        { title: 'Epoxy', coef: null, parent: 'Concrete Seal' },
                        { title: 'Other', coef: null, parent: 'Concrete Seal' }
                    ],
                    package_addons: {}
                },
                {
                    title: 'Quartz Coatings',
                    finishes: [], // No specific finishes provided
                    package_addons: {}
                },
                {
                    title: 'Concrete Overlays',
                    finishes: [], // No specific finishes provided
                    package_addons: {}
                },
                {
                    title: 'Other',
                    finishes: [], // No specific finishes provided
                    package_addons: {}
                }
            ], 
            base_items: [], //array of objects with .title,  .short, .long, .coef (num), .flat (num)
            addon_items: [ //array of objects with .title,  .short, .long, .coef (num), .flat (num)
                    {key: 'Add-on 1', title: null, short: null, long: null, coef: null, flat: null},
                    {key: 'Add-on 2', title: null, short: null, long: null, coef: null, flat: null},
                    {key: 'Add-on 3', title: null, short: null, long: null, coef: null, flat: null},
                    {key: 'Add-on 4', title: null, short: null, long: null, coef: null, flat: null},
                    {key: 'Add-on 5', title: null, short: null, long: null, coef: null, flat: null},
                ], 
            coat_options: [], //.service, .style[], .coef, .short, .colors(.title,.icon,.preview)
            inpersons: {}, //object with state conditions, sorted by survey Page, to send to contact page if true
            nodisplays: {}, //pages to hide, with conditions to do so
            quote_range: null, //display final quote in a range of +/- this % value
            homepage: '', //link back to company's homepage
            singlepackage: null, //if true, only custom package will be quoted
            //**Company-selected information **/
            areatype_choices: null, //chosen options from areatype_options
            //servicetype_choices: [], //chosen options from servicetype_options
            finishtype_choices: [], //chosen options from finishes contained in servicetype_options. Object with multiple arrays of objects
            businessname: '', //company name
            has_multiplebranches: null, //has multiple branches with same pricing model
            branches: [], //array of all branches and respective contact info
            price_factors: [], //array of objects with .title, .coef, .coef_ranged, or .flat
            collect_address: null, //collect address T/F, even though not used for pricing
            other_pricing_factors: null, //other pricing factors to collect (string)
            use_ranged_quote: null, //use ranged quote T/F
        };
    }

    componentDidMount = () => {
        //const id = this.props.subdomain;
        //const {businessid} = this.state;
        //if(!businessid){this.initBizID(id)};
        //if(businessid){this.getCompanyInfo(businessid)};
        //this.checkValues();
    }

    initBizID = (id) => {
        this.setState({
            businessid: id,
        });
    }

    nextPage = (next, submit_additional_option = false, current_index = null, current_page = null) => {
        const {pages} = this.state;
        console.log('nextPage')
        console.log(next)
        console.log(submit_additional_option)
        console.log(current_index)
        console.log(current_page)
        if (submit_additional_option){
            //remove numbers after #, then add 1
            let next_index = current_index + 2; // add 1 to match page title, and another to increment
            next = current_page.replace(/#\d+/, '') + '#' + next_index;
        }
        console.log(next)
        this.setState({
            pages: [...pages, next],
        }, /*this.checkValues()*/);
    };


    prevPage = () => {
        const {pages} = this.state;
        pages.pop()
        this.setState({
            pages: [...pages]
        }, /*this.checkValues()*/);
        
    };

    handleChange = input => e => {
        //console.log(e.target.id);
        console.log(e.currentTarget.id);
        let st = e.currentTarget.id;
        let val = e.target.value;
        if (val !== null && val !== '' && typeof val !== 'undefined'){
            st = e.target.value;
        }
        //console.log("Handlechange")
        //console.log(st)
        this.setState({[input]: st});
    };

    handleChangeNext = (next,input) => e => {
        //console.log(input);
        let st = e.currentTarget.id;
        let val = e.target.value;
        if (val !== null && val !== '' && typeof val !== 'undefined'){
            st = e.target.value;
        }
        this.setState({[input]: st}, this.nextPage(next));
    };

    handleChangeObject = (input, ob) => {
        this.setState({[input]: ob});
    };

    handleChangeObjectNext = (next, input, ob) => {
        this.setState({[input]: ob}, this.nextPage(next));
    };

    handleAddIndexedItemToArray = (arr_name, index, param) => e => {
        const new_arr = this.state[arr_name];
        console.log("updating indexed item to array...")
        let st = e.currentTarget.id;
        let val = e.target.value;
        if (val !== null && val !== '' && typeof val !== 'undefined'){
            st = e.target.value;
        }

        if (!new_arr[index]){
            new_arr[index] = {}//TODO BUG: index faulty, circular loop grows the array
        } 
        console.log(new_arr)
        console.log(new_arr[index])
        console.log(new_arr[index][param])
        //new_arr[index][param] = st;
        console.log(st)
        console.log(new_arr)

        this.setState({ [arr_name] : [...new_arr]});

    }

    //pushes object to array, or updates if already exists by removing the old item and adding the new one
    handleAddItemToArray = (arr_name, item) => { 
        console.log("adding item to array...")
        console.log(item)
        const new_arr = this.state[arr_name] || [];
        if(findIndexByProperty(new_arr, "title", item.title) > -1){
            console.log("removing old item from array...")
            new_arr.splice(findIndexByProperty(new_arr, "title", item.title), 1);
        }
        new_arr.push(item);
        this.setState({ [arr_name] : [...new_arr]});
    };

    //removes object from array, if a matching key or title is found
    handleRemoveItemFromArray = (arr_name, item) => {
        console.log("removing item from array...")
        console.log(item)
        console.log(arr_name)
        //search for item in array with matching key (or title if no key), remove it
        const new_arr = this.state[arr_name];
        const found_atindex = (item.key) ? findIndexByProperty(new_arr, "key", item.key) : findIndexByProperty(new_arr, "title", item.title);
        if ( found_atindex > -1 ){
            new_arr.splice(found_atindex, 1);
        }
        this.setState({ [arr_name] : [...new_arr]});
    }

    //updates package_addons attribute in servicetype_choices array with new object
    // Toggles (if existing) are removed, and new ones are added (if not existing already)
    handleUpdateServiceTypePackageAddons = (package_name, addon, servicetype) => {
        console.log("updating package addons in servicetype_choices...")
        console.log(addon)
        console.log(servicetype)
        console.log(package_name)
        if (package_name.toLowerCase().includes('basic')){
            package_name = 'base';
        } else if (package_name.toLowerCase().includes('recommended')){
            package_name = 'recommended';
        } else if (package_name.toLowerCase().includes('deluxe')){
            package_name = 'deluxe';
        } else if (package_name.toLowerCase().includes('customize')){
            package_name = 'customize';
        }
        const new_arr = [...this.state.servicetype_choices];
        const found_atindex = findIndexByProperty(new_arr, "title", servicetype);
        if ( found_atindex > -1 ){
            const service_object = new_arr[found_atindex];
            
            if (!service_object.package_addons) { //initialize package_addons if it doesn't exist
                service_object.package_addons = {};
            } 
            if (!service_object.package_addons[package_name]){ //if array package_name doesn't exist yet at servicetype_choices[found_atindex].package_addons, create it
                service_object.package_addons[package_name] = [];
            } 

            const addon_index = findIndexByProperty(service_object.package_addons[package_name], "key", addon.key);
            if (addon_index > -1){
                //if addon already exists, remove it
                service_object.package_addons[package_name].splice(found_atindex, 1);
            } else {
                //addon doesn't exist yet, add it
                service_object.package_addons[package_name].push(addon);
                console.log(service_object)
                new_arr[found_atindex] = service_object;
                console.log(new_arr)
                this.setState({ servicetype_choices : [...new_arr]});
            }
        
        } else {
            console.error('servicetype not found')
        }
    }


    //updates specific object parameters in array. If matching item is not found, a new item is added to the array.
    handleUpdateItemInArray = (arr_name, item, item_parameter_name, item_paramater_value) => {
        console.log("updating item in array...")
        console.log(item)
        console.log(arr_name)
        item[item_parameter_name] = item_paramater_value;
        //search for item in array with matching key (or title if no key)
        const new_arr = this.state[arr_name];
        const found_atindex = (item.key) ? findIndexByProperty(new_arr, "key", item.key) : findIndexByProperty(new_arr, "title", item.title);
        if ( found_atindex > -1 ){
            new_arr[found_atindex] = item;
        } else {
            new_arr.push(item);
        }
        this.setState({ [arr_name] : [...new_arr]});
    }


    handleUpdateObjectToArrayNext = (next, arr, obj, obj_index) => {
        //if needed in future, could also check if obj key is exact match to an already existing arr[obj], and replace/update
        //for now, just push onto end of arr
        console.log('UpdateObjectToArrayNext')
        let new_arr = this.state[arr];
        
        if(!new_arr[obj_index]){
            new_arr.push(obj); 
            console.log('pushed')
        } else {
            new_arr[obj_index] = obj;
            console.log('updated at index ' + obj_index)
        }
        this.setState({[arr]: [...new_arr]}, this.nextPage(next));
    }

    handleServiceTypeChoice = (choice) => {
        console.log('handleServiceTypeChoice')
        const {servicetype_choices} = this.state;
        const reformatted_choice = {title: choice.title, finishes: []};
        console.log(choice)
        console.log(reformatted_choice)
        console.log(servicetype_choices)
        const found_atindex = findIndexByProperty(servicetype_choices,"title", choice.title);
        if ( found_atindex > -1 ){
            servicetype_choices.splice(found_atindex, 1);
        } else {
            servicetype_choices.push(reformatted_choice);
        }
        console.log(servicetype_choices)
        this.setState({servicetype_choices: servicetype_choices});
    }

    handleFinishChoices = (choice) => { //toggle nested finish choice objects into finish choice arrays, based on "parent" property
        const {servicetype_choices} = this.state;
        const parent = choice.parent;
        let updated_choices = servicetype_choices;
        const parent_index = findIndexByProperty(updated_choices, "title", parent);
        const finish_index = findIndexByProperty(updated_choices[parent_index].finishes, "title", choice.title);
        if (finish_index > -1){
            updated_choices[parent_index].finishes.splice(finish_index, 1);
        }
        else {
            updated_choices[parent_index].finishes.push(choice);
        }
        this.setState({servicetype_choices: updated_choices});
    }

    handlePriceFactorValue = (value, factor_title) => {
        const {price_factors} = this.state;
        const factor_index = findIndexByProperty(price_factors, "title", factor_title);
        price_factors[factor_index].coef = value;
        this.setState({price_factors: price_factors});
    }

    getCompanyInfo = async (id) => {
        //const {businessid, businessname, branch_options, areatype_options, base_items, addon_items, coat_options } = this.state;
        console.log("requesting company info...");
        console.log(id)
        const response = await  axios.get(
            'https://hg480kughk.execute-api.us-east-2.amazonaws.com/dev/companyInfoFunction',
            { 
                params: { business: id } 
            }
        );
        const data = JSON.parse(response.data);
        const coats = JSON.parse(data.styles);
        this.setState({
            businessname: data.name,
            businesslogo: data.logo,
            branch_options: JSON.parse(data.branches),
            areatype_options: JSON.parse(data.areas),
            servicetype_options: JSON.parse(data.services),
            base_items: JSON.parse(data.base),
            addon_items: JSON.parse(data.addons),
            coat_options: coats,
            inpersons: JSON.parse(data.inpersons),
            nodisplays: JSON.parse(data.nodisplays),
            quote_range: data.quote_range,
            homepage: data.homepage,
            singlepackage: data.singlepackage
        });
        console.log(this.state.colors);
    };

    autoCompleteBranchField = (varname) => { //continue autocompleting unless user has already used unique values between branches
        const {branches} = this.state;
        if (branches.length !== 1 && hasTwoUniqueValues(branches, varname)){
            return false;
        } else {
            return true;
        }
    }

    getNewBranchObject = (index) => {
        //Required array format: {type, autocomplete_type, placeholder, varname, value, required (bool)}
        const {branches, uses_travelcosts} = this.state;
        const requested_branch = branches[index];

        const branch_info = [
            {
                varname: 'branchname',
                type: 'text',
                autocomplete_type: 'organization',
                placeholder: 'Branch name',
                show: true,
                required: true,
                value: (requested_branch) ? requested_branch.branchname : null
            },
            {
                varname: 'branchphone',
                type: 'tel',
                autocomplete_type: 'tel',
                placeholder: 'Phone',
                show: true,
                required: true,
                value: ((requested_branch) ? requested_branch.branchphone : (branches.length > 0 && this.autoCompleteBranchField('branchphone'))) ? branches[0].branchphone : null
            },
            {
                varname: 'branchemail',
                type: 'email',
                autocomplete_type: 'email',
                placeholder: 'Email (for leads)',
                show: true,
                required: true,
                value: ((requested_branch) ? requested_branch.branchemail : (branches.length > 0 && this.autoCompleteBranchField('branchemail'))) ? branches[0].branchemail : null
            },
            {
                varname: 'branchwebpage',
                type: 'url',
                autocomplete_type: 'url',
                placeholder: 'Website url',
                show: true,
                required: true,
                value: ((requested_branch) ? requested_branch.branchwebpage : (branches.length > 0 && this.autoCompleteBranchField('branchwebpage'))) ? branches[0].branchwebpage : null
            },
            {
                varname: 'branchaddress',
                type: 'text',
                autocomplete_type: 'street-address',
                placeholder: 'Branch address',
                show: uses_travelcosts,
                required: true,
                value: (requested_branch) ? requested_branch.branchaddress : null
            }
        ]
        console.log("returning branch object")
        console.log(branch_info)
        console.log('returning new branch object')
        return branch_info;
    }

    getServiceTypeIndex = (current_page) => { //returns current index of servicetype_choices array. Skips any indexes with no finishtype options to present.
        const {servicetype_choices, servicetype_options} = this.state;
        //index is determined using the last number in the page title
        const regex = /#(\d+)/; //get digits following '#' character
        const match = current_page.match(regex);
        const current_service_index = match ? parseInt(match[1]) : 0;

        for(let i = current_service_index; i < servicetype_choices.length; i++){
            
            let option_index = findIndexByProperty(servicetype_options, "title", servicetype_choices[i].title);
            console.log('current service index: ' + i)
            console.log('option index: ' + option_index)
            console.log(servicetype_options[option_index])
            if (servicetype_options[option_index] && servicetype_options[option_index].finishes.length > 0){ // match found
                for(let j=i+1; j < servicetype_choices.length; j++){ // now look ahead to see if any future service choices have finish options
                    
                    option_index = findIndexByProperty(servicetype_choices, "title", servicetype_choices[j].title);  
                    
                    if (servicetype_options[option_index] && servicetype_options[option_index].finishes.length > 0){ // future match found
                        return [i]; //return index of current match with respect to the options array
                    } 
                } 
                return [i, -1]; //the current index is the last match found, return current index and -1 to indicate end
            } 
        }
        console.error('no service type index found')
    }
    getFinishPriceIndex = (current_page) => { //returns current index of finishtype_choices array, to know which category to get prices for
        const {finishtype_choices} = this.state;
        //index is determined using the last number in the page title
        const regex = /#(\d+)/; //get digits following '#' character
        const match = current_page.match(regex);
        const current_finish_index = match ? parseInt(match[1]) : 0;
        return current_finish_index;
    }

    render() {
        const { pages, ismobile} = this.state;
        const {businessname, businesslogo, branch_options, areatype_options, servicetype_options, base_items, addon_items, coat_options, inpersons, quote_range, 
            homepage, nodisplays, singlepackage} = this.state;
        const {areatype_choices, servicetype_choices, has_multiplebranches, branches, finishtype_choices, price_factors, collect_address, other_pricing_factors,
                use_ranged_quote} = this.state;
        const values_general = { 
            pages 
        };
        const values_onboarding = { //FairQuote onboarding value defaults 
            //businessid,
            businesslogo,
            branch_options,
            areatype_options,
            servicetype_options,
            base_items,
            addon_items,
            coat_options,
            inpersons,
            quote_range,
            homepage,
            singlepackage
        };
        const values_inputs = { //Company-selected information
            businessname,
            areatype_choices,
            servicetype_choices,
            has_multiplebranches,
            branches,
            finishtype_choices,
            price_factors,
            collect_address,
            other_pricing_factors,
            use_ranged_quote
        };
        var nextpage;
        var thispage;
        var factor_title;
        //const branch_object = this.getNewBranchObject();

        const current_page = pages[pages.length -1];
        //BEGIN PAGE NAVIGATION
        if (current_page === 'Company Name'){
            thispage = current_page;
            nextpage = 'Branches';
            return (
                <div className='container'>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_onboarding}   
                    />  
                    <TextInputQuestion
                        handleChange = {this.handleChange}
                        nextPage = {this.nextPage}
                        nextpage = {nextpage}
                        pages = {pages}
                        pagetitle = {thispage}
                        showpage = {true}
                        header_question = "What is your company name as you'd like it to appear on your FairQuote?"
                        input_category = {'businessname'}
                    />
                    <ContinueFooter
                        nextPage={this.nextPage}
                        enable = {true}
                        nextpage = {nextpage}
                    />
                </div>
            )
        }
        else if (current_page === 'Branches'){
            thispage = current_page;
            nextpage = 'Add Branch #1';
            return (
                <div className='container'>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_onboarding}   
                    />  
                    <MultipleChoiceQuestion
                        handleChangeObjectNext = {this.handleChangeObjectNext}
                        nextPage = {this.nextPage}
                        nextpage = {nextpage}
                        pages = {pages}
                        pagetitle = {thispage}
                        showpage = {true}
                        header_question = "Do you have more than one branch location that will use the same pricing model?"
                        input_category = {'has_multiplebranches'}
                        options = {[{title: 'Yes, multiple'}, {title: 'No, just one'}]}
                    />
                </div>
            )
        }
        else if (current_page.includes('Add Branch')){
            const regex = /#(\d+)/; //get digits following '#' character
            const match = current_page.match(regex);
            const current_branch_index = match ? parseInt(match[1]) - 1 : null; //minus 1 to match actual array index
            console.log('current branch index: ' + current_branch_index)
            const branch_exists = current_branch_index && branches[current_branch_index];
            const submit_additional_option = has_multiplebranches && has_multiplebranches.toLowerCase().includes('yes');
            thispage = current_page;
            nextpage = 'Materials';
            return (
                <div className='container'>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_onboarding}   
                    />  
                    <SingleColumnInputObject
                        key={current_branch_index}
                        handleUpdateObjectToArrayNext = {this.handleUpdateObjectToArrayNext}
                        handleAddIndexedItemToArray = {this.handleAddIndexedItemToArray}
                        nextPage = {(next) => this.nextPage(next, submit_additional_option, current_branch_index, current_page)}
                        nextpage = {nextpage}
                        pages = {pages}
                        pagetitle = {thispage}
                        showpage = {true}
                        header_question = {"Fill out the information for " + ((current_branch_index > 0) ? "branch #" + (current_branch_index + 1) : "your branch") + " below."}
                        inputs = {this.getNewBranchObject(current_branch_index)}
                        input_category = 'branches'
                        category_readonly_currentobject = {branches[current_branch_index]}
                        category_index = {current_branch_index}
                        //submit_additional_option = {has_multiplebranches && has_multiplebranches.toLowerCase().includes('yes')}
                    />
                    <ContinueFooter
                        nextPage={this.nextPage}
                        enable = {true}
                        nextpage = {nextpage}
                        alternate_text = {(submit_additional_option) ? 'All Done' : 'Continue'} //if there are multiple branches, change text to "All Done"
                        extra_c2a = {(submit_additional_option) ? {title: 'Add Another', enable: true, nextPage : (next) => this.nextPage(next, submit_additional_option, current_branch_index, current_page)}  : null}
                    />
                </div>
            )        
        }     
        else if (current_page ==='Materials'){
            thispage = current_page;
            nextpage = 'Materials Options';
            console.log(values_inputs)
            return (
                <div className='container'>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_onboarding}   
                    />  
                    <CheckBoxesQuestion
                        key={current_page}
                        handleChangeObject = {this.handleChangeObject}
                        nextPage = {this.nextPage}
                        customHandleChoice = {this.handleServiceTypeChoice}
                        nextpage = {nextpage}
                        pages = {pages}
                        pagetitle = {thispage}
                        showpage = {true}
                        header_question = 'What types of concrete coating do you offer?'
                        input_category = {'servicetype_choices'}
                        options = {servicetype_options}
                        values_inputs = {values_inputs}
                    />
                    <ContinueFooter
                        nextPage={this.nextPage}
                        enable = {servicetype_choices && servicetype_choices.length > 0}
                        nextpage = {nextpage}
                    />
                </div>
            )
        }  
        else if (current_page.includes('Materials Options')){
            const parsed_index = this.getServiceTypeIndex(current_page);
            const current_servicetype_index = parsed_index[0];
            const options_index = findIndexByProperty(servicetype_options, "title", servicetype_choices[current_servicetype_index].title);
            thispage = current_page;
            console.log(parsed_index)
            if (parsed_index && parsed_index.length > 1){ //last match found, parsed_index[1] is -1
                nextpage = 'Base Pricing';
            } else if (parsed_index){
                nextpage = 'Materials Options #' + (current_servicetype_index + 1);
            }
            console.log(servicetype_choices[current_servicetype_index])
            console.log('next page: ' + nextpage)
            return(
                <div className='container'>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_onboarding}   
                    />  
                    <CheckBoxesQuestion
                        key={current_page}
                        handleChangeObject = {this.handleChangeObject}
                        nextPage = {this.nextPage}
                        customHandleChoice = {this.handleFinishChoices}
                        nextpage = {nextpage}
                        pages = {pages}
                        pagetitle = {thispage}
                        showpage = {current_servicetype_index !== null && current_servicetype_index !== undefined}
                        header_question = {(current_servicetype_index !== null && current_servicetype_index !== undefined) ? `Which of the following options do you give your customers for ${servicetype_choices[current_servicetype_index].title}?`: ''}
                        use_for_is_checked = {servicetype_choices[current_servicetype_index].finishes}
                        options = {(current_servicetype_index !== null && current_servicetype_index !== undefined) ? servicetype_options[options_index].finishes : []}
                        values_inputs = {values_inputs}
                    />
                    <ContinueFooter
                        nextPage={this.nextPage}
                        enable = {servicetype_choices}
                        nextpage = {nextpage}
                    />
                </div>
            )
        }
        //TODO: handle "other" finish types
        else if (current_page.includes('Base Pricing')){
            const current_finishprice_index = this.getFinishPriceIndex(current_page);
            thispage = current_page;
            const finishes = this.state.servicetype_choices[current_finishprice_index].finishes;
            if (current_finishprice_index >= servicetype_choices.length - 1){
                nextpage = 'Pricing Factors';
            } else if (current_finishprice_index >= 0){
                nextpage = 'Base Pricing #' + (current_finishprice_index + 1);
            }
            console.log('current finish index: ' + current_finishprice_index)
            console.log('this page: ' + thispage)
            console.log('next page: ' + nextpage)
            return (
                <div className='container'>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_onboarding}   
                    />  
                    <SingleColumnSingleFieldInputs
                        key={current_page}
                        handleChangeObject = {this.handleChangeObject}
                        nextPage = {this.nextPage}
                        nextpage = {nextpage}
                        pages = {pages}
                        pagetitle = {thispage}
                        showpage = {true}
                        header_question = {`Assuming a perfect floor, what is your base cost per square foot for the following ${servicetype_choices[current_finishprice_index].title} options:`}
                        input_category = {'finishtype_choices'}
                        options = {(finishes.length > 0) ? finishes : [{title: servicetype_choices[current_finishprice_index].title, coef: null}]}
                        input_type = 'coef'
                    />
                    <ContinueFooter
                        nextPage={this.nextPage}
                        enable = {true}
                        nextpage = {nextpage}
                    />
                </div>
            )
        }
        else if (current_page === ('Pricing Factors')){
            thispage = current_page;
            nextpage = 'Address Collection';
            return(
                <div className='container'>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_onboarding}   
                    />  
                    <CheckBoxesQuestion
                        key={current_page}
                        handleChangeObject = {this.handleChangeObject}
                        nextPage = {this.nextPage}
                        nextpage = {nextpage}
                        pages = {pages}
                        pagetitle = {thispage}
                        showpage = {true}
                        header_question = {`Which of the following factors actively play a part in your current pricing model?`}
                        input_category = {'price_factors'}
                        options = {[
                            {title: 'Stains on concrete', coef: null, flat: null},
                            {title: 'Cracked concrete (length & width)', coef: null, flat: null},
                            {title: 'Travel distance', coef: null},
                            {title: 'Accessibility issues (stairs etc.)', coef: null, flat: null},
                            {title: 'Previous coatings (epoxy, tile, vinyl etc.)', coef: null, flat: null},
                            {title: 'Other', coef: null}
                        ]}
                        values_inputs = {values_inputs}
                    />
                    <ContinueFooter
                        nextPage={this.nextPage}
                        enable = {servicetype_choices}
                        nextpage = {nextpage}
                    />
                </div>
            )
        }
        else if (current_page === 'Address Collection'){
            thispage = current_page;
            nextpage = 'Other Pricing Factors';
            factor_title = "Travel distance";
            return(
                <div className='container'>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_onboarding}   
                    />
                    <MultipleChoiceQuestion
                        key={current_page}
                        handleChangeObjectNext = {this.handleChangeObjectNext}
                        nextPage = {this.nextPage}
                        nextpage = {nextpage}
                        pages = {pages}
                        pagetitle = {thispage}
                        showpage = {findIndexByProperty(price_factors, "title", factor_title) === -1}
                        header_question = {`Although you don't charge extra for travel, would you still like us to ask the customer for their address to collect lead info?`}
                        input_category = {'collect_address'}
                        options = {[{title: 'Yes', value: true}, {title: 'No', value: false}]}
                    />
                </div>
            )
        }
        else if (current_page === 'Other Pricing Factors'){
            thispage = current_page;
            nextpage = 'Travel Costs';
            factor_title = "Other";
            return(
                <div className='container'>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_onboarding}   
                    />
                    <TextInputQuestion
                        key={current_page}
                        handleChange = {this.handleChange}
                        nextPage = {this.nextPage}
                        nextpage = {nextpage}
                        pages = {pages}
                        pagetitle = {thispage}
                        showpage = {findIndexByProperty(price_factors, "title", factor_title) > -1}
                        header_question = {`What other factors (aside from square footage) come into consideration?`}
                        input_category = {'other_pricing_factors'}
                    />
                    <ContinueFooter
                        nextPage={this.nextPage}
                        enable = {true}
                        nextpage = {nextpage}
                    />
                </div>
            )
        }
        else if (current_page === 'Travel Costs'){
            thispage = current_page;
            nextpage = 'Floor Cracking';
            factor_title = "Travel distance";
            console.log(price_factors)
            console.log(findIndexByProperty(price_factors, "title", factor_title))
            return(
                <div className='container'>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_onboarding}   
                    />
                    <SingleColumnSingleFieldInputs
                        key={current_page}
                        customHandleValue = {(value) => {this.handlePriceFactorValue(value, factor_title)}}
                        nextPage = {this.nextPage}
                        nextpage = {nextpage}
                        pages = {pages}
                        pagetitle = {thispage}
                        showpage = {findIndexByProperty(price_factors, "title", factor_title) > -1}
                        header_question = {`How much do you charge per mile of travel from your offices?`}
                        input_category = {'price_factors'}
                        options = {[price_factors[findIndexByProperty(price_factors, "title", factor_title)]]} //TODO, this is giving [undefined] array for options, causing an error.
                        input_type = 'coef'
                    />
                    <ContinueFooter
                        nextPage={this.nextPage}
                        enable = {true}
                        nextpage = {nextpage}
                    />
                </div>
            )
        }
        else if (current_page === 'Floor Cracking'){
            thispage = current_page;
            nextpage = 'Floor Removal Default';
            factor_title = "Cracked concrete (length & width)";
            return(
                <div className='container'>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_onboarding}   
                    />
                    <SingleColumnSingleFieldInputs
                        key={current_page}
                        customHandleValue = {(value) => {this.handlePriceFactorValue(value, factor_title)}}
                        nextPage = {this.nextPage}
                        nextpage = {nextpage}
                        pages = {pages}
                        pagetitle = {thispage}
                        showpage = {findIndexByProperty(price_factors, "title", factor_title) > -1}
                        header_question = {`How much do you charge per linear foot of cracking?`}
                        input_category = {'price_factors'}
                        options = {[price_factors[findIndexByProperty(price_factors, "title", factor_title)]]}
                        input_type = 'coef'
                    />
                    <ContinueFooter
                        nextPage={this.nextPage}
                        enable = {true}
                        nextpage = {nextpage}
                    />
                </div>
            )
        }
        else if (current_page === 'Floor Removal Default'){ //TODO: load default coef values from DB
            thispage = current_page;
            nextpage = 'Quote Range';
            factor_title = "Previous coatings (epoxy, tile, vinyl etc.)";
            return(
                <div className='container'>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_onboarding}   
                    />
                    <SingleColumnSingleFieldInputs
                        key={current_page}
                        customHandleValue = {(value) => {this.handlePriceFactorValue(value, factor_title)}}
                        nextPage = {this.nextPage}
                        nextpage = {nextpage}
                        pages = {pages}
                        pagetitle = {thispage}
                        showpage = {findIndexByProperty(price_factors, "title", factor_title) > -1}
                        header_question = {`The default extra charges per square foot of floor removal are below. You may edit them if you'd like, or click "Continue" to accept them.`}
                        input_category = {'price_factors'}
                        options = {[price_factors[findIndexByProperty(price_factors, "title", factor_title)]]}
                        input_type = 'coef'
                    />
                    <ContinueFooter
                        nextPage={this.nextPage}
                        enable = {true}
                        nextpage = {nextpage}
                    />
                </div>
            )
        } 
        else if (current_page === 'Quote Range'){
            thispage = current_page;
            nextpage = 'Multi-Package';
            return(
                <div className='container'>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_onboarding}   
                    />
                    <MultipleChoiceQuestion
                        key={current_page}
                        handleChangeObjectNext = {this.handleChangeObjectNext}
                        nextPage = {this.nextPage}
                        nextpage = {nextpage}
                        pages = {pages}
                        pagetitle = {thispage}
                        showpage = {true}
                        header_question = {`Would you prefer to offer your customers a price range or an exact price?`}
                        input_category = {'use_ranged_quote'}
                        options = {[{title: 'Range (+/- 5% of the calculated price)', value: true}, {title: 'Exact price', value: false}]}
                    />
                </div>
            )
        }
        else if (current_page === 'Multi-Package'){
            thispage = current_page;
            nextpage = 'Add-ons';
            return(
                <div className='container'>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_onboarding}   
                    />
                    <MultipleChoiceQuestion
                        key={current_page}
                        handleChangeObjectNext = {this.handleChangeObjectNext}
                        nextPage = {this.nextPage}
                        nextpage = {nextpage}
                        pages = {pages}
                        pagetitle = {thispage}
                        showpage = {true}
                        header_question = {`Would you like to display multiple packages on the quote page or one single package with add-on options?`}
                        input_category = {'use_multi_package'}
                        options = {[{title: 'Multiple package options', value: true}, {title: 'Single option only', value: false}]}
                    />
                </div>
            )
        }
        else if (current_page === 'Add-ons'){
            thispage = current_page;
            nextpage = 'Add-on Pricing Types';
            factor_title = "Add-ons";
            return(
                <div className='container'>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_onboarding}   
                    />
                    <SingleColumnSingleFieldInputs
                        key={current_page}
                        //customHandleValue = {(value) => {this.handlePushItemToArray('addon_items', {title: value})}}
                        handleChangeObject = {this.handleChangeObject}
                        nextPage = {this.nextPage}
                        nextpage = {nextpage}
                        pages = {pages}
                        pagetitle = {thispage}
                        showpage = {true}
                        header_question = {`What optional add-ons (or upsells) do you offer your customers?`}
                        header_directions = {`This should only include things that a typical customer is familiar with and can easily picture 
                        (i.e. overhead storage, stair/sidewall coat, cabinetry etc.) You will be able to group them by service area/type later.`}
                        input_category = {'addon_items'}
                        options = {this.state.addon_items}
                        input_type = 'title'
                        allow_additional_options = {true}
                    />
                    <ContinueFooter
                        nextPage={this.nextPage}
                        enable = {true}
                        nextpage = {nextpage}
                    />
                </div>
            )
        }
        else if (current_page === 'Add-on Pricing Types'){
            thispage = current_page;
            nextpage = 'Flat Fee Prices';
            return(
                <div className='container'>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_onboarding}   
                    />
                    <DragDropOptions
                        items={this.state.addon_items}
                        drop_categories={['PerSquareFoot', 'PerLinearFoot', 'FlatFee']}
                        item_bank_name='Add-on Items'
                        header_question = {`Group the add-ons based on their pricing structure.`}
                        handleAddItemToCategory = {this.handleAddItemToArray}
                        handleRemoveItemFromCategory = {this.handleRemoveItemFromArray}
                    />
                    <ContinueFooter
                        nextPage={this.nextPage}
                        enable = {true}
                        nextpage = {nextpage}
                    />
                </div>
            )
        }
        else if (current_page === 'Flat Fee Prices'){
            //const current_finishprice_index = this.getFinishPriceIndex(current_page);
            thispage = current_page;
            nextpage = 'Square Footage Prices';
            return (
                <div className='container'>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_onboarding}   
                    />  
                    <SingleColumnSingleFieldInputs
                        key={current_page}
                        customHandleValue = {(value,item) => this.handleUpdateItemInArray('addon_items', item, 'flat', value)}
                        nextPage = {this.nextPage}
                        nextpage = {nextpage}
                        pages = {pages}
                        pagetitle = {thispage}
                        showpage = {this.state.FlatFee && this.state.FlatFee.length > 0}
                        header_question = {`What is the flat rate price for the following add-ons?`}
                        input_category = {'addon_items'}
                        options = {this.state.FlatFee}
                        input_type = 'flat'
                    />
                    <ContinueFooter
                        nextPage={this.nextPage}
                        enable = {true}
                        nextpage = {nextpage}
                    />
                </div>
            )
        }
        else if (current_page === 'Square Footage Prices'){
            //const current_finishprice_index = this.getFinishPriceIndex(current_page);
            thispage = current_page;
            nextpage = 'Linear Footage Prices';
            return (
                <div className='container'>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_onboarding}   
                    />  
                    <SingleColumnSingleFieldInputs
                        key={current_page}
                        customHandleValue = {(value,item) => this.handleUpdateItemInArray('addon_items', item, 'coef', value)}
                        nextPage = {this.nextPage}
                        nextpage = {nextpage}
                        pages = {pages}
                        pagetitle = {thispage}
                        showpage = {this.state.PerSquareFoot && this.state.PerSquareFoot.length > 0}
                        header_question = {`What is the additional price per square foot for the following add-ons?`}
                        input_category = {'addon_items'}
                        options = {this.state.PerSquareFoot}
                        input_type = 'coef'
                    />
                    <ContinueFooter
                        nextPage={this.nextPage}
                        enable = {true}
                        nextpage = {nextpage}
                    />
                </div>
            )
        }
        else if (current_page === 'Linear Footage Prices'){
            //const current_finishprice_index = this.getFinishPriceIndex(current_page);
            thispage = current_page;
            nextpage = 'Package Creator';
            return (
                <div className='container'>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_onboarding}   
                    />  
                    <SingleColumnSingleFieldInputs
                        key={current_page}
                        customHandleValue = {(value,item) => this.handleUpdateItemInArray('addon_items', item, 'linear', value)}
                        nextPage = {this.nextPage}
                        nextpage = {nextpage}
                        pages = {pages}
                        pagetitle = {thispage}
                        showpage = {this.state.PerLinearFoot && this.state.PerLinearFoot.length > 0}
                        header_question = {`What is the price per linear foot for the following add-ons?`}
                        input_category = {'addon_items'}
                        options = {this.state.PerLinearFoot}
                        input_type = 'linear'
                    />
                    <ContinueFooter
                        nextPage={this.nextPage}
                        enable = {true}
                        nextpage = {nextpage}
                    />
                </div>
            )
        }
        else if (current_page.includes('Package Creator')){
            const parsed_index = this.getServiceTypeIndex(current_page);
            const current_servicetype_index = parsed_index[0];
            const current_servicetype = servicetype_choices[current_servicetype_index];
            thispage = current_page;
            console.log(parsed_index)
            if (parsed_index && parsed_index.length > 1){ //last match found, parsed_index[1] is -1
                nextpage = 'Base Pricing';
            } else if (parsed_index){
                nextpage = 'Package Structure #' + (current_servicetype_index + 1);
            }
            return(
                <div className='container'>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_onboarding}   
                    />
                    <PackageCreator
                        items={this.state.addons}
                        drop_categories={['Basic Package ($)', 'Recommended Package ($$)', 'Deluxe Package ($$$)']}
                        dropped_items={current_servicetype.package_addons}
                        item_bank_name='Add-on Items'
                        header_question = {`Assign which add-ons (if any) you would like included in your ${current_servicetype.title} packages to appear to your customers.`}
                        header_directions = {`Update, duplicate, or remove items until you are satisfied with the package content.`}
                        handleAddItemToCategory = {(arrayname, item) => this.handleUpdateServiceTypePackageAddons(arrayname, item, current_servicetype.title)}
                        handleRemoveItemFromCategory = {(arrayname, item) => this.handleUpdateServiceTypePackageAddons(arrayname, item, current_servicetype.title)}
                    />
                    <ContinueFooter
                        nextPage={this.nextPage}
                        enable = {true}
                        nextpage = {nextpage}
                    />
                </div>
            )
        }

        else{//DEFAULT PAGE
            return (
                <div className='container'>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_onboarding}   
                    />
                    <FontAwesomeIcon 
                        icon={faSyncAlt}
                        class='fa-spin spinner'
                    />
                </div>
            )
        }
    }
}

export default ConcreteOnboardingSurvey;
