import React, { Component } from 'react';

class GrowFromZeroItem extends Component {
  constructor(props) {
    super(props);
    this.state = { 
        isVisible: false,
        item: props.item,
    };
  }

  componentDidMount() {
    // Trigger the fade-in effect after the component mounts with a slight delay
    setTimeout(() => {
      this.setState({ isVisible: true });
    }, 10); // A delay of 10 milliseconds
  }
  

  render() {
    const { isVisible, item } = this.state;
    const { index, onClick } = this.props;
    const container_class = (this.props.container_class) ? this.props.container_class : '';
    const delay_time = (index && typeof index === 'number') ? 100 + (index * 500) : 250; //if key is a number, it is an index, one o multile items. offset animation speeds.
    const animation_delay = `${delay_time}ms`;

    return (
      <div key={index} onClick={onClick} className={`${container_class} grow-from-zero`} style={{ '--grow-animation-delay': animation_delay }}>
        {item}
      </div>
    );
  }
}

export default GrowFromZeroItem;
