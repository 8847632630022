import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';
import Matomo from '../../Matomo';
import { findObjectByProperty } from '../../../Helpers';

//shows the summary of an asphalt quote, broken down by sections
//Example quote object: address
// : 
// undefined
// clicked_locations
// : 
// (13) [{…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}]
// driveway_condition
// : 
// null
// driveway_status
// : 
// null
// jobLatLng
// : 
// {lng: -76.2566488463685, lat: 43.03352821897031}
// jobtype
// : 
// {title: 'Asphalt Sealing', preview: 'https://fq-concrete.s3.us-east-2.amazonaws.com/Job+Previews/Services/polish_ECO_polish.jpg'}
// quoteid
// : 
// "5EE7"
// sealed_before
// : 
// true
// selected_package
// : 
// {save_premium: 21, tally_low: 209, short: 'Apply sealant for surface protection', title: 'Sealing', tally: null, …}
// sqfootage
// : 
// 902.96
// totalquote_exact
// : 
// "209"
// totalquote_shown
// : 
// "209 - 219"

class AsphaltQuoteSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mounted: false,
        };
    }

    componentDidMount() {
        this.setState({mounted: true});
    }

    render() {
        const { quoteInfo } = this.props;
        const sealed_before_text = quoteInfo.sealed_before ? 'Yes' : 'No';
        return (
            <CSSTransition in={this.state.mounted} timeout={300} classNames="fade-fast" unmountOnExit>
                <div>
                    <p className='medium center-horizontal'>Customer Information</p>
                    <p className='small less-margin'>Customer: {quoteInfo.customername}</p>
                    <p className='small less-margin'>Address: {quoteInfo.address}</p>
                    <p className='small less-margin'>Phone Number: {quoteInfo.phone}</p>
                    <p className='small less-margin'>Email: {quoteInfo.email}</p>

                    <p className='medium center-horizontal'>Quote Information</p>
                    <p className='m-small less-margin'>Job Type: <b>{quoteInfo.jobtype.title}</b></p>
                    <p className='small less-margin'>Verified Square Footage: {quoteInfo.sqfootage}</p>
                    <p className='small less-margin highlight-text'>Original Quote Provided to Customer: <b>${quoteInfo.totalquote_shown}</b></p>
                    <p className='small less-margin highlight-text'>Verified & Updated Quote: <b>${quoteInfo.totalquote_exact}</b></p>
                    {/* <p className='small less-margin'>Original Square Footage: {quoteInfo.sqfootage_}</p> */}

                    <p className='medium center-horizontal'>Other Details/Notes:</p>
                    {quoteInfo.driveway_status && <p className='small less-margin'>Driveway Status: {quoteInfo.driveway_status}</p>}
                    {quoteInfo.driveway_condition && <p className='small less-margin'>Driveway Condition: {quoteInfo.driveway_condition}</p>}
                    {quoteInfo.sealed_before !== null && <p className='small less-margin'>Sealed Before: {sealed_before_text}</p>}
                    <p className='small less-margin'>Included Services:</p>
                    <ul>
                    {quoteInfo.selected_package.items.map((item, index) => {
                        return (
                            <li key={index} className='small no-margin'>{item.title}</li>
                        );
                    })}
                    </ul>
                    
                </div>
            </CSSTransition>
        );
    }
}

export default AsphaltQuoteSummary;
