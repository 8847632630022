import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {useParams} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowRight, faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons'
import fairquote_logo from './questions/fairquote_logo.png'
import { CSSTransition } from 'react-transition-group'
import Matomo from './Matomo';
import CollectAddressInputBar from './general_questions/CollectAddressInputBar';
import Loading from './general_questions/components/LoadingIcon';
import { setMobileStyling } from '../Helpers';


export default function ScheduleContact(props) {

    const pagetype = (props.pagetype) ? props.pagetype : 'job'; //can be estimate, call, or job. estimate is passed from inperson estimate, job is default
    const isjob = pagetype === 'job';
    const iscall = pagetype === 'call';
    const isestimate = pagetype === 'estimate';
    const iserror = pagetype === 'error'; //default error page, sent here to call company directly
    const [email, setEmail] = useState(props.values_customer.email);
    const [emailerror, setEmailError] = useState(null);
    const [phone, setPhone] = useState(props.values_customer.phone);
    const [phoneerror, setPhoneError] = useState(null);
    const [customername, setCustomerName] = useState(props.values_customer.customername);
    const [nameerror, setNameError] = useState(null);
    const [address, setCustomerAddress] = useState(props.values_customer.address);
    const [preferredcontact, setPreferredContact] = useState(props.values_customer.preferredcontact);
    const [jobtype, setJobType] = useState((props.values_customer.jobtype && props.values_customer.jobtype.title) ? props.values_customer.jobtype.title : props.values_customer.jobtype);
    const [jobinfo, setJobInfo] = useState();
    const [emailsent, setEmailSent] = useState(false);
    const [mounted, setMounted] = useState(false);
    const [is_mobile_display, setMobileDisplay] = useState(props.ismobile);
    const [showinputflag, setShowInputFlag] = useState(false);
    const [businessid, setBusinessID] = useState(null);
    const [present_for_quote_verification, setPresentForQuoteVerification] = useState(props.values_customer.present_for_quote_verification);

    let {industry, quoteid} = useParams();
    const {email_regex, phone_regex, handleChangeObject, values_customer, showpage, default_nextpage, nextPage} = props;

    let title = "Schedule Call";
    let content = "Let's have a quick call to discuss the details of project and what we can do to help. Let us know the best way to reach you, and we'll get in touch as soon as possible!"
    if (isjob) {
        title = "Schedule Job";
        content = "Ready to schedule? Fill out your contact information and we will get in touch to schedule your job!"
    } else if (isestimate) {
        title = "Schedule Estimate";
        content = "To provide an accurate estimate, we'll need to see your space in person or give you a quick call. Let us know how best to contact you, and we will reach out to complete your quote!"
    } else if (iserror) {
        title = "Sorry, something happened...we're looking into it now!";
        content = `Your time is priceless and we don't want to hold you up while this is fixed.\n\nLet's have a quick call or chat to discuss the details of your project and what we can do to help. How can we best reach you?`
    }
    const enablecheckboxes =  email && email_regex.test(email) && phone && phone_regex.test(phone) && customername && props.values_customer.address;
    const logo = <a href={"https://www.thefairquote.com"} target="_blank" ><img className='logo-description' src = {fairquote_logo} /></a>
    const senticon = <FontAwesomeIcon icon={faArrowRight}/>
    const sched_job_api = props.sched_job_api;
    const send_email_api = props.send_email_api;
    const get_quoteinfo_api = props.get_quoteinfo_api;

    const handleEmail= () => e => {
        const email = e.target.value;
        setEmail(e.target.value);
        console.log(props)
        if(email && email_regex.test(email)){
            handleChangeObject('email', e.target.value)
            setEmailError(null);
        } else if (!email){
            setEmailError(null);
        } else {
            setEmailError('Invalid email');
        }
    };
    const handlePhone= () => e => {
        const phone = e.target.value;
        setPhone(e.target.value);
        if(phone && phone_regex.test(phone)){
            handleChangeObject && handleChangeObject('phone', e.target.value)
            setPhoneError(null);
        } else if (!phone){
            setPhoneError(null);
        } else {
            setPhoneError('Invalid phone number');
        }
    };
    const handleCustomerName= () => e => {
        const name = e.target.value;
        setCustomerName(e.target.value);
        if(name && name.length > 1 && name.trim().split(' ').length >= 2){
            handleChangeObject && handleChangeObject('customername', e.target.value)
            setNameError(null);
        } else if (!name){
            setNameError(null);
        } else {
            setNameError('Invalid name (first & last)');
        }
    };
    const handleCustomerAddress= (input_name, address) => { //input name included to match handleChangeObject
        console.log("handleCustomerAddress")
        console.log(address)
        setCustomerAddress(address);
        if(address){
            handleChangeObject && handleChangeObject('address', address)
        }
    }
    const handlePreferredContact= () => e => {
        setPreferredContact(e.target.value);
        handleChangeObject && handleChangeObject('preferredcontact', e.target.value)
    };

    const handlePresentForQuoteVerification = () => e => {
        setPresentForQuoteVerification(e.target.value);
        handleChangeObject && handleChangeObject('present_for_quote_verification', e.target.value)
    }

    const handleSchedule = async (reason) => {
        if (isjob) {
            //schedule job
            window._paq.push(['trackGoal', 6]);
        } else if (iscall) {
            window._paq.push(['trackGoal', 7]);
        } else if (isestimate) {
            window._paq.push(['trackGoal', 3]);
        } 
        const enablesubmit =  (!emailerror && !phoneerror && !nameerror && email && phone && customername && address && preferredcontact ) ; //iserror doesn't require address
        console.log('enablesubmit', enablesubmit)
        console.log(emailerror, phoneerror, nameerror, preferredcontact, iserror, address);
        if (!enablesubmit){
            setShowInputFlag(true);
            return;
        } else {
            setEmailSent(true);
            const values = {
                id: quoteid,
                industry: industry,
                businessid: businessid,
                pagetype : pagetype,
                customername: customername,
                email: email, 
                phone: phone,
                preferredcontact: preferredcontact,
                streetaddress: address,
                action_type: 'schedule',
                present_for_quote_verification: present_for_quote_verification
            }
            console.log(values);
            if( pagetype === 'job'){
                if (industry === 'concrete' || industry === 'piano-moving'){
                    const response = await axios.patch(
                    sched_job_api, values);
                    console.log(response);
                } else { //default to new method of sending through SendEmailFunction
                    const response = await axios.post(
                        send_email_api, values);
                    console.log(response);
                }
            } else if (pagetype === 'estimate'){
                props.handleScheduleEstimate(values, pagetype)
            } else if (pagetype === 'call' || pagetype === 'error'){
                props.handleScheduleEstimate(values, 'call')
            }
        }
    }


    useEffect(() => {
        /*-Matomo Analytics-*/
        if (isjob) {
            Matomo('scheduleJob-from-email');
        } else if (iscall) {
            Matomo('scheduleCallForm');
        } else if (isestimate) {
            Matomo('scheduleEstimateForm');
        } else {
            //default page format is call
            Matomo('scheduleCallForm');
        }
        /*----End Matomo----*/

        if (showpage === false){ //showpage needs to be explicitly defined in this case
            console.log('skipping ScheduleContact')
            nextPage(default_nextpage, true);
        }

        setMobileStyling(props.ismobile);

        setMounted(true);
        if (isjob) {
            (async () => { //react hooks useEffect async work around..
                const response = await axios.get(
                    get_quoteinfo_api + 'industry=' + industry + '&requested=' + quoteid,
                );
                console.log(response);
                console.log(response.data);
                console.log(typeof(response.data));
                try{
                    const info = response.data;
                    if (info.email) {setEmail(info.email)};
                    if (info.customername) {setCustomerName(info.customername)};
                    if (info.phone) {setPhone(info.phone)};
                    if (info.preferredcontact) {setPreferredContact(info.preferredcontact)}
                    if (info.streetaddress) {setCustomerAddress(info.streetaddress)};
                    if (info.jobtype) {setJobType(info.jobtype.title || info.jobtype)}
                    if (info.businessid) {setBusinessID(info.businessid)};
                    //if (info.address) {setCustomerAddress(info.address)};
                } catch(err) {
                    console.log(err);
                }
                console.log(jobinfo);
            })()
        }
      }, []); //[] runs handleGetQuote only once

    const getUserInputFlag = () => {
        if(showinputflag){
            let initial_prompt = 'Please enter a valid'
            let flag = initial_prompt;
            if (!customername || customername.trim().split(' ').length < 2){
                flag += ' name (first & last)'
            } 
            if (!phone || !phone_regex.test(phone)){
                if( flag.length > initial_prompt.length ){
                    flag += ','
                }
                flag += ' phone (with area code)'
            }
            if (!email || !email_regex.test(email)){
                if( flag.length > initial_prompt.length ){
                    flag += ','
                }
                flag += ' email'
            }
            if (!address){
                if( flag.length > initial_prompt.length ){
                    flag += ','
                }
                flag += ' address'
            }
            if (!preferredcontact){
                if( flag.length > initial_prompt.length ){
                    flag += ','
                }
                flag += ' preferred contact method'
            }
            if (flag.length > initial_prompt.length){
                return flag;
            } else {
                return null;
            }
        }
    }

    const getMiniPlaceholder = (stateval, placeholder, errorMessage) => {
        if(errorMessage) {
          return <p className = {(stateval) ? 'miniplaceholder invalid' : 'miniplaceholder invis'}>{errorMessage}</p>
        } else {
          return <p className = {(stateval) ? 'miniplaceholder' : 'miniplaceholder invis'}>{placeholder}</p>
        }
      }

    const renderForm = () => {
        return(
            <CSSTransition
                in={mounted}
                timeout={1000}
                classNames={is_mobile_display ? "slide-to-left" : "fade"}
                unmountOnExit 
                appear
            >
                <section className={isjob ? "modal-schedule" : "contactform"}>
                    <h2 className='large'>{title} {quoteid} {jobtype && '('+jobtype+')'}</h2>
                    {content}
                    <div className="formwrapper fullpage">
                        <input className= {(!customername) ? 'empty' : '' }
                            type="text"
                            name="name" 
                            autoComplete="name"
                            placeholder= 'Name (First & Last)'
                            value = {customername}
                            alt = 'your first and last name'
                            onChange = {handleCustomerName()}
                            //data-Matomo-mask 
                        />
                        {getMiniPlaceholder(values_customer.customername, 'Name', nameerror)}
                    </div>
                    <div className="formwrapper fullpage">
                        <input className= {(!phone) ? 'empty' : '' }
                            type="tel" 
                            name="tel"
                            autoComplete="on"
                            placeholder= 'Phone (Text-Capable)'
                            value = {phone}
                            alt = 'best phone number to reach you at'
                            onChange= {handlePhone()}
                            //data-Matomo-mask 
                        />
                        {getMiniPlaceholder(values_customer.phone, 'Phone', phoneerror)}
                    </div>
                    <div className="formwrapper fullpage">
                        <input className= {(!email) ? 'empty' : '' }
                            type="email"
                            name="email"
                            autoComplete="on"
                            placeholder= 'Email'
                            value = {email}
                            alt = 'email address to send a copy of your quote to'
                            onChange= {handleEmail()}
                            //data-Matomo-mask 
                        />
                        {getMiniPlaceholder(values_customer.email, 'Email', emailerror)}
                    </div>
                    {!iserror && <CollectAddressInputBar customer_address={address} className='formwrapper fullpage' handleChangeObject={(name, address) => handleCustomerAddress(name, address)} />}
                    {/*<div className="formwrapper fullpage">
                        <input className= {(!address) ? 'empty' : '' }
                            type="text"
                            name="street-address"
                            autoComplete="on"
                            placeholder= 'Street Address'
                            value = {address}
                            alt = 'your street address'
                            onChange= {handleCustomerAddress()}
                            data-Matomo-mask 
                        />
                        <p className = {(address) ? 'miniplaceholder' : 'miniplaceholder invis'}>Street Address</p>
                    </div>*/}
                    <div className="contact" style={{alignSelf:'flex-start', width:'100%'}}>
                        {/* <p className='small invis no-margin'>.</p> */}
                        <p className={'contacttext'}>Preferred method of contact: </p>
                        <div className='boxgroupitem'>
                            <input 
                                className = "checkbox" 
                                type="checkbox" 
                                id="call" 
                                name="contact" 
                                value="Call" 
                                onClick={handlePreferredContact()} 
                                checked={(preferredcontact==="Call")}
                                />
                            <label  for="call">&nbsp;Call</label>
                        </div>
                        <div className='boxgroupitem'>
                            <input 
                                className = "checkbox"
                                type="checkbox" 
                                id="text" 
                                name="contact" 
                                value="Text" 
                                onClick={handlePreferredContact()} 
                                checked={(preferredcontact==="Text")}
                                />
                            <label  for="text">&nbsp;Text</label>
                        </div>
                        <div className='boxgroupitem'>
                            <input     
                                className = "checkbox"
                                type="checkbox" 
                                id="email" 
                                name="contact" 
                                value="Email" 
                                onClick={handlePreferredContact()} 
                                checked={(preferredcontact==="Email")}
                                />
                            <label  for="email">&nbsp;Email</label>
                        </div>
                    </div>
                    {industry === 'asphalt' && isjob &&
                            <div className='options-dropdown column' style={{marginTop:'15px'}}>
                                <label for="stopby"><b>Before scheduling your job</b> we may need to stop by in person to verify your quote measurements and conditions. If so, would you like to be present for this?</label>
                                <select id="stopby" name="stopby" selected={values_customer.present_for_quote_verification} onChange={handlePresentForQuoteVerification()}>
                                    <option id="yes" value="yes" selected={values_customer.present_for_quote_verification === 'yes'}>Yes, contact me to schedule a time</option>
                                    <option id="no" value="no" selected={values_customer.present_for_quote_verification === 'no'}>No, I don't need to be there, stop by whenever</option>
                                </select>
                            </div>}
                    <p className='small invis no-margin'>.</p>
                    <p className='small invalid no-margin'>{getUserInputFlag()}</p>
                    <button
                        className='formsubmitbtn'
                        disabled = {emailsent}
                        onClick = {handleSchedule}
                        >
                        {!emailsent ? "My contact information is correct" : "We will be in touch soon!"}
                        {!emailsent ? senticon : ''}
                    </button>
                    {/*<h1 className='small logo-text center-horizontal'>powered by&nbsp;{logo}&#8482;</h1> */}
                </section>
            </CSSTransition>
        )
    }

    const afterQuoteRedirect = (afterquote_link) => {
        //visit link
        window.location.href = afterquote_link;
    }

    const renderEmailSent = () => {
        const homepage = (props.values_business) ? props.values_business.homepage : null;
        const afterquote_url = (props.values_business) ? props.values_business.afterquote_url : null;

        if (afterquote_url){
            afterQuoteRedirect(afterquote_url)
            return(
                <div className='center'>
                    <Loading/>
                </div>
            )
        } else {
            let main_text = `Someone will be in touch with you as soon as possible!`;
            if (iserror) {
                main_text = `Someone will be in touch with you as soon as possible to discuss your project!`;
            }
            return(
                <section className="modal-schedule">
                        <div className='center'>
                            <h2 className='large'>{main_text}</h2>
                            {isjob ? <p className='small light'>A copy of this quote has also been sent to you through the email you provided ({email})</p> : null }
                            {isjob ? <p className='x-small'>If you don't see a copy of your quote, please make sure to check all inbox folders.</p> : null }
                            {homepage && <p className='small'>Feel free to <a href={homepage} className='clickable'>check out some of our work <FontAwesomeIcon icon={faExternalLinkAlt}/></a></p>}
                        </div>
                        <h1 className='large invis'>.</h1>
                        <h1 className='x-small logo-text center-horizontal'>powered by&nbsp;{logo}&#8482;</h1> 
                </section>
            )
        }
    }

    return (
        <div className='surveywrapper'>
            <div className='contactformwrapper'>
                {(emailsent) ? renderEmailSent() : renderForm()}
            </div>
        </div>
    );
    
}