import React, {Component} from 'react'
import { CSSTransition } from 'react-transition-group';

import { PreviewModal } from './Modals/PreviewModal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons'
import Matomo from '../Matomo'
import { FooterContact } from '../general_questions/FooterContact';
import { capatilizeFirstLetters, findIndexByProperty, findObjectByProperty, sendToInpersons } from '../../Helpers';
import MultipleChoiceText from '../general_questions/MultipleChoiceText';
import DropdownSections from '../general_questions/components/DropdownSections';


export class FinishType extends Component {
    constructor (props) {
        super(props);
        this.state = {
            nextpage : props.next,
            local_finishes: [],
            showmodal : '',
            mounted: false,
            hover_finish: '',
            err: null,
        }
    }

    componentDidMount = () => {
        // /*-Matomo Analytics-*/
        // const title = 'FinishType';
        // Matomo(title);
        // /*----End Matomo----*/
        //console.log(this.props.values_customer.servicetype.Finishes)
        this.setState({ mounted: true });
        
        //Skip to next page if only one option:
        if(this.props.values_customer && this.props.values_customer.servicetype.Finishes.length === 1){
            const event = new Event("finish");
            this.continue(this.props.values_customer.servicetype.Finishes[0], true)(event);
        }
        // this.mergeFinishTypeInfo(); //get descriptions and titles for all buttons
    }

    // // match finishtype info to a coat_option preview icon
    // mergeFinishTypeInfo = () => {
    //     /*get Finish type description, if there is one, from matching Style*/
    //     const {Finishes} = this.props.values_customer.servicetype
    //     const {coat_options} = this.props.values_business;
    //     const {local_finishes} = this.state;
    //     console.log("mergeFinishTypeInfo")
    //     console.log(Finishes)
    //     for (let i = 0; i < Finishes.length; i++){
    //         let finish_title;
    //         let finish_description;
    //         let preview_icon;
    //         for(let j = 0; j < coat_options.length; j++){
    //             const istiered = coat_options[j].sort_type && coat_options[j].sort_type === 'tiered';
    //             if(coat_options[j].style.toLowerCase() === Finishes[i].finish.toLowerCase()){
    //                 if (istiered){
    //                     const preview_tier = coat_options[j].Colors.tier1 || coat_options[j].Colors.tier2 || coat_options[j].Colors.tier3;
    //                     preview_icon = preview_tier[0].icon;
    //                 } else {
    //                     preview_icon = coat_options[j].Colors[0].icon;
    //                 }
    //                 finish_title = coat_options[j].style;
    //                 finish_description = coat_options[j].short;
    //                 console.log("finish_title: " + finish_title)
    //                 let merged_finish_object = findObjectByProperty(Finishes, "finish", finish_title.toLowerCase());
    //                 console.log(merged_finish_object)
    //                 if (merged_finish_object){
    //                     merged_finish_object.description = finish_description;
    //                     merged_finish_object.image_icon = preview_icon;
    //                     merged_finish_object.title = finish_title;
    //                     local_finishes.push(merged_finish_object)
    //                 }
    //             }
    //         }
    //     }
    //     this.setState({local_finishes : local_finishes});
    // }

    continue = (selected_finish, skip=false) => e => {
        const {page_title} = this.props;
        const finishes = this.props.values_customer.servicetype.Finishes;
        const reason = 'Customer requested a finish type that requires an in-person estimate.';
        
        try {
            const is_inperson = sendToInpersons(selected_finish, 'finishtype', this.props.values_customer, this.props.values_business.inpersons, page_title);

            if (is_inperson){
                this.props.handleChangeEstimateReason(reason, null, null, skip);
            }
            else if (!is_inperson && selected_finish && this.state.nextpage){
                //console.log("skipping to next page")
                this.props.handleChangeObjectNext(this.state.nextpage, 'finishtype', selected_finish, skip);
            }
        } catch (error) {
            console.log("error in FinishType.continue")
            this.setState({err: error});
        }
    }

    renderButtonText = (finish) => {
        console.log("Render button text for:");
        console.log(finish);

        return (
            <div className='btn-with-preview' >
                {(finish.preview) ? <img src={finish.preview} alt={finish.title} className='preview' /> : null}
                <p className='title hover m-large' id={finish.title} onClick={(e) => this.showModal()}>{finish.title}</p>
            </div>
        )

        /*if ( ((this.state.hover_finish === finish.title) && finish.description) 
                || (this.props.values_customer.finishtype && (this.props.values_customer.finishtype.finish.toLowerCase() === finish.title.toLowerCase()))
            ){
            return(
                <div className='btn-hover-description' >
                    <img src={finish.preview} alt={finish.title} className='preview' />
                    <p className='title hover m-large main' id={finish.title} >{finish.title}</p>
                    <div className='never-mobile'>
                        {this.renderMultiLineDescription(finish.description)}
                    </div>
                </div>
            )

        } else {
            return (
                <div className='btn-with-preview' >
                    <img src={finish.preview} alt={finish.title} className='preview' />
                    <p className='title hover m-large' id={finish.title} onClick={(e) => this.showModal()}>{finish.title}</p>
                </div>
            )
        }*/
    }

    getFinishesWithInfo = () => {
        console.log("getting finishes with info")
        const {servicetype} = this.props.values_customer;
        const {coat_options} = this.props.values_business;
        let all_finishes = servicetype.Finishes;
        let finishes = [];
        // let styles = [];
        // let styles_text = '';
        // for (let i = 0; i < styles.length; i++){ //get all styles the finish is offered in
        //     if(i === styles.length - 1){
        //         styles_text = epoxy_styles_text + 'and ' + epoxy_styles[i];
        //     } else {
        //         tyles_text = styles_text + styles[i] + ', ';
        //     }
        // }

        const epoxy_info = {
            title: `Epoxy`,
            pros: ['Exceptionally durable for its cost',  'Resistant to water, chemicals, stains, and abrasions', 'Can be customized with various colors, patterns, and textures'],
            cons: ['Can be slippery when wet, if flakes or other texture is not added to application', 'Longer curing time (typically 12-24 hours to walk on, 72 hours for full cure)'],
            best_for: 'Cost-effective and highly versatile solution for garages, basements, and other high-traffic areas around your home or business',
            cost: null
        }
        const quartz_info = {
            title: `Quartz`,
            pros: ['Extremely durable and hard, resistant to scratches, chips, and stains', 'Non-porous, does not require sealing, resistant to bacteria and viruses', 'Low maintenance, easy to clean'],
            cons: ['More expensive than other materials'],
            best_for: 'Areas with a higher frequency of water exposure such as bathrooms, pool decks, kitchen counters, etc.',
            cost: null
        }
        const polyurethane_info = {
            title: `Polyurethane`,
            pros: ['Very high durability, up to 4x as much as traditional epoxy', 'Better UV resistance, prevents yellowing and/or cracking over time', 'Fast cure time'],  
            cons: ['More expensive than other materials'],
            best_for: 'Garages, basements, large commercial applications, high-traffic areas',
            cost: null
        }
        const metallic_info = {
            title: `Metallic`,
            pros: ['Unique and attractive visuals that can mimic the look of natural metal', 'Can be applied to various surfaces, including floors, walls, and furniture', 'Reflective quality enhances brightness and feel of a space'],
            cons: ['More expensive than standard finishes', 'Wear and tear may be more visible, requiring careful maintenance'],
            best_for: 'Modern homes, high-end applications',
            cost: null
        }
        const eco_polish_info = {
            title: `Eco-Polish`,
            pros: ['Environmentally friendly, no harsh chemicals', 'Cost effective & resistant to stains and abrasions', 'Easy to clean and low maintance between re-applications'],
            cons: ['Though still durable, other polishing methods can be even more so'],
            best_for: 'Commercial applications, high-traffic areas, eco-conscious customers',
            cost: null
        }
        const mechanical_polish_info = {
            title: `Mechanical Polish`,
            pros: ['Hardens & densifies the concrete surface for a highly durable, long-lasting finish', 'Low maintenance, easy to clean', 'Can be customized with various levels of gloss', 'Higher abrasion and moisture resistence than other finishes'],
            cons: ['Higher upfront cost than other finishes, though long-term cost can be much lower due to durability', 'More inovolved installation process'],
            best_for: 'Commercial applications, high-traffic areas, customers looking for a long-lasting finish',
            cost: null
        }
        const microtopping_info = {
            title: `Microtopping`,
            pros: ['Highly customizable, can be applied in a variety of colors and textures', 'Can be applied over existing surfaces, reducing waste and cost', 'Durable and long-lasting, resistant to stains and abrasions'],
            cons: ['May require re-application every 5-10 years'],
            best_for: 'Indoor and outdoor applications, residential and commercial',
            cost: null
        }
        const acrylic_stain_info = {
            title: `Acrylic`,
            pros: ['Highly versatile, can be used in a variety of applications', 'Non-porous, resistant to stains and bacteria growth', 'Repairable, minor scratches and damages can be easily repaired', 'Adds a high-gloss or wet look that makes the color and texture more vibrant'],
            cons: ['More prone to scratches and heat damage', 'May not be as durable as other materials in the long term'],
            best_for: 'Indoor and outdoor applications where a high-gloss finish is desired',
            cost: null
        }



        //add key and info to each finish
        for (let i = 0; i < all_finishes.length; i++){
            console.log(all_finishes[i])
            let finish = {} 
            if(all_finishes[i].finish.toLowerCase() === 'epoxy' && findIndexByProperty(finishes, 'key', 'epoxy') === -1){ //if epoxy finish and not already added
                finish.styles = all_finishes[i].styles;
                finish.key = 'epoxy'
                finish.title = 'Epoxy'
                finish.info = epoxy_info;
                finishes.push(finish);
            }
            else if(all_finishes[i].finish.toLowerCase() === 'quartz'){
                finish.styles = all_finishes[i].styles;
                finish.key = 'quartz'
                finish.title = 'Quartz'
                finish.info = quartz_info;
                finishes.push(finish)
            }
            else if(all_finishes[i].finish.toLowerCase() === 'acrylic'){
                finish.styles = all_finishes[i].styles;
                finish.key = 'acrylic'
                finish.title = 'Acrylic'
                finish.info = acrylic_stain_info;
                finishes.push(finish)
            }
            else if(all_finishes[i].finish.toLowerCase() === 'polyurethane'){
                finish.styles = all_finishes[i].styles;
                finish.key = 'polyurethane'
                finish.title = 'Polyurethane'
                finish.info = polyurethane_info;
                finishes.push(finish)
            }
            else if(all_finishes[i].finish.toLowerCase() === 'metallic'){
                finish.styles = all_finishes[i].styles;
                finish.key = 'metallic'
                finish.title = 'Metallic'
                finish.info = metallic_info;
                finishes.push(finish)
            }
            else if(all_finishes[i].finish.toLowerCase() === 'eco polish'){
                finish.styles = all_finishes[i].styles;
                finish.key = 'eco polish'
                finish.title = 'Eco Polish'
                finish.info = eco_polish_info;
                finishes.push(finish)
            }
            else if(all_finishes[i].finish.toLowerCase() === 'mechanical polish'){
                finish.styles = all_finishes[i].styles;
                finish.key = 'mechanical polish'
                finish.title = 'Mechanical Polish'
                finish.info = mechanical_polish_info;
                finishes.push(finish)
            }
            else if(all_finishes[i].finish.toLowerCase() === 'microtopping'){
                finish.styles = all_finishes[i].styles;
                finish.key = 'microtopping'
                finish.title = 'Microtopping'
                finish.info = microtopping_info;
                finishes.push(finish)
            }
            else if(all_finishes[i].finish.toLowerCase() === 'acrylic stain'){
                finish.styles = all_finishes[i].styles;
                finish.key = 'acrylic stain'
                finish.title = 'Acrylic Stain'
                finish.info = acrylic_stain_info;
                finishes.push(finish)
            } else{ //default, usually a custom finsih like "diy epoxy" for hitechcoatings
                console.log("unique finish found, initializing with default values....")
                finish.styles = all_finishes[i].styles;
                finish.key = all_finishes[i].finish;
                finish.title = all_finishes[i].finish;
                finish.info = {
                    title: capatilizeFirstLetters(all_finishes[i].finish),
                    pros: [],
                    cons: [],
                    best_for: null,
                    cost: null
                }
                finishes.push(finish)
            }

        }

        //of applicable finishes, label most and least expensive based on coat_options
        let most_expensive = null;
        let least_expensive = null;
        for (let i = 0; i < finishes.length; i++){
            console.log(finishes[i])
            let finish_title = finishes[i].title.toLowerCase();
            const current_finish = findObjectByProperty(all_finishes, 'finish', finish_title, false); 
            const stylename = current_finish.styles[0];
            const current_coat = findObjectByProperty(coat_options, 'style', stylename, false);

            console.log(current_coat)
            if(current_coat){
                if(most_expensive === null && current_coat.coef !== null){
                    most_expensive = current_coat;
                    least_expensive = current_coat;
                } else {
                    if(current_coat.coef > most_expensive.coef){
                        most_expensive = current_coat;
                    }
                    if(current_coat.coef< least_expensive.coef){
                        least_expensive = current_coat;
                    }
                }
            }
            console.log("Most expensive", most_expensive)
            console.log("Least expensive",least_expensive)
        }

        for (let i = 0; i < finishes.length; i++){
            // if(finishes[i].key === 'epoxy'){
            //     key = 'flake';
            // }
            if(finishes[i].styles.includes(most_expensive.style.toLowerCase())){
                finishes[i].info.cost = '$$$ - Highest cost option';
            }
            else if(finishes[i].styles.includes(least_expensive.style.toLowerCase())){
                finishes[i].info.cost = '$ - Lowest cost option';
            }
            else if(finishes[i].info.cost === null){
                finishes[i].info.cost = '$$';
            } 
        }

        //sort finishes by cost, $ - Lowest cost option then $ then $$ then $$$ then $$$$ - Highest cost option
        finishes.sort((a, b) => {
            if(a.info && a.info.cost === '$ - Lowest cost option'){
                return -1;
            }
            if(b.info && b.info.cost === '$ - Lowest cost option'){
                return 1;
            }
            if(a.info && a.info.cost === '$$'){
                return -1;
            }
            if(b.info && b.info.cost === '$$'){
                return 1;
            }
            if(a.info && a.info.cost === '$$$ - Highest cost option'){
                return 1;
            }
            if(b.info && b.info.cost === '$$$ - Highest cost option'){
                return -1;
            }
            return 0;
        })

        return finishes;
    }

    getFinishEducationComponent = () => {
        const {coat_options} = this.props.values_business;
        const {servicetype} = this.props.values_customer;
        const {is_mobile_display, handleChangeObjectNext} = this.props;
        const all_finishes = this.props.values_customer.servicetype.Finishes;
        let finishes = this.getFinishesWithInfo();
        let education = [];
        console.log(finishes)
        for (let i = 0; i < finishes.length; i++){
            let title= finishes[i].title;
            let finish_styles = findObjectByProperty(servicetype.Finishes, 'finish', title, false).styles;
            const coats = findObjectByProperty(coat_options, "style", finish_styles[0], false);
            let image_icon;
            console.log(coats)
            if (coats.sort_type === 'tiered'){
                image_icon = coats.Colors.tier1[0].icon;
            } else {
                image_icon = coats.Colors[0].icon
            }
            console.log(image_icon)
            // console.log(image_icon.Colors)
            // console.log(image_icon.Colors[0])
            let dropdown_section =  //reformat for DropdownSections
                [
                    // {
                    //     title: finishes[i].title,
                    //     short: 'Best For',
                    //     text: finishes[i].info.best_for,
                    //     key: "Best For " + finishes[i].title
                    // },
                    {
                        short: 'Cost',
                        text: finishes[i].info.cost,
                        key: "Cost " + finishes[i].title
                    },
                    {
                        short: 'Pros',
                        text: finishes[i].info.pros,
                        key: "Pros " + finishes[i].title
                    },
                    {
                        short: 'Cons',
                        text: finishes[i].info.cons,
                        key: "Cons " + finishes[i].title
                    }
                ]
            const finish_object = findObjectByProperty(all_finishes, 'finish', finishes[i].title, false);
            console.log("finish_object", finish_object)
            education.push(
                <div className='dropdownsectionwrapper' style={{ textAlign:'left'}} key={finishes[i].key}>
                    <div className='row flex-start'>
                         {image_icon &&<div className='color-icon no-click' style={{marginRight:'20px'}}>
                            <img src={image_icon} alt={finishes[i].title} className='preview' />
                        </div>}
                        <div className='column' style={{maxWidth: '80%'}} onClick={this.continue(finish_object)}>
                            <h3 className='medium'>{finishes[i].title}</h3>
                            <div className='choose-finishtype-btn'>
                                <p className='small no-margin'>Continue with {finishes[i].title}</p>
                            </div>
                        </div>
                        <div className='column' style={{marginTop:'10px', width: '100%'}}>
                            <p className={is_mobile_display ? 'medium less-margin' : 'm-small less-margin'}>Best For: <p className='light inline'>{finishes[i].info.best_for}</p></p>
                                <DropdownSections
                                    info={dropdown_section}
                                />
                        </div>
                    </div>
                </div>
            )
        }
        return education;
    }

    render() { 
        const { values_customer, values_business, is_mobile_display, handleChangeEstimateReason, handleChangeObjectNext, nextPage, next} = this.props;
        const { local_finishes } = this.state;
        console.log(local_finishes)
        if (this.state.err) throw this.state.err;
        return (
            <div className='surveywrapper'>
                <div className='multiplechoice-transitionbuffer'>
                    <CSSTransition
                        in={this.state.mounted}
                        timeout={1000}
                        classNames="fade"
                        unmountOnExit 
                        appear
                    >
                        <div className='multiplechoice no-position-absolute'>
                            <div className='textwrapper'>
                                <h2 className='large'> What final finish type would you like done for your {values_customer.areatype.title}?</h2>
                            </div>
                            {this.getFinishEducationComponent()}
                            <div className='textwrapper'>
                                <h2 className='large invis'>.</h2>
                            </div>
                        </div>
                    </CSSTransition>
                    <FooterContact values_business={values_business} phone={values_customer.branch.phone}/>
                </div>
            </div>
            // <MultipleChoiceText
            //     key={local_finishes}
            //     page_title = {'Finishes'}
            //     handleChangeObjectNext={handleChangeObjectNext}
            //     handleChangeEstimateReason={handleChangeEstimateReason}
            //     showpage = {true}
            //     nextPage={nextPage}
            //     default_next = {next}
            //     header_question = {`What final finish would you like done for your ${values_customer.areatype.title}?`}
            //     default_state_var = {'finishtype'}
            //     choices = {local_finishes.map((finish) => (
            //         {title: finish.title,
            //             value: findObjectByProperty(local_finishes, "title", finish.title),
            //             state_var: "finishtype",
            //             image_icon: findObjectByProperty(local_finishes, "title", finish.title).image_icon
            //         }
            //     ))}
            //     values_customer={values_customer} 
            //     values_business={values_business}
            //     is_mobile_display={is_mobile_display}
            // />
        )
    }
}

export default FinishType;