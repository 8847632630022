import { useState, useMemo, useEffect } from "react";
import { GoogleMap, useLoadScript, Marker, DistanceMatrixService } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSyncAlt, faArrowRight, faTimes} from '@fortawesome/free-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';
import Matomo from "../Matomo";
import { sendToInpersons } from "../../Helpers";

  // // Define libraries array outside the component to prevent re-creation on each render
  // const libraries = ["places"];

export default function Places(props) {

  // const { isLoaded } = useLoadScript({
  //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  //   libraries,
  // });

  useEffect(() => {

    /*-Matomo Analytics-*/
    const title = 'CollectAddress';
    Matomo(title);
    /*----End Matomo----*/

    //Skip to next page:
    if(!props.showpage){
      console.log('skipping CollectAddress')
      props.nextPage(props.next, true);
    }

  }, []);

  // if (!isLoaded) return <FontAwesomeIcon 
  //                         icon={faSyncAlt}
  //                         class='fa-spin spinner'
  //                         />

  return (
      <Map 
        title = {props.title}
        nextPage = {props.nextPage}
        nextpage = {props.next}
        handleChangeNext = {props.handleChangeNext}
        handleChangeObject = {props.handleChangeObject}
        handleUpdateDistance = {props.handleUpdateDistance}
        values_customer = {props.values_customer}
        values_business = {props.values_business}
        is_mobile_display = {props.is_mobile_display}
        custom_header = {props.custom_header}
        destination_state_var = {props.destination_state_var} // user inputted address
        source_state_val = {props.source_state_val} // origin address to use for distance calculation
        distance_mode = {props.distance_mode}
      />
  )

}



function Map(props) {
  const {nextpage, handleChangeNext, handleChangeObject, handleUpdateDistance, checkInpersons, nextPage, values_customer, values_business, is_mobile_display, destination_state_var, source_state_val, distance_mode} = props;
  
  const [center, setCenter] = useState(values_customer.jobLatLng || values_customer.branch.hq_coord || { lat: 47.7180, lng: -116.9516 }); //default to Coeur d'Alene, ID
  const [selected, setSelected] = useState(null); //lat lng
  const [mounted, setMounted] = useState(false);
  const [address, setAddress] = useState(props.values_customer[props.destination_state_var] || '');
  const [zoom, setZoom] = useState(10);
  const [distance, setDistance] = useState(null); // in miles or minutes
  const [errorMessage, setErrorMessage] = useState(null);
  const addressRegex = (values_business.get_specific_address) ? /^\d.*?([^,]+,){3,}[^,]+$/
  : /.*/; //at least 3 commas (more than just city, state, country), and starts with digit
  console.log('regex: ',addressRegex)
  let title;
  if (props.custom_header){
    title = props.custom_header;
  } else if (values_business && values_business.get_specific_address) {
    title = "What is the address of this job?"
  } else {
    title = "What city is the job located in?";
  }

  useEffect(() => {
    // Initialize jobLatLng on component mount
    initJobLatLng(props);
    // Track changes to jobLatLng and update center accordingly
    if (values_customer.jobLatLng && (values_customer.jobLatLng.lat !== center.lat || values_customer.jobLatLng.lng !== center.lng)) {
      setCenter(values_customer.jobLatLng);
    }
    // Re-run effect if values_customer.jobLatLng changes
  }, [values_customer.jobLatLng, props]);

  async function initJobLatLng(props) { //edge case: if address is already set, but jobLatLng is not, then find it based on address
    const {values_customer, handleChangeObject} = props;
      //if address is already set, but jobLatLng is not, then find it based on address
      if(!values_customer.jobLatLng || values_customer.jobLatLng.lat === null || values_customer.jobLatLng.lng === null){
        const address = values_customer[props.destination_state_var];
        if(address){
          const results = await getGeocode({ address });
          const { lat, lng } = await getLatLng(results[0]);
          handleChangeObject('jobLatLng', { lat, lng });
        }
      }
  }

  function calculateDistance(response) {
    if(response.rows[0].elements[0].status !== "OK"){
      setDistance('Unable to calculate distance.')
      return;
    }
    if(!addressRegex.test(address)){ // if address is not valid, set error message and return before updating distance
      setErrorMessage("Please enter a valid address (including street number)");
      return;
    } else {
      setErrorMessage(null);
    }
    console.log(response)
    const { distance, duration } = response.rows[0].elements[0];
    const meters_in_a_mile = 1609.344;
    console.log("Distance (km):", distance)
    console.log("Duration:", duration)

    const miles = distance.value / meters_in_a_mile; // convert meters to miles
    const minutes = duration.value / 60; // convert seconds to minutes

    if(distance_mode === 'minutes'){
      console.log("Duration calculated: " + minutes);
      setDistance(parseFloat(minutes.toFixed(2)));
      return;
    } else {
      console.log("Distance calculated: " + miles);
      setDistance(miles);
    }
  }

  // async function getLatLng(address){
  //   const results = await getGeocode(address);
  //   const { lat, lng } = await getLatLng(results[0]);
  //   return {lat, lng};
  // }

  function renderGoogleMap(placement) { //mobile or desktop
    console.log('getGoogleMap: '+placement+ ' '+is_mobile_display)
    const map_element =
      <GoogleMap
        zoom={zoom}
        center={selected || center}
        mapContainerClassName="mapwrapper"
      >
        {selected && <Marker position={selected} />}
      </GoogleMap>

      if(placement === 'mobile' && is_mobile_display){
        return map_element;
      } else if (placement === 'desktop' && !is_mobile_display){
        return map_element;
      }
  }

  function handleUpdateParent(e){
    const should_update_parent = (address);
    let should_send_to_inpersons = false;
    console.log("should update: " + should_update_parent)
    if(should_update_parent){
      handleUpdateDistance('travel_distance', distance, address); //address is optional third paramter that is used in PainoSurvey to calculate return trip distance
      if (selected){
        console.log(selected)
        handleChangeObject('jobLatLng', {lat: selected.lat, lng: selected.lng});
      }
      // if (checkInpersons){
      //   should_send_to_inpersons = checkInpersons(); //check if in person quote is needed, after distance is updated
      //   if (should_send_to_inpersons){
      //     handleChangeEstimateReason('inperson');
      //   }
      // }
      handleChangeNext(nextpage, destination_state_var)(e);
    } else {
      nextPage(nextpage)
    }
  }

  useEffect(() => {
    setMounted(true);
  }, []);

  //console.log('address: '+address)
  //console.log('source_state_val: '+source_state_val)

  console.log(addressRegex.test(address))

  return (
    <div className ='surveywrapper'>
      {renderGoogleMap('desktop')}
      <CSSTransition
        in={mounted}
        timeout={1000}
        classNames={is_mobile_display ? "fade" : "slide-to-left"}
        unmountOnExit 
        appear
        >
          <div className='questionwrapper'>
            <section className="collect-email">
              <h2 className='large'>{title}</h2>
              {props.values_business.get_specific_address && <p className='description m-small'>This is used to calculate travel costs.</p>}
              <PlacesAutocomplete address={address} setSelected={setSelected} setZoom={setZoom} setAddress={setAddress} setErrorMessage={setErrorMessage}/>
            </section>
            {errorMessage && <div className="invalid no margin">{errorMessage}</div>}
            <button type='button' 
                disabled = { !address || !addressRegex.test(address) || (values_business.industry === 'piano-moving' && distance === null)}
                class='formsubmitbtn'
                id={address}
                onClick={(e) => handleUpdateParent(e)}
                >
                Continue
                {(address) ? 
                    <FontAwesomeIcon 
                        icon={faArrowRight}
                    /> 
                    : ''
                }
              </button>
            {selected && <Marker position={selected} />}
            {address && source_state_val && <DistanceMatrixService  //update on change of address
              options={{
                destinations: [address],
                origins: [source_state_val],
                travelMode: "DRIVING",
              }}
              callback={(response) => {
                calculateDistance(response)
              }}  
            />}
            {renderGoogleMap('mobile')}
          </div>
        </CSSTransition>
        
      </div>
  );
}

const PlacesAutocomplete = ({ address, setSelected, setZoom, setAddress, setErrorMessage }) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete(
    {
      requestOptions: {
        // Restrict the types of place predictions to return only addresses
        types: ['address'],
      },
      debounce: 150,
    }
  );

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();
    //console.log(address)
    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    // Extract postal code from the address components
    const postalCode = results[0].address_components.find(component => component.types.includes("postal_code"));
    const fullAddressWithPostal = `${address}, ${postalCode ? postalCode.long_name : ''}`;
    console.log(fullAddressWithPostal);
    setAddress(fullAddressWithPostal);
    setSelected({ lat, lng });
    setZoom(18);
    //setAddress(address);
  };

  function clearAddress() {
    setAddress('');
    setSelected(null);
    setZoom(10);
    setValue('');
    setErrorMessage(null);
    //handleChangeObject('address', '');
  }

  return (
    <div className="formwrapper google-maps">
      <Combobox onSelect={handleSelect} data-matomo-unmask >
        <ComboboxInput
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={!ready}
          className="combobox-input"
          placeholder={address || "Search an address"}
        />
        <ComboboxPopover>
          <ComboboxList>
            {status === "OK" && 
              data.map(({ place_id, description }) => (
                <ComboboxOption key={place_id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
      <div className="clear">
        <FontAwesomeIcon 
            icon={faTimes} 
            className=".clear"
            onClick={()=> clearAddress()}
          />
      </div>
    </div>
  );
};