//modal that interfaces with custom GPT assistant, with prompts for user input
//components: GPTmodal, GPTQuestionPrompts

import React, { Component } from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import { CSSTransition } from 'react-transition-group';
import Matomo from '../../Matomo';
import GPTChatSession from '../components/GPTChatSession';

class GPTModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            mounted: false,
        };
    }

    async componentDidMount() {
        /*-Matomo Analytics-*/
        const title = 'GPTabout';
        await Matomo(title);
        /*----End Matomo----*/
        this.setState({mounted: true})
    }

    //before unmounting, set mounted to false so that the modal fades out
    componentWillUnmount() {
        this.setState({mounted: false})
    }

    handleShow = () => {
        this.setState({ show: true });
    };

    render() {
        const {handleClose, company_name, industry} = this.props;
        return (
            <CSSTransition in={this.state.mounted} timeout={300} classNames="fade-fast" unmountOnExit>
            <div className={this.props.displayclass}>
                <section className="modal-main">
                    <div className="right">
                        <div className="close-modal-icon" onClick={handleClose}>
                            <FontAwesomeIcon 
                                className='large no-margin'
                                icon={faTimes}
                                color='black'
                            />
                        </div>
                    </div>
                    <GPTChatSession prompts={this.props.prompts} intro_query={this.props.intro_query} company_name={company_name} industry={industry}/>
                </section>
            </div>
            </CSSTransition>
        );
    }
}

export default GPTModal;
