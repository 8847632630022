import React, {Component} from 'react'

//props: values_customer, state_var, choice, handleClick, showModal

export class ButtonWithDescription extends Component {

    constructor (props) {
        super(props);
        this.state = {
            hover_btn: '',
        }
    }

    getButtonActivity = val =>{
        var saved = this.props.values_customer[this.props.state_var];
        if (saved===val && saved !== undefined) {
            return 'rowbtn rowbtn-with-note active';
        }
        return 'rowbtn rowbtn-with-note';
    }

    getButtonText = (choice) => {
        const show_info = ((choice.value === this.state.hover_btn) && choice.info);

        if(choice.description && !show_info){
            const btn_note = <div className='rowbtn-with-note'>
                                <p className= 'rowbtn-with-note note' >&nbsp;</p>
                                <p className= 'rowbtn-with-note main' >{choice.title}</p>
                                <p className= 'rowbtn-with-note note' >{choice.description}</p>
                            </div>
            return btn_note;
        } else if (show_info){
            return(
                <div className='btn-hover-description' >
                    <p className='title hover medium' id={choice} >{choice.title}</p>
                    {this.renderMultiLineDescription(choice.info)}
                </div>
            )
        } else { 
            return choice.title;
        }
    }

    renderMultiLineDescription = (desc) => { //break *semicolon* description into multiple html lines
        let multilined = [];
        for(let i = 0; i < desc.length; i++){
            if (desc.charAt(i) === ';' || i === 0){
                if(desc.charAt(i) === ';'){
                    i = i+1;
                }
                for(let j = i; j < desc.length; j++){
                    if(desc.charAt(j) === ';' || j === desc.length - 1){
                        if(j === desc.length - 1){
                            multilined.push(desc.substring(i,desc.length));
                        } else {
                            multilined.push(desc.substring(i,j));
                        }
                        i=j+1;
                    }
                }
            }
        }
        
        if(!this.props.is_mobile_display){
            return multilined.map((line)  => (
                <p className='description x-small no-margin'>&#8226; {line}</p>
            ))     
        }
    }

    setHoverButton = (title) => {
        this.setState({ hover_btn: title });
    }

    render() { 
        const {choice, handleClick, showModal, is_mobile_display} = this.props;
        return (
            <button
                key={choice.value}
                type='button' 
                className={this.getButtonActivity(choice.value)}
                id={choice.value}
                onClick={handleClick}
                onMouseEnter={() => this.setHoverButton(choice.value)}
                onMouseLeave={() => this.setHoverButton('')}>
                
                {this.getButtonText(choice)}
            </button> 
        )
    }
}

export default ButtonWithDescription;