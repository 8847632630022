import React, { Component } from 'react';

class FadeInItem extends Component {
  constructor(props) {
    super(props);
    this.state = { 
        item: props.item,
        key: props.key,
    };
  }

  componentDidMount() {
    // Trigger the fade-in effect after the component mounts with a slight delay
    // setTimeout(() => {
    //   this.setState({ isVisible: true });
    // }, 10); // A delay of 10 milliseconds
  }
  

  render() {
    const { isVisible, item, key } = this.state;
    const { animation_delay, fade_duration, extra_class } = this.props;
    const delay_time = (animation_delay) ? `${animation_delay}ms` : '0ms';
    const duration_time = (fade_duration) ? `${fade_duration}ms` : '1.5s';
    return (
      <div key={key} className={`fade-in ` + extra_class} style={{ '--fade-in-animation-delay': delay_time, '--fade-duration': duration_time }}>
        {item}
      </div>
    );
  }
}

export default FadeInItem;
