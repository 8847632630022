import React, {Component} from 'react';
import {AboutModal} from './Modals/AboutModal'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight, faComments, faComment} from '@fortawesome/free-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';
import { FooterContact } from '../general_questions/FooterContact';
import GPTModal from '../general_questions/modals/GPTmodal'
import AI_Assistant_Icon from '../general_questions/images/J.png'
import garagePic from './garagefloor.jpg'
import Matomo from '../Matomo.js';
import {ChatCallToAction} from '../general_questions/components/ChatCallToAction.js';

export class SquareFootage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showmodal : false,
            next : props.next,
            mounted: false
        }    
        this.getButtonActivity = this.getButtonActivity.bind(this);
        this.continue = this.continue.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleEnterKey = this.handleEnterKey.bind(this);
    }

    componentDidMount = () => {
        /*-Matomo Analytics-*/
        const title = 'SquareFootageGarage';
        Matomo(title);
        /*----End Matomo----*/

        //Skip to striping page if servicetype is striping:
        if(this.props.values_customer && this.props.values_customer.servicetype.service.toLowerCase().includes('striping')){
            const event = new Event("emptyevent");
            this.continue("Striping", true)(event);
        }

        this.setState({ mounted: true });
        //console.log(this.props.values_business.addon_items[0].Areas["garage"])
        //addon[this.props.values_customer.areatype.title.toLowerCase()][pack]

    }

    componentWillUnmount = () => {
        this.setState({ mounted: false });
    }


    showModal = () => {
        document.body.style.overflow = 'hidden';
        this.setState({ showmodal: true });
    };
    
    hideModal = () => {
        document.body.style.overflow = 'auto';
        this.setState({ showmodal: false });
    };

    continue = (next, skip = false) => e =>{
        e.preventDefault();
        this.props.nextPage(next, skip);
    };

    handleEnterKey = e => {
        console.log("handleEnterKey")
        console.log(e)
        if (e && e.key.toLowerCase() === 'enter') {
            console.log("enter key pressed")
            this.props.nextPage(this.state.next);
        }
    }

    getButtonActivity = val =>{
        var saved = this.props.values_customer.knowssqfootage;
        if (saved===val) {
            return 'columnbtn active';
        }
        return 'columnbtn';
    };

    getCoverImg = () => {
        /*switch(this.props.values_customer.areatype.title.toLowerCase()){
            case "garage":
                return garagePic;
            case "commercial space":
                return commercialPic;
            case "pool deck":
                return poolDeckPic;
            case "home exterior":
            case "exterior project":
                return homeExteriorPic;
            case "home interior":
            case "interior project":
                return homeInteriorPic;
            default:
                return garagePic;
        }*/
        if (this.props.values_customer.servicetype && this.props.values_customer.servicetype.preview){
            return this.props.values_customer.servicetype.preview;
        }
        else {
            return garagePic;
        }
    }


    /*checkInput = input => e =>{
        e.preventDefault();
        if (isNaN(parseInt(input))){
            alert({input} + ' is not a number')
        } else {
            this.props.handleChange('sqfootage')
        }
    }*/


    render() { 
        const { handleChange, handleChangeEstimateReason, values_customer, values_business, is_mobile_display } = this.props;
        const garage = 'Garage Size';
        const schedule = 'Schedule Estimate';
        const reason = 'Customer did not know/was unable to measure the square footage of the job area.';
        let shouldDisplay = values_customer.knowssqfootage;
        let showWarning = (values_customer.knowssqfootage.toLowerCase() === 'false')
        const faq_prompts = [
            {   
                display_text: `How do I measure an irregularly shaped space?`,
                gpt_query: `How do I measure an irregularly shaped space?`,
            },
            {
                display_text: `Why is square footage needed to get a quote?`,
                gpt_query: `Why do you need to know the square footage to give me a quote?`,
            },
            {
                display_text: `How accurate does my measurement need to be for the quote?`,
                gpt_query: `How accurate does my square footage measurement need to be for the quote?`,
            }
        ]

        const aboutthis = [
            
            {title: 'How do I find the square footage?', text: <p className="small spaced light">Square 
            footage is calculated by multiplying an object’s width by its length. If you have a tape measure this is pretty straightforward. To 
            find the square footage of your space, have someone hold a tape measure against the wall on one end of the garage while you walk 
            to the opposite wall. This measurement will be the “width”. Next, do the same thing with the other two walls and record your 
            measurement. <a href="https://www.thecalculatorsite.com/misc/square-footage-calculator.php" target='_blank' rel="noopener noreferrer" className='clickable underline'>
            This website</a> has a calculator that makes finding the square footage from two measurements quite simple.</p>},

            {title: 'How do I measure an irregularly shaped space?', text: <p className="small spaced light">If you have a mostly square or rectangular concrete area, but 
            a pool or other surface you don't want coated within that space, first measure & calculate the total area of the general space. Then calculate or find the area of the pool or other non-concrete
             surface. Finally, subtract the area of the non-concrete surface from the total area to give you the area of the actual square footage that will be coated.<br/><br/>
             Tip: your measurement only needs to be as accurate as you'd like your estimate to be, so if you have a pretty good idea of the project size, you are free to input that 
            to receive your instant quote, and have us verify the measurements in-person before starting any work. For more complex areas, or if you have any questions, you may choose to have someone come out 
            in person to measure for you by selecting that option instead. </p>},
            
            {title: 'Why is square footage needed to get a quote?', text: <p className="small spaced light">Square footage will let us know the total surface area of your space. Since a
            concrete coating is applied to the whole floor, a good estimate for surface area is critical in providing an accurate quote. If you don’t 
            know the square footage or just don’t have the time to measure it, no worries, just select “No, I don’t have the square footage” 
            and we can get a rough estimate based on how many cars fit in your garage. </p>},
        ]

        const renderNumInput = () => {
            var prompt = 'Enter it now...'
            var input = this.props.values_customer.sqfootage;
            if (input !== 0 && input !== null){
                prompt = input;
            }
            if (shouldDisplay === 'true') {
                return(
                    <div className="inputwrapper">
                        <input 
                            type="number" 
                            placeholder= {prompt}
                            alt = 'square footage of workspace'
                            max = '999999'
                            onChange= {handleChange('sqfootage')}
                        />
                        <button
                            disabled = {!values_customer.sqfootage}
                            onKeyDown={this.handleEnterKey}//TODO: meant to submit on enter key
                            onClick={this.continue(this.state.next)}>
                                <FontAwesomeIcon 
                                icon={faArrowRight}
                                size='lg'
                                />
                        </button>
                        <p>sq ft</p>
                    </div>
                )
            } else {
                return null;
            }
        };
    
        const getWarning = () => {
            const isgarage = (values_customer.areatype.title.toLowerCase() === 'garage')
            const garage_warning = <p className='small spaced left-complete'>We can still provide you a close estimate, but our reps will need to take measurements of your space to give an exact quote, which may be slightly higher or lower than this initial estimate.</p>
            const info_modal = <div>
                                    <p className='x-small clickable left-complete no-margin'>&nbsp;</p>  
                                    {/* <AboutModal show={this.state.showmodal} handleClose={this.hideModal} info={aboutthis}></AboutModal> */}
                                    <p className='x-small clickable left-complete' onClick={this.showModal}>&#9432; How to measure square footage myself</p>
                                </div>
            const other_warning = <p className='small spaced left-complete'>To provide you an accurate quote, our reps will need to take measurements of your space.</p>     
            const garage_btn = "Continue";
            const other_btn = "Set up a time";
            if (showWarning){            
                return (
                <div className='warning'>
                    { isgarage ? garage_warning : other_warning }
                    <button className='next' onClick={isgarage ? this.continue(garage) : () => handleChangeEstimateReason(reason)}>
                        { isgarage ? garage_btn : other_btn }
                        <FontAwesomeIcon 
                                icon={faArrowRight}
                                onClick={isgarage ? this.continue(garage) : () => handleChangeEstimateReason(reason)}
                                />
                    </button>
                    {isgarage ? null : info_modal}
                </div>
                )
            }
        }

        const renderQuestion = () => {
            const isgarage = (values_customer.areatype.title.toLowerCase() === 'garage')
            return(
                <div className='questionwrapper'>
                    <CSSTransition
                        in={this.state.mounted}
                        timeout={1000}
                        classNames={is_mobile_display ? "fade" : "slide-to-left"}
                        unmountOnExit 
                        appear
                    >
                        {/* <div className='slide-to-left-vanilla'> */}
                        <div className='animation'>
                            <div className='inputflexcontainer1of2'>
                                <div className='textwrapper'>
                                    <h2 className='large'> Can you provide the square footage of your {(!isgarage) ? <p className='inline bolder'>concrete</p> : ''} {values_customer.areatype.title}{(values_customer.areatype.title.substring(0,4).toLowerCase() === 'home') ? ' space' : '' }?</h2>
                                    {/*<AboutModal show={this.state.showmodal} handleClose={this.hideModal} info={aboutthis}/>*/}
                                    {this.state.showmodal ? <GPTModal handleClose={this.hideModal} prompts={faq_prompts} displayclass={"modal display-block"} company_name={this.props.values_business.businessname} intro_query={'How do I find the square footage of my floor?'} industry='concrete coatings'/> : null}
                                    <p className='clickable display-flex-row' onClick={this.showModal}><img className='assistant-icon' src={AI_Assistant_Icon} alt='chat icon'/>&nbsp;{aboutthis[0].title}</p>
                                </div>
                                <div className='buttonwrapper'>
                                    <button
                                        type='button' 
                                        className={this.getButtonActivity('true')}
                                        id={(this.props.values_customer.knowssqfootage === 'true') ? null : 'true'}
                                        onClick={handleChange('knowssqfootage')}>
                                            <p className= 'btn-with-note note' id={'true'} onClick={(e) => handleChange('knowssqfootage')}>&nbsp;</p>
                                            <p className= 'btn-with-note main' id={'true'} onClick={(e) => handleChange('knowssqfootage')}>Yes, let me get the tape measure!</p>
                                            <p className= 'btn-with-note note' id={'true'} onClick={(e) => handleChange('knowssqfootage')}>Provides {(isgarage) ? 'most accurate' : 'fastest'} quote</p>
                                    
                                    </button>
                                </div>
                            </div>
                            <div className='inputflexcontainer2of2'>
                                    {renderNumInput()}
                                    <button
                                        type='button' 
                                        className={this.getButtonActivity('false')}
                                        id={(this.props.values_customer.knowssqfootage === 'false') ? null : 'false'}
                                        onClick={handleChange('knowssqfootage')}>
                                        <p className='btn-with-note main' id={'false'} onClick={handleChange('knowssqfootage')}>No, I'd like someone to measure for me</p>
                                    </button>
                                    {getWarning()}
                            </div>
                            {/* </div> */}
                            <div className="fillremainderflex"/>
                        </div>
                    </CSSTransition>
                    <FooterContact values_business={values_business} phone={values_customer.branch.phone}/>
                </div>
            )
        }

        return (
                <div className ='surveywrapper'>
                    <div className='largeimagewrapper'>
                        <img className='coverphoto' src= {this.getCoverImg()}/>
                    </div>
                    {renderQuestion()}
                </div>
        )
    }
}

export default SquareFootage;