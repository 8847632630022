import React, { Component } from 'react';
import { GoogleMap, Marker, Polyline, Polygon, LoadScriptNext } from '@react-google-maps/api';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { CSSTransition } from 'react-transition-group';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTrashAlt, faUndo, faSearchPlus, faMagnifyingGlassMinus} from '@fortawesome/free-solid-svg-icons'
import Matomo from '../Matomo';
import ContinueFooter from '../questions/ContinueFooter';
import QuoteSummaryModal from './modals/QuoteSummaryModal';
import Loading from '../general_questions/components/LoadingIcon';

  //TODO: Add unique poly line icons for first and last vertex? 
  //TODO: Add custom zoom in/out buttons



class SquareFootageMapHighRes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submit: false,
      mounted: false,
      latitude: null,
      longitude: null,
      zoom: 13,
      max_zoom: null,
      center : {
        lat: props.values_customer.jobLatLng.lat,
        lng: props.values_customer.jobLatLng.lng
      },
      errorMessage: null,
      nextpage: props.next,
      clickedLocations : props.values_customer.clicked_locations || [],
      currentVertexIndex: null, //polyline onclick vertex reference
      poly_area: this.checkCompletedPolygon(props.values_customer.clicked_locations) ? props.values_customer.sqfootage_user || props.values_customer.sqfootage : null,
      showQuoteModal: false,
      hd_loading: false, // Tracks if Nearmap tiles are loading
      //actions: [], TODO: track actions to allow for undo/redo
    };
    this.mapRef = React.createRef();  // Using React.createRef() to manage map reference
  }

  componentDidMount = async () => {
    /*-Matomo Analytics-*/
    const title = 'SquareFootageMapHighRes';
    await Matomo(title);
    /*----End Matomo----*/
    console.log('componentDidMount')
    this.setState({ mounted: true });
    // if (!this.state.poly_area && this.state.clickedLocations && this.state.clickedLocations.length > 0){
    //   this.setJobArea(this.state.clickedLocations);
    // }
  }

  setupNearmapTiles = () => {
    const map = this.mapRef.current.state.map; // Get the actual map instance from the ref

    if (!map || !map.overlayMapTypes) {
      console.error("Map instance or overlayMapTypes is not available.");
      return;
    }

    const nearmapTileUrl = (coord, zoom) => {
      console.log("making NearMap request", zoom, coord.x, coord.y)
      return `https://api.nearmap.com/tiles/v3/Vert/${zoom}/${coord.x}/${coord.y}.jpg?apikey=${process.env.REACT_APP_NEARMAP_API_KEY}`;
      //'https://api.nearmap.com/tiles/v3/Vert/{zoom}/{x}/{y}.jpg?until={UNTIL_DATE}&apikey={NEARMAP_API_KEY}',
    };

    const nearmapTileLayer = new window.google.maps.ImageMapType({
      getTileUrl: nearmapTileUrl,
      tileSize: new window.google.maps.Size(256, 256)
    });


    map.overlayMapTypes.insertAt(0, nearmapTileLayer); // Overlay Nearmap tiles on Google Map
    // Listen for when all tiles are loaded
    window.google.maps.event.addListener(map, 'tilesloaded', () => {
      this.setState({ hd_loading: false });
      console.log("All tiles loaded.");
    });

        // Set loading true when starting to add the tiles
        this.setState({ hd_loading: true });
  }

  handleMapClick = (event) => {
    let clickedLocations = this.state.clickedLocations;
    //let actions = this.state.actions;
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    clickedLocations = [...this.state.clickedLocations, { lat, lng }];
    //actions.push({'add': {lat, lng}});
    this.setState({ clickedLocations: clickedLocations }, this.setJobArea(clickedLocations));
  };

  renderMapMarkers = () => {
    if(this.state.clickedLocations.length > 0) {
      return this.state.clickedLocations.map((location, index) => {
        //{clickedLocations[-1] && <Marker position={{ lat: clickedLocations[-1].lat, lng: clickedLocations[-1].lng }} />}
        return <Marker key={index} position={{ lat: location.lat, lng: location.lng }} />
      })
    }
  }

  checkCompletedPolygon = (path=null) => {
    const clickedLocations = path ? path : this.state.clickedLocations;
    const poly_path = path ? path : clickedLocations;
    if(!poly_path){
      return false;
    }
    const first = poly_path[0];
    const last = poly_path[poly_path.length - 1];
    const ispolygon = poly_path.length > 2 && (first.lat === last.lat) && (first.lng === last.lng);
    //console.log('ispolygon: ', ispolygon);
    //console.log(clickedLocations);
    return ispolygon;
  }

   findCenterPoint = (polygon) => {
    let totalLat = 0;
    let totalLng = 0;

    // Iterate through each vertex of the polygon
    for (let i = 0; i < polygon.length; i++) {
        totalLat += polygon[i].lat;
        totalLng += polygon[i].lng;
    }

    // Calculate the average latitude and longitude
    const avgLat = totalLat / polygon.length;
    const avgLng = totalLng / polygon.length;

    // Create a new object with the calculated average latitude and longitude
    const centerPoint = { lat: avgLat, lng: avgLng };

    return centerPoint;
}


  setJobArea = (path) => {
    console.log('setJobArea')
    const {handleSaveMapClicks} = this.props;
    const completed_poly = this.checkCompletedPolygon(path);
    let poly_area;
    if (window.google.maps && completed_poly){
      const updated_center = this.findCenterPoint(path);
      poly_area = window.google.maps.geometry.spherical.computeArea(path);
      poly_area = parseFloat((poly_area * 10.7639).toFixed(2)); //convert to square feet
      console.log('poly_area: ', poly_area)
      this.setState({ poly_area: poly_area }, handleSaveMapClicks(path, poly_area, updated_center));
    } 
    if (!completed_poly || !poly_area) { //clear poly_area if polygon is not complete
      this.setState({ poly_area: null }, handleSaveMapClicks(path, null));
    }
  }

  setZoom = () => {
    if(window.google.maps){
      const new_zoom = window.google.maps.getZoom();
      console.log('current zoom: ', new_zoom)
      this.setState({ zoom: new_zoom });
    }
  }

  getMaxZoom = () => {
    const {center} = this.state;
    let max_zoom = new window.google.maps.MaxZoomService();
    //let coordinate = new window.google.maps.LatLng(this.state.center.lat, this.state.center.lng);
    if(max_zoom && center){
      max_zoom.getMaxZoomAtLatLng(center, (result) => {
        if (result.status !== "OK") {
          console.error("Error in MaxZoomService");
        } else {
          console.log("Max zoom: ", result.zoom);
          this.setState({ zoom: result.zoom, max_zoom: result.zoom });
        }
      });
    }
  }

  zoomIn = () => {
    let zoom = this.state.zoom;
    zoom = zoom + 1;
    this.setState({ zoom: zoom });
  }

  zoomOut = () => {
    let zoom = this.state.zoom;
    zoom = zoom - 1;
    this.setState({ zoom: zoom });
  }

  renderPolygon = () => {
    const options = {
      fillColor: "#FFFF00",
      fillOpacity: 0.35,
      strokeColor: "#FFFF00'",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      clickable: false,
      draggable: false,
      editable: true,
      geodesic: false,
      zIndex: 1
    }
    if (this.checkCompletedPolygon() && window.google && window.google.maps){
      return (
        <Polygon
          key={JSON.stringify(this.state.clickedLocations)} //update key with unique identifier
          //ref={(polyline) => (this.polyline = polyline)}
          paths={this.state.clickedLocations}
          options={options}
          onRightClick={this.handlePolyRightClick}
          onClick={this.handlePolyClick}
          onMouseUp={this.handlePolyDrag}
        />
      )
    }
  }

  renderPolyLine = () => {
    const { clickedLocations } = this.state;
    const poly_options = {
      strokeColor: '#FFFF00',
      strokeOpacity: 0.5,
      strokeWeight: 3,
      fillColor: '#FFFF00',
      fillOpacity: 0.35,
      clickable: false,
      draggable: false,
      editable: true,
      visible: true,
      //radius: 30000,
      paths: this.state.clickedLocations,
      zIndex: 1
    };
    return(
      <Polyline
        key={JSON.stringify(this.state.clickedLocations)} //update key with unique identifier
        onRightClick={this.handlePolyRightClick}
        onClick={this.handlePolyClick}
        onMouseUp={this.handlePolyDrag}
        path={this.state.clickedLocations}
        options={poly_options}
        draggable={true}
        //ref={(polyline) => { this.polyline = polyline && polyline.getPath() }} //reference used to update polyline path
      />
    )
  }

  handlePolyClick = (e) => {
    console.log('polyline clicked: ', e)
    let clickedLocations  = this.state.clickedLocations;
    if(e.vertex != undefined){ //vertex clicked
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
          
      clickedLocations = [...clickedLocations, {lat, lng}];
      this.setState({ clickedLocations: clickedLocations }, this.setJobArea(clickedLocations));
    }
  };

  handlePolyRightClick = (e) => {
    console.log('polyline right clicked: ', e);
    let clickedLocations = [...this.state.clickedLocations];
    const index = e.vertex;
    if (index !== undefined) { // vertex clicked
      clickedLocations.splice(index, 1);
      this.setState({ clickedLocations: clickedLocations }, this.setJobArea(clickedLocations));
    }
  };

  handlePolyDrag = (e) => {
    console.log('polyline dragged: ', e)
    let clickedLocations  = this.state.clickedLocations;
    const is_polygon = this.checkCompletedPolygon(clickedLocations);
    console.log('lat', e.latLng.lat(), 'lng', e.latLng.lng());
    if(e.vertex != undefined){ //vertex clicked
      const new_lat = e.latLng.lat();
      const new_lng = e.latLng.lng();

      clickedLocations[e.vertex].lat = new_lat;
      clickedLocations[e.vertex].lng = new_lng;
      //if vertex is first or last in a completed polygon, update the other
      console.log('checkCompletedPolygon: ', is_polygon)
      if(is_polygon){
        if(e.vertex === 0){
          clickedLocations[clickedLocations.length - 1].lat = new_lat;
          clickedLocations[clickedLocations.length - 1].lng = new_lng;
        } else if (e.vertex === clickedLocations.length - 1){
          clickedLocations[0].lat = new_lat;
          clickedLocations[0].lng = new_lng;
        }
      }
      this.setState({ clickedLocations: clickedLocations }, this.setJobArea(clickedLocations));

    } else if (e.edge != undefined) { //edge clicked
      this.addEdgeToLine(e);
    }
  }

  addEdgeToLine = e => {
    console.log('add edge to line: ', e)
    let clickedLocations  = this.state.clickedLocations;
    if(e.edge != undefined){ //edge clicked
      let new_lat = e.latLng.lat();
      let new_lng = e.latLng.lng();

      new_lat += (new_lat - clickedLocations[e.edge].lat); //for some reason, trying to add edge without this causes a new vertex 
      new_lng += (new_lng - clickedLocations[e.edge].lng); //to be rendered only half way along path from where it should be. this doubles its path

      clickedLocations.splice(e.edge+1, 0, {edge: e.edge, lat: new_lat, lng: new_lng});
      console.log(clickedLocations)
      this.setState({ clickedLocations: clickedLocations }, this.setJobArea(clickedLocations));
    }
  }

  clearAll = () => {
    console.log('clear all')
    this.setState({ clickedLocations: [], poly_area: null }, this.setJobArea([]));
  }

  renderGoogleMap = (placement) => {
    const {is_mobile_display} = this.props;
    const { handleChangeNext, values_customer } = this.props;
    const { clickedLocations, latitude, longitude,center, zoom, max_zoom, errorMessage, nextpage, poly_area } = this.state;
    const containerStyle = {
      width: '100%',
      height: '100%',
    };
    const mapOptions = {
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
      mapTypeId:'satellite',
      draggableCursor: 'crosshair',
    }
    const map_element = 
      // <LoadScriptNext googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
          <GoogleMap
            ref={this.mapRef}  // Setting ref to the GoogleMap instance
            key={values_customer.hd_mode}
            onLoad={ () => {
                this.getMaxZoom();
                values_customer.hd_mode && this.setupNearmapTiles();
              }
            } //runs once?
            //onZoomChanged={this.setZoom}
            mapContainerStyle={containerStyle}
            center={center || {lat: 0, lng: 0}}
            zoom={zoom}
            tilt={0}         
            onClick={this.handleMapClick}
            mapTypeId='satellite'
            options={mapOptions}
          >                
            {/*{this.renderMapMarkers()}*/}
            {this.state.clickedLocations && !this.checkCompletedPolygon() && this.renderPolyLine()}
            {this.renderPolygon()}
          </GoogleMap>
      // </LoadScriptNext>

      return map_element;
  }

 refreshPage = () => {
    window.location.reload();
  };

  showQuoteModal = () => {
    this.setState({ showQuoteModal: true });
  }
  hideQuoteModal = () => {
    this.setState({ showQuoteModal: false });
  }
  toggleResolution = () => {
    const {hd_mode} = this.props.values_customer
    const {handleChangeObject} = this.props;
    if (hd_mode){
      handleChangeObject('hd_mode', false)
    } else {
      handleChangeObject('hd_mode', true)
    }
  }

  render() {
    const { handleChangeNext, user_mode, original_quote, updated_quote, updated_quote_info, original_quote_info, values_customer, is_mobile_display } = this.props;
    const { clickedLocations, latitude, longitude,center, zoom, max_zoom, errorMessage, nextpage, poly_area, showQuoteModal} = this.state;
    const main_header = (user_mode === 'customer') ? 'Click to create a border around your job area.' : 'Drag or add points to adjust the job area';
    const sub_header = (!is_mobile_display) ? 'Left click to add a point, right click to remove.' : 'Tap to add and drag points';
    const note = (user_mode === 'company') ? `To revert back to the user's original selection, refresh the page.` : null;
    const trashcan_disabled = clickedLocations.length === 0;
    const undoall_disabled = original_quote === updated_quote;
    const quote_difference = updated_quote - original_quote;
    const quote_diff_text = (quote_difference > 0) ? `(+$${quote_difference})` : (quote_difference < 0) ? `(-$${Math.abs(quote_difference)})` : '(No change)';
    const updated_quote_text = (updated_quote !== 'N/A') ? '$'+updated_quote : updated_quote;
    const original_quote_text = (original_quote && !original_quote.toLowerCase().includes('none')) ? '$'+original_quote : original_quote; //'none' signifies a quote was not given
    const zoomin_disabled = zoom === max_zoom;
    const zoomout_disabled = zoom === 8;
    const trashcan =  <FontAwesomeIcon
                        icon={faTrashAlt}
                        //size='2x'
                        onClick={(trashcan_disabled) ? null : this.clearAll}
                        alt='Clear all points'
                        className={(trashcan_disabled)? 'disabled' : 'active'}
                      />
    const undoall =  <FontAwesomeIcon
                        icon={faUndo}
                        //size='2x'
                        onClick={(undoall_disabled) ? null : this.refreshPage}
                        alt='Undo all changes'
                        className={(undoall_disabled)? 'disabled' : 'active'}
                      />
    const hd_mode = (this.state.hd_loading) ? <div className='center' style={{position:'relative'}}><p className='xx-small no-margin small-pulse-fade disabled' style={{position:'absolute'}}>HD Loading</p></div> : <p className={values_customer.hd_mode ? 'bolder active' : 'bolder disabled'} onClick={this.toggleResolution}>HD</p>
    const quoteSummaryModal = (user_mode === 'company') ? <QuoteSummaryModal quoteInfo={updated_quote_info} handleClose={this.hideQuoteModal}/> : null;
  
    //const title = "What is the street address for this job?"
    //console.log(center)
    return (
      <div className='surveywrapper'>
        {showQuoteModal && quoteSummaryModal}
        <CSSTransition
                in={this.state.mounted}
                timeout={1000}
                classNames="fade"
                unmountOnExit 
                appear
        >
          <div className='mapwrapper'>
            <div className='map-directions-wrapper'>
              <div className='map-title'>
                  <h4 className='m-large'>{main_header}</h4>
                  <h4 className={!is_mobile_display ? 'x-small light left no-margin-left' : 'x-small light center'}>{sub_header}</h4>
                  <h4 className='x-small light left no-margin-left'>{note}</h4>
              </div>

              <div className='map-row'>
                <div className='map-column'>
                  <div className='map-info'>
                    <div className='opaque-background'/>
                    {values_customer.jobtype && <p className='medium min-line-height left no-margin'>Service Type: <br/><b>{values_customer.jobtype.title}</b></p>}
                    <p key={this.state.poly_area} className={(poly_area) ? 'small left' : 'small left grey'}>Measured sq ft: <br/>{(poly_area)? poly_area : 'N/A\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0'}</p>
                  {original_quote && <p className='small left'>Quote Given to Customer: <br/>{original_quote_text}</p>}
                  {updated_quote && <p className='small left'>Exact Quote as Measured: <br/><p className='logo-color bold no-margin'>{updated_quote_text}</p></p>}
                  {user_mode === 'company' && 
                      <button
                              className='formsubmitbtn fit-content'
                              disabled = {!updated_quote}
                              onClick={this.showQuoteModal}
                              >
                              Review Updated Quote
                      </button>
                  }
                  </div>
                </div>
                <div className='map-column'>
                    <div className='map-control'>
                      {trashcan}
                    </div>
                    <div className='map-control'>
                      {hd_mode}
                    </div>
                </div>
                
              </div>
            </div>
            {this.renderGoogleMap('desktop')}
          </div>
        </CSSTransition>
        {this.props.nextPage !== false && 
          <ContinueFooter
              nextPage={this.props.nextPage}
              enable = {poly_area}
              nextpage = {this.props.next}
          />
        }
      </div>

    );
  }
}

export default SquareFootageMapHighRes;


