import React, {Component} from 'react'
import ScheduleContact from '../../ScheduleContact';
import ProgressBar from '../../questions/ProgressBar';
import Header from '../../Header';

// Purpose: Error catching to render a fallback UI
// Limitations: Remember, does not catch errors for:
//  - Event handlers
//  - Asynchronous code (e.g., setTimeout or requestAnimationFrame callbacks)
//  - Server side rendering
//  - Errors thrown in this error boundary itself
//  - For these cases, will need to use regular JavaScript error handling techniques like try/catch.


export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        console.log("ErrorBoundary getDerivedStateFromError")
        if (error){
            console.log(error)
            return { hasError: true };
        }
    }

    sendErrorNotification = (error) => {
        //TODO: send error notification email
    }


    componentDidCatch(error, errorInfo) {
        // You can log the error to an error reporting service
        console.error("Error caught by error boundary:", error, errorInfo);
        this.setState({ hasError: true }, this.sendErrorNotification(error));
    }

    render() {
        const {values_general, values_business, values_customer, ismobile, email_regex,phone_regex, handleChangeObject, handleScheduleEstimate, prevPage} = this.props;
        console.log("ErrorBoundary render")
        console.log(this.state.hasError)
        if (this.state.hasError) {
            // render custom fallback UI
            return(
                <div className='container'>
                    <Header
                        prevPage={prevPage} 
                        values_general={values_general}
                        values_business={values_business}
                    />
                    <ProgressBar
                        progress= '100'
                    />
                    <ScheduleContact
                        pagetype={'error'} 
                        handleScheduleEstimate={handleScheduleEstimate}
                        handleChangeObject={handleChangeObject} 
                        values_customer={values_customer}
                        values_business={values_business}  
                        ismobile = {ismobile}      
                        email_regex = {email_regex}
                        phone_regex = {phone_regex}     
                    />
                </div>
            )
        }

        return this.props.children;
    }
}


export default ErrorBoundary;