import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPenSquare,faTrash} from '@fortawesome/free-solid-svg-icons'
import { faClone } from '@fortawesome/free-regular-svg-icons'

export class DragDropItem extends Component {
    
    render() { 
            const {item, handleCopyItem, handleDeleteItem, handleEditItem} = this.props;
            
            return(
                <div className='drag-drop-item' key={item.key}>
                    &nbsp;{item.title}
                    <div className='icon-group'>
                        <FontAwesomeIcon icon={faPenSquare} className='icon' onClick={() => handleEditItem(item.key)}/>
                        <FontAwesomeIcon icon={faClone} className='icon' onClick={() => handleCopyItem(item.key)}/>
                        <FontAwesomeIcon icon={faTrash} className='icon' onClick={() => handleDeleteItem(item.key)}/>
                    </div>
                </div>
            )

    }

}
    
export default DragDropItem;