import React, {Component} from 'react';
import { findIndexByProperty } from '../../Helpers';
import Matomo from '../Matomo';
import { CSSTransition } from 'react-transition-group';
import CoefInput, { PriceInput } from './question_childrencomponents/PriceInput';
import TitleInput from './question_childrencomponents/TitleInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

export class SingleColumnSingleFieldInputs extends Component {
    
    //requires HandleChangeObject, showpage, pagetitle options (array), input_category (string), header_question (string) props, input_type (string), allow_additional_options (boolean)
    //options is an array of objects with title and coef properties
    
    constructor(props) {
        super(props);
        this.state = {
            options : props.options,
            choices : [],
            input_category : props.input_category, //name of parent state variable input will modify 
            mounted: false
            //choices : [], //user chosen or default
        };
    }

    componentDidMount = () => {
        const {showpage, pagetitle} = this.props;
        /*-Matomo Analytics-*/
        Matomo(pagetitle);
        /*----End Matomo----*/

        //Skip to next page
        if(!showpage){
            const event = new Event("service");
            //event.data=this.props.values_business.areatype_options[0];
            this.continue(this.props.nextpage)(event);
        }

        this.setState({ mounted: true });
    }

    continue = (next) => e =>{
        const {submit_additional_options, nextPage, pagetitle, category_index} = this.props;
        e.preventDefault();
        this.props.nextPage(next)
    };

    setValue = (option, object_parameter_name) => e => {
        const {customHandleValue} = this.props;
        console.log("setValue")
        console.log(option)
        if (customHandleValue){
            console.log('customHandleValue')
            console.log(e.target.value)
            console.log(option)
            customHandleValue(e.target.value, option);
        } else {
            const {choices, input_category} = this.state;
            const choice = option;
            choice[object_parameter_name] = e.target.value;
            //if object doesn't have key prop, use title as key
            if (!choice.key){
                choice.key = choice.title;
            }
            const found_atindex = findIndexByProperty(choices,"key", choice.key);
            if ( found_atindex > -1 ){
                choices[found_atindex] = choice;
            } else {
                choices.push(choice);
            }
            this.props.handleChangeObject(input_category, choices)
        }
    }

    //add option by appending an object to the options array
    handleAddOption = () => {
        const {options} = this.state;
        //create option object that matches the structure of the the object at options[0], but with emnpty values
        const new_option = {};
        for (let key in options[0]){
            new_option[key] = '';
        }
        //if key is already in use, find a number at the end of the key and increment it
        const key = options[options.length-1].key;
        const key_number = key.match(/\d+$/);
        if (key_number){
            new_option.key = key.replace(/\d+$/, parseInt(key_number[0])+1);
        } else {
            new_option.key = key + '1';
        }
        options.push(new_option);
        this.setState({options: options})
    }

    render() { 
            const {options} = this.state;
            const {input_type, allow_additional_options, header_directions, header_question} = this.props;
            console.log(this.props.pagetitle)
            console.log(options)
            return(
                
                <div className='surveywrapper'>
                    <CSSTransition
                        in={this.state.mounted}
                        timeout={1000}
                        classNames="fade"
                        unmountOnExit 
                        appear
                    >
                        {options && options[0] !== undefined &&
                        <div className='multiplechoice'>
                            <h2 className='large'>{header_question}</h2>
                            {header_directions && <h4 className='m-small'>{header_directions}</h4>}
                            {options && options.map((option) => (
                                input_type === 'title' && <TitleInput key='title' option={option} handleOnChange={this.setValue}/> ||
                                input_type === 'coef' && <PriceInput key='coef' option={option} handleOnChange={this.setValue} price_parameter={input_type}/> ||
                                input_type === 'flat' && <PriceInput key='flat' option={option} handleOnChange={this.setValue} price_parameter={input_type}/> ||
                                input_type === 'linear' && <PriceInput key='linear' option={option} handleOnChange={this.setValue} price_parameter={input_type}/>
                            ))}
                            {allow_additional_options && 
                            <div className='row'>
                                <p className='small'>Add another option</p>
                                <FontAwesomeIcon
                                    icon={faPlusCircle}
                                    onClick={this.handleAddOption}
                                />
                            </div>}
                        </div>
                        }
                    </CSSTransition>
                </div>
            )

    }

}
    
export default SingleColumnSingleFieldInputs;