import React, {Component} from 'react';
import {AboutModal} from './Modals/AboutModal'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';
import { FooterContact } from '../general_questions/FooterContact';

import garagePic from './garagefloor.jpg'
import Matomo from '../Matomo.js';

export class SquareFootageStriping extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showmodal : false,
            next : props.next,
            mount: false
        }    
        this.getButtonActivity = this.getButtonActivity.bind(this);
        this.continue = this.continue.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleEnterKey = this.handleEnterKey.bind(this);
    }

    componentDidMount = () => {
        /*-Matomo Analytics-*/
        const title = 'SquareFootageGarage';
        Matomo(title);
        /*----End Matomo----*/
        this.setState({ mounted: true });
        console.log(this.props.values_business.addon_items[0].Areas["garage"])
        //addon[this.props.values_customer.areatype.title.toLowerCase()][pack]
    }
    componentWillUnmount = () => {
        this.setState({ mounted: false });
    }


    showModal = () => {
        document.body.style.overflow = 'hidden';
        this.setState({ showmodal: true });
    };
    
    hideModal = () => {
        document.body.style.overflow = 'auto';
        this.setState({ showmodal: false });
    };

    continue = (next, skip = false) => e =>{
        e.preventDefault();
        this.props.nextPage(next, skip);
    };

    handleEnterKey = e => {
        console.log("handleEnterKey")
        console.log(e)
        if (e && e.key.toLowerCase() === 'enter') {
            console.log("enter key pressed")
            this.props.nextPage(this.state.next);
        }
    }

    getButtonActivity = val =>{
        var saved = this.props.values_customer.knowssqfootage;
        if (saved===val) {
            return 'columnbtn active';
        }
        return 'columnbtn';
    };

    getCoverImg = () => {
        /*switch(this.props.values_customer.areatype.title.toLowerCase()){
            case "garage":
                return garagePic;
            case "commercial space":
                return commercialPic;
            case "pool deck":
                return poolDeckPic;
            case "home exterior":
            case "exterior project":
                return homeExteriorPic;
            case "home interior":
            case "interior project":
                return homeInteriorPic;
            default:
                return garagePic;
        }*/
        if (this.props.values_customer.servicetype && this.props.values_customer.servicetype.preview){
            return this.props.values_customer.servicetype.preview;
        }
        else {
            return garagePic;
        }
    }


    /*checkInput = input => e =>{
        e.preventDefault();
        if (isNaN(parseInt(input))){
            alert({input} + ' is not a number')
        } else {
            this.props.handleChange('sqfootage')
        }
    }*/


    render() { 
        const { handleChange, handleChangeEstimateReason, values_customer, values_business, is_mobile_display } = this.props;
        const reason = 'Customer did not know/was unable to measure the linear of the job area.';
        let shouldDisplay = values_customer.knowssqfootage;
        let showWarning = (values_customer.knowssqfootage.toLowerCase() === 'false')

        const aboutthis = [
            
            {title: 'How do I measure this?', text: <p className="small spaced light">Linear
            feet is simply another way of saying total length. You can find the linear feet by taking the total sum length of the striping you would like completed. Width is simply the avergae estimated width of your striping, in inches. If an instant estimate is not as important, you can choose to have someone come out and measure for you.</p>},
            
            {title: 'Why is linear feet needed to get a quote?', text: <p className="small spaced light">We use the linear feet and width to calculate the total square footage of the job, which will let us know the total surface area of your space. Since a
            concrete coating is applied to the whole floor, a good estimate for surface area is critical in providing an accurate quote. If you don’t 
            know the square footage or just don’t have the time to measure it, no worries, we can send someone out to measure for you.</p>}
        ]

        const renderNumInput = () => {
            var prompt = 'Enter it now...'
            var input = this.props.values_customer.sqfootage;
            if (input !== 0 && input !== null){
                prompt = input;
            }
            if (shouldDisplay === 'true') {
                return(
                    <div>
                        <p className='invis no-margin'>.</p>
                        <p className='left x-small no-margin'>Total striping length (linear feet)</p>
                        <div className="inputwrapper">
                            <input 
                                type="number" 
                                placeholder= {prompt}
                                alt = 'linear footage of workspace'
                                max = '999999'
                                onChange= {handleChange('sqfootage')}
                            />
                            <button 
                                className='medium'
                                disabled = {true}
                                onKeyDown={this.handleEnterKey}//TODO: meant to submit on enter key
                                onClick={this.continue(this.state.next)}>
                                    ft
                            </button>
                        </div>
                        <p className='invis no-margin'>.</p>
                        <p className='left x-small no-margin'>Average stripe width (inches)</p>
                        <div className="inputwrapper">
                            <input 
                                type="number" 
                                placeholder= {prompt}
                                alt = 'linear footage of workspace'
                                max = '999999'
                                onChange= {handleChange('sqfootage')}
                            />
                            <button
                                className='medium'
                                disabled = {true}
                                onKeyDown={this.handleEnterKey}//TODO: meant to submit on enter key
                                onClick={this.continue(this.state.next)}>
                                    in
                            </button>
                        </div>
                        <p className='invis no-margin'>.</p>
                    </div>
                )
            } else {
                return null;
            }
        };
    
        const getWarning = () => {
            const info_modal = <div>
                                    <p className='x-small clickable left-complete no-margin'>&nbsp;</p>  
                                    <AboutModal show={this.state.showmodal} handleClose={this.hideModal} info={aboutthis}></AboutModal>
                                    <p className='x-small clickable left-complete' onClick={this.showModal}>&#9432; How to measure linear feet myself</p>
                                </div>
            const other_warning = <p className='small spaced left-complete'>To provide you an accurate quote, our reps will need to take measurements of your space.</p>     
            const other_btn = "Set up a time";
            if (showWarning){            
                return (
                <div className='warning'>
                    { other_warning }
                    <button className='next' onClick={() => handleChangeEstimateReason(reason)}>
                        { other_btn }
                        <FontAwesomeIcon 
                                icon={faArrowRight}
                                onClick={() => handleChangeEstimateReason(reason)}
                                />
                    </button>
                    { info_modal}
                </div>
                )
            }
        }

        const renderQuestion = () => {
            return(
                <div className='questionwrapper'>
                    <CSSTransition
                        in={this.state.mounted}
                        timeout={1000}
                        classNames={is_mobile_display ? "fade" : "slide-to-left"}
                        unmountOnExit 
                        appear
                    >
                        <div>
                            <div className='inputflexcontainer1of2'>
                                <div className='textwrapper'>
                                    <h2 className='large'> Can you provide the desired measurements of your {values_customer.areatype.title}{(values_customer.areatype.title.substring(0,4).toLowerCase() === 'home') ? ' space' : '' }?</h2>
                                    <AboutModal show={this.state.showmodal} handleClose={this.hideModal} info={aboutthis}>
                                    </AboutModal>
                                    <p className='clickable' onClick={this.showModal}> &#9432; {aboutthis[0].title}</p>
                                </div>
                                <div className='buttonwrapper'>
                                    <button
                                        type='button' 
                                        className={this.getButtonActivity('true')}
                                        id={(this.props.values_customer.knowssqfootage === 'true') ? null : 'true'}
                                        onClick={handleChange('knowssqfootage')}>
                                            <p className= 'btn-with-note note' id={'true'} onClick={(e) => handleChange('knowssqfootage')}>&nbsp;</p>
                                            <p className= 'btn-with-note main' id={'true'} onClick={(e) => handleChange('knowssqfootage')}>Yes, let me get the tape measure!</p>
                                            <p className= 'btn-with-note note' id={'true'} onClick={(e) => handleChange('knowssqfootage')}>Provides {'fastest'} quote</p>
                                    
                                    </button>
                                </div>
                            </div>
                            <div className='inputflexcontainer2of2'>
                                    {renderNumInput()}
                                    <button
                                        type='button' 
                                        className={this.getButtonActivity('false')}
                                        id={(this.props.values_customer.knowssqfootage === 'false') ? null : 'false'}
                                        onClick={handleChange('knowssqfootage')}>
                                        <p className='btn-with-note main' id={'false'} onClick={handleChange('knowssqfootage')}>No, I'd like someone to measure for me</p>
                                    </button>
                                    {getWarning()}
                            </div>
                            <div className="fillremainderflex"/>
                        </div>
                    </CSSTransition>
                    <FooterContact values_business={values_business} phone={values_customer.branch.phone}/>
                </div>
            )
        }

        return (
                <div className ='surveywrapper'>
                    <div className='largeimagewrapper'>
                        <img className='coverphoto' src= {this.getCoverImg()}/>
                    </div>
                    {renderQuestion()}
                </div>
        )
    }
}

export default SquareFootageStriping;