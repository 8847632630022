import React, {Component} from 'react';
import { findIndexByProperty } from '../../../Helpers';
import Matomo from '../../Matomo';
import { CSSTransition } from 'react-transition-group';

// This component renders a numerical input choice that maps to a .coef property in the parent component

export class PriceInput extends Component {
    
    render() { 
        const {option, handleOnChange, price_parameter} = this.props;
        
        return(
            <div className='boxgroupitem'>
                <label for={option.title || option}>&nbsp;{option.title || option}&nbsp;</label>
                <p className='small grey'>$</p>
                <input 
                    key = {option.key || option.title || option}
                    className = "" 
                    type="number"
                    id={option.title || option} //if option is an object, use title property
                    name={option.title || option} 
                    value={option[price_parameter]}
                    onChange={handleOnChange(option, price_parameter)} 
                    />
            </div>
        )
    }

}
    
export default PriceInput;