import React, { Component } from 'react';


export class ContinueFooter extends Component{

    continue = next => e =>{
        e.preventDefault();
        this.props.nextPage(next);
    }

    clickHandler = (nextpage, customHandler=null) => e => {
        const {skip_history} = this.props;
        e.preventDefault();
        console.log(nextpage)
        if (customHandler){
            customHandler(nextpage);
        } else {
            this.props.nextPage(nextpage, skip_history);
        }
    }

    render () {
        const { enable, nextpage, alternate_text, extra_c2a, skip_history } = this.props; //extra_c2a is an object with a .title, .enable, and .nextPage, its own nextPage handler
        const calltoaction = (alternate_text) ? alternate_text : 'Continue';
        return (
            <div className='continuefooter'>
                {extra_c2a && <button className="next" disabled={!extra_c2a.enable} onClick={this.clickHandler(nextpage, extra_c2a.nextPage)}>{extra_c2a.title}</button>}
                <button className="next" disabled={!enable} onClick={this.clickHandler(nextpage)}>{calltoaction}</button>
            </div>
        )
    }

}

export default ContinueFooter;