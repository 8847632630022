import React, {Component} from 'react';
import {AboutModal} from './Modals/AboutModal.js';
import { CSSTransition } from 'react-transition-group';
import picA from './1_resized.jpg';
import picB from './2_resized.jpg';
import picC from './3_resized.jpg';
import picD from './4_resized.jpg';
import exampleSpalling from './spalling_concrete.webp';
import { FooterLogo } from '../general_questions/FooterLogo.js';
import Matomo from '../Matomo.js';
import { FooterContact } from '../general_questions/FooterContact.js';
import ConditionChooser from '../general_questions/ConditionChooser.js';


export class FloorCondition extends Component {
    constructor (props) {
        super(props);
        this.state = {
            showmodal : false,
            nextpage : props.next,
            mounted : false
        }
    }

    componentDidMount = () => {      
        //Skip to next page if necessary
        if(!this.props.showpage){
            const event = new Event("emptyevent");
            this.continue(this.state.nextpage, true)(event);
        }
        this.setState({ mounted: true });
    }

    continue = (next, skip = false) => e =>{
        e.preventDefault();
        this.props.nextPage(next, skip);
    };

    // getFloorNumericalValue = (floorcondition) => { //converts floorcondition string into numerical values to compare
    //     let value;
    //     switch (floorcondition){
    //         case "none":
    //             value = 0;
    //             break;
    //         case "light":
    //             value = 1;
    //             break;
    //         case "medium":
    //             value = 2;
    //             break;
    //         case "heavy":
    //             value = 3;
    //             break;
    //         default:
    //             console.log("ERROR:  no floorcondition value provided to getFloorNumericalValue")
    //             break;
    //     }
    //     return value;
    // }

    // sendToInpersons = (selected_floorcondition) => {
    //     const inpersons = this.props.values_business.inpersons;
    //     const areatype = this.props.values_customer.areatype.title.toLowerCase();
    //     const servicetype = this.props.values_customer.servicetype.service.toLowerCase();
    //     const page = "FloorCondition";
    //     let is_inperson = true;
    //     let jobtype; //ultimitely set to either the servicetype, areatype, or default whatever is specifically identified in the Inpersons object
        
    //     //console.log("Inpersons: " + selected_floorcondition)
    //     //console.log(selected_floorcondition)
        
    //     if (inpersons[page]){                                   //if a inpersons exists for this page
    //         if (inpersons[page][servicetype]) {                    //and exists for the current selected servictype (or servicetype or default)
    //             jobtype = servicetype;
    //         }
    //         else if (inpersons[page][areatype]) {
    //             jobtype = areatype;
    //         }
    //         else if (inpersons[page]["default"]){
    //             jobtype = "default";
    //         }
    //         //console.log("jobtype: " + jobtype)
    //         //console.log(inpersons[page][jobtype])
    //         if (jobtype){
    //             for (const [condition, condition_value] of Object.entries(inpersons[page][jobtype])){  //check ONE condition at a time
    //                 switch (condition) {
    //                     case "inperson":
    //                         if (condition_value === "never"){  //immediately DO NOT send to in person quote page, regardless of other conditions
    //                             return false;
    //                         } else if (condition_value === "always"){ //immediately DO send to in person quote page, regardless of other conditions
    //                             return true;
    //                         }
    //                         break;
    //                     case "minfloordamage":
    //                         is_inperson = this.getFloorNumericalValue(selected_floorcondition) >= this.getFloorNumericalValue(condition_value);
    //                         console.log(is_inperson)
    //                         break;
    //                     case "maxfloordamage":
    //                         is_inperson = this.getFloorNumericalValue(selected_floorcondition) <= this.getFloorNumericalValue(condition_value);
    //                         break;
    //                 }
    //                 if (!is_inperson){                          //if ANY conditions are not met, do not send to in person quotepage
    //                     return false;
    //                 }
    //             }
    //             return true;
    //         }
    //     }
    //     else{
    //         return false;
    //     }
   
    // }    

    render() { 
        const { handleChangeObjectNext, handleChangeEstimateReason ,values_business, values_customer, is_mobile_display, nextPage, next } = this.props;
        const none = 'none';
        const light = 'light';
        const medium = 'medium';
        const heavy =  'heavy';
        const reason = 'Customer has floor damage that requires an in-person estimate for their service selection.';

        const damage_levels = [
            {
                value: none,
                description: 'Clean, new concrete',
                numerical_value: 0,
                picture: picA,
            },
            {
                value: light,
                description: 'Few cracks, and/or chipping',
                numerical_value: 1,
                picture: picB,
            },
            {
                value: medium,
                description: 'Medium cracks, chipping, and/or light stains',
                numerical_value: 2,
                picture: picC,
            },
            {
                value: heavy,
                description: 'Large cracks, chipping, and/or dark stains',
                numerical_value: 3,
                picture: picD,
            },
        ]

        return (
            <ConditionChooser
                page_title="FloorCondition"
                header_question="Which picture best matches your floor?"
                condition_levels={damage_levels}
                handleChangeObjectNext={handleChangeObjectNext}
                handleChangeEstimateReason={handleChangeEstimateReason}
                estimate_reason={reason}
                default_next={next}
                //sendToInpersons={this.sendToInpersons}
                values_business={values_business}
                values_customer={values_customer}
                is_mobile_display={is_mobile_display}
                nextPage={nextPage}
                default_state_var="floorcondition"
                showpage={true}
            />

        )
    }
}

export default FloorCondition;