import React, {Component} from 'react';
import axios from 'axios';
import Header from './Header';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSyncAlt} from '@fortawesome/free-solid-svg-icons';
import ScheduleContact from './ScheduleContact';
import {ContinueFooter} from './questions/ContinueFooter';
import {conditionIsMet, findObjectByProperty, isShownPage, setMobileStyling, updateThemeColors} from '../Helpers.js'
import Branch from './general_questions/Branch';
import SquareFootageMap from './asphalt_questions/SquareFootageMap';
import CollectAddress from './general_questions/CollectAddress';
import ProgressBar from './questions/ProgressBar';
import JobType from './general_questions/JobType';
import { Sealant } from './asphalt_questions/Sealant';
import { DrivewayStatus } from './asphalt_questions/DrivewayStatus';
import ConditionChooser from './general_questions/ConditionChooser';
import MakeStatement from './general_questions/MakeStatement';
import Quote from './general_questions/Quote';
import driveway_condition_A from './asphalt_questions/Driveway Condition A.jpg';
import driveway_condition_B from './asphalt_questions/Driveway Condition B.jpg';
import driveway_condition_C from './asphalt_questions/Driveway Condition C.jpg';
import QuotePackages from './questions/QuotePackages';
import Places from './general_questions/CollectAddressHook';
import CollectContact from './questions/CollectContact';
import MultipleChoiceText from './general_questions/MultipleChoiceText.js';
import ImageUpload from './general_questions/ImageUpload.js';
import upload_ex_entire from './asphalt_questions/upload_ex_entire.png';
import upload_ex_damage from './asphalt_questions/upload_ex_damage.png';
import upload_ex_seam from './asphalt_questions/upload_ex_seam.jpg';
import ErrorBoundary from './general_questions/components/ErrorBoundry.js';
import QuotePackages_v2 from './general_questions/QuotePackages_v2.js';
import QuoteServices from './general_questions/QuoteServices.js';
import MakeStaticStatement from './general_questions/MakeStaticStatement.js';
import { LoadScript } from '@react-google-maps/api';
import SquareFootageMapConfirmation from './asphalt_questions/SquareFootageMapConfirmation.js';
import SquareFootageMapHighRes from './asphalt_questions/SquareFootageMapHighRes.js';

export class AsphaltSurvey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //------------------------------//
            //** General State Conditions **//
            pages: ['Branch'], //array history of pages
            driveway_condition_levels : [ //..could move to backend if needs to be dynamic
                {
                    key: 'A',
                    value: 'No sealer, topcoat looks good', //match description
                    picture: driveway_condition_A, //src
                    description: 'No sealer, topcoat looks good',
                    suggested_jobtype: 'Asphalt Sealing',
                    suggested_description: 'Based on your selection we recommend a new sealcoat.',
                    gpt_prompts : [
                        {
                            display_text: `Tell me more about sealcoating`,
                            gpt_query: `If my topcoat looks good but doesn't have a sealer, what does a new sealcoat entail?`,
                        },
                        {   
                            display_text: `Why do I need a new sealcoat?`,
                            gpt_query: `If my topcoat looks good but doesn't have a sealer, why do I need a new sealcoat?`,
                        }
                    ],
                    //nextpage?
                },
                {
                    key: 'B',
                    value: 'Topcoat is moderately damaged (minor dips & cracks)',
                    picture: driveway_condition_B,
                    description: 'Topcoat is moderately damaged (minor dips & cracks)',
                    suggested_jobtype: 'Resurface (New Topcoat)',
                    suggested_description: 'Based on your selection we recommend a resurface (new topcoat).',
                    gpt_prompts : [
                        {   
                            display_text: `Tell me more about resurfacing`,
                            gpt_query: `My topcoat is moderately damaged, what does repaving (no excavation needed) entail?`,
                        },
                        {   
                            display_text: `Why do I need this?`,
                            gpt_query: `My topcoat is moderately damaged, why do I need resurfacing (new topcoat)?`,
                        },
                    ],
                },
                {
                    key: 'C',
                    value: 'Topcoat is significantly damaged',
                    picture: driveway_condition_C,
                    description: 'Topcoat is significantly damaged',
                    suggested_jobtype: 'Resurface (New Topcoat)',
                    suggested_description: 'Based on your selection, we recommend repaving (excavation needed).',
                    gpt_prompts : [
                        {   
                            display_text: `Tell me more about repaving`,
                            gpt_query: `My topcoat is significantly damaged, what does repaving (excavation needed) entail?`,
                        },
                        {   
                            display_text: `Why do I need this?`,
                            gpt_query: `My topcoat is significantly damaged, why do I need repaving (excavation needed)?`,
                        },
                    ],
                },
            ],
            driveway_status_choices: [
                {title: 'Currently Paved', value: 'paved', preview: null},
                {
                    title: 'Not Paved', 
                    description: 'gravel/rock driveway', 
                    value: 'Not paved', 
                    preview: null, 
                    suggested_jobtype: 'New Driveway',
                    suggested_description: `Based on your selection, we'll give you a quote for a new driveway.`
                },
                {title: 'New Home Build', 
                    value: 'nodriveway', 
                    description: 'No driveway (new home build)', 
                    gpt_prompts : [
                        {   
                            display_text: `What does a new driveway entail?`,
                            gpt_query: `What does a new asphalt driveway service for a new home build entail?`,
                        },
                        {   
                            display_text: `How long should I wait?`,
                            gpt_query: `How long should I wait to get a driveway for a new home build?`,
                        }
                    ],
                    preview: null,
                    suggested_jobtype: 'New Driveway',
                    suggested_description: `Based on your selection, we'll give you a quote for a new driveway.`
                },
                {title: 'Has Binder Coat ONLY', 
                    description: `(the base layer used under asphalt)`,
                    gpt_prompts : [
                        {   
                            display_text: `What does a new topcoat entail?`,
                            gpt_query: `If I have a binder coat already, what does a new asphalt topcoat entail?`,
                        },
                        {   
                            display_text: `Why do I need a new topcoat?`,
                            gpt_query: `If I have a binder coat already, why do I need a new asphalt topcoat?`,
                        }
                    ],
                    value: 'Has binder coat ONLY', 
                    preview: null,
                    suggested_jobtype: 'Resurface (New Topcoat)',
                    suggested_description: `Based on your selection, we'll give you a quote for a new topcoat.`
                },
            ],
            //---------------------//
            //** Customer Inputs **//
            sqfootage_lowend: null, //square footage of work space, low end of range
            sqfootage_highend: null, //square footage of work space, high end of range
            sqfootage_user: null, //square footage as measured by user
            jobtype: null, //type of job, from jobtype_options
            driveway_status: null, //paved, unpaved, nodriveway
            driveway_condition: null, //A,B,C,D (A best, D worst)
            sealed_before: null, // true/false
            email: null, //customer email
            phone: "", //customer phone
            customername: null, //customer name
            preferredcontact: null, //preferred contact method
            address: null, //customer street address
            jobLatLng: null, //customer job address .lat .lng
            branch: null, // company branch
            estimate_reason: null, //reason needed for an in-person estimate
            travel_distance: null, //distance in miles from branch address to job address
            job_images: [], //base64 encoded images of job
            clicked_locations: [], //array of saved click locations on map
            driveway_status: null, //paved, unpaved, nodriveway
            hd_mode: true,
            permissioned_followup: (props.subdomain === 'cnysealing' || props.subdomain === 'demo') ? 'true' : null, //ask for permission to follow up on saved quotes
            present_for_quote_verification: 'yes', //customer present for quote verification,  true/false
            //--------------------------//
            //** Business Information **//
            businessid: props.subdomain, //table ref
            businesslogo: null, //s3 logo key
            businessname: '', //company name
            businessaddress: '', 
            jobtype_options: null, //array of objects with .title,(coating) .services[]
            branch_options: [], //array of objects with .name, .phone, .email, .address (in format `${street}, ${city}, ${state} ${zipCode}` for GoogleMapsAPI)
            base_items: [], //array of objects with .title,  .short, .long, .coef (num), .flat (num)
            addon_items: [], //array of objects with .title,  .short, .long, .coef (num), .flat (num)
            inpersons: {}, //object with state conditions, sorted by survey Page, to send to contact page if true
            nodisplays: {}, //pages to hide, with conditions to do so
            quote_range: null, //display final quote in a range of +/- this % value
            homepage: '', //link back to company's homepage
            marketing_tag: null, //company's marketing tagline in footer
            custom_theme: null, //company's custom theme color
            logo_pdf: null, //company's logo for pdf (white background), if applicable
            industry: 'asphalt',   //company's industry
            packages: [], //array of objects with .title, .description, .price, .services[], .Render{}
            afterquote_url : null, //url to redirect to after quote is viewed (optional)
            jobtypes_without_scheduled_followup: ['Asphalt Sealing'], //jobtypes that require scheduled followup (show toggle in schedual modal)
            //-----------------------//
            //** Quote Information **//
            quoteid: '', //table key
            quote_loaded: false,
            quote_packages: null, //array of objects with .title, .description, .price, .services[], .Render{}, with calculated prcing
            fees: null, //extra costs for job not explicity broken down in package
            //-----------------------//
            //** General State Conditions **//
            EMAIL_REGEX : new RegExp(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/
            ),
            PHONE_REGEX : new RegExp(  //1-xxx-xxx-xxxx, xxx-xxx-xxxx, xxxxxxxxxx, xxx xxx xxxx etc.
                /^(?:1(?:[ -]?\(\d{3}\)[ -]?\d{3}[ -]?\d{4}|\d{3}[ -]?\(\d{3}\)[ -]?\d{4})|\(\d{3}\)[ -]?\d{3}[ -]?\d{4}|\d{10}|\d{11}|\d{3}[ -]?\d{3}[ -]?\d{4})$/
            ),
            map_loaded: false,
        };
    }

    componentDidMount = () => {
        const id = this.props.subdomain;
        const {businessid} = this.state;
        if(!businessid){this.initBizID(id)};
        if(businessid){this.getCompanyInfo(businessid)};
        setMobileStyling(this.props.embedded || this.props.is_mobile_display);
        this.checkValues();
    }

    componentDidUpdate = (prevProps, prevState) => {
        setMobileStyling(this.props.embedded || this.props.is_mobile_display);
    }

    updateWindowDimensions = () => {
        this.setState({ win_width: window.innerWidth, win_height: window.innerHeight }); 
    }

    // updateColors(theme) {
    //     document.documentElement.style.setProperty('--default-background-color', theme.default_background);
    //     document.documentElement.style.setProperty('--default-shadow-color', theme.default_shadow);
    //     document.documentElement.style.setProperty('--background-text-primary-color', theme.background_text_primary);
    //     document.documentElement.style.setProperty('--background-text-secondary-color', theme.background_text_secondary);
    //     document.documentElement.style.setProperty('--button-text-primary-color', theme.button_text_primary);
    //     document.documentElement.style.setProperty('--button-text-secondary-color', theme.button_text_secondary);
    //     document.documentElement.style.setProperty('--button-background-primary-color', theme.button_background_primary);
    //     document.documentElement.style.setProperty('--button-background-selected-color', theme.button_background_selected);
    //     document.documentElement.style.setProperty('--button-nav-background-color', theme.button_nav_background);
    //     document.documentElement.style.setProperty('--button-nav-background-color-dark', theme.button_nav_background_dark);
    //     document.documentElement.style.setProperty('--clickable-text-color', theme.clickable_text);
    //     document.documentElement.style.setProperty('--clickable-text-color-dark', theme.clickable_text_dark);
    //     if(theme.shadowbuttons){ //add shadowing to buttons with very dark backgrounds
    //         document.documentElement.style.setProperty('--shadow-button-amount', '5px');
    //     }
    //     document.documentElement.style.setProperty('--empty-input-background-color', theme.default_background);
    // }

    setMapLoaded = () => {
        console.log("Map loaded");
        this.setState({map_loaded: true});
    }

    initBizID = (id) => {
        this.setState({
            businessid: id,
        });
    }

    nextPage = (next, skip_current=false) => {
        const {pages} = this.state;
        const updated_pages = [...pages];
        if (skip_current){
            updated_pages.pop()
        }
        console.log('Next page: ' + next)
        updated_pages.push(next);
        this.setState({
            pages: [...updated_pages]
        }, this.checkValues());
    };

    prevPage = () => {
        const {pages} = this.state;
        pages.pop()
        this.setState({
            pages: [...pages]
        }, this.checkValues());
        
    };

    handleChange = input => e => {
        //console.log(e.target.id);
        console.log(e.currentTarget.id);
        console.log(e.currentTarget.checked);
        console.log(e.target.checked);
        let st = e.currentTarget.id;
        let val = e.target.value;
        if (val !== null && val !== '' && typeof val !== 'undefined'){
            st = e.target.value;
        }
        console.log("Handlechange")
        console.log(st)
        this.setState({[input]: st});
    };

    handleChangeNext = (next,input) => e => {
        console.log(input);
        let st = e.currentTarget.id;
        let val = e.target.value;
        if (val !== null && val !== '' && typeof val !== 'undefined'){
            st = e.target.value;
        }
        this.setState({[input]: st}, this.nextPage(next));
    };

    handleChangeObject = (input, ob) => {
        this.setState({[input]: ob});
    };

    handleChangeObjectNext = (next, input, ob, skip=false) => {
        this.setState({[input]: ob}, this.nextPage(next, skip));
    };

    handleDrivewayCondition = (next, input, ob, skip=false) => {
        const {driveway_condition_levels, jobtype_options} = this.state;
        console.log("handleDrivewayCondition");
        console.log(ob);
        const suggested_jobtype = driveway_condition_levels[this.findIndexByProperty(driveway_condition_levels, 'value', ob)].suggested_jobtype;
        console.log(suggested_jobtype);
        const jobtype = findObjectByProperty(jobtype_options, 'title', suggested_jobtype);
        console.log(jobtype);
        this.setState({[input]: ob, jobtype: jobtype}, this.nextPage(next, skip));
    };

    handleAsphaltStatus = (next, input, ob, skip=false) => {
        const {driveway_status_choices, jobtype_options} = this.state;
        console.log("handleAsphaltStatus");
        console.log(ob);
        const suggested_jobtype = driveway_status_choices[this.findIndexByProperty(driveway_status_choices, 'value', ob)].suggested_jobtype;
        console.log(suggested_jobtype);
        const jobtype = findObjectByProperty(jobtype_options, 'title', suggested_jobtype);
        console.log(jobtype);
        this.setState({[input]: ob, jobtype: jobtype}, this.nextPage(next, skip));
    };

    handleChangeEstimateReason = (reason, state_name=null, state_val=null) => {
        if(state_name && state_val){ //update estimate reason plus another state element
            this.setState({estimate_reason: reason, [state_name]: state_val}, this.nextPage('Schedule'));
        }
        else { //just update the estimate reason
            this.setState({estimate_reason: reason}, this.nextPage('Schedule'));
        }
    };

    handleSaveMapClicks = (clicks, sqfootage, centerJobLatLng) => {
        if (!centerJobLatLng){
            centerJobLatLng = this.state.jobLatLng;
        }
        this.setState({clicked_locations: clicks, sqfootage_user: sqfootage, jobLatLng: centerJobLatLng});
    }

    handleSaveAreaRange = (inner_area, outer_area, clicked_area) => {
        this.setState({sqfootage_lowend: inner_area, sqfootage_highend: outer_area});
    }

    getReMappedJobType = () => {
        //reformat jobtypes to match CNY's general categories used to determine email recipients, departments, etc. If this ever becomes dynamic, move to backend by adding a new field mapping jobtypes to departments or soemthing
        //The jobtype is ultimitely used in Zapier to determine which department to send the info to, included services is where the more detailed info is stored
        const {jobtype} = this.state;
        console.log("getmappedjobtype", jobtype);
        if (!jobtype){
            return;
        }
        const {customer_type} = this.state;
        const jobtype_lowercase = jobtype.title.toLowerCase();
        if (jobtype_lowercase.includes('sealing')){
            if (customer_type !== 'commercial'){
                return {title:'Residential Sealing'};
            } else {
                return {title:'Commercial Sealing'};
            }
        } else if (jobtype_lowercase.includes('resurface') || jobtype_lowercase.includes('new driveway') || jobtype_lowercase.includes('new topcoat') || jobtype_lowercase.includes('new addition') || jobtype_lowercase.includes('pave')){
            if (customer_type !== 'commercial'){
                return {title:'Residential Paving'};
            } else {
                return {title:'Commercial Paving'};
            }
        }
    }


    handleSubmitQuote = async (tallied_showntocustomer, tallied_exact, services, action_type) => { //action_type = 'save', 'schedule'
        console.log("handleSubmitQuote", action_type, tallied_showntocustomer, tallied_exact);
        if (action_type === 'save'){
            /**MATOMO ANALYTICS**/
            //_paq.push(['trackEvent', 'Contact', 'Email Link Click', 'name@example.com']);
            window._paq.push(['trackGoal', 1]);
            /**END MATOMO **/
        } else if (action_type === 'schedule'){
            /**MATOMO ANALYTICS**/
            //_paq.push(['trackEvent', 'Contact', 'Email Link Click', 'name@example.com']);
            window._paq.push(['trackGoal', 2]);
            /**END MATOMO **/
        }

        //var id = Math.random().toString(16).slice(2);
        const { jobtype, businessid, quoteid, email , customername, phone, branch, businessname, businesslogo, industry, preferredcontact, permissioned_followup, present_for_quote_verification} = this.state;

        let base = '';
        if (services){
            for (var i = 0; i < services.length; i++){
                if (i > 0){
                    base = base + '; ' + services[i].title;
                } else if (i === 0) {
                    base = services[i].title;
                }
            }
        }
        console.log(services);
        console.log(base);
        const response = await axios.post(
            //'https://dilqskv43l3zmifawahozxuq4m0zqmbo.lambda-url.us-east-2.on.aws/', // !for testing purposes only!
          'https://fok7u3r3f7.execute-api.us-east-2.amazonaws.com/prod/genQuoteFunction/savequote',
          { 
            id: quoteid,
            industry: industry,
            email: email,
            phone: phone, 
            preferredcontact: preferredcontact,
            businessname: businessname,
            businessid: businessid,
            businessphone: branch.phone,
            businessemail: branch.email,
            businesslogo: businesslogo,
            totalquote_exact: tallied_exact,
            totalquote_shown: tallied_showntocustomer,
            customername: customername,
            branch: branch.name,
            included: base,
            addons: 'none',
            action_type: action_type,
            permissioned_followup: (action_type === 'save') ? permissioned_followup : null, //only send permissioned followup status if saving quote
            present_for_quote_verification: (action_type === 'schedule' && !this.state.jobtypes_without_scheduled_followup.includes(jobtype.title)) ? present_for_quote_verification : null, //only send present for quote verification status if scheduling job and is a job type that requires it
            }
        );
        console.log(response);
        return response;
    }
    
    handleViewedQuote = async (tallied_showntocustomer, tallied_exact, selected_package, services) => {
        console.log("handleViewedQuote");
       /**MATOMO ANALYTICS**/
        //_paq.push(['trackEvent', 'Contact', 'Email Link Click', 'name@example.com']);
        window._paq.push(['trackGoal', 4]);
        /**END MATOMO **/

        //var id = Math.random().toString(16).slice(2);
        const { businessid, quoteid, email , customername, phone, branch, businessname, businesslogo, address, affiliate, industry, travel_distance, 
            other_info, urgency, logo_pdf, custom_theme, sqfootage_lowend, sqfootage_highend, sqfootage_user, jobLatLng, jobtype, driveway_condition, driveway_status, sealed_before, job_images, clicked_locations} = this.state;
        const formatted_jobtype = this.getReMappedJobType();
        console.log(formatted_jobtype);
        let base = '';
        if (services){
            for (var i = 0; i < services.length; i++){
                if (i > 0){
                    base = base + '; ' + services[i].title;
                } else if (i === 0) {
                    base = services[i].title;
                }
            }
        }
        console.log(services);
        console.log(base);
        const response = await axios.post(
          'https://fok7u3r3f7.execute-api.us-east-2.amazonaws.com/prod/genQuoteFunction/save-lead',
          { 
                id: quoteid,
                industry: industry,
                email: email,
                phone: phone, 
                streetaddress: address,
                businessname: businessname,
                businessid: businessid,
                businessphone: branch.phone,
                businessemail: branch.email,
                businesslogo: businesslogo,
                custom_theme: custom_theme,
                logo_pdf: logo_pdf,

                //basecost: tallied,
                totalquote_exact: tallied_exact, //in this case, will be last quote selected by customer
                totalquote_shown: tallied_showntocustomer, //in this case, will be last quote selected by customer
                addons: 'None',
                included: base,
                customername: customername,
                branch: branch.name,
                affiliate: (affiliate && affiliate.should_refer) ? affiliate : '',
                other_info: other_info,
                selected_package: selected_package,
                //urgency: urgency,

                //asphalt specific
                sqfootage_lowend: sqfootage_lowend, //square footage of work space, low
                sqfootage_highend: sqfootage_highend, //square footage of work space, high
                sqfootage_user: sqfootage_user, //square footage as measured by user
                jobtype: formatted_jobtype, //type of job
                driveway_status: driveway_status, //paved, unpaved, nodriveway
                driveway_condition: driveway_condition, //A,B,C,D (A best, D worst)
                sealed_before: sealed_before, // true/false
                jobLatLng: jobLatLng, //customer job address .lat .lng
                travel_distance: travel_distance, //distance in miles from branch address to job address
                job_images: job_images, //base64 encoded images of job
                clicked_locations: clicked_locations, //array of saved click locations on map
            }
        );
        console.log(response);
        if (response.data && response.data.id){
            console.log("Quote ID: " + response.data.id);
            this.setState({quoteid: response.data.id});
        }
        return response;
    }

    handleScheduleEstimate = async (contact, leadtype) => {
        /**MATOMO ANALYTICS**/
        //_paq.push(['trackEvent', 'Contact', 'Email Link Click', 'name@example.com']);
        window._paq.push(['trackGoal', 3]);
        /**END MATOMO **/

        const { estimate_reason, logo_pdf, sqfootage_lowend, sqfootage_highend, sqfootage_user, custom_theme, jobtype, businessid, businessname, branch, businesslogo, industry, driveway_condition, driveway_status, sealed_before, jobLatLng, travel_distance, job_images, clicked_locations } = this.state;
        const response = await axios.post(
            'https://h0a0i2dw7h.execute-api.us-east-2.amazonaws.com/dev/concreteQuoteFunction/scheduleestimate',
            { 
                leadtype: leadtype, //i.e. call, estimate, job, error
                industry: industry,
                jobtype: (jobtype) ? jobtype.title : null,
                email: contact.email,
                phone: contact.phone, 
                businessname: businessname,
                businessid: businessid,
                businessphone: branch.phone,
                businessemail: branch.email,
                businesslogo: businesslogo,
                customername: contact.customername,
                preferredcontact: contact.preferredcontact,
                streetaddress: contact.streetaddress,
                estimate_reason: estimate_reason,
                custom_theme: custom_theme,
                logo_pdf: logo_pdf,

                //asphalt specific
                sqfootage_lowend: sqfootage_lowend, //square footage of work space, low
                sqfootage_highend: sqfootage_highend, //square footage of work space, high
                sqfootage_user: sqfootage_user, //square footage as measured by user
                jobtype: jobtype, //type of job, from jobtype_options
                driveway_status: driveway_status, //paved, unpaved, nodriveway
                driveway_condition: driveway_condition, //A,B,C,D (A best, D worst)
                sealed_before: sealed_before, // true/false
                jobLatLng: jobLatLng, //customer job address .lat .lng
                travel_distance: travel_distance, //distance in miles from branch address to job address
                job_images: job_images, //base64 encoded images of job
                clicked_locations: clicked_locations, //array of saved click locations on map
            }
        );
        console.log(response);
    }

    getCompanyInfo = async (id) => {
        //const {businessid, businessname, branch_options, areatype_options, base_items, addon_items, coat_options } = this.state;
        console.log("requesting company info...");
        console.log(id)
        const {industry} = this.state;
        const response = await  axios.get(
            'https://ekdymqwbk5bj7jxf7rx6pbidfy0fllos.lambda-url.us-east-2.on.aws/',
            { 
                params: { business: id, industry: industry} 
            }
        );
        console.log(response);
        const data = JSON.parse(response.data).body;
        console.log(data);
        const marketing_footer = (data.marketing_tag) ? JSON.parse(data.marketing_tag) : null;
        const custom_theme = (data.custom_theme) ? JSON.parse(data.custom_theme) : null;
        this.setState({
            businessname: data.name,
            businesslogo: data.logo,
            branch_options: JSON.parse(data.branches),
            jobtype_options: JSON.parse(data.JobTypes),
            //servicetype_options: JSON.parse(data.services),
            //base_items: JSON.parse(data.base),
            //addon_items: JSON.parse(data.addons),
            inpersons: JSON.parse(data.Inpersons),
            nodisplays: JSON.parse(data.DoNotDisplay),
            quote_range: data.quote_range,
            afterquote_url: data.afterquote_url,
            homepage: data.homepage,
            marketing_tag: marketing_footer,
            custom_theme: custom_theme,
            logo_pdf: data.logo_pdf,
            packages: JSON.parse(data.Packages),
        }, () => {        if(this.state.custom_theme){
            console.log("updating color");
            updateThemeColors(this.state.custom_theme);
        }});
    }

    getDBQuote = async () => {

        const { businessid, jobtype, sqfootage_lowend, sqfootage_highend, driveway_status,driveway_condition, address, sealed_before, packages} = this.state;

        const response = await axios.post(
            //'https://h0a0i2dw7h.execute-api.us-east-2.amazonaws.com/dev/generalQuoteFunction/asphaltQuotes', //CURRENT PROD!
            'https://h0a0i2dw7h.execute-api.us-east-2.amazonaws.com/prod/generalQuoteFunction/asphaltQuotes', //CURRENT DEV!
            { 
                business: businessid,
                jobtype,
                sqfootage_lowend,
                sqfootage_highend,
                driveway_status,
                driveway_condition,
                address,
                sealed_before,
                packages
            }
        );
        const data = JSON.parse(response.data);
        console.log(data);

        this.setState({
            quoteid: data.id,
            quote_packages: data.packages,
            fees: data.other_fees,
            quote_loaded: true
            });
      }

    checkValues= () => { //ensures no conflicting user answers are submitted to DB
        console.log("checking values");
        console.log(this.state);
        if (!this.state.branch && this.state.branch_options && this.state.branch_options.length === 1){ //if only one branch option, set state to reflect current branch
            this.setState ({branch: this.state.branch_options[0]}); 
        }
    };

    findIndexByProperty(arr, propertyName, propertyValue) { //find index of object in array give a single property
        const index = arr.findIndex(obj => obj[propertyName] === propertyValue);
        return index;
      }

    getServicePackages = () => {
        //for each package, check .Render prop to see if it should be included. Loop through all Render objects, and if any are true, include package. For each object,
        // loop through all key value pairs and check if they match state conditions. If they do, include package. If none match, move on to next Render object.
        // ( "&&" conditions = conditons grouped by object, "||" conditions = conditions grouped by being containe din distinct objects)
        const { quote_packages, jobtype } = this.state;
        let options_to_check = []; //more than one "Renders" category to check is possible (all, jobtype, default, etc.)
        let should_render = false;
        let packages_to_render = [];
        let render_conditions;

        if (!quote_packages){
            console.error("No service packages to render!");
            return ;
        }

        for (let i = 0; i < quote_packages.length; i++){
            should_render = false;
            console.log("Checking package: " + quote_packages[i].title)
            if (quote_packages[i].Areas["all"]){
                options_to_check.push("all");
            } else if (quote_packages[i].Areas["default"]){
                options_to_check.push("default");
            } else if (quote_packages[i].Areas[jobtype]) {
                options_to_check.push(jobtype);
            }
            console.log("options to check: ")
            console.log(options_to_check)
            for (let j = 0; j < options_to_check.length; j++){
                render_conditions = quote_packages[i].Areas[options_to_check[j]].Render;
                console.log("render conditions: ")  
                console.log(render_conditions)
                for (let k = 0; k < render_conditions.length; k++){
                    should_render = true; 
                    for (const [key, value] of Object.entries(render_conditions[j])){
                        if (!conditionIsMet(key, value, this.state)){ //if any condition is not met, move on to next render object
                            console.log("condition not met: " + key + " " + value + " breaking from loop at A")
                            should_render = false;
                            break;
                        }
                        //should_render = true; //if all conditions are met within a single object, include package
                        //break;
                    }
                    if (should_render){
                        console.log("should render: " + should_render)
                        packages_to_render.push(quote_packages[i]); //if all conditions are met within a single object, default include package
                        break;
                    }
                }
                console.log("should render: " + should_render)
                options_to_check = [];
            }
        }
        console.log("packages to render: ")
        console.log(packages_to_render)
        return packages_to_render;
    }


    render() {
        const { is_mobile_display} = this.props
        const { pages, driveway_condition_levels, driveway_status_choices, map_loaded} = this.state;
        const {sqfootage_lowend, sqfootage_highend, sqfootage_user, businessid, quoteid, driveway_status, driveway_condition, email, phone, customername, address, preferredcontact, branch, jobLatLng, jobtype, job_images} = this.state;
        const {businessname, businesslogo, branch_options, jobtype_options, base_items, addon_items, inpersons, quote_range, homepage, nodisplays, marketing_tag, industry, afterquote_url, hd_mode} = this.state;
        const { fees, win_height, win_width, sealed_before, clicked_locations, permissioned_followup, present_for_quote_verification, jobtypes_without_scheduled_followup } = this.state;
        const values_general = { 
            pages 
        };
        const values_customer = { //Customer Modified
            branch,
            jobtype,
            sqfootage_highend,
            sqfootage_lowend, 
            sqfootage_user,
            driveway_status,
            driveway_condition,
            email,
            phone,
            customername,
            address,
            preferredcontact,
            jobLatLng,
            job_images,
            sealed_before,
            clicked_locations,
            hd_mode,
            permissioned_followup,
            present_for_quote_verification
        };
        const values_business = { //Company-specific Information
            businessid,
            businessname,
            businesslogo,
            branch_options,
            jobtype_options,
            nodisplays,
            base_items,
            addon_items,
            inpersons,
            quote_range,
            homepage,
            marketing_tag,
            industry,
            embedded: this.props.embedded,
            afterquote_url,
            get_specific_address : true, //always require street address with number, not just city
            jobtypes_without_scheduled_followup : jobtypes_without_scheduled_followup
        };
        const values_quote = {  //modified by Quote API
            quoteid, 
            fees
        };

        let suggested_job_statement;
        let suggested_gpt_prompts;
        let driveway_obj;
        if (driveway_status && driveway_status !== 'paved') {
            driveway_obj = driveway_status_choices[this.findIndexByProperty(driveway_status_choices, 'value', driveway_status)];
        } 
        else if (driveway_condition) {
            driveway_obj = driveway_condition_levels[this.findIndexByProperty(driveway_condition_levels, 'value', driveway_condition)];
        } 
        if (driveway_obj){
            suggested_job_statement = driveway_obj.suggested_description;
            suggested_gpt_prompts = driveway_obj.gpt_prompts;
        }

        let thispage = pages[pages.length -1];
        console.log("this page is: " + thispage);
        let nextpage;
        let returnedPage = null;
        const current_page = pages[pages.length -1];


        if (current_page === "Schedule" && this.state.map_loaded){
            returnedPage = (
                <div className='container'>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_business}
                        is_mobile_display = {is_mobile_display}
                    />
                    <ProgressBar
                        progress= '100'
                    />
                    <ScheduleContact
                        key = {thispage}
                        pagetype={'estimate'} 
                        handleScheduleEstimate={this.handleScheduleEstimate}
                        handleChangeObject={this.handleChangeObject} 
                        values_customer={values_customer}
                        values_business={values_business}
                        win_width = {win_width}        
                        is_mobile_display = {is_mobile_display}      
                        email_regex = {this.state.EMAIL_REGEX}
                        phone_regex = {this.state.PHONE_REGEX}     
                    />
                </div>
            )
        }
        else if (current_page === "Contact" && this.state.map_loaded){
            returnedPage = (
                <div className='container'>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_business}
                        is_mobile_display = {is_mobile_display}
                    />
                    <ProgressBar
                        progress= '100'
                    />
                    <ScheduleContact
                        key = {thispage}
                        pagetype={'call'} 
                        handleScheduleEstimate={this.handleScheduleEstimate}
                        handleChangeObject={this.handleChangeObject} 
                        values_customer={values_customer}
                        values_business={values_business}
                        win_width = {win_width}        
                        is_mobile_display = {is_mobile_display}      
                        email_regex = {this.state.EMAIL_REGEX}
                        phone_regex = {this.state.PHONE_REGEX}     
                    />
                </div>
            )
        }
        else if (current_page === "Branch"){
            console.log("rendering branch");
            if (!this.state.branch_options || !this.state.jobtype_options){
                console.log("loading branch");
                returnedPage = (
                    <div className='container' key={this.state.branch_options}>
                        <Header
                            prevPage={this.prevPage} 
                            values_general={values_general}
                            values_business={values_business}   
                        />
                        <FontAwesomeIcon 
                            icon={faSyncAlt}
                            class='fa-spin spinner'
                        />
                    </div>
                )
            } else {
                console.log("branch loaded");
                returnedPage = (
                    <div className='container'>
                        <Header
                            prevPage={this.prevPage} 
                            values_general={values_general}
                            values_business={values_business}
                            is_mobile_display = {is_mobile_display}
                        />
                        <ProgressBar
                            progress= '0'
                        />
                        <Branch
                            key = {JSON.stringify(this.state.branch_options)}
                            handleChangeObjectNext={this.handleChangeObjectNext}
                            showpage = {this.state.branch_options.length !== 1}
                            nextPage={this.nextPage}
                            pages={pages}
                            next = {'Industry'}
                            values_customer={values_customer} 
                            values_business={values_business}  
                            is_mobile_display = {is_mobile_display}   
                        />
                    </div>
                )
            }
        }
        else if (current_page === "Industry"){
            returnedPage = (
                <div className='container'>
                    <Header

                        prevPage={this.prevPage}
                        values_general={values_general}
                        values_business={values_business}
                        is_mobile_display = {is_mobile_display}
                    />
                    <ProgressBar
                        progress= '0'
                    />
                    <MultipleChoiceText
                        page_title = {thispage}
                        handleChangeObjectNext={this.handleChangeObjectNext}
                        handleChangeEstimateReason={this.handleChangeEstimateReason}
                        showpage = {true}
                        nextPage={this.nextPage}
                        default_next = {'Demographic'}
                        pages={pages}
                        header_question = {'What service do you need?'}
                        default_state_var = {'jobtype'}
                        choices = {[
                            {title: 'Asphalt Paving & Sealing', value: {title:'Asphalt'}, nextpage: 'Demographic'},
                            {title: 'Concrete', value: {title:'Concrete'}, nextpage: 'Contact'},
                            {title: 'Epoxy Flooring', value: {title:'Epoxy Flooring'}, nextpage: 'Contact'},
                            {title: 'Construction', value: {title:'Construction'}, nextpage: 'Contact'},
                            {title: 'Commercial Snow Plowing', value: {title:'Commercial Snow Plowing'}, nextpage: 'Contact'},
                            {title: 'Other', value: {title:'Other'}, nextpage: 'Contact'},
                        ]}
                        hide_gpt = {true}
                        values_customer={values_customer}
                        values_business={values_business} 
                        is_mobile_display = {is_mobile_display}
                        estimate_reason = {'Customer requested an estimate for a service not quotable online'}
                    />
                </div>
            )
        }
        else if (current_page === "Demographic"){
            thispage = 'Demographic';
            nextpage = 'Recommendation';
            returnedPage = (
                <div className='container'>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_business}
                        is_mobile_display = {is_mobile_display}
                    />
                    <ProgressBar
                        progress= '5'
                    />
                    <MultipleChoiceText
                        page_title = {thispage}
                        handleChangeObjectNext={this.handleChangeObjectNext}
                        handleChangeEstimateReason={this.handleChangeEstimateReason}
                        showpage = {true}
                        nextPage={this.nextPage}
                        default_next = {nextpage}
                        pages={pages}
                        header_question = {'What type of work do you need done?'}
                        default_state_var = {'customer_type'}
                        choices = {[
                            {title: 'Residential', value: 'residential', info:`Asphalt Sealing;New Topcoat;New Driveway or Addition`, preview: null},
                            {title: 'Commercial', value: 'commercial', info:`Milling;New Parking Lot;Line Striping;Asphalt Sealing;Paving`, nextpage: 'Commercial Job Type', preview: null},
                        ]}
                        hide_gpt = {true}
                        values_customer={values_customer}
                        values_business={values_business} 
                        is_mobile_display = {is_mobile_display}
                        estimate_reason = {'Customer requested an estimate for a commercial job'}
                    />
                </div>
            )
        }
        else if (current_page === "Commercial Job Type"){
            thispage = 'Commercial Job Type';
            nextpage = 'Job Type';
            returnedPage = (
                <div className='container'>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_business}
                        is_mobile_display = {is_mobile_display}
                    />
                    <ProgressBar
                        progress= '5'
                    />
                    <MultipleChoiceText
                        page_title = {thispage}
                        handleChangeObjectNext={this.handleChangeObjectNext}
                        handleChangeEstimateReason={this.handleChangeEstimateReason}
                        showpage = {true}
                        nextPage={this.nextPage}
                        default_next = {nextpage}
                        pages={pages}
                        header_question = {'What commercial service would you like a quote for?'}
                        default_state_var = {'jobtype'}
                        choices = {[
                            {title: 'Sealing', value: {title:'Commercial Sealing'}, preview: null},
                            {title: 'Paving', value: {title:'Commercial Paving'}, preview: null},
                        ]}
                        hide_gpt = {true}
                        values_customer={values_customer}
                        values_business={values_business} 
                        is_mobile_display = {is_mobile_display}
                        estimate_reason = {'Customer requested an estimate for a commerical job'}
                    />
                </div>
            )
        }
        else if (current_page === "Recommendation"){
            returnedPage = (
                <div className='container'>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_business}
                        is_mobile_display = {is_mobile_display}
                    />
                    <ProgressBar
                        progress= '20'
                    />
                    <MultipleChoiceText
                        page_title = {thispage}
                        handleChangeObjectNext={this.handleChangeObjectNext}
                        handleChangeEstimateReason={this.handleChangeEstimateReason}
                        showpage = {true}
                        nextPage={this.nextPage}
                        default_next = {nextpage}
                        pages={pages}
                        header_question = {'Do you already know what you need?'}
                        default_state_var = {'needs_suggestion'}
                        choices = {[
                            {title: 'Yes, not my first rodeo', value: false, nextpage: 'Job Type'},
                            {title: 'No, help me decide', value: true, nextpage: 'Driveway'},
                        ]}
                        hide_gpt = {true}
                        values_customer={values_customer}
                        values_business={values_business} 
                        is_mobile_display = {is_mobile_display}
                    />
                </div>
            )
        }
        /***BEGIN "!RECOMMENDATION" ROUTE ***/   
        else if (current_page === "Job Type"){
            returnedPage = (
                <div className='container'>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_business}
                        is_mobile_display = {is_mobile_display}
                    />
                    <ProgressBar
                        progress= '35'
                    />
                    <JobType
                        handleChangeObjectNext={this.handleChangeObjectNext}
                        showpage = {true}
                        nextPage={this.nextPage}
                        pages={pages}
                        next = {'Sealant Status'}
                        values_customer={values_customer} 
                        values_business={values_business}  
                        is_mobile_display = {is_mobile_display}   
                        gpt_prompts = {[
                            {   
                                display_text: `What is asphalt sealing?`,
                                gpt_query: `What is asphalt sealing?`
                            },
                            {
                                display_text: `What is an asphalt top coat?`,
                                gpt_query: `What is an asphalt top coat??`
                            },
                            {
                                display_text: `How do I know if my driveway needs to be replaced?`,
                                gpt_query: `How do I know if my driveway needs to be replaced?`
                            }
                        ]}
                    />
                </div>
            )
        }
        else if (current_page === "Sealant Status"){
                returnedPage = (
                    <div className='container'>
                        <Header
                            prevPage={this.prevPage} 
                            values_general={values_general}
                            values_business={values_business}
                            is_mobile_display = {is_mobile_display}
                        />
                        <ProgressBar
                            progress= '60'
                        />
                        <MultipleChoiceText
                            page_title = {'Sealant Status'}
                            handleChangeObjectNext={this.handleChangeObjectNext}
                            handleChangeEstimateReason={this.handleChangeEstimateReason}
                            showpage = {this.state.driveway_condition === 'A' || (this.state.jobtype && this.state.jobtype.title.toLowerCase().includes('sealing'))}
                            nextPage={this.nextPage}
                            default_next = {'Job Address'}
                            pages={pages}
                            header_question = {'Has your driveway been sealed before?'}
                            default_state_var = {'sealed_before'}
                            choices = {[
                                {title: 'Yes it has', value: true, preview: null},
                                {title: 'No, not to my knowledge', value: false, preview: null},
                            ]}
                            values_customer={values_customer}
                            values_business={values_business} 
                            is_mobile_display = {is_mobile_display}
                        />
                    </div>
                )
        }
        /***END "!RECOMMENDATION" ROUTE ***/ 
        /********************************* */  
        /***BEGIN "RECOMMENDATION" ROUTE ***/   
        else if (current_page === "Driveway"){
            returnedPage = (
                <div className='container'>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_business}
                        is_mobile_display = {is_mobile_display}
                    />
                    <ProgressBar
                        progress= '35'
                    />
                    <MultipleChoiceText
                        page_title = {'Driveway'}
                        handleChangeObjectNext={this.handleAsphaltStatus}
                        handleChangeEstimateReason={this.handleChangeEstimateReason}
                        showpage = {true}
                        nextPage={this.nextPage}
                        default_next = {'Driveway Condition'}
                        pages={pages}
                        header_question = {'Which best describes your driveway/asphalt status?'}
                        default_state_var = {'driveway_status'}
                        choices = {driveway_status_choices}
                        values_customer={values_customer}
                        values_business={values_business} 
                        is_mobile_display = {is_mobile_display}
                        gpt_prompts = {[
                            {   
                                display_text: `What is a binder coat?`,
                                gpt_query: `What is a binder coat?`,
                            },
                            {
                                display_text: `How do I know if my area has a binder coat already?`,
                                gpt_query: `How do I know if my area has a binder coat already?`,
                            }
                        ]}
                    />
                </div>
            )
        }
        else if (current_page === "Driveway Condition"){
            returnedPage = (
                <div className='container'>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_business}
                        is_mobile_display = {is_mobile_display}
                    />
                    <ProgressBar
                        progress= '50'
                    />
                    <ConditionChooser
                        page_title = {'Driveway Condition'}
                        handleChangeObjectNext={this.handleDrivewayCondition}
                        showpage = {this.state.driveway_status === 'paved'}
                        nextPage={this.nextPage}
                        default_next = {'Suggested Job Type'}
                        pages={pages}
                        header_question = {'Which driveway most closely matches the condition of your asphalt?'}
                        default_state_var = {'driveway_condition'}
                        condition_levels = {driveway_condition_levels}
                        values_customer={values_customer} 
                        values_business={values_business}  
                        is_mobile_display = {is_mobile_display}
                    />
                </div>
            )
        }
        else if (current_page === "Suggested Job Type"){
            if (!suggested_gpt_prompts){
                returnedPage = (
                    <div className='container'>
                        <MakeStatement
                            page_title = {'Suggested Job Type'}
                            handleChangeNext={this.handleChangeNext}
                            showpage = {true}
                            nextPage={this.nextPage}
                            next = {'Sealant Status'}
                            pages={pages}
                            header_statement = {suggested_job_statement}
                            values_customer={values_customer}
                            values_business={values_business}
                            is_mobile_display = {is_mobile_display}
                        />
                    </div>
                )
            } else {
                returnedPage = (
                    <div className='container'>
                        <Header
                            prevPage={this.prevPage} 
                            values_general={values_general}
                            values_business={values_business}
                            is_mobile_display = {is_mobile_display}
                        />                   
                        <ProgressBar
                            progress= '55'
                        />
                        <MakeStaticStatement
                            page_title = {'Suggested Job Type'}
                            handleChangeNext={this.handleChangeNext}
                            showpage = {true}
                            nextPage={this.nextPage}
                            next = {'Sealant Status'}
                            pages={pages}
                            header_statement = {suggested_job_statement}
                            values_customer={values_customer}
                            values_business={values_business}  
                            is_mobile_display = {is_mobile_display}
                            gpt_prompts = {suggested_gpt_prompts}
                        />
                        <ContinueFooter
                            nextPage={this.nextPage}
                            enable = {true}
                            nextpage = 'Sealant Status'
                            skip_history = {true}
                            //alternate_text = {(!this.state.job_images) ? 'Skip' : null}
                        />
                    </div>
                )
            }
        }
        /***END "RECOMMENDATION" ROUTE ***/   
        else if (current_page === "Job Address"){
            returnedPage = (
                <div className='container'>
                    <Header
                        prevPage={this.prevPage}
                        values_general={values_general}
                        values_business={values_business}  
                        is_mobile_display = {is_mobile_display} 
                    />
                    <ProgressBar
                        progress= '75'
                    />
                    {/* <CollectAddress
                        handleChangeObject={this.handleChangeObject}
                        handleChangeNext={this.handleChangeNext}
                        nextPage={this.nextPage}
                        next = {'Square Footage'}
                        showpage = {true}
                        values_customer={values_customer}   
                        values_business={values_business}
                        is_mobile_display = {is_mobile_display} 
                        pages = {pages}                                             
                /> */}
                    <Places
                        handleChangeNext = {this.handleChangeNext}
                        handleChangeObject = {this.handleChangeObject}
                        handleUpdateDistance = {this.handleChangeObject}
                        nextPage={this.nextPage}
                        next = {'Square Footage'}
                        showpage = {true}
                        values_customer={values_customer}   
                        values_business={values_business}
                        is_mobile_display = {is_mobile_display}    
                        pages = {pages}     
                        custom_header = {'What is the address of the job?'}
                        destination_state_var = 'address'
                        source_state_val = {values_customer.branch.address}//source_state_val = {values_customer.branch.address}
                        //distance_mode = {travel_distance_mode}
                    />
                </div>
            )
        }
        else if (current_page === "Square Footage"){
            returnedPage = (
                <div className='container'>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_business}   
                        is_mobile_display = {is_mobile_display}
                    />
                    <ProgressBar
                        progress= '90'
                    />
                    <SquareFootageMapHighRes
                        values_customer={values_customer}
                        nextPage={this.nextPage}
                        next = {'Square Footage Confirmation'}
                        handleChangeObject={this.handleChangeObject}
                        handleSaveMapClicks={this.handleSaveMapClicks} //save map clicks anytime polygon is created, ?and on next page click?
                        user_mode = {'customer'}
                        is_mobile_display = {is_mobile_display}
                    />

                </div>
            )
        }
        else if (current_page === "Square Footage Confirmation"){
            returnedPage = (
                <div className='container'>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_business}   
                        is_mobile_display = {is_mobile_display}
                    />
                    <ProgressBar
                        progress= '90'
                    />
                    <SquareFootageMapConfirmation
                        values_customer={values_customer}
                        nextPage={this.nextPage}
                        next = {'Image Upload'}
                        handleChangeObject={this.handleChangeObject}
                        handleSaveAreaRange={this.handleSaveAreaRange} 
                        user_mode = {'customer'}
                        is_mobile_display = {is_mobile_display}
                    />

                </div>
            )
        }
        else if (current_page === "Image Upload"){
                thispage = pages[pages.length -1];
                returnedPage = (
                    <div className='container'>
                        <Header
                            prevPage={this.prevPage}
                            values_general={values_general}
                            values_business={values_business}
                            is_mobile_display = {is_mobile_display}
                        />
                        <ProgressBar
                            progress= '70'
                        />
                        <ImageUpload
                            page_title={thispage}
                            showpage = {true}
                            nextPage={this.nextPage}
                            next = {'Email Address'}
                            header_question = {'Do you have any pictures of your existing asphalt/job area conditions that you can share?'}
                            header_statement = {'This step is optional, but recommended for the most accurate quote.'}
                            images = {[
                                {title: 'Road to house (general view)', src: upload_ex_entire},
                                {title: 'Entryway Seam', src: upload_ex_seam},
                                {title: 'Damage', src: upload_ex_damage},
                                {title: 'Other', src: ''}
                            ]}
                            handleChangeObject={this.handleChangeObject}
                            job_images = {this.state.job_images}
                            is_mobile_display = {is_mobile_display}
                        />
                        <ContinueFooter
                            nextPage={this.nextPage}
                            enable = {true}
                            nextpage = 'Email Address'
                            alternate_text = {(!this.state.job_images) ? 'Skip' : null}
                        />
                    </div>
                )
        }
        else if (current_page === "Email Address"){
                thispage = pages[pages.length -1];
                returnedPage = (
                    <div className='container'>
                        <Header
                            prevPage={this.prevPage}
                            values_general={values_general}
                            values_business={values_business}  
                            is_mobile_display = {is_mobile_display}
                        />
                        <ProgressBar
                            progress= '92'
                        />
                        <CollectContact
                            handleChange={this.handleChange}
                            nextPage={this.nextPage}
                            next = {'Quote'}
                            showpage = {true}
                            page_title = {thispage}
                            pages={pages}
                            values_customer={values_customer} 
                            values_business={values_business}
                            is_mobile_display = {is_mobile_display}
                            getDBQuote={this.getDBQuote}
                            requirePhone = {true}
                            regex = {this.state.EMAIL_REGEX} 
                            regex_phone = {this.state.PHONE_REGEX}
                            enable = {this.state.quote_packages}                                                   
                        />
                    </div>
                )
        }
        else if (current_page === "Quote"){
            returnedPage = (
                <div className='container'>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_business}   
                        is_mobile_display = {is_mobile_display}
                    />
                    <ProgressBar
                        progress= '100'
                    />
                    {/* <QuotePackages_v2
                        handleChange={this.handleChange}
                        handleChangeObject={this.handleChangeObject}
                        handleSaveQuote={this.handleSaveQuote}
                        handleScheduleJob={this.handleScheduleJob}
                        handleViewedQuote={this.handleViewedQuote}
                        values_customer={values_customer}
                        values_business={values_business}
                        values_quote={values_quote} 
                        addon_items={values_business.addon_items}
                        //singlepackage={true}  
                        is_mobile_display = {is_mobile_display}    
                        email_regex = {this.state.EMAIL_REGEX}
                        phone_regex = {this.state.PHONE_REGEX}
                        offer_discount = {true}
                    /> */}
                    <QuoteServices
                        handleChange={this.handleChange}
                        handleChangeObject={this.handleChangeObject}
                        handleSubmitQuote={this.handleSubmitQuote}
                        handleViewedQuote={this.handleViewedQuote}
                        values_customer={values_customer}
                        values_business={values_business}
                        values_quote={values_quote} 
                        addon_items={values_business.addon_items}
                        package_objects = {this.getServicePackages()}
                        is_mobile_display = {is_mobile_display}    
                        email_regex = {this.state.EMAIL_REGEX}
                        phone_regex = {this.state.PHONE_REGEX}
                        offer_discount = {false}
                    />
                </div>
            )
        }
        else{
            returnedPage = (
                <div className='container' key={branch_options}>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_business}   
                        is_mobile_display = {is_mobile_display}
                    />
                    <FontAwesomeIcon 
                        icon={faSyncAlt}
                        class='fa-spin spinner'
                    />
                </div>
            )

        }
        return (
            <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} onLoad={this.setMapLoaded} libraries={this.props.google_maps_libraries}>
                {this.state.map_loaded ? //wait for Error Boundary to render until map is loaded
                    <ErrorBoundary
                        key={pages}
                        handleScheduleEstimate={this.handleScheduleEstimate}
                        handleChangeObject={this.handleChangeObject} 
                        prevPage={this.prevPage}
                        values_customer={values_customer}
                        values_business={values_business}  
                        values_general={values_general}
                        is_mobile_display = {is_mobile_display}      
                        email_regex = {this.state.EMAIL_REGEX}
                        phone_regex = {this.state.PHONE_REGEX}  
                    >   
                        {returnedPage}
                    </ErrorBoundary> 
                    :{returnedPage}
                }
            </LoadScript>
        )     
    }
}

export default AsphaltSurvey;
