//modal that interfaces with custom GPT assistant, with prompts for user input
//components: GPTmodal, GPTQuestionPrompts

import React, { Component } from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes, faExclamationTriangle, faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons'
import { CSSTransition } from 'react-transition-group';
import Matomo from '../../Matomo';
import SingleSelectionPreview from '../components/SingleSelectionPreview';

class CoatPreviewModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            mounted: false,
            color: this.props.color,
            preview_index: 0,
            image_loaded: false,
        };
    }

    async componentDidMount() {
        /*-Matomo Analytics-*/
        const title = 'ColorPreviewModal';
        await Matomo(title);
        /*----End Matomo----*/
        //this.getCenter().scrollIntoView();
        this.setState({mounted: true})
        if (this.props.show){
            this.handleShow();
        }
    }

    //before unmounting, set mounted to false so that the modal fades out
    componentWillUnmount() {
        this.setState({mounted: false})
    }

    scrollToCenterOfImage = (containerSelector, imageSelector) => {
        // Get the container and image elements
        var container = document.querySelector(containerSelector);
        var image = document.querySelector(imageSelector);
        console.log(container, image);
      
        if (container && image) {
          // Calculate the center position
          console.log(image.offsetWidth, container.offsetWidth);
          console.log(image.offsetHeight, container.offsetHeight);
          var centerX = (image.offsetWidth - container.offsetWidth) / 2;
          var centerY = (image.offsetWidth - container.offsetHeight) / 2;
            console.log(centerX, centerY);
          // Scroll the container to the center of the image
          container.scrollLeft = centerX;
          container.scrollTop = centerY;
        } else {
          console.error('Container or image not found');
        }
      }
    

    handleShow = () => {
        this.setState({ show: true });
    };

    getHeader = (header, directions) => {
        const {ismobile, color} = this.props;
        if (!ismobile) {
            return (
                <div className='preview-title'>
                    <div className='display-flex-row'>
                        <div className= 'color-icon preview'>
                            <img
                                className='large-icon'
                                src={color.icon}
                                alt={header} 
                                //onClick={this.setCurrentPreview(c)}
                            />
                        </div>
                        <div className='display-flex-column'>
                            <h4 className='medium'>{header}</h4>
                            {/* <h4 className='small light'>{color.previews.length} preview(s) available</h4> */}
                            <h4 className='small'>{directions}</h4>
                            <h4 className='x-small light'>This preview is AI-generated.</h4>
                        </div>
                    </div>
                </div>
            )
        } else {
            return(
                <div className='preview-title'>
                    <div className='display-flex-column'>
                        <div className= 'display-flex-row justify-left'>
                            <div className= 'color-icon preview'>
                                <img
                                    className='large-icon'
                                    src={color.icon}
                                    alt={header} 
                                    //onClick={this.setCurrentPreview(c)}
                                />
                            </div>
                            <h4 className='medium'>{header}</h4>
                        </div>
                        <h4 className='small font-margin-top'>{directions}</h4>
                        <h4 className='x-small light no-margin'>This preview is AI-generated.</h4>
                    </div>
                </div>
            )
        }
    }

    handleLeftArrow = () => {
        const {preview_index} = this.state;
        this.setState({preview_index: preview_index - 1})
    }

    handleRightArrow = () => {
        const {preview_index} = this.state;
        this.setState({preview_index: preview_index + 1})
    }

    getPreviewImg = () => {
        const {preview_index} = this.state;
        const {color} = this.props;

        // const has_left = (preview_index > 0);
        // const has_right = (preview_index < color.previews.length - 1);
        // const left_arrow = <FontAwesomeIcon  icon={faChevronLeft} disabled={!has_left}/>;
        // const right_arrow = <FontAwesomeIcon  icon={faChevronRight} disabled={!has_right}/>;

        if (color.previews[preview_index]) {
            return (
                <div className='display-flex-row justify-none'>
                    {/* <button
                        className='preview-image-nav left-arrow'
                        onClick={this.handleLeftArrow}
                        disabled={!has_left}
                    >
                        {left_arrow}
                    </button> */}
                    <img
                        className='preview-photo'
                        src={color.previews[preview_index]}
                        alt={color.title}
                        onLoad={() => {
                            this.scrollToCenterOfImage('.fullimagewrapper-modal', '.preview-photo');
                        }}
                    />
                    {/* <button
                        className='preview-image-nav right-arrow'
                        onClick={this.handleRightArrow}
                        disabled={!has_right}
                    >
                        {right_arrow}
                    </button> */}
                </div>
            )
        } else {
            return (
                <div className='center-absolute'>
                    <p className='medium description'> Sorry <FontAwesomeIcon icon={faExclamationTriangle}/>
                    <p className='small description'>We're still working on a preview for {color.title}.</p></p>
                </div>
            )
        }

    }


    render() {
        const {handleClose, show, color} = this.props;
        const showHideClassName = show ? "modal display-block" : "modal display-none";
        const header = (color) ? color.title + ' ' + color.style + ' Preview' : 'Preview';
        const directions = `Don't stress this part too much. You can always change your color later.`;
        return show && color ? (
            <CSSTransition in={this.state.mounted} timeout={300} classNames="fade-fast" unmountOnExit>
                <div className="modal display-flex-row">
                    <section className="modal-preview-fullscreen coating">
                        <div key={color.icon} className='fullimagewrapper-modal'>
                            <div className='modal-header-row'>
                                {this.getHeader(header, directions)}
                                <div className="close-modal-icon" onClick={handleClose}>
                                    <FontAwesomeIcon 
                                        className='large no-margin'
                                        icon={faTimes}
                                        color='black'
                                    />
                                </div>
                            </div>
                            {this.getPreviewImg()}
                        </div>
                    </section>
                </div>
            </CSSTransition>
        ) : null;
    }
}

export default CoatPreviewModal;
