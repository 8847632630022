import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';
import Matomo from '../../Matomo';
import { findObjectByProperty } from '../../../Helpers';
import AsphaltQuoteSummary from '../components/AsphaltQuoteSummary';
import axios from 'axios';
import Loading from '../../general_questions/components/LoadingIcon';

class QuoteSummaryModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            mounted: false,
            pending_quote_update: false,
            notifications_sent: false
        };
    }

    componentDidMount() {
        this.setState({mounted: true});
        console.log('QuoteSummaryModal mounted with info:', this.props.quoteInfo);
    }

    componentWillUnmount() {
        this.setState({mounted: false});
    }

    handleUpdateQuoteAndNotify = async () => {
        const { quoteInfo } = this.props;
        const { pending_quote_update, notifications_sent } = this.state;
        console.log('Sending updated quote to customer:', quoteInfo);
        if(!pending_quote_update){
            this.setState({pending_quote_update: true})
        }
        try {
            const response = await axios.post(
            'https://44n4l2dhurrqmazbhcw3d7yrka0cqzjl.lambda-url.us-east-2.on.aws/',
                { 
                id: quoteInfo.quoteid,
                industry: quoteInfo.industry,
                sqfootage_verified: quoteInfo.sqfootage,
                totalquote_exact: quoteInfo.totalquote_exact,
                clicked_locations_verified: quoteInfo.clicked_locations
                }
            );
            console.log(response);
        } catch (error){
            console.error("Error updating quote:", error);
        } finally {
            this.setState({ pending_quote_update: false, notifications_sent: true });
        }
    }

    getMainBody = () => {
        const {pending_quote_update, notifications_sent} = this.state;
        const {quoteInfo} = this.props
        if (pending_quote_update){
            return (
                <div className='center'>
                    <Loading/>
                </div>
            )
        } else if (notifications_sent) {
            return (
                <div className='column'>
                    <p className='m-small'>Success! Customer FairQuote #{quoteInfo.quoteid} for {quoteInfo.customername} has been updated and the customer has been notified. A copy of the updated quote details has also been sent to your inbox.</p>
                    <p className='x-small light'>Questions, help, or feedback? Contact us: <a href='mailto:support@thefairquote.com'>support@thefairquote.com</a></p>
                </div>
            )
        } else {
            return <AsphaltQuoteSummary quoteInfo={quoteInfo} />
        }
    }

    render() {
        const { handleClose, quoteInfo, notifications_sent, pending_quote_update } = this.props;
        //if delay_show prop, wait 1/2 second before showing modal
        return (
            <CSSTransition in={this.state.mounted} timeout={300} classNames="fade-fast" unmountOnExit>
                <div className={"modal display-block"}>
                    <section className="modal-main">
                        <div className="right">
                            <div className="close-modal-icon" onClick={handleClose}>
                                <FontAwesomeIcon className='large no-margin' icon={faTimes} color='black' />
                            </div>
                        </div>
                        {this.getMainBody()}
                        <div className='fillremainderflex'></div>
                        {!notifications_sent && 
                            <button className="formsubmitbtn fit-content" onClick={this.handleUpdateQuoteAndNotify} disabled={pending_quote_update}>
                                <p className='m-small no-margin row'>Send Customer Updated Quote{' '}<FontAwesomeIcon icon={faEnvelope} className='medium inline align-self-center' style={{margin: '0px 0px 0px 5px'}}/></p>
                            </button>
                        }

                    </section>
                </div>
            </CSSTransition>
        );
    }
}

export default QuoteSummaryModal;
