import React, {Component} from 'react';
import floorPic from './exposed_coated.JPG'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faExclamationCircle, faImage} from '@fortawesome/free-solid-svg-icons'
import { CSSTransition } from 'react-transition-group'
import { PreviewModal } from './Modals/PreviewModal'

import Matomo from '../Matomo';
import { FooterContact } from '../general_questions/FooterContact';
import {CapitalizedText} from '../general_questions/components/CapitalizedText';
import { capatilizeFirstLetters } from '../../Helpers';

export class ExistingCoat extends Component {

    constructor (props) {
        super(props);
        this.state = {
            showabout : false,
            showmodal: '',
            nextpage: props.next,
            mounted: false
        }    
        this.getButtonActivity = this.getButtonActivity.bind(this);
        this.showAboutModal = this.showAboutModal.bind(this);
        this.hideAboutModal = this.hideAboutModal.bind(this);
        this.handleChangeExistingType = this.handleChangeExistingType.bind(this);
        this.handleChangeHasExisting = this.handleChangeHasExisting.bind(this);
    }

    componentDidMount = () => {
        /*-Matomo Analytics-*/
        const title = 'ExistingCoat';
        Matomo(title);
        /*----End Matomo----*/
        this.setState({ mounted: true });
    }

    showAboutModal = () => { // ?when passed in a specific state value to change, exceeds max update depth..?
        this.setState({ showabout: true });
    };
    
    hideAboutModal = () => {
        this.setState({ showabout: false });
    };

    showPreviewModal = () => e => {
        document.body.style.overflow = 'hidden';
        this.setState({ showmodal: e.currentTarget.id});
    };
    
    hidePreviewModal = () => {
        document.body.style.overflow = 'auto';
        this.setState({ showmodal: '' });
    };

    getButtonActivity = val =>{
        var saved = this.props.values_customer.hasexistingcoat;
        if (saved===val) {
            return 'columnbtn active';
        }
        return 'columnbtn';
    };
    getPreviewModal = () => {
        const title = 'Pre-existing vs. No Coating';
        const content = {title: title, src : floorPic};
        const show = (this.state.showmodal === title);
        return(
            <PreviewModal 
                show={show} 
                handleClose={() => this.hidePreviewModal()} 
                info={content}>
            </PreviewModal>
        )
    }
    handleChangeHasExisting = () => e => {
        const reason = 'Customer has a pre-existing coating application or flooring in their job area.';
        const value = e.target.value;
        if(value === 'false'){
            this.props.handleChangeNext(this.state.nextpage,'hasexistingcoat')(e)
        }
        else if (this.sendToInpersons(value)){
            this.props.handleChangeEstimateReason(reason,'hasexistingcoat', value)
        }
        else {
            this.props.handleChange('hasexistingcoat')(e)
        }
    }
    handleChangeExistingType = () => e =>{
        console.log("handleChangeExistingType")
        console.log(e)
        //if (e.detail === 0){
            const reason = 'Customer has a pre-existing ' + e.target.value + ' application or flooring in their job area.';
            const value = e.target.value;
            if (this.sendToInpersons(value)){
                this.props.handleChangeEstimateReason(reason,'existingcoat', value)
            }
            else{
                this.props.handleChangeNext(this.state.nextpage,'existingcoat')(e)
            }
        //}
    }
    sendToInpersons = (selected_coat) => {
        const inpersons = this.props.values_business.inpersons;
        const areatype = this.props.values_customer.areatype.title.toLowerCase();
        const servicetype = this.props.values_customer.servicetype.service.toLowerCase();
        const page = "ExistingCoat";
        let is_inperson = true;
        let jobtype; //ultimitely set to either the servicetype, areatype, or default whatever is specifically identified in the Inpersons object
        
        console.log("Inpersons: " + selected_coat)
        //console.log(selected_floorcondition)
        
        if (inpersons[page]){                                   //if a inpersons exists for this page
            if (inpersons[page][servicetype]) {                    //and exists for the current selected servictype (or servicetype or default)
                jobtype = servicetype;
            }
            else if (inpersons[page][areatype]) {
                jobtype = areatype;
            }
            else if (inpersons[page]["default"]){
                jobtype = "default";
            }
            console.log("jobtype: " + jobtype)
            console.log(inpersons[page][jobtype])
            if (jobtype){
                for (const [condition, condition_value] of Object.entries(inpersons[page][jobtype])){  //check ONE condition at a time
                    switch (condition) {
                        case "inperson":
                            if (condition_value === "never"){  //immediately DO NOT send to in person quote page, regardless of other conditions
                                return false;
                            } else if (condition_value === "always"){ //immediately DO send to in person quote page, regardless of other conditions
                                return true;
                            }
                            break;
                        case "existingcoat":
                            is_inperson = (condition_value === selected_coat)
                            break;
                        case "hasexistingcoat":
                            is_inperson = (condition_value === this.props.values_customer.hasexistingcoat)
                            break;
                    }
                    if (!is_inperson){                          //if ANY conditions are not met, do not send to in person quotepage
                        return false;
                    }
                }
                return true;
            }
        }
        else{
            return false;
        }
   
    }

    render() { 
        const { handleChangeNext, handleChangeEstimateReason, values_business, values_customer, is_mobile_display} = this.props;
        const yes = 'yes';
        const shouldDisplay = values_customer.hasexistingcoat === 'true';
        const placeholder = (values_customer.existingcoat) ? capatilizeFirstLetters(values_customer.existingcoat) : 'Select type...';
        console.log("placeholder: ")
        console.log(placeholder)

        const renderDropdown = () => {
            //var prompt = 'Enter it now...'
            if (shouldDisplay) {
                return(
                    <div className='dropdownwrapper'>
                        <select 
                            name="types" id="types"
                            value={values_customer.existingcoat}
                            onChange={e => this.handleChangeExistingType()(e)} 
                        >
                            <option value="" className="deselected" disabled selected hidden>{placeholder}</option> {/*TODO: mac os show checkmark next to first option only this way..*/}
                            <option value="epoxy">Epoxy</option>
                            <option value="tile">Tile</option>
                            <option value="vinyl">Vinyl</option>
                            <option value="clear sealer">Clear Sealer</option>
                        </select>
                    </div>
                )
            } else {
                return null;
            }
        };
    
        return (
                <div className ='surveywrapper'>
                    <div className='largeimagewrapper'>
                        <img class='coverphoto' src= {floorPic}/>
                    </div>

                    <div className='questionwrapper'>
                        <CSSTransition
                            in={this.state.mounted}
                            timeout={1000}
                            classNames={is_mobile_display ? "fade" : "slide-to-left"}
                            unmountOnExit 
                            appear
                        >
                            <div>
                                <div className='inputflexcontainer1of2'>
                                    <div className='textwrapper'>
                                        <h2 className='large'> Is your concrete surface covered by a previous coating/flooring? </h2>
                                    </div>
                                    {this.getPreviewModal()}
                                    <div className='preview-link-margin'>
                                        <p className='m-small clickable mobile-only' id='Pre-existing vs. No Coating' onClick={this.showPreviewModal()}>                               
                                            <FontAwesomeIcon 
                                            icon={faImage}
                                            />
                                            &nbsp;See Example
                                        </p>
                                    </div>
                                    <div className='buttonwrapper'>
                                    <button
                                            type='button' 
                                            class={this.getButtonActivity('true')}
                                            value='true'
                                            onClick={this.handleChangeHasExisting()}>
                                            Has pre-existing coating or flooring
                                            {/*<p className= 'btn-with-note note' id={yes} >&nbsp;</p>
                                                <p className= 'btn-with-note main' id={yes} >Has pre-existing coating or flooring</p>
                                            <p className= 'grey btn-with-note note ' id={yes}>
                                                <FontAwesomeIcon 
                                                        icon={faExclamationCircle}
                                                        //size='3x'
                                                /> Requires in-person estimate</p>*/}
                                        </button>
                                    </div>
                                </div>
                                <div className='inputflexcontainer2of2'>
                                    {renderDropdown()}
                                    <button
                                        type='button' 
                                        class={this.getButtonActivity('false')}
                                        value='false'
                                        onClick={this.handleChangeHasExisting()}>
                                        No pre-existing coating or flooring (bare surface)
                                    </button>
                                </div>
                            </div>
                        </CSSTransition>
                        <FooterContact values_business={values_business} phone={values_customer.branch.phone} hasbuttonbelow={values_customer.existingcoat}/>
                    </div>
                </div>
        )
    }
}

export default ExistingCoat;