import React, {Component} from 'react';
import { CSSTransition } from 'react-transition-group';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';

import stepsPic from './stairs_coated.jpg';
import Matomo from '../Matomo.js';
import { FooterContact } from '../general_questions/FooterContact';

export class Stairs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            next : props.next,
            mount: false,
            showinput: props.values_customer.stairs > 0
        }    
        this.showStairInput = this.showStairInput.bind(this);
    }

    componentDidMount = () => {
        /*-Matomo Analytics-*/
        const title = 'Stairs';
        Matomo(title);
        /*----End Matomo----*/
        //TODO: skip page if stairs is not a addon for this area
        //Skip to next page:
        if(!this.props.showpage){
            const event = new Event("emptyevent");
            this.continue(this.state.next, true)(event);
        }
        this.setState({ mounted: true });
    }

    continue = (next, skip = false) => e =>{
        e.preventDefault();
        this.props.nextPage(next, skip);
    };
    showStairInput = () => { //toggles input bar on Click
        console.log("showstairinput")
        console.log(this.state.showinput)
        let show;
        if(!this.state.showinput){
            show = true;
        } else {
            show = false;
        }
        console.log('updating to ' + show)
        this.setState({showinput: show})
    }
    getButtonActivity = val =>{
        var saved = this.props.values_customer.stairs && this.props.values_customer.stairs > 0;
        if (saved===val) {
            return 'columnbtn active';
        }
        return 'columnbtn';
    };

    getCoverImg = () => {
            return stepsPic;
    }

    renderNumInput = () => {
        console.log("renderNumInput")
        const {values_customer, handleChange} = this.props;
        var prompt = 'How many?'
        var input = this.props.values_customer.stairs;
        if (input !== 0 && input !== "0" && input !== null){
            prompt = input;
        }
        if (this.state.showinput) {
            return(
                <div className="inputwrapper">
                    <input 
                        type="number" 
                        placeholder= {prompt}
                        alt = 'number of steps to coat'
                        max = '99'
                        onChange= {handleChange('stairs')}
                    />
                    <button
                        disabled = {!values_customer.stairs}
                        onClick={this.continue(this.state.next)}>
                            <FontAwesomeIcon 
                            icon={faArrowRight}
                            size='lg'
                            />
                    </button>
                    <p>stairs</p>
                </div>
            )
        } else {
            return null;
        }
    }

    render() { 
        const { handleChange, handleChangeNext, values_customer, values_business, is_mobile_display } = this.props;

        const renderQuestion = () => {
            return(
                <div className='questionwrapper'>
                    <CSSTransition
                        in={this.state.mounted}
                        timeout={1000}
                        classNames={is_mobile_display ? "fade" : "slide-to-left"}
                        unmountOnExit 
                        appear
                    >
                        <div>
                            <div className='inputflexcontainer1of2'>
                                <div className='textwrapper'>
                                    <h2 className='large'> Are there any stairs or steps near your {values_customer.areatype.title}{(values_customer.areatype.title.substring(0,4).toLowerCase() === 'home') ? ' space' : '' } you'd like coated?</h2>
                                </div>
                                <div className='buttonwrapper'>
                                    <button
                                        type='button' 
                                        className={this.getButtonActivity('true')}
                                        onClick={this.showStairInput}>
                                            <p className= 'btn-with-note note' onClick={this.showStairInput}>&nbsp;</p>
                                            <p className= 'btn-with-note main' onClick={this.showStairInput}>Yes</p>
                                    
                                    </button>
                                </div>
                            </div>
                            <div className='inputflexcontainer2of2'>
                                    {this.renderNumInput()}
                                    <button
                                        type='button' 
                                        className={this.getButtonActivity('false')}
                                        value={0}
                                        onClick={handleChangeNext(this.state.next,'stairs')}>
                                        <p className='btn-with-note main' id={0} onClick={handleChangeNext(this.state.next,'stairs')}>No Stairs</p>
                                    </button>
                            </div>
                        </div>
                    </CSSTransition>
                    <FooterContact values_business={values_business} phone={values_customer.branch.phone}/>
                </div>
            )
        }

        return (
                <div className ='surveywrapper'>
                    <div className='largeimagewrapper'>
                        <img className='coverphoto crop-left-first' src= {this.getCoverImg()}/>
                    </div>
                    {renderQuestion()}
                </div>
        )
    }
}

export default Stairs;