import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPhoneAlt} from '@fortawesome/free-solid-svg-icons';

const FooterContact = ({ values_business, phone, size='reg', hasbuttonbelow=false}) => { //marketing tag is an optional object, consisting of statement, link title, and link url
    
        const div_classname = (hasbuttonbelow) ? 'bottom-contact-info extra-margin-below' : 'bottom-contact-info';
        const button_classname = (size !== 'large') ? 'footer-marketing-btn bold small' : 'footer-marketing-btn bold larger';
        const {businessname, embedded} = values_business;
        const marketing_tag = values_business.marketing_tag || {link_url: values_business.homepage, link_title: `See our work!`};
    
    const handlePhoneClick = () => {
        window._paq.push(['trackGoal', 8]);
    }

    if(embedded){
        return <div></div>
    }
    
    if(marketing_tag){
        return (
            <div className={div_classname}>
                <p className='x-small light bold'>{marketing_tag.statement}</p>
                {marketing_tag.link_url ? <a className={button_classname} href={marketing_tag.link_url} target='_blank'>{marketing_tag.link_title}</a>
                : <div className='x-small'></div>}
                <div className='row middle'>
                    <FontAwesomeIcon icon={faPhoneAlt}/>
                    <a className='small' href={'tel:+1.' + phone}>&nbsp;{phone}</a>
                    <p className='xx-small grey no-margin'>v3.05</p>
                </div>
            </div>
        
    );
    }
    else {
        return (

                <div className={div_classname}>
                    <p className='x-small'>Contact {businessname}</p>
                    <div className='row middle' onClick={handlePhoneClick()}>
                        <FontAwesomeIcon icon={faPhoneAlt}/>
                        <a className='small' href={'tel:+1.' + phone}>&nbsp;{phone}</a>
                    </div>
                    <p className='xx-small no-margin'>v3.00</p>
                </div>
            
        );
    }
};

export {FooterContact};
