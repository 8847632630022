import React, {Component} from 'react';
import Matomo from '../Matomo';
import { CSSTransition } from 'react-transition-group';
import SubmitInputFooter from './question_childrencomponents/SubmitInputFooter';
import ContinueFooter from '../questions/ContinueFooter';
import { findIndexByProperty } from '../../Helpers';
import ColumnInputFields from './question_childrencomponents/ColumnInputFields';

//This component is intended for a single object with multiple feilds, that will fit into an array of objects.
export class SingleColumnInputObject extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputs: props.inputs,
            mounted: false,
            user_inputted_object: {}
        };
    }

    componentDidMount = () => {
        const {showpage, pagetitle} = this.props;
        /*-Matomo Analytics-*/
        Matomo(pagetitle);
        /*----End Matomo----*/
        
        //Skip to next page
        if(!showpage){
            const event = new Event("service");
            //event.data=this.props.values_business.areatype_options[0];
            this.continue(this.props.nextpage)(event);
        }

        this.initUserInputObject();
        this.setState({ mounted: true });
        console.log(this.state.inputs)
    }

    continue = (next) => e =>{
        const {submit_additional_options, nextPage, pagetitle, category_index} = this.props;
        console.log("submit_additional_options: " + submit_additional_options)
        e.preventDefault();
        if (submit_additional_options){
            nextPage(pagetitle + "#" + category_index)
        } else {
            this.props.nextPage(next)
        }
    };

    initUserInputObject = () => {
        console.log('initUserInputObject')
        const {user_inputted_object, inputs} = this.state;
        const {category_readonly_currentobject} = this.props;

        console.log('current object')
        console.log(category_readonly_currentobject)
        if (category_readonly_currentobject){
            for (let key in category_readonly_currentobject){
                if (!user_inputted_object[key]){
                    user_inputted_object[key] = category_readonly_currentobject[key];
                    console.log("changed " + key)
                }
            }
        } else{
            for (let i = 0; i < inputs.length; i++){
                let field_varname = inputs[i].varname;
                let field_value = inputs[i].value;

                if(!user_inputted_object[field_varname] && inputs[i].show && inputs[i].value){
                    
                    console.log('changing' + field_varname)
                    field_varname = inputs[i].varname;
                    field_value = inputs[i].value;

                    user_inputted_object[field_varname] = field_value;
                    console.log(user_inputted_object)
                }
            }
        }
        this.setState({user_inputted_object : user_inputted_object});
    }

    handleInputChange = (inputs, user_inputted_object, varname) => e => {
        user_inputted_object[varname] = e.target.value;
        console.log('handleinput change, changing object to ' + e.target.value)
        //console.log(user_inputted_object);

        const input_field_index = findIndexByProperty(inputs, 'varname', varname);
        let updated_input_params = inputs;
        updated_input_params[input_field_index].value = e.target.value;

        this.setState({user_inputted_object : user_inputted_object, inputs : [...updated_input_params]});
    }

    disableSubmitBtn = () => {
        const {user_inputted_object, inputs} = this.state;
        let answered_input;
        let displayed_input;
        for (let i = 0; i < inputs.length; i++){
            answered_input = user_inputted_object[inputs[i].varname];
            displayed_input = inputs[i].show;
            if(!answered_input && displayed_input){
                return true;
            }
        }
        return false;
    }

    render() { 
        /* 
        Required array format: {type, autocomplete_type, placeholder, varname, value, required (bool)}
        input_category represents an array in parent state where changes should be made, and category_index represents the current index in the array. 
        */
            const {handleAddIndexedItemToArray, header_question, inputs, input_category, category_index} = this.props;

      
            return(
                <div className='surveywrapper'>
                    <CSSTransition
                        in={this.state.mounted}
                        timeout={1000}
                        classNames="fade"
                        unmountOnExit 
                        appear
                    >
                        <div className='multiplechoice'>
                            <h2 className='large'>{header_question}</h2>
                            {inputs.map((input) => (
                                input.show &&
                                <div className="formwrapper">
                                    <input 
                                        type={input.type}
                                        name={input.varname}
                                        autoComplete={input.autocomplete_type}
                                        placeholder= {input.placeholder}
                                        value = {input.value}
                                        alt = {input.placeholder}
                                        onChange = {handleAddIndexedItemToArray(input_category, category_index, input.varname)}
                                    />
                                    <p className = {(input.value) ? 'miniplaceholder' : 'miniplaceholder invis'}>{input.placeholder + (input.required) ? "*" : ""}</p>
                                </div>
                            ))}
                        </div>
                    </CSSTransition>
                </div>
            )

    }

}
    
export default SingleColumnInputObject;