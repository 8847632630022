import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import Matomo from '../Matomo.js';
import { AboutModal } from '../questions/Modals/AboutModal.js';
import { faChevronLeft, faChevronRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findObjectByProperty } from '../../Helpers.js';

// The state image is used to store the base64 encoded image data.
// handleImageChange method is triggered when a file is selected. It uses FileReader to read the file as a data URL.
// The input element is of type file and accepts only image files.
// If an image is uploaded, a preview is displayed using an img element.

export class ImageUpload extends Component {
  constructor (props) {
    super(props);
    this.state = {
        showmodal : false,
        nextpage : props.next,
        mounted : false,
        image: props.job_image,
        startIndex: 0, 
        visibleItemsCount: null, // Number of items to display
        itemWidth: 245, // Width of each item (update as needed)
        scrollOffset: 0, // New state variable for scroll offset
        all_choices: props.images,
        err: null,
    }
  }

  componentDidMount = () => {
    const {page_title, showpage} = this.props;

    /*-Matomo Analytics-*/
    const title = page_title;
    Matomo(title);
    /*----End Matomo----*/

    //Skip to next page if necessary
    if(!showpage){
        console.log('skipping page: ' + title)
        this.props.nextPage(this.props.next, true);
    }
    if(this.props.is_mobile_display === null || this.props.is_mobile_display === undefined){
      console.error("is_mobile_display is not provided in ImageUpload for " + page_title)
    }
    window.addEventListener('resize', this.handleWindowResize);
    this.handleWindowResize();
    this.setState({ mounted: true });
    this.getTop().scrollIntoView();
  }

  componentWillUnmount() {
    // Remove the event listener when the component is unmounted
    window.removeEventListener('resize', this.handleWindowResize);
  }

  getTop = () => {
    return document.getElementById('top');
  };

  showModal = () => {
    document.body.style.overflow = 'hidden';
    this.setState({ showmodal: true });
  };

  hideModal = () => {
    document.body.style.overflow = 'auto';
    this.setState({ showmodal: false });
  };

  handleWindowResize = () => {
    // Calculate and update the visibleItemsCount based on the window width
    const button_width_px = this.state.itemWidth;
    const margin_left_px = 100;
    const veiwport_buttons = Math.floor((window.innerWidth - margin_left_px) / button_width_px);
    const max_buttons = 4;
    const visibleItemsCount = Math.min(veiwport_buttons, max_buttons);
    
    this.setState({ visibleItemsCount });
  }

  scrollLeft = () => {
      this.setState(prevState => {
          const newStartIndex = Math.max(prevState.startIndex - prevState.visibleItemsCount, 0);
          console.log("scrolling left " + newStartIndex * prevState.itemWidth + "px")
          return {
              startIndex: newStartIndex,
              scrollOffset: newStartIndex * prevState.itemWidth
          };
      });
  }

  scrollRight = () => {
      this.setState(prevState => {
        console.log(prevState)
        console.log(prevState.startIndex)
        console.log(prevState.visibleItemsCount)
        console.log(prevState.all_choices.length)
          const newStartIndex = Math.min(prevState.startIndex + prevState.visibleItemsCount, prevState.all_choices.length - prevState.visibleItemsCount);
          console.log("scrolling right " + newStartIndex * prevState.itemWidth + "px")
          const newScrollOffset = newStartIndex * prevState.itemWidth;
          return {
              startIndex: prevState.scrollOffset !== newScrollOffset ? newStartIndex : prevState.startIndex,
              scrollOffset: newScrollOffset
          };
      });
  }

  handleImageChange = image => e => {
    e.preventDefault();
    console.log('image upload triggered', image.title)
    const {handleChangeObject} = this.props;
    const {job_images} = this.props;
    // Assuming only one file is uploaded
    const file = e.target.files[0];

    if (file && file.type.match('image.*')) {
      const reader = new FileReader();
      //console.log('image upload started')
      reader.onloadend = () => {
        //console.log('image upload complete', image.title)
        //if image already exists with same image title, replace with latest image, else add new image
        const existingImage = findObjectByProperty(job_images, 'title', image.title);
        if(existingImage){
          console.log('replacing existing image', existingImage.title)
          existingImage.src = reader.result;
          handleChangeObject('job_images', job_images);
        } else {
          handleChangeObject('job_images', [...job_images, {title: image.title, src: reader.result}]);
        }
      };

      reader.readAsDataURL(file);
    }
  };

  getButtonImage = (title) => {
      //find image in job_images, return src
      const {job_images} = this.props;
      if (job_images && job_images.length > 0) {
        const image = job_images.find(image => image.title === title);
        if(image) return image.src;
      }
  }
  renderButtons = () => {
    const { all_choices } = this.state;
    return all_choices.map((image)  => (
      <div className='column' key={image.title}>
        <input type="file" accept="image/*" onChange={this.handleImageChange(image)} className='fileuploadinput' id={"image-upload " + image.title} key={image.title}/>
        <label for={"image-upload " + image.title} className='fileuploadlabel'>
          <div className='img-upload-box'>
            {(this.getButtonImage(image.title) || image.src) ? <img key={image.title} src={this.getButtonImage(image.title) || image.src} className={(this.getButtonImage(image.title)) ? 'user-uploaded' : null}/> : <div className='img-placeholder'/>}
            <FontAwesomeIcon icon={faPlus} className='fa-plus'/>
            <p className={(this.getButtonImage(image.title)) ? 'small bold dark-background' : 'small bold'}>{image.title}</p>
          </div>
        </label>
        {/* {this.props.job_image && (
          <div>
            <img src={this.props.job_image} alt="Preview" className='image-upload-preview'  />
          </div>
        )}     */}
      </div>
    ))  
  }

  render() {
    const { image, visibleItemsCount, itemWidth, scrollOffset, all_choices } = this.state;
    const {header_question, header_statement, images, is_mobile_display} = this.props;
    const render_right_nav = all_choices.length > visibleItemsCount && scrollOffset < (all_choices.length - visibleItemsCount) * itemWidth;
    const render_left_nav = all_choices.length > visibleItemsCount && scrollOffset > 0;
    const button_row_max_width = (visibleItemsCount + .75) * itemWidth;
    const button_row_min_width = (visibleItemsCount) * itemWidth;
    const aboutthis = [
            
      {title: 'What should my image include?', text: <p className="small spaced light">If you can, provide a image that shows your job area in detail. 
      This helps us determine asphalt condition and other factors that go into your quote.</p>}
  ]

    return (
      <div className='surveywrapper' id='top'>
        <AboutModal show={this.state.showmodal} handleClose={this.hideModal} info={aboutthis}/>
          <div className='multiplechoice-transitionbuffer'>
            <CSSTransition
                    in={this.state.mounted}
                    timeout={1000}
                    classNames="fade"
                    unmountOnExit 
                    appear
            >
              <div className='multiplechoice'>
                <div className='textwrapper'>
                    <h2 className='large'> {header_question} </h2>
                    <h4 className='small'> {header_statement} </h4>
                    <p className='clickable' onClick={this.showModal}> &#9432; What should I be looking for?</p>
                </div>
                {!is_mobile_display && <div className='row' style={{justifyContent:'center', maxWidth : button_row_max_width, minWidth: button_row_min_width,alignSelf: 'center'}}>
                  <div className='navicon-group'>
                      <FontAwesomeIcon 
                          className={(render_left_nav) ? 'navicon' : 'navicon invis'}
                          icon={faChevronLeft}
                          //size='3x'
                          onClick={this.scrollLeft}
                      />
                      <FontAwesomeIcon 
                          className={(render_left_nav) ? 'navicon' : 'navicon invis'}
                          icon={faChevronLeft}
                          //size='3x'
                          onClick={this.scrollLeft}
                      />
                  </div>
                  <div className={(render_left_nav || render_right_nav) ? "choices-container scrolling" : "choices-container"} style={{width: button_row_min_width}}>
                      <div className={(render_left_nav || render_right_nav) ? "choices-wrapper" : "choices-wrapper no-margin"} style={{ transform: `translateX(-${scrollOffset}px)`, transition: 'transform .5s ease-in-out' }}>
                          {this.renderButtons()}  
                      </div>
                  </div>
                  <div className='navicon-group'>
                      <FontAwesomeIcon 
                          className={(render_right_nav) ? 'navicon' : 'navicon invis'}
                          icon={faChevronRight}
                          //size='3x'
                          onClick={this.scrollRight}
                      />
                      <FontAwesomeIcon 
                          className={(render_right_nav) ? 'navicon' : 'navicon invis'}
                          icon={faChevronRight}
                          //size='3x'
                          onClick={this.scrollRight}
                      />
                  </div>
                </div>}
                {/* Mobile button rendering  */}
                {is_mobile_display &&
                    <div className='row'>
                      <div className='img-upload-mobile-container'>
                        {this.renderButtons()}
                      </div>
                    </div>
                }
                {!is_mobile_display && <div className='textwrapper'>
                    <h2 className='large invis'>.</h2>
                    <h4 className='small invis'>.</h4>
                    <p className='small btntitle invis'>.</p>
                </div>}
                <div className="fillremainderflex"/>
                </div>
            </CSSTransition>
          </div>
      </div>
    );
  }
}

export default ImageUpload;
