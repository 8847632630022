import React, {Component} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

//props: values_customer, state_var, choice, handleClick, showModal

export class ButtonWithIcon extends Component {

    constructor (props) {
        super(props);
        this.state = {
            hover_btn: '',
        }
    }

    getButtonActivity = val =>{ 
        var saved = this.props.values_customer[this.props.state_var];
        if (saved===val && saved !== undefined) {
            return 'rowbtn active';
        }
        return 'rowbtn';
    }

    
    render() { 
        const {choice, handleClick, showModal} = this.props;

        return (
            <button
                key={choice.title}
                type='submit' 
                className={this.getButtonActivity(choice.title)}
                id={choice.title}
                onClick={ handleClick }
                >
                    <div className='centericon'>
                        <FontAwesomeIcon 
                            icon={choice.icon}
                            size='3x'
                            />
                    </div>
                    <div className='buttontext withicon' >
                        <p id={choice.title}>{choice.title}</p>
                    </div>
            </button>
        )
    }
}

export default ButtonWithIcon;