//Displays statement as a pulsing laod screen. Duration is calculated based on the length of the statement, or on "duration" prop if provided.

import React, {Component} from 'react';
import {AboutModal} from '../questions/Modals/AboutModal.js';
import { CSSTransition } from 'react-transition-group';
import { FooterLogo } from './FooterLogo.js';
import Matomo from '../Matomo.js';
import { FooterContact } from './FooterContact.js';
import GrowFromZeroItem from './components/animations/GrowFromZeroItem.js';
import GPTModal from './modals/GPTmodal.js';

export class MakeStaticStatement extends Component {
    constructor (props) {
        super(props);
        this.state = {
            showmodal : false,
            intro_query : '',
            nextpage : props.next,
            mounted : false
        }
    }

    componentDidMount = () => {
        /*-Matomo Analytics-*/
        const title = this.props.page_title;
        Matomo(title);
        /*----End Matomo----*/
        
        //Skip to next page if necessary
        if(!this.props.showpage){
            this.continue(true);
        } else {
            this.setState({ mounted: true });
            // console.log("setting timeout");
            // const duration = this.props.duration || this.props.header_statement.length * 40;
            // console.log(duration)
            // setTimeout(() => this.continue(true), duration); // Use arrow function syntax to pass the function reference
        }
        this.setState({ mounted: true });
    }

    continue = (skip=false) =>{
        this.props.nextPage(this.state.nextpage, skip);
    };

    showModal = (prompt) => {
        document.body.style.overflow = 'hidden';
        this.setState({ showmodal: true, intro_query: prompt.gpt_query});
    };
    
    hideModal = () => {
        document.body.style.overflow = 'auto';
        this.setState({ showmodal: false, intro_query: '' });
    };

    displayGPTPrompts = () => {
        const { gpt_prompts } = this.props;
        if (gpt_prompts && gpt_prompts.length > 0) {
            return gpt_prompts.map((prompt) => {
                const display_text = prompt.display_text || prompt.gpt_query; // Use gpt_query if display_text is not provided
                const item = <p key={prompt.gpt_query}>{display_text}</p>;
                return (
                    <div key={prompt.gpt_query} style={{width:'min-content'}}>
                        <GrowFromZeroItem
                            key={prompt.gpt_query}
                            item={item}
                            container_class={'gpt-prompt'}
                            onClick={() => this.showModal(prompt)} // Use gpt_query to identify the prompt
                        />
                    </div>
                );
            });
        }
    };

    render() { 
        const { img_src, is_mobile_display, values_business, values_customer} = this.props;
        const { state_var, header_statement, header_class_size, animation_effect, custom_component, subheader_statement} = this.props;
        //const image = (img_src) ? <img src={img_src} className='pulse-img' /> : null;
        //let animation_class = (animation_effect) ? animation_effect : 'pulse-text';
        console.log('header statement: ' + header_statement)
        return (
            <div className='surveywrapper'>
                {this.state.showmodal ? <GPTModal handleClose={this.hideModal} displayclass={"modal display-block"} company_name={this.props.values_business.businessname} industry={values_business.industry} intro_query={this.state.intro_query}/> : null}
                <CSSTransition
                        in={this.state.mounted}
                        timeout={1000}
                        classNames="fade"
                        unmountOnExit 
                        appear
                >
                    <div className='column' style={{maxWidth:'100%'}}>
                        {header_statement && <div className='make-statement-static'>
                                <h2 className={'center'}>{header_statement} </h2>
                        </div>}
                        {subheader_statement && <div className='make-statement-static'>
                                <h2 className={'center m-small'}>{subheader_statement} </h2>
                        </div>}
                        {custom_component && <div className='make-statement-static'>
                                {custom_component}
                        </div>}

                        <div style={{width: '100%', alignSelf: 'center', marginTop:'20px', display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap:'wrap'}}>
                            {this.displayGPTPrompts()}
                        </div>
                    </div>
                </CSSTransition>
            </div>
        )
    }
}

export default MakeStaticStatement;