import React, {Component} from 'react';
import { CSSTransition } from 'react-transition-group';
import {AboutModal} from './Modals/AboutModal.js'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight, faComments} from '@fortawesome/free-solid-svg-icons';
import { FooterLogo } from '../general_questions/FooterLogo.js';
import floorPic from './sidewall.jpg'
import Matomo from '../Matomo.js';
import { FooterContact } from '../general_questions/FooterContact.js';
import GPTModal from '../general_questions/modals/GPTmodal.js';
import AI_Assistant_Icon from '../general_questions/images/J.png'

export class Sidewalls extends Component {

    constructor (props) {
        super(props);
        this.state = {
            nextpage : props.next,
            showmodal : false,
            mounted : false
        }    
        this.getButtonActivity = this.getButtonActivity.bind(this);
        this.continue = this.continue.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    componentDidMount = () => {
        /*-Matomo Analytics-*/
        const title = 'Sidewalls';
        Matomo(title);
        /*----End Matomo----*/
        
        //Skip to next page if areatype is not a garage:
        if(this.props.values_customer && this.props.values_customer.areatype.title.toLowerCase() !=='garage'){
            const event = new Event("emptyevent");
            console.log(this.props.pages)
            this.continue(this.state.nextpage, true)(event);
        }
        
        this.setState({ mounted: true });
    }

    showModal = () => {
        this.setState({ showmodal: true });
    };
    
    hideModal = () => {
        this.setState({ showmodal: false });
    };
    
    continue = (next, skip = false) => e =>{
        e.preventDefault();
        this.props.nextPage(next, skip);
    };

    getButtonActivity = val =>{
        var saved = this.props.values_customer.hassidewall;
        if (saved===val) {
            return 'columnbtn active';
        }
        return 'columnbtn';
    };

    render() { 
        const { handleChangeNext, handleChange, values_customer, values_business, handleChangeEstimateReason, is_mobile_display } = this.props;

        const inperson_reason = 'Customer requested an in-person linear measurement of concrete sidewalls.';
        const faq_prompts = [
            {   
                display_text: 'What are sidewalls?',
                gpt_query: 'What are sidewalls?',
            },
            {   
                display_text: `How do I measure my sidewalls?`,
                gpt_query: `How do I measure my sidewall height?`,
            },
            {
                display_text: `What if my sidewall height varies?`,
                gpt_query: `How do I measure my sidewall height if it varies?`,
            }
        ]
        const aboutthis = [         
            {title: 'What are side walls?', text: <p className="small spaced light">Side walls are part of the 
            foundation of your garage that studs are mounted to in order to build the actual walls. The sidewall is typically between 4 
            - 8 inches high and can be coated to match the rest of the concrete in your garage. </p>
            }, {title: 'How do I measure them?', text: <p className="small spaced light">We just need the vertical height of your sidewalls in inches. 
            If the height varies at all, find the lowest and heighest points of the sidewall and use the average of the two measurements.</p>
            },
        ]

        const renderNumInput = () => {
            var prompt;
            var input = this.props.values_customer.sidewallheight;
            if (input !== 0 && input !== null){
                prompt = input;
            }
            return(
                <div className="inputwrapper">
                    <input 
                        type="number" 
                        placeholder= {prompt}
                        alt = 'concrete sidewall height in inches'
                        max = '99'
                        onChange= {handleChange('sidewallheight')}
                    />
                    <button
                        disabled = {values_customer.sidewallheight===0 || !values_customer.sidewallheight}
                        onClick={handleChangeNext(this.state.nextpage, 'hassidewall')}>
                            <FontAwesomeIcon 
                            icon={faArrowRight}
                            size='lg'
                            />
                    </button>
                    <p>inches</p>
                </div>
                )
        };
    
        return (
                <div className ='surveywrapper'>
                    <div className='largeimagewrapper'>
                        <img class='coverphoto' src= {floorPic}/>
                    </div>
                    <div className='questionwrapper'>
                        <CSSTransition
                            in={this.state.mounted}
                            timeout={1000}
                            classNames={is_mobile_display ? "fade" : "slide-to-left"}
                            unmountOnExit 
                            appear
                        >
                            <div>
                                <div className='textwrapper'>
                                    <h2 className='large'> How high are your concrete sidewalls, if present? </h2>
                                    {/*<AboutModal show={this.state.showmodal} handleClose={this.hideModal} info={aboutthis}/>*/}
                                    {this.state.showmodal ? <GPTModal handleClose={this.hideModal} prompts={faq_prompts} displayclass={"modal display-block"} company_name={this.props.values_business.businessname} industry='concrete coatings'/> : null}
                                    <p className='clickable display-flex-row' onClick={this.showModal}> <img className='assistant-icon' src={AI_Assistant_Icon} alt='chat icon'/>&nbsp;About this question</p>
                                </div>
                                <div className='inputflexcontainer2of2'>
                                        {renderNumInput()}
                                        <p>&nbsp;</p>
                                        <p className='clickable underline grey'
                                            id={0}
                                            onClick={handleChangeNext(this.state.nextpage,'sidewallheight')}>
                                            I don't have sidewalls
                                        </p>
                                        <p className='clickable underline grey'
                                            id={0}
                                            onClick={() => handleChangeEstimateReason(inperson_reason)}>
                                            Have someone measure for me
                                        </p>
                                </div>
                            </div>
                        </CSSTransition>
                        <FooterContact values_business={values_business} phone={values_customer.branch.phone}/>
                    </div>
                    
                </div>
        )
    }
}

export default Sidewalls;