import React, {Component} from 'react';
import { CSSTransition } from 'react-transition-group';
import {AboutModal} from './Modals/AboutModal.js'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight, faComments} from '@fortawesome/free-solid-svg-icons';
import { FooterLogo } from '../general_questions/FooterLogo.js';
import floorPic from './floor_cracks.jpg';
import measurePic from './measure_curved_line.png';
import Matomo from '../Matomo.js';
import { FooterContact } from '../general_questions/FooterContact.js';
import GPTModal from '../general_questions/modals/GPTmodal.js';
import AI_Assistant_Icon from '../general_questions/images/J.png'

export class FloorCrackLength extends Component {

    constructor (props) {
        super(props);
        this.state = {
            nextpage : props.next,
            showmodal : false,
            mounted : false
        }    
    }

    componentDidMount = () => {
        /*-Matomo Analytics-*/
        const title = 'Floor Crack Length';
        Matomo(title);
        /*----End Matomo----*/
        
        //Skip to next page if floor has no cracks/damage
        if(!this.props.showpage){
            const event = new Event("emptyevent");
            this.continue(this.state.nextpage, true)(event);
        }
        this.setState({ mounted: true });
    }

    showModal = () => {
        this.setState({ showmodal: true });
    };
    
    hideModal = () => {
        this.setState({ showmodal: false });
    };
    
    continue = (next, skip = false) => e =>{
        e.preventDefault();
        this.props.nextPage(next, skip);
    };

    getButtonActivity = val =>{
        var saved = this.props.values_customer.floorcracklength;
        if (saved===val) {
            return 'columnbtn active';
        }
        return 'columnbtn';
    };

    render() { 
        const { handleChangeNext, handleChange, values_customer, values_business, handleChangeEstimateReason, is_mobile_display } = this.props;
        
        const inperson_reason = 'Customer requested an in-person linear measurement of floor cracks.';
        const faq_prompts = [
            {
                display_text : 'What counts as a large crack?',
                gpt_query : 'What counts as a large crack?',
            }
        ]
        const aboutthis = [         
            {title: 'How do I measure this?', text: <div><p className="small spaced light">A <b>large crack</b> would be anything you can fit a pencil into.<br/><br/> <b>Linear feet</b> is another way of saying the total length of any crack(s). This can be measured by a 1) measuring tape, or 2) if the crack is curved, first laying a string or chord that matches the length/shape of the crack, then measuring the length of the string or chord when straightened out. </p><span/><img src= {measurePic}/></div>},
        ]

        const renderNumInput = () => {
            var prompt;
            var input = this.props.values_customer.floorcracklength;
            if (input !== 0 && input !== null){
                prompt = input;
            }
            return(
                <div className="inputwrapper">
                    <input 
                        type="number" 
                        placeholder= {prompt}
                        alt = 'linear feet of any crack(s)'
                        max = '99'
                        onChange= {handleChange('floorcracklength')}
                    />
                    <button
                        disabled = {!values_customer.floorcracklength}
                        onClick={this.continue(this.state.nextpage)}>
                            <FontAwesomeIcon 
                            icon={faArrowRight}
                            size='lg'
                            />
                    </button>
                    <p>feet</p>
                </div>
                )
        };
    
        return (
                <div className ='surveywrapper'>
                    <div className='largeimagewrapper'>
                        <img class='coverphoto' src= {floorPic} alt = 'a cracked concrete floor'/>
                    </div>
                    <div className='questionwrapper'>
                        <CSSTransition
                            in={this.state.mounted}
                            timeout={1000}
                            classNames={is_mobile_display ? "fade" : "slide-to-left"}
                            unmountOnExit 
                            appear
                        >
                            <div>
                                <div className='textwrapper'>
                                        <h2 className='large'> What is the approximate total length of large irregular cracks in the floor, if any? </h2>
                                        {this.state.showmodal ? <GPTModal handleClose={this.hideModal} prompts={faq_prompts} displayclass={"modal display-block"} intro_query='How do I measure the total length of large, irregularly shaped cracks?' company_name={this.props.values_business.businessname} industry='concrete coatings'/> : null}
                                    <p className='clickable display-flex-row' onClick={this.showModal}> <img className='assistant-icon' src={AI_Assistant_Icon} alt='chat icon'/>&nbsp;How do I measure this?</p>
                                </div>
                                <div className='inputflexcontainer2of2'>
                                        {renderNumInput()}
                                        <p>&nbsp;</p>
                                        <p className='clickable underline grey'
                                            id='0'
                                            onClick={handleChangeNext(this.state.nextpage,'floorcracklength')}>
                                            No cracks, the work surface is clean
                                        </p>
                                        <p className='clickable underline grey'
                                            id='0'
                                            onClick={() => handleChangeEstimateReason(inperson_reason)}>
                                            Have someone measure for me
                                        </p>
                                </div>
                            </div>
                        </CSSTransition> 
                        <FooterContact values_business={values_business} phone={values_customer.branch.phone}/> 
                    </div>   
                </div>
        )
    }
}

export default FloorCrackLength;