import React, {Component} from 'react'
import animated_logo from './fairquotelogo_mag_breathe.gif'
import Matomo from '../Matomo'


export class LoadingQuote extends Component {
    constructor (props) {
        super(props);
        this.state = {
            mounted: false
        }
    }

    componentDidMount = () => {
        /*-Matomo Analytics-*/
        const title = 'Loading';
        Matomo(title);
        /*----End Matomo----*/
        this.setState({ mounted: true });
    }

    render() { 

        return (
            <div className='loadingwrapper'>
                    <p className='large inline logo-text no-margin'>Crunching the numbers...</p>
                    <img className='center' src={animated_logo} alt="fairquote logo"/>
            </div>
        )
    }
}

export default LoadingQuote;