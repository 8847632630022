import React, {Component} from 'react'
import {ColorModal} from './Modals/ColorModal.js'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTrashAlt, faCheckCircle, faCircle, faTimes} from '@fortawesome/free-solid-svg-icons'
import { CSSTransition } from 'react-transition-group';
import Matomo from '../Matomo.js'
import CoatPreviewModal from '../general_questions/modals/CoatPreviewmodal.js';


export class ColorPicker extends Component {
    constructor (props) {
        super(props);
        this.state = {
            coats: [],
            showmodal : '',
            choices : props.colors,
            mounted : false,
            hover_coat: '',      
            hover_icon: ''      
        }
    }

    componentDidMount = () => {
        /*-Matomo Analytics-*/
        const title = 'ColorPicker';
        Matomo(title);
        /*----End Matomo----*/
        const coats = this.getCoatOptions();
        console.log(coats)
        this.setState({ coats: coats, mounted: true});
        
        //Skip this page if only one option:
        if(coats && coats[0].Colors === "none"){
            const event = new Event("nocoat");
            event.data="none"
            this.props.handleChangeObject('colors', coats)
            this.props.nextPage(this.props.nextpage, true)
        } 

        //Scroll to top of page
        this.getTop().scrollIntoView();
    }

    getTop = () => {
        return document.getElementById('top')
    };

    showModal = (modal_name) => {
        document.body.style.overflow = 'hidden';
        this.setState({ showmodal: true });
    };
    
    hideModal = () => { //TODO call this method without saving color selections when closing x on modal
        console.log("Hide modal")
        document.body.style.overflow = 'auto';
        this.setState({ showmodal: false });
    };

    findInDict(array, urlvalue, tier = null) {
        //console.log('urlvalue: ' + urlvalue); 
        //console.log('typevalue: ' + typevalue);
        for(var i = 0; i < array.length; i += 1) {
            if(array[i].icon === urlvalue) {
                console.log(tier, array[i].tier)
                if(tier && tier === array[i].tier){
                    return i;
                } else if (!tier) {
                    return i;
                }
            }
        }
        return -1;
    }

    getCoatOptions = () => {                //search through all coat options, and finishtype.styles array, to add relevent coats for rendering
        const {coat_options, styletype} = this.props;
        let final_options = [];
        console.log(coat_options, styletype)
        for(let i = 0; i < coat_options.length; i++){
            // for(let j = 0; j < finishtype.styles.length; j++){
            //     console.log(finishtype.styles[j])
                if(coat_options[i].style.toLowerCase() === styletype.toLowerCase()){
                    final_options.push(coat_options[i])
                }
            // }
        }
        console.log(final_options)
        return final_options;
    }

    setHoverIcon= (title, tier = null) => {
        if (tier){
            this.setState({hover_icon: title + tier})
        } else {
            this.setState({ hover_icon: title });
        }
    }

    renderButtonText = (coat) => {
        console.log("Render button text")
        console.log(coat)
        if ( (this.state.hover_coat === coat.style) && coat.short){
            return(
                <div className='btn-hover-description' >
                    <p className='title hover medium' id={coat.style} >{coat.style}</p>
                    {this.renderMultiLineDescription(coat.short)}
                </div>
            )

        } else {
            return <p id={coat.style} onClick={(e) => this.showModal(coat.style)}>{coat.style}</p>
        }
    }
    renderMultiLineDescription = (desc) => { //break *semicolon* description into multiple html lines
        let multilined = [];
        for(let i = 0; i < desc.length; i++){
            if (desc.charAt(i) === ';' || i === 0){
                if(desc.charAt(i) === ';'){
                    i = i+1;
                }
                for(let j = i; j < desc.length; j++){
                    if(desc.charAt(j) === ';' || j === desc.length - 1){
                        if(j === desc.length - 1){
                            multilined.push(desc.substring(i,desc.length));
                        } else {
                            multilined.push(desc.substring(i,j));
                        }
                        i=j+1;
                    }
                }
            }
        }
        
        return multilined.map((line)  => (
            <p className='description x-small'>&#8226; {line}</p>
        ))     
    }

    //DEPRECIATED
    /*renderMultipleCoatTypePage = () => { //when there is more than one coat type to choose from
        const {coats} = this.state;
        const {colors} = this.props;
        return(
            <div className='multiplechoice'>
                <div className='textwrapper'>
                    <h2 className='large'> Let's talk colors! </h2>
                    <h4 className='small'>Click each coat style to see options. Choose up to five options.</h4>
                </div>
                <div className="row colors">
                    {coats.map((coat)  => (
                        <div>
                            <ColorModal activemod={this.state.showmodal} userChose={this.userChoice} handleClose={this.handleCloseUpdate} coat={coat} actives={this.state.choices} findWithAttr={this.findInDict}/>
                            <button
                                type='button' 
                                class='rowbtn'
                                id={coat.style}
                                onClick={this.showModal()}
                                onMouseEnter={() => this.setHoverButton(coat.style)}
                                onMouseLeave={() => this.setHoverButton('')}>
                                <div className='buttontext'>
                                    {this.renderButtonText(coat)}
                                </div>

                            </button> 
                        </div>
                    ))}                                                
                </div>
                <div className='color-choice-list'>
                        <h4 className='medium grey'>Your Selections to Compare:</h4>
                        <div className= 'row left no-margin color-icon-height border-sides'>
                            {colors.map(( c ) => (
                                <div className='color-icon no-top-margin no-click'>
                                    <img
                                        className='mediumicon'
                                        src = {c.icon}
                                        alt={c.short} 
                                    />
                                    <FontAwesomeIcon
                                        icon={faTrashAlt}
                                        color='black'
                                        size='2x'
                                        id={c.icon}
                                        onClick={this.userChoice(c)}
                                        />
                                </div>
                                ))}
                        </div>
                </div>
            </div>
        )
    }*/


    getIconActivity = (url, max_choices, tier) =>{
        const actives = this.state.choices;
        const index = this.findInDict(actives, url, tier);
        const tiered_key = (tier) ? url + tier : url;

        if (index > -1) {
            return 'color-icon active large-icon';
        } else if (this.state.hover_icon === tiered_key){
            return 'color-icon large-icon'
        }
        else if (actives.length >= max_choices){
            return 'color-icon disabled large-icon';
        }
        return 'color-icon large-icon';
    }
    getActiveLabels = (c, tier) => {
        const actives = this.state.choices;
        const url = c.icon;
        const title = c.title;
        const index = this.findInDict(actives, url, tier);
        if (index > -1){
            return(
                <div>
                    <p className='logo-text x-small color-title bold'>{title}</p>
                    <FontAwesomeIcon
                    icon={faCircle}
                    size='2x'
                        />
                    <FontAwesomeIcon
                    icon={faCheckCircle}
                    size='2x'
                        />
                </div>
            )
        } else if (this.state.hover_icon === url){
            return(
                <div>
                    <p className='logo-text x-small color-title bold'>{title}</p>
                </div>
            )
        }

    }
    getExtraPriceTag = (color) => {
        let extra_price_tag = '';
        if(color.additional_coef){
            extra_price_tag = <p className='logo-text xx-small color-price-tag bold'>{"+$" + color.additional_coef.toFixed(2) + "/sq ft"}</p>
        }
        return extra_price_tag;
    }
    getColorContent = (coat) => {
        if (coat.Colors === 'contactfq'){
            console.log("Rendering contact FQ")
            return(
                <div>
                    <div className='color-list'>
                        <h3 className='large directions center-absolute ninety-div-width'>{"[Contact a FairQuote representitive to learn more about customization options for " + coat.style + "]"}</h3>
                    </div>
                </div>
            )
        } else if (coat.sort_type === 'tiered'){ //cuurently only used by hitechcoatings 9custom request)
            return(
                <div>
                    <div className='column'>
                        {coat.Colors.tier1 && 
                            <div>
                                <p className='color-list-header'>Tier 1<p>$</p></p>
                                <div className='color-list'>
                                    {coat.Colors["tier1"].map((c) => (
                                        <div className={this.getIconActivity(c.icon, 1, 1)} 
                                            key={c.icon}
                                            alt={c.short}
                                            id={c.icon} 
                                            width="10" 
                                            height="10" 
                                            onClick={this.handleIconClick(c, coat, 1, 1)}
                                            onMouseEnter={() => this.setHoverIcon(c.icon, 1)}
                                            onMouseLeave={() => this.setHoverIcon('')}
                                            >
                                                <img
                                                    className='mediumicon'
                                                    src = {c.icon}
                                                    alt={c.short}/>
                                            {/*{this.getExtraPriceTag(c)}*/}
                                            {this.getActiveLabels(c, 1)}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        }
                        {coat.Colors.tier2 &&
                            <div>
                                <p className='color-list-header'>Tier 2 (includes all Tier 1 colors)<p>$$</p></p>
                                <div className='color-list'>
                                {coat.Colors["tier2"].map((c) => (
                                    <div className={this.getIconActivity(c.icon, 1, 2)} 
                                        key={c.icon}
                                        alt={c.short}
                                        id={c.icon} 
                                        width="10" 
                                        height="10" 
                                        onClick={this.handleIconClick(c, coat, 1, 2)}
                                        onMouseEnter={() => this.setHoverIcon(c.icon, 2)}
                                        onMouseLeave={() => this.setHoverIcon('')}
                                        >
                                            <img
                                                className='mediumicon'
                                                src = {c.icon}
                                                alt={c.short}/>
                                        {/*{this.getExtraPriceTag(c)}*/}
                                        {this.getActiveLabels(c, 2)}
                                    </div>
                                ))}
                                </div>
                            </div>
                        }
                        {coat.Colors.tier3 &&
                            <div>
                                <p className='color-list-header'>Tier 3 (includes all Tier 1 & 2 colors)<p>$$$</p></p>
                                <div className='color-list'>
                                {coat.Colors["tier3"].map((c) => (
                                    <div className={this.getIconActivity(c.icon, 1, 3)} 
                                        key={c.icon}
                                        alt={c.short}
                                        id={c.icon} 
                                        width="10" 
                                        height="10" 
                                        onClick={this.handleIconClick(c, coat, 1, 3)}
                                        onMouseEnter={() => this.setHoverIcon(c.icon, 3)}
                                        onMouseLeave={() => this.setHoverIcon('')}
                                        >
                                            <img
                                                className='mediumicon'
                                                src = {c.icon}
                                                alt={c.short}/>
                                        {/*{this.getExtraPriceTag(c)}*/}
                                        {this.getActiveLabels(c, 3)}
                                    </div>
                                ))}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            )
        }
        
        else {
            return(
                <div>
                    <div className='color-list'>
                        {coat.Colors.map((c) => (
                            <div className={this.getIconActivity(c.icon, 1)} 
                                alt={c.short}
                                id={c.icon} 
                                width="10" 
                                height="10" 
                                onClick={this.handleIconClick(c, coat, 1)}
                                onMouseEnter={() => this.setHoverIcon(c.icon)}
                                onMouseLeave={() => this.setHoverIcon('')}
                                >
                                    <img
                                        className='mediumicon'
                                        src = {c.icon}
                                        alt={c.short}/>
                                {/*{this.getExtraPriceTag(c)}*/}
                                {this.getActiveLabels(c)}
                            </div>
                        ))}
                    </div>
                </div>
            )
        }
    }
   
    handleCloseUpdate = ( )=> {
        const {handleChangeObject} = this.props;
        document.body.style.overflow = 'auto';
        const {choices} = this.state;
        this.setState({ showmodal: '' }, handleChangeObject('colors', choices));
    }

    //update color state and open preview modal
    handleIconClick = (color, coat, max_choices, tier=null) => e => {
        //console.log("Icon click")
        console.log("Tiered: " + tier)
        e.preventDefault();
        const {areatype} = this.props.values_customer;
        const isgarage = (areatype.title.toLowerCase() === 'garage') ? true : false;
        const coat_name = color.title + ' ' + coat.style;
        this.userChoice(color, coat, max_choices, tier);
        if(isgarage && color.previews && color.previews.length > 0){
            this.showModal(coat_name);
        }
    }

    //assuming tiers 1-3, get lowest tier that the color is available in
    getLowestTier = (color, tier) => {
        console.log("Get lowest tier")
        const {coat_options} = this.props;
        let lowest_tier = tier;
        for (let i = 0; i < coat_options.length; i++){
            if (coat_options[i].Colors){
                if (coat_options[i].Colors.tier1){
                    if (this.findInDict(coat_options[i].Colors.tier1, color.icon) > -1 && 1 < lowest_tier){
                        lowest_tier = 1;
                    }
                }
                if (coat_options[i].Colors.tier2){
                    if (this.findInDict(coat_options[i].Colors.tier2, color.icon) > -1 && 2 < lowest_tier){
                        lowest_tier = 2;
                    }
                }
                //do not need to check tier 3, as it is the highest tier
            }
        }
        console.log("Lowest tier: " + lowest_tier)
        return lowest_tier;
    }

    //update state to reflect selected color. "color" parameter represents the texture/finish type, coat the specific color.
    //some colors within a coat have additional costs. If so, update .coef here to reflect the total cost calculated into the future quote.
    userChoice = (color, coat, max_choices, tier=null) => { 
        console.log("User choice")
        const {handleChangeObject} = this.props;
        const {choices} = this.state;

        var index = this.findInDict(choices, color.icon, tier);
        if (index > -1){ //if already selected, remove
            choices.splice(index, 1) //remove
            this.setState({
                choices: choices, 
            }, handleChangeObject('colors', choices));
        } else if (choices.length < max_choices) { //if not selected, add
            color.style = coat.style;
            // console.log(coat.coef !== undefined)
            // console.log(coat.coef !== null)
            // console.log(coat.coef >= 0)
            if(coat.coef !== undefined && coat.coef !== null && coat.coef >= 0){
                color.coef = coat.coef;

                if(color.additional_coef){ //if color has additional cost, add it to the total cost
                    color.coef += color.additional_coef;
                }
                
            }
            if(coat.coef_ranged){
                color.coef_ranged = coat.coef_ranged;
            }
            if(coat.sort_type){ //if coat/finish has tiered pricing, add that to the color object so it renders in the right packages
                color.sort_type = coat.sort_type;
                color.lowest_tier = this.getLowestTier(color, tier);
                color.tier = tier;
            }
            color.price = coat.price;
            choices.push(color);
            this.setState({
                choices: choices, //add
            }, handleChangeObject('colors', choices));
        } else if ( (max_choices === 1) && choices.length === 1){ 
            choices.pop();      //if only 1 option at a time, toggle
            color.style = coat.style;
            if(coat.coef !== undefined && coat.coef !== null && coat.coef >= 0){
                color.coef = coat.coef;

                if(color.additional_coef){ //if color has additional cost, add it to the total cost
                    color.coef += color.additional_coef;
                }
                
            }
            if(coat.coef_ranged){
                color.coef_ranged = coat.coef_ranged;
            }
            if(coat.sort_type){ //if coat/finish has tiered pricing, add that to the color object so it renders in the right packages
                color.sort_type = coat.sort_type;
                color.lowest_tier = this.getLowestTier(color, tier);
                color.tier = tier;
            }
            color.price = coat.price;
            choices.push(color);
            this.setState({
                choices: choices, //add
            }, handleChangeObject('colors', choices));
        }
    };

    render() { 
        const { handleChangeObject, colors, coat_options, is_mobile_display} = this.props;
        const { coats } = this.state;
        const is_tiered = (coats[0] && coats[0].sort_type === 'tiered') ? true : false;
        const headertext = (!coats[0] || !coats[0].style || coats[0].Colors === 'contactfq') ? '' : <div className='textwrapper'>
                        <h2 className='large'> Let's talk colors! </h2>
                        <h4 className='small'>Choose your preferred {coats[0].style} color. You can always change it later.</h4>
                        {is_tiered && <h4 className='small italic light max-par-width title left'>Note: "Tier" refers to the level of services your package will include. Some coats are only available in higher service packages. <p className='invis'>.</p><p>For now, just choose your favorite coat and we'll walk through service packages with you more in little a bit.</p></h4>}
                        </div>;


        //const flakemodal = <ColorModal activemod={this.state.showmodal} userChose={userChoice} handleClose={handleCloseUpdate} title={flake} content={flakeoptions} actives={this.state.choices} findWithAttr={this.findInDict}/>
        //const solidmodal = <ColorModal activemod={this.state.showmodal} userChose={userChoice} handleClose={handleCloseUpdate} title={solid} content={solidoptions} actives={this.state.choices} findWithAttr={this.findInDict}/>
    
        return (
            <div className='surveywrapper' id = 'top'>
                {this.state.showmodal && coats && <CoatPreviewModal show={this.state.showmodal} handleClose={this.hideModal} color={this.state.choices[0]} coat={coats[0]} ismobile={is_mobile_display}/>}
                <div className='multiplechoice-transitionbuffer'>
                    <CSSTransition
                            in={this.state.mounted}
                            timeout={1000}
                            classNames="fade"
                            unmountOnExit 
                            appear
                    >
                        <div className='multiplechoice no-position-absolute no-footer-tag'>
                            <div className='flex-item auto-margin'>
                            {headertext}
                            <section className="nomodal-color">
                                <CSSTransition
                                    in={true}
                                    timeout={150}
                                    classNames="fade-fast"
                                    unmountOnExit 
                                    appear
                                    //onEntered={}
                                    //onExit={}
                                >
                                {coats[0] && this.getColorContent(coats[0])}
                                </CSSTransition>
                            </section>
                            </div>
                        </div>
                    </CSSTransition>
                </div>
            </div>
        )
    }
}

export default ColorPicker;