import React, {Component} from 'react'
import { CSSTransition } from 'react-transition-group';
import { PreviewModal } from './Modals/PreviewModal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons'

import Matomo from '../Matomo'
import { FooterContact } from '../general_questions/FooterContact';
import { findObjectByProperty, sendToInpersons } from '../../Helpers';
import MultipleChoiceText from '../general_questions/MultipleChoiceText';


export class ServiceType extends Component {
    constructor (props) {
        super(props);
        this.state = {
            nextpage : props.next,
            showmodal : '',
            mounted: false,
            render_as_mobile: props.ismobile,
            err: null,
        }
    }

    componentDidMount = () => {
        // /*-Matomo Analytics-*/
        // const title = 'ServiceType';
        // Matomo(title);
        // /*----End Matomo----*/

        //Skip to next page if only one option:
        if(this.props.values_customer && this.props.values_customer.areatype.Services.length === 1){
            const event = new Event("service");
            //event.data=this.props.values_customer.areatype.Services[0];
            this.continue(this.props.values_customer.areatype.Services[0], true)(event);
        }
        //const matched_servicetypes = this.initServiceTypes();
        this.setState({ mounted: true });
    }

    mergeServiceTypeInfo = (servicetype_title) => { //match areatype.service to servicetype_options.Finish info
        const { servicetype_options } = this.props.values_business;
        const { Services } = this.props.values_customer.areatype;
        const preview_url = findObjectByProperty(Services, "service", servicetype_title, false).preview;
        console.log(preview_url)
        let servicetype_obj = findObjectByProperty(servicetype_options, "service", servicetype_title, false); //case insensitive
        console.log(servicetype_title)
        console.log(servicetype_obj)
        if (servicetype_obj){
            servicetype_obj.preview = preview_url;
        }
        console.log(servicetype_obj)
        return servicetype_obj;
    }

    continue = (selected_service, skip=false) => e => {
        const { values_customer, values_business, page_title } = this.props;
        const { matched_servicetypes } = this.state;
        const reason = 'Customer requested a service type that requires an in-person estimate.';
        //const serv = findObjectByProperty(values_customer.areatype.Services, "service", service_title) || 'Error: no matching service found';
        //console.log(serv)
        try {
            const is_inperson = sendToInpersons(selected_service, 'servicetype', this.props.values_customer, this.props.values_business.inpersons, page_title);

            if (is_inperson){
                this.props.handleChangeEstimateReason(reason, null, null, skip);
            }
            else if (!is_inperson && selected_service && this.state.nextpage){
                console.log("skipping to next page")
                const selected_service_merged = this.mergeServiceTypeInfo(selected_service.service);
                console.log(selected_service_merged)
                this.props.handleChangeObjectNext(this.state.nextpage, 'servicetype', selected_service_merged, skip);
            }
        } catch (error) {
            console.log("error in ServiceType.continue")
            this.setState({err: error});
        }
    }

    // getPreviewModal = () => {
    //     const { Services } = this.props.values_customer.areatype;
    //     const service = Services.find(service => service.service === this.state.showmodal);
    //     if (!service){
    //         return;
    //     }
    //     const title = service.service;
    //     const url = service.preview;
    //     const content = [{title: title, content: <img className='service' src = {url} alt={title} />}];
    //     const show = (this.state.showmodal === title);
    //     return(
    //         <PreviewModal 
    //             show={show} 
    //             handleClose={() => this.hideModal()} 
    //             info={content}>
    //         </PreviewModal>
    //     )
    // }

    render() { 
        const { values_customer, values_business, next, nextPage, handleChangeObjectNext, handleChangeEstimateReason, is_mobile_display} = this.props;
        const { ismobile, matched_servicetypes } = this.state;
        
        if (this.state.err) throw this.state.err;
        return (
            <MultipleChoiceText
                key={values_customer.areatype.Services}
                page_title = {'Services'}
                handleChangeObjectNext={handleChangeObjectNext}
                handleChangeEstimateReason={handleChangeEstimateReason}
                estimate_reason = {'Customer requested a service type that requires an in-person estimate.'}
                showpage = {true}
                nextPage={nextPage}
                default_next = {next}
                header_question = {`What service would you like done for your ${values_customer.areatype.title}?`}
                default_state_var = {'servicetype'}
                choices = {values_customer.areatype.Services.map((service) => (
                    {title: service.service,
                        value: this.mergeServiceTypeInfo(service.service), 
                        state_var: "servicetype",
                        preview: findObjectByProperty(values_customer.areatype.Services, "service", service.service).preview
                    }
                ))}
                values_customer={values_customer} 
                values_business={values_business}
                is_mobile_display={is_mobile_display}
            />
        )
    }
}

export default ServiceType;