import React, { Component } from 'react';

export class ProgressBar extends Component{

    render () {
        const { progress } = this.props;

        return (
   
            <progress value={progress} max="100"/>
            
        )
    }

}

export default ProgressBar;