import React, {Component} from 'react';
import Matomo from '../Matomo';
import { CSSTransition } from 'react-transition-group';

export class MultipleChoiceQuestion extends Component {
    
    //requires HandleChangeObjectNext, input_category (string), header_question, pages, showpage props
    
    constructor(props) {
        super(props);
        this.state = {
            mounted: false
        };
    }

    componentDidMount = () => {
        const {showpage, pagetitle} = this.props;
        /*-Matomo Analytics-*/
        Matomo(pagetitle);
        /*----End Matomo----*/
        
        //Skip to next page
        if(!showpage){
            const event = new Event("service");
            //event.data=this.props.values_business.areatype_options[0];
            this.continue(this.props.nextpage)(event);
        }

        this.setState({ mounted: true });
    }

    continue = (next) => e =>{
        e.preventDefault();
        this.props.nextPage(next)
    };

    render() { 
            const {handleChangeObjectNext, header_question, input_category, nextpage, options} = this.props;
            console.log(options)
            return(
                <div className='surveywrapper'>
                    <CSSTransition
                        in={this.state.mounted}
                        timeout={1000}
                        classNames="fade"
                        unmountOnExit 
                        appear
                    >
                        <div className='multiplechoice'>
                            <h2 className='large'>{header_question}</h2>
                            <div className='row'>
                                {options.map((option) => (
                                    <button
                                        type='submit' 
                                        className='rowbtn'
                                        id={option.title}
                                        onClick={ () => {handleChangeObjectNext(nextpage,input_category, option.value || option.title)} }
                                    >
                                        {option.title}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </CSSTransition>
                </div>
            )

    }

}
    
export default MultipleChoiceQuestion;