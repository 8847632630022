import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import { CSSTransition } from 'react-transition-group';
import Matomo from '../../Matomo';

const InfoModal = ({ handleClose, show, children, info }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

    /*-Matomo Analytics-*/
      if (show){
        const title = 'info-' + info[0].title;
        Matomo(title);
    }
    /*----End Matomo----*/

  return (
    <div className={showHideClassName}>
      <section className="modal-info">
        <div className="right">
          <FontAwesomeIcon 
            icon={faTimes}
            color='grey'
            size='lg'
            onClick={handleClose}
                  />
        </div>
        <CSSTransition
                    in={show}
                    timeout={150}
                    classNames="fade-fast"
                    unmountOnExit 
                    appear
                    //onEntered={}
                    //onExit={}
                >
            <div>
              {children && children}
              {info.map(({ title, short, text, img_src = null })  => (
                <div>
                  <h3 className='large'>{title}</h3>
                  {short}
                  {text}
                  {img_src && <img src={img_src} alt={title} />}
                  <h3 className='large'>&nbsp;</h3>
                </div>
              ))}
          </div>
        </CSSTransition>
        <div className='right fillremainderflex'>
          <p className="clickable underline" onClick={handleClose}>Done</p>
        </div>
      </section>
    </div>
  );
};

export {InfoModal};