import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowRight} from '@fortawesome/free-solid-svg-icons'
import { CSSTransition } from 'react-transition-group'

import inboxPic from './fqInbox2.jpg'
import Matomo from '../Matomo';
import { FooterContact } from '../general_questions/FooterContact';

export class CollectContact extends Component {
    constructor (props) {
        super(props);
        this.state = {
            nextpage : this.props.next,
            mounted: false,
        }
    }

    componentDidMount = () => {
        /*-Matomo Analytics-*/
        const title = this.props.page_title;
        Matomo(title);
        /*----End Matomo----*/

        //Skip to next page if areatype is not a garage:
        if(!this.props.showpage){
            const event = new Event("emptyevent");
            console.log(this.props.pages)
            this.Continue(true)(event);
        } 

        this.setState({ mounted: true });
        this.props.getDBQuote();
    }

    Continue = (skip=false) => e =>{
        e.preventDefault();
        this.props.nextPage(this.state.nextpage, skip);
    }

    isValidInput = () => {
        const {values_customer, regex, regex_phone, requirePhone} = this.props;
        console.log(values_customer.phone);
        if (regex.test(values_customer.email)) {
            if (requirePhone) {
                return (values_customer.customername && values_customer.customername.length > 1 && values_customer.customername.trim().split(' ').length >= 2 && regex_phone.test(values_customer.phone));
            } else {
                return (values_customer.customername && values_customer.customername.length > 1 && values_customer.customername.trim().split(' ').length >= 2);
            }
        }
        return false;
    }

    getMiniPlaceholder = (contact_placeholder, values_customer) => {
        const {regex, regex_phone, requirePhone} = this.props;
        if (contact_placeholder.toLowerCase() === 'phone') {
            if (regex_phone.test(values_customer.phone)){
                return <p className = {(values_customer.phone) ? 'miniplaceholder' : 'miniplaceholder invis'}>Phone (Text capable)</p>
            } else {
                return <p className = {(values_customer.phone) ? 'miniplaceholder invalid' : 'miniplaceholder invis'}>Invalid Phone (Text capable)</p>
            }
        }
        else if (contact_placeholder.toLowerCase() === 'email') {
            if (regex.test(values_customer.email)){
                return <p className = {(values_customer.email) ? 'miniplaceholder' : 'miniplaceholder invis'}>Email</p>
            } else {
                return <p className = {(values_customer.email) ? 'miniplaceholder invalid' : 'miniplaceholder invis'}>Invalid Email</p>
            }
        }
        else if (contact_placeholder.toLowerCase() === 'name') {
            if (values_customer.customername && values_customer.customername.length > 1){
                return <p className = {(values_customer.customername) ? 'miniplaceholder' : 'miniplaceholder invis'}>Name (First & Last)</p>
            } else {
                return <p className = {(values_customer.customername) ? 'miniplaceholder invalid' : 'miniplaceholder invis'}>Invalid Name (First & Last)</p>
            }
        }
    }
    
    render() {
        const {nextPage, handleChange, values_customer, values_business, is_mobile_display, requirePhone} = this.props;
        const title = "Where can we verify you're a real human & send a copy of your personalized quote?"
        const possesive_businessname = (values_business.businessname[values_business.businessname.length - 1] === 's') ? values_business.businessname + "'" : values_business.businessname + "'s";

        //const valid_input = values_customer.email && values_customer.customername && values_customer.customername.length > 2 && email_regex.test(values_customer.email);

        return (
            <div className ='surveywrapper'>
                <div className='largeimagewrapper'>
                    <img class='coverphoto' src= {inboxPic}/>
                </div>
                    <div className='questionwrapper'>
                        <CSSTransition
                            in={this.state.mounted}
                            timeout={1000}
                            classNames={is_mobile_display ? "fade" : "slide-to-left"}
                            unmountOnExit 
                            appear
                        >
                            <div>
                                <section className="collect-email">
                                    <div className='textwrapepr'>
                                        <h2 className='large'>{title}</h2>
                                    </div>
                                    <div className="formwrapper">
                                        <input 
                                            type="text"
                                            name="name" 
                                            autoComplete="name"
                                            placeholder= {'Name (First & Last)'}
                                            value = {values_customer.customername}
                                            alt = 'your first and last name'
                                            onChange = {handleChange('customername')}
                                            //data-matomo-mask 
                                        />
                                        {this.getMiniPlaceholder('name', values_customer)}
                                    </div>
                                    <div className="formwrapper">
                                        <input 
                                            type={'email'}
                                            name={'email'}
                                            autoComplete="email"
                                            placeholder= {'Email Address'}
                                            value = {values_customer.email}
                                            alt = {'your email address'}
                                            onChange= {handleChange('email')}
                                            //data-matomo-mask 
                                        />
                                        {this.getMiniPlaceholder('email', values_customer)}
                                    </div>
                                    {requirePhone && <div className="formwrapper">
                                        <input 
                                            type={'tel'}
                                            name={'tel'}
                                            autoComplete="tel"
                                            placeholder= {'Phone (Text-Capable)'}
                                            value = {values_customer.phone}
                                            alt = {'phone number that can recieve texts'}
                                            onChange= {handleChange('phone')}
                                            //data-matomo-mask 
                                        />
                                        {this.getMiniPlaceholder('phone', values_customer)}
                                    </div>}
                                </section>
                                <div className='column'>
                                    <p className='description x-small'>We use this information to verify you're not a robot and to send you a copy of your quote, in accordance with {possesive_businessname} <a href={values_business.homepage} target='_blank'>privacy policy.</a></p>
                                    <button type='button' 
                                            disabled = { !this.isValidInput() || !this.props.enable}
                                            class='formsubmitbtn'
                                            onClick={this.Continue()}>
                                            See Result on Next Page
                                            {/*{(valid_input) ? 
                                                <FontAwesomeIcon 
                                                    icon={faArrowRight}
                                                    onClick={this.Continue}
                                                /> 
                                                : ''
                                            }*/}
                                        </button>
                                    {/*<p>&nbsp;</p>
                                    <p 
                                        className="clickable underline grey" 
                                        onClick={this.Continue()}>
                                            Skip this step
                                        </p>*/}
                                </div>
                                <div className="fillremainderflex"/>
                            </div>
                    </CSSTransition>  
                    <FooterContact values_business={values_business} phone={values_customer.branch.phone}/>
                </div>
            </div>
        );
    }
};

export default CollectContact;