import React, {Component} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPhoneAlt, faCheckCircle, faCheck, faSyncAlt, faChevronCircleLeft, faChevronCircleRight, faFlag, faChevronLeft, faChevronRight, faArrowRight} from '@fortawesome/free-solid-svg-icons';
import { InfoModal } from '../questions/Modals/InfoModal';
import Matomo from '../Matomo';
import { FooterContact } from './FooterContact';
import { conditionIsMet, findIndexByProperty, findObjectByProperty, tallyServiceQuote } from '../../Helpers';
import { EmailModal } from './modals/EmailModal';
import { ScheduleModal } from './modals/ScheduleModal';

// provides quotes for different servicetypes, rather than one servictype with variations


export class QuoteServices extends Component {
    // static getDerivedStateFromProps(props, state) { //ensures re-render after api response is recieved
    //     if (props.colors !== state.colors) {
    //       return { colors: props.colors };
    //     }
    //     return null;
    //   }
    constructor (props) {
        super(props);
        this.state = {
            showmodal : '',
            infomodal : null,
            emailsent : false,
            custom_checked: null,
            selected_items: [],
            //selected_base: [],
            showbreakdown : false,
            cats: null,
            current_package: null,
            //save_premium: (props.offer_discount) ? 100 : 0, //depricated: now determined by package price
            win_height: null,
            nav_isnext: true,
            mounted: false,
            package_entered: false,
            showinputflag: false, //passed to schedule now modal
            startIndex: 0,
            visiblePackagesCount: null, // Number of items to display
            itemWidth: null,
            itemHorizontalMargin: null, // Width of each package, mobile is vw, desktop is px
            scrollOffset: 0, // New state variable for scroll offset
            lead_created: false, //flag to determine if lead has been created in quote viewed
        }
    }

    componentDidMount = async () =>{
        /*-Matomo Analytics-*/
        const title = 'QuotePackages';
        Matomo(title);
        
        //Uncomment lines below to enable heatmaps...although it may have been cutting session videos short
        //window._paq.push(['HeatmapSessionRecording::matchTrackerUrl']);
        //window._paq.push(['HeatmapSessionRecording::disableAutoDetectNewPageView']);
        
        /*----End Matomo----*/
    
        document.body.style.overflow = 'auto';

        //if (this.props.values_customer.sidewallheight === null || this.props.values_customer.sidewallheight === 0){
       // this.initCustom(this.props.addon_items);
        if(!this.state.cats){
            this.initPackages();
        }
        if(this.state.cats){
            this.setPackage(this.state.current_package);
        }
        window.addEventListener('resize', this.handleWindowResize);
        this.handleWindowResize();
        this.setState({ mounted: true });
        if (!this.state.lead_created){
            setTimeout(await this.submitViewedQuote, 1000); //save lead info automatically after 1 seconds on page, if it hasn't already been done
        }
        //this.submitViewedQuote();
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    componentDidUpdate = () => {
        const {cats, current_package} = this.state;
        if(!cats || (cats[0].tally_low < 0 && cats[0].tally_low !== 'call')){
            this.initPackages();
        }
    }

    handleWindowResize = () => { //determines if package should be rendered as mobile or desktop
       //console.log("handling window resize")
        const {cats, itemWidth, itemHorizontalMargin} = this.state;
        const {is_mobile_display} = this.props;
       //console.log("is mobile display2: " + is_mobile_display)
        const item_width = (is_mobile_display) ? window.innerWidth * (90 / 100) : 350; // Width of each package, mobile is converted from vw to px, desktop is px
        const item_margin = (is_mobile_display) ? window.innerWidth * (2 / 100) : 30; // Width of each package, mobile is vw, desktop is px
        const margin_left_px = 100; //navicons
        const package_min_width_px =  item_width + item_margin; //make sure itemWidth and itemHorizontalMargin are set before calculating
       //console.log("item width: " + item_width)
       //console.log("item margin: " + item_margin)
        const max_adjacent_packages = Math.floor((window.innerWidth - margin_left_px) / package_min_width_px);

        if (is_mobile_display){
            this.setState({ visiblePackagesCount: 1, itemHorizontalMargin: item_margin, itemWidth: item_width }); //don't try to fit more than one package at a time on mobile
        } else if (cats && max_adjacent_packages >= cats.length){ //if all packages can fit on screen, reset to display all
            this.setState({ visiblePackagesCount: max_adjacent_packages, itemHorizontalMargin: item_margin, itemWidth: item_width, startIndex: 0, scrollOffset: 0 });
        } else {
            this.setState({ visiblePackagesCount: max_adjacent_packages, itemHorizontalMargin: item_margin, itemWidth: item_width }); //TODO: make sure current package is visible after resize
        }
        
    }

    //determines if package item should be counted and rendered in current package
    //checking for grayed only is used to determine if item should be rendered as grayed out in the package
    packageItemShouldBeIncluded = (item) => {
       console.log("packageItemShouldBeIncluded()")
       console.log(item)
       const has_price = item.price || item.price_low || item.price_high;
        let is_included = false;
        let is_grayed = false;

        const should_include = this.shouldInclude(item.Include);
        const should_exclude = this.shouldExclude(item.Exclude);
       console.log("should include: " + should_include)
       console.log("should exclude: " + should_exclude)

        if ( should_include && !should_exclude ){  // matches current package and not explicitly excluded 
            if (item.flat === "Included") { //item is a "base" item, doesn't need to have a specific cost tied to it
                is_included = true;
            } else if (item.flat === 'Call Us'){ //item requires calling for a quote
                is_included = true;
            } else if (has_price){ //item is not always included and needs to have a cost tied to it to render
                is_included = true;
            }
        } else if (!should_include && !should_exclude){ //for grayed items, item is not included but also not explicitly excluded
            if (item.flat === "Included") { //item is a "base" item, doesn't need to have a specific cost tied to it
                is_grayed = true;
            } else if (item.flat === 'Call Us'){ //item requires calling for a quote
                is_grayed = true;
            } else if (has_price){ //item is not always included and needs to have a cost tied to it to render
                is_grayed = true;
            }
        }
        return [is_included, is_grayed];
    }


    // initialize package items and prices
    initPackages = () => {  
        if(this.state.cats ){
            return;
        }
        //console.log("initializing package tally");                             //loop through all addons and "check" for current package
        const {addon_items, base_items, singlepackage} = this.props.values_business;
        const {cats, visiblePackagesCount} = this.state;
        const {is_mobile_display, package_objects, values_customer} = this.props;
        
        let cat_addons= [];
        let base = [];
        let categories = []; 
        //package_items will include all addon items as well as base items
        //grayed_items are rendered as grayed out in the package, not included in any quote tallies etc.
        
        //loop through titles to create package objects
        for (let i = 0; i < package_objects.length; i++){
           //console.log(package_objects[i])
            const package_obj = {
                title: package_objects[i].title, 
                short: package_objects[i].short, 
                long: package_objects[i].long, 
                items: [], 
                grayed_items: [], 
                tally: null, 
                lead: 'schedule'
            };
            const package_items = (package_objects[i].Areas[values_customer.jobtype]) ? package_objects[i].Areas[values_customer.jobtype].Addons : package_objects[i].Areas['all'].Addons;   

            for (let i = 0; i < package_items.length; i++){ //for every base, loop through all package types and add to list
                const item = package_items[i];
                const [is_included, is_grayed] = this.packageItemShouldBeIncluded(item);
               console.log("is included: " + is_included)
               console.log("is grayed: " + is_grayed)
                if (is_included){ //in package and area
                    console.log('current package: ' + package_obj.title + ", adding package_items " + package_items[i].title);
                    package_obj.items.push(package_items[i]);
                } 
                if (is_grayed){ //"checking_for_grayed_only" flag=true
                    console.log('current package: ' + package_obj.title + ", graying package_items " + package_items[i].title);
                    package_obj.grayed_items.push(package_items[i]);
                }
                //cat_addons = []; //reset for next iteration
            }
            categories.push(package_obj);
        }


        const custom_package = findObjectByProperty(categories, "title", "customize");
        let initial_custom_checked = (custom_package) ? custom_package.items : null;
        if (initial_custom_checked){
            initial_custom_checked = initial_custom_checked.filter(item => item.flat !== 'Call Us');
        }

        //console.log(categories)
        for (let i=0; i < categories.length; i++){ // calculate final tallies
            console.log("Calculating tallies for " + categories[i].title)
            //tallyServiceQuote returns [tally_low, and tally_high]
            const tally = tallyServiceQuote(categories[i].items, this.props.values_quote.fees);
            categories[i].tally_low = tally[0];
            categories[i].tally_high = tally[1];
            if (categories[i].tally_low < 1000){ //adjust save premium based on lowest package pricepoint
                categories[i].save_premium = Math.ceil(categories[i].tally_low * .1);
            } else {
                categories[i].save_premium = 100;
            }
        }
        //console.log("checking for duplicate packages to hide..")
        categories = this.removeDuplicatePackages(categories);
        let init_current_package = 0;
        const init_start_index = (!is_mobile_display && visiblePackagesCount > 1) ? Math.min(init_current_package, 0) : init_current_package; //make sure lowest tier package is at least visible (not selected) if there are multiple packages
        const init_offset = init_start_index * (this.state.itemWidth + this.state.itemHorizontalMargin);
        //console.log(categories)
        this.setState({cats: categories, custom_checked: initial_custom_checked, current_package: init_current_package, selected_items: [...categories[init_current_package].items], startIndex: init_start_index, scrollOffset: -init_offset});
    }


    removeDuplicatePackages = (packages) => {
        for (let i = 0; i < packages.length; i++){  //for every package,
            for (let j = 0; j < packages.length; j++){ //check every other package...
                if(i !== j){ //don't compare packages with themselves
                   //console.log(packages[i].tally+ " tally vs " + packages[j].tally);
                   //console.log(packages[i].items.length+ " items vs " + packages[j].items.length)
                    if(packages[i].tally_low === packages[j].tally_low && packages[i].items.length === packages[j].items.length){ //first check items length and tally prices match...
                       //console.log("tallies match: " + packages[i].tally + " vs " + packages[j].tally)
                        let items_match = true;
                        for(let k = 0; k < packages[i].items.length; k++){  //...then loop through all .items
                            if(packages[i].items[k].title !== packages[j].items[k].title){ //...to check if ANY items don't match
                               //console.log("items don't match: " + packages[i].items[k].title + " vs " + packages[j].items[k].title)
                                items_match = false;
                            }
                        }

                        if(items_match){ //if items ALL match...
                           //console.log("Removing *" + packages[i].title + "* package due to redundancy with *" + packages[j].title + "* package")
                            packages.splice(i,1); //clear the "highest" package (i.e. "deluxe" removed, "recommended" kept)
                            i = (i === 0) ? 0 : i-1 ; //account for new length
                            j = (j === 0) ? 0 : j-1; //account for new length
                        }
                    } 
                    //console.log("tallies DON'T match: " + packages[i].tally + " vs " + packages[j].tally)
                }
            }
        }
        return packages;
    }

    getPackageQuotes(package_obj){
        //console.log(index)
        
        const range = this.props.values_business.quote_range;
        const {businessid} = this.props.values_business;
        let full_quote;
        let discounted_quote;

        if(!package_obj){
            return -1;
        } 
        if (package_obj.tally_low === 'call'){
            return ['call', 'call', 'call'];
        }

        // if (range > 0){
        //     let upper_bound;
        //     let lower_bound;
        const upper_bound = package_obj.tally_high;
        const lower_bound = package_obj.tally_low;
        const exact_quote = (upper_bound === lower_bound) ? parseFloat(((upper_bound + lower_bound)/ 2).toFixed(0)).toLocaleString() : parseFloat((upper_bound).toFixed(0)).toLocaleString();

        if (upper_bound === lower_bound){ //if exact quote (price minimum), add a range of  +5% to the quote (can't go below minimum)
            full_quote = parseFloat((lower_bound + package_obj.save_premium).toFixed(0)).toLocaleString() + ' - ' + parseFloat(( (upper_bound * 1.05) + package_obj.save_premium).toFixed(0)).toLocaleString();
            discounted_quote = parseFloat((lower_bound).toFixed(0)).toLocaleString() + ' - ' + parseFloat((upper_bound * 1.05).toFixed(0)).toLocaleString();
        } else {
            full_quote = (upper_bound !== lower_bound) ? parseFloat((lower_bound + package_obj.save_premium).toFixed(0)).toLocaleString() + ' - ' + parseFloat((upper_bound + package_obj.save_premium).toFixed(0)).toLocaleString() : parseFloat((upper_bound + package_obj.save_premium).toFixed(0)).toLocaleString();
            discounted_quote = (upper_bound !== lower_bound) ? parseFloat((lower_bound).toFixed(0)).toLocaleString() + ' - ' + parseFloat((upper_bound).toFixed(0)).toLocaleString() : parseFloat((lower_bound).toFixed(0)).toLocaleString();
        }
        // } else {
        //     full_quote = parseFloat((package_obj.tally + save_premium).toFixed(0)).toLocaleString();
        //     discounted_quote = parseFloat((package_obj.tally).toFixed(0)).toLocaleString();
        // }

        //const actual_quote = (package_obj.lead === 'save') ? full_quote : discounted_quote;

        return [discounted_quote, discounted_quote, exact_quote]; //NOTE: full quote is deprectaed, use "disocunted quote" instead ***TODO***: remove all save_premium references
    }
    toggleBreakdown = () => e => {
        if (this.state.showbreakdown === false){
            this.setState({ showbreakdown: true });
        } else {
            this.setState({ showbreakdown: false });
        }
    }
    showModal = () => e => {
        document.body.style.overflow = 'hidden';
        this.setState({ showmodal: e.target.id, emailsent: false });
    };
    
    hideModal = () => {
        document.body.style.overflow = 'auto';
        this.setState({ showmodal: '' });
    };
    emailSent = (wassent) => {
        this.setState({ emailsent : wassent });
    }
    submitSchedule = async () => {
        const {email, phone, customername, address, preferredcontact} = this.props.values_customer;
        const {email_regex, phone_regex} = this.props;
        const {cats, current_package, lead_created} = this.state;

        if (!lead_created){ //if lead hasn't been created, re submit lead info first
            await this.submitViewedQuote();
        }

        if(!email || !email_regex.test(email) || !phone || !phone_regex.test(phone)|| !customername || !address || !preferredcontact){
            this.setState({ showinputflag: true });
        } else {
            const package_obj = cats[current_package];
            const [full_quote, discounted_quote, exact_quote] = this.getPackageQuotes(package_obj);
            await this.props.handleSubmitQuote(discounted_quote, exact_quote, this.state.selected_items, 'schedule'); 
            this.emailSent(true);
        }
    }
    submitSaveQuote = async () => {
        const {cats, current_package, lead_created} = this.state;
       //console.log('submitting save')
        const package_obj = cats[current_package];
        const [full_quote, discounted_quote, exact_quote] = this.getPackageQuotes(package_obj);
        

        if (!lead_created){ //if lead hasn't been created, re submit lead info first
            await this.submitViewedQuote();
        }

        //for now, submit using DISCOUNTED QUOTE instead of full_quote, even though it does not match the displayed "save" pricing. When a compnay clicks on the square footage review link, they would see an "exact quote" that does not reflect FairQuote save premium, which would be confusing as to why the exact quote is lower than the range provided to the customer. BLUF: more intuitive on the backend for the compnay to use the base/exact/"discounted" quote at this time.
        await this.props.handleSubmitQuote(discounted_quote, exact_quote, this.state.selected_items, 'save'); 
        this.emailSent(true);
    }
    submitViewedQuote = async () => { //submits lead information for base price only
        const {cats, current_package} = this.state;
       //console.log('submitting viewed quote')
        const package_obj = cats[current_package];
        const [full_quote, discounted_quote, exact_quote] = this.getPackageQuotes(package_obj);
        const shown_quote = (package_obj.lead === 'save') ? full_quote : discounted_quote;
        const response = await this.props.handleViewedQuote(shown_quote, exact_quote, cats[current_package], this.state.selected_items);
        console.log(response)
        if (response.status === 200){
            this.setState({ lead_created: true });
        }
    }

    isSelected= (val) =>{
        const {custom_checked} = this.state;
        if(!custom_checked){
            return true
        }
        const found = custom_checked.some(el => el.title === val);
        if (found || val === this.state.radio.title) {
            return true;
        }
        return false;
    };
    toggleCheckbox = () => e =>{ //handles more than one selected checkbox. for custom option ONLY
        const val = e.target.value;
        const {custom_checked, cats} = this.state;
        const {addon_items, base_items} = this.props.values_business;
        const all_items = [...base_items, ...addon_items];
        const found = custom_checked.some(el => el.title === val);
        const custom_index = cats.length - 1;
        const new_custom_checked = [...custom_checked];

        if (!found && val !== undefined){ //not found, add to list
            const addon = all_items.find(el => el.title === val)
           //console.log(addon)
            //cats[custom_index].items = [...custom_checked,addon];
            new_custom_checked.push(addon);
            const tally = tallyServiceQuote(new_custom_checked, this.props.values_quote.fees);
            cats[custom_index].tally_low = tally[0];
            cats[custom_index].tally_high = tally[1];
           //console.log(cats[custom_index].items)
            this.setState({cats: cats, custom_checked: new_custom_checked, selected_items: new_custom_checked});
        } else {
            const index = custom_checked.findIndex(x => x.title ===val);
            //console.log('index is:' + index);
            if (index > -1) {
                new_custom_checked.splice(index, 1);
                //cats[custom_index].items = [...custom_checked];
                const tally = tallyServiceQuote(new_custom_checked, this.props.values_quote.fees); //calculate quote for custom package, but don't change orignal package items
                cats[custom_index].tally_low = tally[0];
                cats[custom_index].tally_high = tally[1];
                //console.log("custom checked: ");
                //console.log(custom_checked);
                //console.log(this.state.addons);
                this.setState({cats: cats, custom_checked: new_custom_checked, selected_items: new_custom_checked}); 
            }
        }
    }

    // tallyQuote = (cat_addons) => {
    //    console.log("Tally Quote")
    //    console.log(cat_addons)
    //     // if (this.props.values_quote.floorquote === null) {
    //     //     return -1; //quote not ready
    //     // }
    //     let checkmarked = cat_addons;
    //    //console.log('checkmarked')
    //    //console.log(checkmarked)

    //     let sum = 0;
    //     if(checkmarked ){
    //         for (let i = 0; i < checkmarked.length; i++){
    //            console.log(checkmarked[i])
    //             if(checkmarked[i].flat === 'Call Us'){ //item requires calling for a quote, don't include in tally
    //                 return "call";
    //             }
    //             if(!isNaN(checkmarked[i].price) && checkmarked[i].price !== null){ //TODO pass in actual current package
    //                 sum += parseFloat(checkmarked[i].price);
    //             }
    //         }
    //     }
    //    //console.log('base, addons, and color sum: ' + sum)
    //     sum += this.props.values_quote.fees; //calculated on backend
    //    //console.log('base, addons, color, and fees sum: ' + sum)
    //     if (isNaN(sum)){
    //         console.error("Error: tallied result was Nan")
    //     }
    //    console.log('final sum: ' + sum)
    //     return sum;
    // }

    //set package to visibly centered based on index
    setPackage = (index) =>{
        const {cats, current_package, visiblePackagesCount, startIndex} = this.state;
        const {values_business} = this.props;
        const {singlepackage} = values_business;
        let new_current_package = index;
        let new_start_index = startIndex;
        let new_scroll_offset = startIndex * (this.state.itemWidth + this.state.itemHorizontalMargin);
       //console.log("setPackage")
       //console.log("Visible packages count: " + visiblePackagesCount)
       //console.log ("cats length: " + cats.length)
        if (singlepackage){
            new_current_package = 0;
        } else if (visiblePackagesCount >= cats.length) { //all packages are visible, no need to scroll
            new_current_package = index;
            new_start_index = 0;
            new_scroll_offset = 0;
        } else { //scrolling
            if (visiblePackagesCount % 2 === 0){ //even number of packages, left justify instead of center
                if(index < current_package) { //scrolling left
                    new_current_package = index;
                    new_start_index = Math.max(index, 0);
                    new_scroll_offset = new_start_index * (this.state.itemWidth + this.state.itemHorizontalMargin);
                } else if (index > current_package) { //scrolling right
                    new_current_package = index
                    new_start_index = Math.min(index, cats.length - visiblePackagesCount);
                    new_scroll_offset = new_start_index * (this.state.itemWidth + this.state.itemHorizontalMargin);
                }
            } else { //odd number of packages, jump to centered position
                new_current_package = index;        
                new_start_index = new_current_package - Math.floor(visiblePackagesCount/2);
                new_scroll_offset = new_start_index * (this.state.itemWidth + this.state.itemHorizontalMargin); 
            }
        } 
        this.setState({current_package: new_current_package, startIndex: new_start_index, scrollOffset: -new_scroll_offset});
    }

    scrollLeft = () => {
        this.setState(prevState => {
            const newStartIndex = Math.max(prevState.startIndex - prevState.visiblePackagesCount, 0);
            return {
                startIndex: newStartIndex,
                scrollOffset: -newStartIndex * (prevState.itemWidth + prevState.itemHorizontalMargin),
                current_package: newStartIndex,
            };
        });
    }
    
    scrollRight = () => {
        this.setState(prevState => {
            const newStartIndex = Math.min(prevState.startIndex + prevState.visiblePackagesCount, prevState.cats.length - prevState.visiblePackagesCount);
            const newScrollOffset = newStartIndex * (prevState.itemWidth + prevState.itemHorizontalMargin);
            return {
                startIndex: prevState.scrollOffset !== newScrollOffset ? newStartIndex : prevState.startIndex,
                scrollOffset: -newScrollOffset,
                current_package: prevState.scrollOffset !== newScrollOffset ? newStartIndex : prevState.startIndex,
            };
        });
    }

    setLeadType = (package_name) => e => { //toggles between "save" and "schedule" lead types
        const new_cats = [...this.state.cats];
        const index = findIndexByProperty(new_cats, 'title', package_name);
        if (index === -1){
           //console.log("ERROR: tried to set lead type for non-existent package: " + package_name)
        }
        new_cats[index].lead = e.currentTarget.id;
        this.setState({cats : [...new_cats]})
    }

    getFloorNumericalValue = (floorcondition) => { //converts floorcondition string into numerical values to compare
        let value;
        switch (floorcondition){
            case "none":
                value = 0;
                break;
            case "light":
                value = 1;
                break;
            case "medium":
                value = 2;
                break;
            case "heavy":
                value = 3;
                break;
            default:
               //console.log("ERROR:  no floorcondition value provided to getFloorNumericalValue")
                break;
        }
        return value;
    }

    checkAcceptableFloorCondition = (includeifs_pack) => {
        //console.log(includeifs_pack)
        let values_customer = this.props.values_customer;
        let minfloordamage = null;
        let maxfloordamage = null;
        let actualfloordamage = this.getFloorNumericalValue(values_customer.floorcondition);

        for (let i = 0; i < includeifs_pack.length; i++){
            for (const [key, value] of Object.entries(includeifs_pack[i])){ 
                if (key === "minfloordamage"){
                    minfloordamage = this.getFloorNumericalValue(value);
                } else if (key === "maxfloordamage"){
                    maxfloordamage = this.getFloorNumericalValue(value);
                }
            }
        }
        //console.log("min floor damage: " + minfloordamage)
        //console.log("max floor damage: " + maxfloordamage)
        //console.log("actual floor damage: " + actualfloordamage)
        if (minfloordamage && maxfloordamage){  //must check if value fits between min AND max floor damage 
            if (maxfloordamage <= actualfloordamage     //floor value is *greater* the more damage, which is why Max <= actual >= Min 
            && minfloordamage >= actualfloordamage
            ){
                return true;
            } else {
                return false;
            }
        }
        else if (minfloordamage !== null){ //specify null, clean floor value is zero (false)
            if(minfloordamage >= actualfloordamage){
                return true;
            }
            return false;
        }
        else if (maxfloordamage !== null){ 
           //console.log(maxfloordamage <= actualfloordamage)
            if(maxfloordamage <= actualfloordamage){
                return true;
            }
            return false;
        }
        else { //does not contain a min/max floor damage parameter
           //console.log("no min/max floor damage parameter to check")
            return true;
        }
    }

    shouldInclude = (includeifs) => { 
        const {values_customer} = this.props;
        if (includeifs === "always"){
            return true; //always included
        }
        for (let i=0; i < includeifs.length; i++){ //for every condition
            for (const [key, value] of Object.entries(includeifs[i])){ //conditions grouped together in a single object must ALL be met
                //console.log("Checking includeif " + key + ": " + value)
                if (conditionIsMet(key, value, values_customer)){
                    return true; //if **ANY** group of conditions are met, addon can be included
                }
            }
        }
        //console.log(key + ": " + value)
        return false; //All conditions must fail for the addon to not be included
    }
    shouldExclude = (excludeifs) => { //If ANY conditions are met, addon is excluded (not displayed) from ALL packages. excludeifs is an Object.
        const {values_customer} = this.props;
        if (excludeifs === "always"){
            return true; //always excluded
        }
        for (let i=0; i < excludeifs.length; i++){ //for every condition
            for (const [key, value] of Object.entries(excludeifs[i])){ //conditions grouped together in a single object must ALL be met
                //console.log("Checking includeif " + key + ": " + value)
                if (conditionIsMet(key, value, values_customer)){
                    return true; //if **ANY** group of conditions are met, addon can be excluded
                }
            }
        }
        //console.log(key + ": " + value)
        return false; //All conditions must fail for the addon to not be excluded
    }

    showInfoModal = () => {
        const {infomodal} = this.state;
        if (!infomodal){
            return null;
        }
        const description = [{title: infomodal.item.title, short: <p className="medium light"><FontAwesomeIcon icon={faCheckCircle}/>&nbsp;{infomodal.item.short}</p>, text: <p className="small spaced light">{infomodal.item.long}</p>, img_src: infomodal.item.src}];
        const show = (this.state.showmodal === infomodal.package_title + infomodal.item.title);
        if (infomodal.item.title && infomodal.item.short){
            return (
                <InfoModal show={show} handleClose={() => this.hideModal()} info={description}>
                                </InfoModal>
            )
        } else {
            return null;
        }
    }

    setInfoModal = (package_title,item) => {
       //console.log("setting info modal")
       //console.log(package_title)
       //console.log(item)
        this.setState({ showmodal: package_title + item.title, infomodal: {package_title: package_title, item: item} });
    }

    handlePhoneClick = () => {
        window._paq.push(['trackGoal', 8]);
    }

    render() { 
        const { handleChange, handleChangeObject, values_customer, values_business } = this.props;
        const {cats, current_package, scrollOffset, visiblePackagesCount, itemHorizontalMargin, itemWidth} = this.state;
        const {is_mobile_display} = this.props || visiblePackagesCount === 1;
        const singlepackage = values_business.singlepackage || (cats && cats.length === 1);
        const na = 'N/A';
        ////console.log("visiblePackagesCOunt: " + visiblePackagesCount)
        ////console.log("current package: " + current_package)
        ////console.log("scroll offset: " + scrollOffset)
        ////console.log("item width: " + itemWidth)
        ////console.log(cats)
        ////console.log(cats && cats.length > visiblePackagesCount)
        ////console.log(cats && -scrollOffset < (cats.length - visiblePackagesCount) * (itemWidth + itemHorizontalMargin))

        const render_right_nav = cats && cats.length > visiblePackagesCount && -scrollOffset < (cats.length - visiblePackagesCount) * (itemWidth + itemHorizontalMargin);
        const render_left_nav = cats && cats.length > visiblePackagesCount && -scrollOffset > 0;
       //console.log("render right nav: " + render_right_nav)
       //console.log("render left nav: " + render_left_nav)
        const button_row_max_width = (visiblePackagesCount + .75) * (itemWidth + itemHorizontalMargin); //add .75 to account for margin
        const button_row_min_width = (visiblePackagesCount) * (itemWidth + itemHorizontalMargin)


        const spinner = <FontAwesomeIcon 
                            icon={faSyncAlt}
                            class='fa-spin spinner'
                            />
        const savetitle = "Save Quote"
        const scheduletitle = "Schedule Job"
        const savecontent = "Please confirm your contact information below and we'll email you a copy of your customized quote."
        const schedulecontent = "Please provide your contact information and we will reach out as soon as possible."
        const savemodal = <EmailModal 
                            activemod={this.state.showmodal}
                            setCustomerEmail={handleChange('email')}
                            setCustomerName={handleChange('customername')}
                            setCustomerPhone={handleChange('phone')} 
                            setPermissionedFollowup={handleChange('permissioned_followup')}
                            handleClose = {this.hideModal}
                            handleSubmit={this.submitSaveQuote} 
                            title={savetitle} 
                            content={savecontent} 
                            email={values_customer.email}
                            email_regex={this.props.email_regex}
                            customername={values_customer.customername}
                            phone={values_customer.phone}
                            emailsent={this.state.emailsent}
                            afterquote_link={values_business.afterquote_url}
                            permissioned_followup={values_customer.permissioned_followup}
                            />
        const schedulemodal = <ScheduleModal 
                                activemod={this.state.showmodal}
                                setCustomerEmail={handleChange('email')}
                                setCustomerPhone={handleChange('phone')}  
                                setCustomerName={handleChange('customername')} 
                                //setCustomerAddress={handleChange('address')}
                                handleChangeObject={handleChangeObject}
                                setPreferred={handleChange('preferredcontact')} 
                                setPresentForQuoteVerification={handleChange('present_for_quote_verification')}
                                handleClose = {this.hideModal}
                                handleSubmit={this.submitSchedule} 
                                showinputflag={this.state.showinputflag}
                                title={'Schedule Job'} 
                                content={schedulecontent} 
                                email={values_customer.email}
                                email_regex={this.props.email_regex}
                                phone={values_customer.phone}
                                phone_regex={this.props.phone_regex}
                                customername={values_customer.customername}
                                preferredcontact={values_customer.preferredcontact}
                                address={values_customer.address}
                                emailsent={this.state.emailsent}
                                afterquote_link={values_business.afterquote_url}
                                industry={values_business.industry}
                                values_customer={values_customer}
                                values_business={values_business}
                                />


        const getDiscountAdjustedQuote = (discounted = false, package_obj) => { //gets the discount-adjusted quote for the current package
           //console.log("Getting calculated quote..")
            const {cats} = this.state;
            const phone = values_customer.branch.phone;
           //console.log("PHONE: " + phone)
            const range = values_business.quote_range;
            let index;

            if(!this.state.cats){
                return spinner;
            }

            const [displayed_full_quote, displayed_discounted_quote, ,] = this.getPackageQuotes(package_obj);
            if (displayed_full_quote === 'call'){
                return(
                    <div>
                        <p className='m-large inline no-margin' onClick={this.handlePhoneClick()}><a className='clickable' href={'tel:+1.' + phone}><FontAwesomeIcon icon={faPhoneAlt}/> Call Us!</a></p> {/*TODO: add immediate api email notification to company with all quote info, if user clicks Call now*/}
                    </div>
                )
            } else if (!discounted) {
                return(
                    <div>
                        <p className='small inline no-margin'>$</p>
                        <p className='m-large inline no-margin'>{displayed_full_quote}</p>
                    </div>
                )
            } else {
                if(range > 0){ //, more space required, format quotes stacked vertically
                    return(
                        <div>
                            <p className='small inline no-margin deselected'>$</p>
                            <p className='discounted m-large inline no-margin deselected'>{displayed_full_quote}</p>
                            <div>
                                <p className='small inline no-margin'>$</p>
                                <p className='m-large inline no-margin'>{displayed_discounted_quote}</p>
                            </div>
                        </div>
                    )
                } else {
                    return(
                        <div>
                            <p className='small inline no-margin deselected'>$</p>
                            <p className='discounted m-large inline no-margin deselected'>{displayed_full_quote}</p>
                            <p className='small inline no-margin'>&nbsp;&nbsp;$</p>
                            <p className='m-large inline no-margin'>{displayed_discounted_quote}</p>
                        </div>
                    )
                }
            }
        }
        const getRadioPrompt = (url) => {
            return (this.state.radio.icon === url);
        }
        const renderPackageItem = (item, package_title, isgrayed = false) => {
           //console.log("rendering package item: " + item.title)
            const {is_mobile_display} = this.props;
            const iscustom = (package_title === 'customize');
            let custom_item = null;
            let custom_price = null;
            let custom_checkbox = null;

            if (iscustom && item.flat === 'Included'){
                custom_price = <div className='x-small no-margin cost'>
                                <p>&nbsp;&nbsp;Included</p>
                                </div>
            } else if (iscustom && item.flat === 'Call Us'){
                custom_checkbox = <input type="checkbox" 
                                    className='addon-checkbox' 
                                    name={item.title}
                                    value={item.title}
                                    onClick={this.toggleCheckbox()}
                                    checked={this.isSelected(item.title)}
                                />
                custom_price = <div className='x-small no-margin cost'>
                                <p className='nowrap'>&nbsp;&nbsp;(Call Us)</p>
                                </div>
            } else if (iscustom && item.flat !== 'Included'){
                custom_checkbox = <input type="checkbox" 
                                    className='addon-checkbox' 
                                    name={item.title}
                                    value={item.title}
                                    onClick={this.toggleCheckbox()}
                                    checked={this.isSelected(item.title)}
                                />
                custom_price= <div className={(this.isSelected(item.title)) ? 'xx-small no-margin cost' : 'xx-small no-margin cost deselected'}>
                                    <p> +${new Number(parseFloat(item.price_low)).toFixed(2)} - {new Number(parseFloat(item.price_high).toFixed(2))}</p>
                                </div>
            }

            return (
                <div className='package-grid-row'>
                    <div className='item-long'>
                    {custom_checkbox}
                        <label className= {!isgrayed ? 'x-small no-margin' : 'x-small no-margin deselected'}
                            for={item.title}>{(!iscustom || item.flat === 'Included') ? (<FontAwesomeIcon icon={faCheck} className={isgrayed ? 'invis' : ''}/>) : ''}
                            {is_mobile_display && <p className={!isgrayed ? 'clickable inline bold' : 'inline deselected bold'} >&#9432;&nbsp;</p>}
                            <b className='item-title' id={package_title + item.title} onClick={() => this.setInfoModal(package_title,item)}>{item.title}</b> 
                        </label>
                    </div>
                    {custom_price}
                </div>
            )
        }
        const renderPackageItems = (package_title) => {
           //console.log("rendering package ITEMS for: " + package_title)
            const package_obj = findObjectByProperty(this.state.cats, 'title', package_title);

            const incorrect_formatted_items = package_obj.items.filter(item => !item.category || ( item.category !== 'prep' && item.category !== 'application' && item.category !== 'finish' && item.category !== 'extras'));
            if (incorrect_formatted_items.length > 0){
               //console.log("No package category found for: " + package_title + " items:")
               //console.log(incorrect_formatted_items)
            }

            const included_nocat_items = package_obj.items.filter(item => item.flat === "Included" && !item.category);
            const additional_nocat_items = package_obj.items.filter(item => item.flat !== "Included" && !item.category);
            const grayed_nocat_items = package_obj.grayed_items.filter(item => !item.category);
            const has_nocat_items = (included_nocat_items.length > 0 || additional_nocat_items.length > 0 || grayed_nocat_items.length > 0);
            
            return (
                <div>
                    {has_nocat_items &&
                        <div>
                            {included_nocat_items.map((item)  => (
                                renderPackageItem(item, package_title)
                            ))}
                            {additional_nocat_items.map((item)  => (
                                renderPackageItem(item, package_title)
                            ))}
                            {grayed_nocat_items.map((item)  => (
                                renderPackageItem(item, package_title, true)
                            ))}
                        </div>
                    }
                </div>
            )
        }

        const renderSinglePackage = (package_obj) => {
           //console.log("rendering single package")
           //console.log(package_obj.title);
            
            let flagtext;
            //const flagtext = (isdeluxe || (isrecommended && this.state.cats.length === 1)) ? 'Best Service' : 'Most Popular';

           //console.log(this.state.cats)
           //console.log(this.state.current_package)
           //console.log(this.state.cats[this.state.current_package])
            const isselected = package_obj.title === this.state.cats[this.state.current_package].title;
            const {offer_discount} = this.props;

            if (!package_obj.title) {return;}
            /*if (!api_is_complete){
                return (
                    <div className='package-main'>
                    {spinner}
                    </div>
                )
            }*/
            try {
                return(
                    <div 
                        className={isselected ? 'package-main active' : 'package-main deactive'}
                        id = {package_obj.title}
                        key = {this.state.cats}
                        onClick = {jumpToPackage()}
                        style={{width: itemWidth, marginRight: (itemHorizontalMargin/2), marginLeft: (itemHorizontalMargin/2)}}
                        >
                        {(flagtext) ? <p className='x-small flag no-margin'><b>{flagtext}</b></p> : ''}
                        <h1 className='m-large no-margin'>{package_obj.title.charAt(0).toUpperCase() + package_obj.title.slice(1)} Service</h1>

                        <div className='package-description'>
                            <label
                                // for={item.title}
                                >
                                {is_mobile_display && <p className={'clickable inline bold'} >&#9432;&nbsp;</p>}
                                <b className='item-title small italic' id={package_obj.title + package_obj.short} onClick={() => this.setInfoModal(package_obj.title,package_obj)}>{package_obj.short}</b> 
                            </label>
                        </div>

                        {(offer_discount && package_obj.lead !== 'save') ?  getDiscountAdjustedQuote(true, package_obj) : getDiscountAdjustedQuote(false, package_obj)}
                        {/* {getDiscountAdjustedQuote(false, package_obj)} */}

                        <div className='package-addon'>
                            {/*<p className='head small no-margin'>Includes</p>*/}
                            {renderPackageItems(package_obj.title)}
                        </div>

                        {/* NO save premium */}
                        {<div className='package-buttons'> 
                            <button type='button' 
                                /* className={isselected ? 'submitleadbtn active' : 'submitleadbtn deselected'} */
                                className='savequotebtn'
                                    id={(package_obj.lead !== 'save' ? savetitle : scheduletitle)}
                                    //disabled = {!this.state.lead_created}
                                    onClick={this.showModal()}>
                                    Save Quote For Later
                            </button>
                            <button type='button' 
                                /* className={isselected ? 'submitleadbtn active' : 'submitleadbtn deselected'} */
                                className='submitleadbtn'
                                    id={(package_obj.lead === 'save' ? savetitle : scheduletitle)}
                                    //disabled = {!this.state.lead_created}
                                    onClick={this.showModal()}>
                                    I'm Interested
                                    <FontAwesomeIcon icon={faArrowRight}/>
                            </button>
                            
                        </div>}
                        {/* END of no save premium */}
                        
                    </div>
                )
            } catch (err) {
                console.error("Single package rendering error: ", err)
            }

        }
        const renderPackages = () => {
           //console.log("render multiple packages");
           const {cats, scrollOffset} = this.state;
            return (
                <div className='row' key={this.state.cats} style={{justifyContent:'center', maxWidth : button_row_max_width, minWidth: button_row_min_width,alignSelf: 'center', flexWrap: 'nowrap'}}>
                    {(!is_mobile_display && visiblePackagesCount !== 1) ? 
                        <div className='package-navicon-group'>
                            <FontAwesomeIcon 
                                className={(render_left_nav) ? 'navicon' : 'navicon invis'}
                                icon={faChevronLeft}
                                //size='3x'
                                onClick={this.scrollLeft}
                            />
                            <FontAwesomeIcon 
                                className={(render_left_nav) ? 'navicon' : 'navicon invis'}
                                icon={faChevronLeft}
                                //size='3x'
                                onClick={this.scrollLeft}
                            />
                        </div> : ''
                    }
                    <div className={(render_left_nav || render_right_nav) ? "choices-container scrolling" : "choices-container"} style={{width: button_row_min_width}}>
                        <div className={(render_left_nav || render_right_nav) ? "choices-wrapper" : "choices-wrapper no-margin"} style={{ transform: `translateX(${scrollOffset}px)`, transition: 'transform .5s ease-in-out' }}>
                            {cats.map (cat  => (
                                
                                    renderSinglePackage(cat)
                               
                            ))}
                        </div>
                    </div>
                    {(!is_mobile_display && visiblePackagesCount !== 1) ? 
                        <div className='package-navicon-group'>
                            <FontAwesomeIcon 
                                className={(render_right_nav) ? 'navicon' : 'navicon invis'}
                                icon={faChevronRight}
                                //size='3x'
                                onClick={this.scrollRight}
                            />
                            <FontAwesomeIcon 
                                className={(render_right_nav) ? 'navicon' : 'navicon invis'}
                                icon={faChevronRight}
                                //size='3x'
                                onClick={this.scrollRight}
                            />
                        </div> : ''
                    }
                </div>
            )
        }
        const jumpToPackage = () => e => {
            let i;
            let index = 1;
            //console.log('jumptoPackage');
            for (i = 0; i < this.state.cats.length; i++){
                //console.log(this.state.cats[i].title + ' ' + e.currentTarget.id);
                if (this.state.cats[i].title === e.currentTarget.id){
                    index = i;
                }
            }
            this.setPackage(index);
        }
        const renderPackageNav = () => {
            return (
                <div className='package-nav'>
                    {is_mobile_display || visiblePackagesCount === 1 ?
                    <div className='package-navicon-group'>
                        <FontAwesomeIcon 
                            className={(render_left_nav) ? 'navicon' : 'navicon invis'}
                            icon={faChevronLeft}
                            //size='3x'
                            onClick={this.scrollLeft}
                        />
                        <FontAwesomeIcon 
                            className={(render_left_nav) ? 'navicon' : 'navicon invis'}
                            icon={faChevronLeft}
                            //size='3x'
                            onClick={this.scrollLeft}
                        />
                    </div> : '' 
                    }
                    <div className='nav-list'>
                        
                        {this.state.cats.map(cat => (
                            <div className={cat.title === this.state.cats[this.state.current_package].title ? 'package-nav-btn active' : 'package-nav-btn'} id={cat.title} onClick={jumpToPackage()}>
                                <p className='x-small no-margin'>
                                    {cat.title.charAt(0).toUpperCase() + cat.title.slice(1)}
                                </p>
                            </div>
                        ))}
                    </div> 
                    {is_mobile_display || visiblePackagesCount === 1 ?
                    <div className='package-navicon-group'>
                        <FontAwesomeIcon 
                            className={(render_right_nav) ? 'navicon' : 'navicon invis'}
                            icon={faChevronRight}
                            //size='3x'
                            onClick={this.scrollRight}
                        />
                        <FontAwesomeIcon 
                            className={(render_right_nav) ? 'navicon' : 'navicon invis'}
                            icon={faChevronRight}
                            //size='3x'
                            onClick={this.scrollRight}
                        />
                    </div> : '' 
                    }
                </div>
            )
        }

        return (
            <div className='parent-packagequotewrapper'>
                {this.showInfoModal()}
                {(!this.state.mounted || !this.state.cats) ? spinner : 
                        <div className='packagequotewrapper' key={this.state.cats}>
                            {schedulemodal}
                            {savemodal}
                            {/* <h1 className='large logo-text' > Your&nbsp;{logo}:  </h1> */}
                            <h1 className='large logo-text light'>Your quote:</h1>
                            <h4 className='x-small light italic'>Please note: this quote is an estimate and does not include tax. To have our team verify your measurements and send you an exact quote, save or schedule your job now!</h4>
                            {( (is_mobile_display && !singlepackage) || render_left_nav || render_right_nav) ? renderPackageNav() : '' }
                            <div className='package-list'>
                                { (singlepackage) ? renderSinglePackage(this.state.cats[this.state.current_package]) : renderPackages() }
                            </div> 
                            <FooterContact values_business={values_business} phone={values_customer.branch.phone} size='large'/>
                        </div>
                }
            </div>
        )
    }
}

export default QuoteServices;