import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';

class DropdownSections extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            mounted: false,
            showDropdowns: [],
        };
    }

    componentDidMount() {
      const { delay_show } = this.props;
    
      if (delay_show) {
        // Delay setting mounted to true if delay_show prop is provided
        setTimeout(() => {
          this.setState({mounted: true});
          this.expandDropdownsInitially();
        }, 500); // Use 1000 ms for 1 second delay
      } else {
        // If no delay_show prop, set mounted to true immediately
        this.setState({mounted: true});
        this.expandDropdownsInitially();
      }
    }
    
    expandDropdownsInitially = () => {
      // Expands any dropdowns that should be initially expanded
      setTimeout(() => {
        for (let i = 0; i < this.props.info.length; i++) {
          const { render_expanded, key } = this.props.info[i];
          if (render_expanded) {
            this.setState(prevState => ({
              showDropdowns: [...prevState.showDropdowns, key]
            }));
          }
        }
      }, 500);
    }

    componentWillUnmount() {
        this.setState({mounted: false});
    }

    handleDropdown = (id) => e => {
      console.log('handleDropdown', id);
        const { showDropdowns } = this.state;
        const isDropdownShown = showDropdowns.includes(id);
        this.setState({
            showDropdowns: isDropdownShown ? showDropdowns.filter(obj => obj !== id) : [...showDropdowns, id],
        });
    };

    getDropdownText = (text) => {
      // If text is an array, each array element is a paragraph
      if (Array.isArray(text)) {
        return text.map((paragraph, index) => <p key={index}>&bull; {paragraph}</p>);
      }
      return <div>{text}</div>;
    }


    render() {
        const { handleClose, info } = this.props;
        //if delay_show prop, wait 1/2 second before showing modal
        return (
          <div className='column'>
              {info.map(({ title, short, text, key, img_src = null }) => (
                <div key={key} className={`dropdown-item ${this.state.showDropdowns.includes(key) ? 'expanded' : ''}`}>
                    {title && <p className='medium'>{title}</p>}
                    <div className='dropdown-row' onClick={e => this.handleDropdown(key)(e)}>
                        <div className='dropdown-title no-margin'>
                            {short}
                        </div>
                        <FontAwesomeIcon
                            icon={faChevronRight}
                            size='lg'
                            className={`dropdown-chevron light ${this.state.showDropdowns.includes(key) ? 'rotate' : ''}`}
                        />
                    </div>
                    <div className="dropdown-content">
                        {this.state.showDropdowns.includes(key) ? (
                            <>
                                {this.getDropdownText(text)}
                                {img_src && <img src={img_src} alt={title} />}
                            </>
                        ) : null}
                    </div>
                </div>
            ))}
          </div>
        );
    }
}

export default DropdownSections;
