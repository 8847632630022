import React, {Component} from 'react'
import { CSSTransition } from 'react-transition-group';
import AnimateHeight from "react-animate-height";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPhoneAlt, faCheckCircle, faCheck, faSyncAlt, faChevronCircleLeft, faChevronCircleRight, faFlag, faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import fairquote_logo from './fairquote_logo_cropped.png';
import { EmailModal } from '../general_questions/modals/EmailModal';
import { ScheduleModal } from '../general_questions/modals/ScheduleModal';
import { InfoModal } from './Modals/InfoModal';
import { PreviewModal } from './Modals/PreviewModal';
import Matomo from '../Matomo';
import { FooterContact } from '../general_questions/FooterContact';
import CoatPreviewModal from '../general_questions/modals/CoatPreviewmodal';
import { findIndexByProperty, findObjectByProperty } from '../../Helpers';
import CoatIconModal from '../general_questions/modals/CoatIconmodal';


export class QuotePackages_v2 extends Component {
    static getDerivedStateFromProps(props, state) { //ensures re-render after api response is recieved
        if (props.colors !== state.colors) {
          return { colors: props.colors };
        }
        return null;
      }
    constructor (props) {
        super(props);
        this.state = {
            showmodal : '',
            infomodal : null,
            emailsent : false,
            custom_checked: null,
            selected_items: [],
            //selected_base: [],
            radio: props.colors[0],
            showbreakdown : false,
            sidewallprice: null,
            cats: null,
            current_package: null,
            save_premium: 100,
            win_height: null,
            nav_isnext: true,
            colors: props.colors,
            mounted: false,
            package_entered: false,
            showinputflag: false, //passed to schedule now modal
            startIndex: 0,
            visiblePackagesCount: null, // Number of items to display
            itemWidth: null,
            itemHorizontalMargin: null, // Width of each package, mobile is vw, desktop is px
            scrollOffset: 0, // New state variable for scroll offset
            current_pack_initialized: false, //flag to determine if current package has been initialized, ensures first time render updates
        }
    }

    componentDidMount = () =>{
        /*-Matomo Analytics-*/
        const title = 'QuotePackages';
        Matomo(title);
        
        //Uncomment lines below to enable heatmaps...although it may have been cutting session videos short
        //window._paq.push(['HeatmapSessionRecording::matchTrackerUrl']);
        //window._paq.push(['HeatmapSessionRecording::disableAutoDetectNewPageView']);
        
        /*----End Matomo----*/
    
        document.body.style.overflow = 'auto';

        //if (this.props.values_customer.sidewallheight === null || this.props.values_customer.sidewallheight === 0){
       // this.initCustom(this.props.addon_items);
        if(!this.state.cats){
            this.initPackages();
        }
        if(this.state.cats){
            this.setPackage(this.state.current_package);
        }
        window.addEventListener('resize', this.handleWindowResize);
        this.handleWindowResize();
        this.setState({ mounted: true });
        setTimeout(this.submitViewedQuote, 2000); //save lead info after 2 seconds on page
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    componentDidUpdate = () => {
        const {cats, current_package, current_pack_initialized} = this.state;
        if(!cats || (cats[0].tally < 0 && cats[0].tally !== 'call')){
            this.initPackages();
        }
        if(!current_pack_initialized && cats && current_package){
            this.setPackage(current_package);
        }
    }

    handleWindowResize = () => { //determines if package should be rendered as mobile or desktop
        console.log("handling window resize")
        const {cats, itemWidth, itemHorizontalMargin} = this.state;
        const {is_mobile_display} = this.props;
        console.log("is mobile display2: " + is_mobile_display)
        const item_width = (is_mobile_display) ? window.innerWidth * (90 / 100) : 350; // Width of each package, mobile is converted from vw to px, desktop is px
        const item_margin = (is_mobile_display) ? window.innerWidth * (2 / 100) : 30; // Width of each package, mobile is vw, desktop is px
        const margin_left_px = 100; //navicons
        const package_min_width_px =  item_width + item_margin; //make sure itemWidth and itemHorizontalMargin are set before calculating
        console.log("item width: " + item_width)
        console.log("item margin: " + item_margin)
        const max_adjacent_packages = Math.floor((window.innerWidth - margin_left_px) / package_min_width_px);

        if (is_mobile_display){
            this.setState({ visiblePackagesCount: 1, itemHorizontalMargin: item_margin, itemWidth: item_width }); //don't try to fit more than one package at a time on mobile
        } else if (cats && max_adjacent_packages >= cats.length){ //if all packages can fit on screen, reset to display all
            this.setState({ visiblePackagesCount: max_adjacent_packages, itemHorizontalMargin: item_margin, itemWidth: item_width, startIndex: 0, scrollOffset: 0 });
        } else {
            this.setState({ visiblePackagesCount: max_adjacent_packages, itemHorizontalMargin: item_margin, itemWidth: item_width }); //TODO: make sure current package is visible after resize
        }
        
    }

    //determines if package item should be counted and rendered in current package
    //checking for grayed only is used to determine if item should be rendered as grayed out in the package
    packageItemShouldBeIncluded = (package_title, item) => {
        console.log("packageItemShouldBeIncluded()")
        console.log(package_title)
        console.log(item)
        const {areatype} = this.props.values_customer;
        let is_included = false;
        let is_grayed = false;
        const conditionals = item.Areas['all'] || 
                                item.Areas[areatype.title.toLowerCase()] || 
                                item.Areas[areatype.title]; //check both upper and lower case for areatypes with this addon

        if ( !conditionals) { //is not included in the current area
            return [false, false];
        }

        const should_include = this.shouldInclude(package_title, conditionals.Include);
        const should_exclude = this.shouldExclude(package_title, conditionals.Exclude);
        console.log("should include: " + should_include)
        console.log("should exclude: " + should_exclude)

        if ( should_include && !should_exclude ){  // matches current package and not explicitly excluded 
            if (item.flat === "Included") { //item is a "base" item, doesn't need to have a specific cost tied to it
                is_included = true;
            } else if (item.flat === 'Call Us'){ //item requires calling for a quote
                is_included = true;
            } else if (item.price && item.price > 0 ){ //item is not always included and needs to have a cost tied to it to render
                is_included = true;
            }
        } else if (!should_include && !should_exclude){ //for grayed items, item is not included but also not explicitly excluded
            if (item.flat === "Included") { //item is a "base" item, doesn't need to have a specific cost tied to it
                is_grayed = true;
            } else if (item.flat === 'Call Us'){ //item requires calling for a quote
                is_grayed = true;
            } else if (item.price && item.price > 0 ){ //item is not always included and needs to have a cost tied to it to render
                is_grayed = true;
            }
        }
        return [is_included, is_grayed];
    }


    // initialize package items and prices
    initPackages = () => {  
        if(this.state.cats || !this.props.values_business.addon_items){
            return;
        }
        //console.log("initializing package tally");                             //loop through all addons and "check" for current package
        const {addon_items, base_items, singlepackage} = this.props.values_business;
        const {colors} = this.props.values_customer;
        const {cats, visiblePackagesCount} = this.state;
        const {is_mobile_display} = this.props;
        const color = {...colors[0]}
        const has_tiered_colors = (color.tier) ? true : false;
        
        let cat_addons= [];
        let base = [];
        let categories = []; 
        //package_items will include all addon items as well as base items
        //grayed_items are rendered as grayed out in the package, not included in any quote tallies etc.
        const package_items = [...base_items, ...addon_items];
        const custom_package = {title: 'customize', items: [], grayed_items: [], tally: null, lead: 'schedule'};
        const recommended_package = {title: 'recommended', items: [], grayed_items: [], tally: null, lead: 'schedule'};
        const deluxe_package = {title: 'deluxe', items: [], grayed_items: [], tally: null, lead: 'schedule'};
        const base_package = {title: 'base', items: [], grayed_items: [], tally: null, lead: 'schedule'};

        //Important: make sure to push package to array in order of rendering
        if (!singlepackage){
            if(has_tiered_colors){
                //even if a user selects a higher tier color, still show them all available packages
                if (color.lowest_tier === 1){ //tier 1 colors apply to all packages
                    categories.push(base_package);
                    categories.push(recommended_package);
                    categories.push(deluxe_package);
                } else if (color.lowest_tier === 2){ //tier 2 colors apply to recommended and deluxe packages
                    categories.push(recommended_package);
                    categories.push(deluxe_package);
                } else if (color.lowest_tier === 3){ //tier 3 colors apply to deluxe package only
                    //console.log("tier 3 color")
                    categories.push(deluxe_package);
                }
            } else {
                categories.push(base_package);
                categories.push(recommended_package);
                categories.push(deluxe_package);
            }
        }
        categories.push(custom_package); //custom package is always an option
        //console.log("Initial Categories array:")
        //console.log(categories)

        for (let i = 0; i < package_items.length; i++){ //for every base, loop through all package types and add to list
            const item = package_items[i];
            
            for (let j = 0; j < categories.length; j++){
                const package_obj = categories[j];
                const [is_included, is_grayed] = this.packageItemShouldBeIncluded(package_obj.title, item);
                if (is_included){ //in package and area
                    //console.log('current package: ' + package_obj.title + ", adding package_items " + package_items[i].title);
                    categories[j].items.push(package_items[i]);
                } 
                if (is_grayed){ //"checking_for_grayed_only" flag=true
                    //console.log('current package: ' + package_obj.title + ", excluding package_items " + package_items[i].title);
                    categories[j].grayed_items.push(package_items[i]);
                }
                //cat_addons = []; //reset for next iteration
            }
        }
        let initial_custom_checked = findObjectByProperty(categories, "title", "customize").items;
        //for each item, if .flat === 'Call Us', then remove from initial_custom_checked
        initial_custom_checked = initial_custom_checked.filter(item => item.flat !== 'Call Us');

        //console.log(categories)
        for (let i=0; i < categories.length; i++){ // calculate final tallies
            if(categories[i].title !== 'customize'){
                categories[i].tally = this.tallyQuote(categories[i].items)
            } else {
                categories[i].tally = this.tallyQuote(initial_custom_checked)
            }
        }
        //console.log("checking for duplicate packages to hide..")
        categories = this.removeDuplicatePackages(categories);
        let init_current_package = 0;
        for (let i = 0; i < categories.length; i++){
            if(categories[i].title === 'recommended'){
                init_current_package = i;
            }
        }
        const init_start_index = (!is_mobile_display && visiblePackagesCount > 1) ? Math.min(init_current_package, 0) : init_current_package; //make sure lowest tier package is at least visible (not selected) if there are multiple packages
        const init_offset = init_start_index * (this.state.itemWidth + this.state.itemHorizontalMargin);
        //console.log(categories)
        this.setState({cats: categories, custom_checked: initial_custom_checked, current_package: init_current_package, selected_items: [...categories[init_current_package].items], startIndex: init_start_index, scrollOffset: -init_offset});
    }


    removeDuplicatePackages = (packages) => {
        for (let i = 0; i < packages.length; i++){  //for every package,
            for (let j = 0; j < packages.length; j++){ //check every other package...
                if(packages[i].title !== 'customize' && packages[i].title !== 'recommended' && packages[j].title !== 'customize' && i !== j){ //...except for "custom", "recomended" (default) or identical packages (i.e. comparing "base" with itself)
                   //console.log(packages[i].tally+ " tally vs " + packages[j].tally);
                   //console.log(packages[i].items.length+ " items vs " + packages[j].items.length)
                    if(packages[i].tally === packages[j].tally && packages[i].items.length === packages[j].items.length){ //first check items length and tally prices match...
                       //console.log("tallies match: " + packages[i].tally + " vs " + packages[j].tally)
                        let items_match = true;
                        for(let k = 0; k < packages[i].items.length; k++){  //...then loop through all .items
                            if(packages[i].items[k].title !== packages[j].items[k].title){ //...to check if ANY items don't match
                               //console.log("items don't match: " + packages[i].items[k].title + " vs " + packages[j].items[k].title)
                                items_match = false;
                            }
                        }

                        if(items_match){ //if items ALL match...
                            console.log("Removing *" + packages[i].title + "* package due to redundancy with *" + packages[j].title + "* package")
                            packages.splice(i,1); //clear the "highest" package (i.e. "deluxe" removed, "recommended" kept)
                            i = (i === 0) ? 0 : i-1 ; //account for new length
                            j = (j === 0) ? 0 : j-1; //account for new length
                        }
                    } 
                    //console.log("tallies DON'T match: " + packages[i].tally + " vs " + packages[j].tally)
                } else if (packages[i].title === 'customize' && i!==j){ //else, if package is custom and not comparing with itself
                    //filter items to count number of selectable items only (i.e. not "Call Us" or "Included")
                    const selectable_items = packages[i].items.filter(item => item.flat !== 'Call Us' && item.flat !== 'Included');
                    if(selectable_items && selectable_items.length < 2) {
                        console.log("Removing Custom package due to having 1 or less (selectable) add-on items") //because in this case, base & either recommended or delux will always be present
                        packages.splice(i,1); //clear the "highest" package (i.e. "deluxe" removed, "recommended" kept)
                        i = i-1; //account for new length
                        j = j-1; //account for new length
                    }
                } 
            }
        }
        return packages;
    }

    getPackageQuotes(package_obj){
        //console.log(index)
        
        const range = this.props.values_business.quote_range;
        const {businessid} = this.props.values_business;
        const { save_premium} = this.state;
        let full_quote;
        let discounted_quote;

        if(!package_obj){
            return -1;
        } 
        if (package_obj.tally === 'call'){
            return ['call', 'call', 'call'];
        }

        if (range > 0){
            let upper_bound;
            let lower_bound;
            if (businessid === 'expertconcretecoatings'){ //provide ranged quote to downside instead of middle
                upper_bound = package_obj.tally //(cats[index].tally * (1 + range));
                lower_bound = (package_obj.tally * (1 - range));
            } else {
                upper_bound = package_obj.tally * (1 + (range/2) );
                lower_bound = package_obj.tally * (1 - (range/2) );
            }

            full_quote = parseFloat((lower_bound + save_premium).toFixed(0)).toLocaleString() + ' - ' + parseFloat((upper_bound + save_premium).toFixed(0)).toLocaleString();
            discounted_quote = parseFloat((lower_bound).toFixed(0)).toLocaleString() + ' - ' + parseFloat((upper_bound).toFixed(0)).toLocaleString();
        } else {
            full_quote = parseFloat((package_obj.tally + save_premium).toFixed(0)).toLocaleString();
            discounted_quote = parseFloat((package_obj.tally).toFixed(0)).toLocaleString();
        }

        const actual_quote = (package_obj.lead === 'save') ? full_quote : discounted_quote;

        return [full_quote, discounted_quote, actual_quote];
    }
    toggleBreakdown = () => e => {
        if (this.state.showbreakdown === false){
            this.setState({ showbreakdown: true });
        } else {
            this.setState({ showbreakdown: false });
        }
    }
    showModal = () => e => {
        document.body.style.overflow = 'hidden';
        this.setState({ showmodal: e.target.id, emailsent: false });
    };
    
    hideModal = () => {
        document.body.style.overflow = 'auto';
        this.setState({ showmodal: '' });
    };
    emailSent = (wassent) => {
        this.setState({ emailsent : wassent });
    }
    submitSchedule = () => {
        const {email, phone, customername, address, preferredcontact} = this.props.values_customer;
        const {email_regex, phone_regex} = this.props;
        const {cats, current_package} = this.state;

        if(!email || !email_regex.test(email) || !phone || !phone_regex.test(phone)|| !customername || !address || !preferredcontact){
            this.setState({ showinputflag: true });
        } else {
            const package_obj = cats[current_package];
            const [, , actual_quote] = this.getPackageQuotes(package_obj);
            this.emailSent(true);
            this.props.handleScheduleJob(actual_quote, this.state.selected_items);
        }
    }
    submitSaveQuote = () => {
        const {cats, current_package} = this.state;
        console.log('submitting save')
        const package_obj = cats[current_package];
        const [, , actual_quote] = this.getPackageQuotes(package_obj);
        this.emailSent(true);
        this.props.handleSaveQuote(actual_quote, this.state.selected_items); 
    }
    submitViewedQuote = () => { //submits lead information for base price only
        const {cats, current_package} = this.state;
        console.log('submitting viewed quote')
        const package_obj = cats[current_package];
        const [, , actual_quote] = this.getPackageQuotes(package_obj);
        this.props.handleViewedQuote(this.state.radio, actual_quote, this.state.selected_items);
    }

    isSelected= (val) =>{
        const {custom_checked} = this.state;
        if(!custom_checked){
            return true
        }
        const found = custom_checked.some(el => el.title === val);
        if (found || val === this.state.radio.title) {
            return true;
        }
        return false;
    };
    toggleCheckbox = () => e =>{ //handles more than one selected checkbox. for custom option ONLY
        const val = e.target.value;
        const {custom_checked, cats} = this.state;
        const {addon_items, base_items} = this.props.values_business;
        const all_items = [...base_items, ...addon_items];
        const found = custom_checked.some(el => el.title === val);
        const custom_index = cats.length - 1;
        const new_custom_checked = [...custom_checked];

        if (!found && val !== undefined){ //not found, add to list
            const addon = all_items.find(el => el.title === val)
           //console.log(addon)
            //cats[custom_index].items = [...custom_checked,addon];
            new_custom_checked.push(addon);
            cats[custom_index].tally = this.tallyQuote(new_custom_checked);
           //console.log(cats[custom_index].items)
            this.setState({cats: cats, custom_checked: new_custom_checked, selected_items: new_custom_checked});
        } else {
            const index = custom_checked.findIndex(x => x.title ===val);
            //console.log('index is:' + index);
            if (index > -1) {
                new_custom_checked.splice(index, 1);
                //cats[custom_index].items = [...custom_checked];
                cats[custom_index].tally = this.tallyQuote(new_custom_checked); //calculate quote for custom package, but don't change orignal package items
                //console.log("custom checked: ");
                //console.log(custom_checked);
                //console.log(this.state.addons);
                this.setState({cats: cats, custom_checked: new_custom_checked, selected_items: new_custom_checked}); 
            }
        }
    }
    toggleRadio = (obj) => e =>{ //alternates between color options
        const {cats} = this.state;
        let dropdown_height = 0;
        if(obj.style.toLowerCase() === 'flake'){
            dropdown_height = 'auto';
        }
        //const index = cats.map(c => c.title).indexOf(pack); //get index of package
        for(let i = 0; i < cats.length; i++){
            cats[i].tally = this.tallyQuote(cats[i].items, obj); //update quote tally for all packages, since all reflect same color
        }
        this.setState({cats: cats, radio: obj, flake_dropdown_height: dropdown_height});
    }
    tallyQuote = (cat_addons, radio = this.state.radio) => {
        const {addon_items} = this.props.values_business;
       //console.log("Tally Quote")
       //console.log(cat_addons)
        if (this.props.values_quote.floorquote === null) {
            return -1; //quote not ready
        }
        if (addon_items.length === 0){
            return -1; //quote not ready
        }
        let checkmarked = cat_addons;
       //console.log('checkmarked')
       //console.log(checkmarked)

        let sum = 0;
        if(checkmarked ){
            for (let i = 0; i < checkmarked.length; i++){
               //console.log(checkmarked[i])
                if(checkmarked[i].flat === 'Call Us'){ //item requires calling for a quote, don't include in tally
                    return "call";
                }
                if(!isNaN(checkmarked[i].price) && checkmarked[i].price !== null){ //TODO pass in actual current package
                    sum += parseFloat(checkmarked[i].price);
                }
            }
        }
       //console.log('addons sum: ' + sum)
        sum += parseFloat(radio.price);
       //console.log('base, addons, and color sum: ' + sum)
        sum += this.props.values_quote.fees; //calculated on backend
       //console.log('base, addons, color, and fees sum: ' + sum)
        if (isNaN(sum)){
            console.error("Error: tallied result was Nan")
        }
       //console.log('final sum: ' + sum)
        return sum;
    }
    // setPackage = (direction) =>{ //determine direction of package change to enable correct sliding animation; update actively selected addon_items
    //     //console.log('change package');
    //     const {cats, current_package} = this.state;
    //     let iscustom;
    //     let max_i = (cats) ? cats.length - 1 : null;
    //     let min_i = 0;
    //     let i = current_package;
    //     let isright = true;
    //     //console.log(direction);

    //     if (typeof direction === 'number'){
    //         if(current_package > direction){
    //             isright = false;
    //         }
    //         i = direction;
    //     }
    //     else if (direction === 'left' && i > min_i){
    //         isright = false;
    //         i = i-1;
    //     } else if (direction === 'right' && i < max_i){
    //         i = i+1;
    //     }

    //     let new_selected_items = [...cats[i].items];

    //     if (cats[i].title === 'customize'){

    //         new_selected_items = [...this.state.custom_checked];   //set addon items in custom package if package checkboxes are initialized

    //     } 
    //    //console.log("new selected items");
    //    //console.log(new_selected_items);

    //     this.setState({current_package: i, nav_isnext: isright, selected_items: [...new_selected_items]});
    // }

    //set package to visibly centered based on index
    setPackage = (index) =>{
        const {cats, current_package, visiblePackagesCount, startIndex} = this.state;
        const {values_business} = this.props;
        const {singlepackage} = values_business;
        let new_current_package = index;
        let new_start_index = startIndex;
        let new_scroll_offset = startIndex * (this.state.itemWidth + this.state.itemHorizontalMargin);
        console.log("setPackage")
        console.log("Visible packages count: " + visiblePackagesCount)
        //console.log ("cats length: " + cats.length)
        if (singlepackage){
            new_current_package = 0;
        } else if (visiblePackagesCount >= cats.length) { //all packages are visible, no need to scroll
            new_current_package = index;
            new_start_index = 0;
            new_scroll_offset = 0;
        } else { //scrolling
            if (visiblePackagesCount % 2 === 0){ //even number of packages, left justify instead of center
                if(index < current_package) { //scrolling left
                    new_current_package = index;
                    new_start_index = Math.max(index, 0);
                    new_scroll_offset = new_start_index * (this.state.itemWidth + this.state.itemHorizontalMargin);
                } else if (index > current_package) { //scrolling right
                    new_current_package = index
                    new_start_index = Math.min(index, cats.length - visiblePackagesCount);
                    new_scroll_offset = new_start_index * (this.state.itemWidth + this.state.itemHorizontalMargin);
                }
            } else { //odd number of packages, jump to centered position
                new_current_package = index;        
                new_start_index = new_current_package - Math.floor(visiblePackagesCount/2);
                new_scroll_offset = new_start_index * (this.state.itemWidth + this.state.itemHorizontalMargin); 
            }
        } 
        this.setState({current_package: new_current_package, startIndex: new_start_index, scrollOffset: -new_scroll_offset, current_pack_initialized: true});
    }

    scrollLeft = () => {
        this.setState(prevState => {
            const newStartIndex = Math.max(prevState.startIndex - prevState.visiblePackagesCount, 0);
            return {
                startIndex: newStartIndex,
                scrollOffset: -newStartIndex * (prevState.itemWidth + prevState.itemHorizontalMargin),
                current_package: newStartIndex,
            };
        });
    }
    
    scrollRight = () => {
        this.setState(prevState => {
            const newStartIndex = Math.min(prevState.startIndex + prevState.visiblePackagesCount, prevState.cats.length - prevState.visiblePackagesCount);
            const newScrollOffset = newStartIndex * (prevState.itemWidth + prevState.itemHorizontalMargin);
            return {
                startIndex: prevState.scrollOffset !== newScrollOffset ? newStartIndex : prevState.startIndex,
                scrollOffset: -newScrollOffset,
                current_package: prevState.scrollOffset !== newScrollOffset ? newStartIndex : prevState.startIndex,
            };
        });
    }

    setLeadType = (package_name) => e => { //toggles between "save" and "schedule" lead types
        const new_cats = [...this.state.cats];
        const index = findIndexByProperty(new_cats, 'title', package_name);
        if (index === -1){
            console.log("ERROR: tried to set lead type for non-existent package: " + package_name)
        }
        new_cats[index].lead = e.currentTarget.id;
        this.setState({cats : [...new_cats]})
    }

    getFloorNumericalValue = (floorcondition) => { //converts floorcondition string into numerical values to compare
        let value;
        switch (floorcondition){
            case "none":
                value = 0;
                break;
            case "light":
                value = 1;
                break;
            case "medium":
                value = 2;
                break;
            case "heavy":
                value = 3;
                break;
            default:
                console.log("ERROR:  no floorcondition value provided to getFloorNumericalValue")
                break;
        }
        return value;
    }

    checkAcceptableFloorCondition = (includeifs_pack) => {
        //console.log(includeifs_pack)
        let values_customer = this.props.values_customer;
        let minfloordamage = null;
        let maxfloordamage = null;
        let actualfloordamage = this.getFloorNumericalValue(values_customer.floorcondition);

        for (let i = 0; i < includeifs_pack.length; i++){
            for (const [key, value] of Object.entries(includeifs_pack[i])){ 
                if (key === "minfloordamage"){
                    minfloordamage = this.getFloorNumericalValue(value);
                } else if (key === "maxfloordamage"){
                    maxfloordamage = this.getFloorNumericalValue(value);
                }
            }
        }
        //console.log("min floor damage: " + minfloordamage)
        //console.log("max floor damage: " + maxfloordamage)
        //console.log("actual floor damage: " + actualfloordamage)
        if (minfloordamage && maxfloordamage){  //must check if value fits between min AND max floor damage 
            if (maxfloordamage <= actualfloordamage     //floor value is *greater* the more damage, which is why Max <= actual >= Min 
            && minfloordamage >= actualfloordamage
            ){
                return true;
            } else {
                return false;
            }
        }
        else if (minfloordamage !== null){ //specify null, clean floor value is zero (false)
            if(minfloordamage >= actualfloordamage){
                return true;
            }
            return false;
        }
        else if (maxfloordamage !== null){ 
           //console.log(maxfloordamage <= actualfloordamage)
            if(maxfloordamage <= actualfloordamage){
                return true;
            }
            return false;
        }
        else { //does not contain a min/max floor damage parameter
           //console.log("no min/max floor damage parameter to check")
            return true;
        }
    }

    conditionIsMet = (key, value) => { //key is the string of the state property to check, value is the value to check against
        const {values_customer} = this.props;
        //check if key string ends in > or <, then check if value is greater or less than
        let is_greaterthan = false;
        let is_lessthan = false;
        let is_not = false;
        let is_notcontains = false;
        let custom_key_value;
        console.log("conditionIsMet: " + key + " : " + value)
        console.log(key.includes("color.style"))
        if(key.includes(">")){
            is_greaterthan = true;
        } else if (key.includes("<")){
            is_lessthan = true;
        } else if (key.includes("!=")){
            is_not = true;
        } else if (key.includes("!contains")){
            is_notcontains = true;
        }

        //special case keys
        if (key.includes("color.style") || key.includes("colors.style")){
            custom_key_value = values_customer.colors[0].style.toLowerCase();
            console.log("custom_key_value: ", custom_key_value)
        }

        if (is_greaterthan){
            if(custom_key_value && custom_key_value > value){
                return true;
            } else if(!custom_key_value && values_customer[key.substring(0, key.length-1)] > value){ //if identical state property value does matches the excludeif condition
               //console.log(key.substring(0,key.length-1) + ' isgreaterthan' + value)
                return true;
            }
        } else if (is_lessthan){
            if(custom_key_value && custom_key_value < value){
                return true;
            } else if(!custom_key_value && values_customer[key.substring(0, key.length-1)] < value){ //if identical state property value does matches the excludeif condition
               //console.log(key.substring(0,key.length-1) + ' islessthan' + value)
                return true;
            }
        }  else if (is_not){
            if(custom_key_value && custom_key_value !== value){
                return true;
            } else if(!custom_key_value && values_customer[key.substring(0, key.length-2)] !== value){ //if identical state property value does matches the condition
                return true;
            }
        } else if (is_notcontains){
            if (custom_key_value && !custom_key_value.includes(value)){
                return true;
            } else if(!custom_key_value && !values_customer[key.substring(0, key.length-9)].includes(value)){ //if identical state property value does matches the condition
                return true;
            }
        }else{ 
            if(custom_key_value && custom_key_value === value){
                return true;
            } else if(values_customer[key] === value){ //if identical state property value does matches the condition
                return true;
            }
        }
        return false;
    }

    shouldInclude = (package_title, includeifs) => { //If ANY conditions are met, addon is included in only listed (Included) packages.includeifs is an Object
        let values_customer = this.props.values_customer;
        let all = "all";
        if(package_title === "customize"){
            return true; //include everything in custom package by default unless excluded (checked seperately in shouldExclude)
        }
        else if (includeifs[all]){
            //console.log("ALL! ")
            if(includeifs[all] === "yes" || includeifs[all] === "always"){
                return true; //automatically include to all packages
            }
            for (let i=0; i < includeifs[all].length; i++){
                for (const [key, value] of Object.entries(includeifs[all][i])){
                   //console.log("Checking includeif " + key + ": " + value)
                    if (this.conditionIsMet(key, value)){
                        return true; //if **ANY** condition is met, addon can be included
                    }
                }
            }
        }
        else if (!includeifs[package_title]){ // no "all", and no matching current package
            return false;
        }
        else if(includeifs[package_title] === "always"){ //no conditions need to be checked, always included
            return true; 
        }
        else if (!this.checkAcceptableFloorCondition(includeifs[package_title])){
            console.log("item will not be Included due to its floor condition")
            return false;
        }
        else{
            for (let i=0; i < includeifs[package_title].length; i++){ //for every condition
                for (const [key, value] of Object.entries(includeifs[package_title][i])){ 
                   //console.log("Checking includeif " + key + ": " + value)
                    if (this.conditionIsMet(key, value)){
                        return true; //if **ANY** condition is met, addon can be included
                    }
                }
            }
        }
        //console.log(key + ": " + value)
        return false; //All conditions must fail for the addon to not be included
    }
    shouldExclude = (package_title, excludeifs) => { //If ANY conditions are met, addon is excluded (not displayed) from ALL packages. excludeifs is an Object.
        const {values_customer} = this.props;
        let all = "all";
        console.log("Checking excludeifs for: " + package_title)
        console.log(excludeifs)
        if (excludeifs[all]){
            if(excludeifs[all] === "yes"){
                return true; //automatically exclude from all packages (including "custom")
            }
            for (let i=0; i < excludeifs[all].length; i++){
                for (const [key, value] of Object.entries(excludeifs[all][i])){
                    console.log("Checking excludeif " + key + ": " + value)
                    if (this.conditionIsMet(key, value)){
                        return true; //if **ANY** condition is met, addon is excluded
                    }
                }
            }
        }
        if(!excludeifs[package_title]){ //do not exlcude from any package not explicitly listed
            return false;
        }
        if (excludeifs[package_title]){
            if(excludeifs[package_title] === "yes" || excludeifs[package_title] === "always"){
                return true; //no conditions need to be checked, always excluded
            } else if (this.checkAcceptableFloorCondition(excludeifs[package_title])){
               //console.log("item will be excluded due to its floor condition")
                return true;
            }
            for (let i=0; i < excludeifs[package_title].length; i++){
                for (const [key, value] of Object.entries(excludeifs[package_title][i])){
                    if (this.conditionIsMet(key, value)){
                        return true; //if **ANY** condition is met, addon is excluded
                    }
                }
            }
        }
       
        return false; //ANY excludeif conditions must pass for the addon to be excluded
    }

    showInfoModal = () => {
        const {infomodal} = this.state;
        if (!infomodal){
            return null;
        }
        const description = [{title: infomodal.item.title, short: <p className="medium light"><FontAwesomeIcon icon={faCheckCircle}/>&nbsp;{infomodal.item.short}</p>, text: <p className="small spaced light">{infomodal.item.long}</p>, img_src: infomodal.item.src}];
        const show = (this.state.showmodal === infomodal.package_title + infomodal.item.title);
        if (infomodal.item.title && infomodal.item.short){
            return (
                <InfoModal show={show} handleClose={() => this.hideModal()} info={description}>
                                </InfoModal>
            )
        } else {
            return null;
        }
    }

    setInfoModal = (package_title,item) => {
        console.log("setting info modal")
        console.log(package_title)
        console.log(item)
        this.setState({ showmodal: package_title + item.title, infomodal: {package_title: package_title, item: item} });
    }

    isFlakeStyle = (style) => {
        style = style.toLowerCase();
        const {values_customer} = this.props;
        const {colors, flakesize} = values_customer;
        const is_flake = flakesize && ( style === 'flake' || style.includes("flake (single-size)") );
        return is_flake;
    }

    handlePhoneClick = () => {
        window._paq.push(['trackGoal', 8]);
    }

    render() { 
        const { handleChange, handleChangeObject, values_customer, values_business, addon_items } = this.props;
        const {cats, current_package, scrollOffset, visiblePackagesCount, itemHorizontalMargin, itemWidth} = this.state;
        const {is_mobile_display} = this.props || visiblePackagesCount === 1;
        const {singlepackage} = values_business;
        const na = 'N/A';
        const included = values_business.base_items;
        const addons = addon_items;
        console.log("visiblePackagesCOunt: " + visiblePackagesCount)
        console.log("current package: " + current_package)
        console.log("scroll offset: " + scrollOffset)
        console.log("item width: " + itemWidth)
        console.log(cats)
        console.log(cats && cats.length > visiblePackagesCount)
        console.log(cats && -scrollOffset < (cats.length - visiblePackagesCount) * (itemWidth + itemHorizontalMargin))

        const render_right_nav = cats && cats.length > visiblePackagesCount && -scrollOffset < (cats.length - visiblePackagesCount) * (itemWidth + itemHorizontalMargin);
        const render_left_nav = cats && cats.length > visiblePackagesCount && -scrollOffset > 0;
        const button_row_max_width = (visiblePackagesCount + .75) * (itemWidth + itemHorizontalMargin); //add .75 to account for margin
        const button_row_min_width = (visiblePackagesCount) * (itemWidth + itemHorizontalMargin)


        const spinner = <FontAwesomeIcon 
                            icon={faSyncAlt}
                            class='fa-spin spinner'
                            />
        const savetitle = "Save Quote"
        const scheduletitle = "Schedule Job"
        const savecontent = "Please confirm your contact information below and we'll email you a copy of your customized quote."
        const schedulecontent = "Please provide your contact information and we will reach out as soon as we can to schedule your project."
        const savemodal = <EmailModal 
                            activemod={this.state.showmodal}
                            setCustomerEmail={handleChange('email')}
                            setCustomerName={handleChange('customername')}
                            setCustomerPhone={handleChange('phone')} 
                            handleClose = {this.hideModal}
                            handleSubmit={this.submitSaveQuote} 
                            title={savetitle} 
                            content={savecontent} 
                            email={values_customer.email}
                            email_regex={this.props.email_regex}
                            customername={values_customer.customername}
                            phone={values_customer.phone}
                            emailsent={this.state.emailsent}
                            afterquote_link={values_business.afterquote_url}/>
        const schedulemodal = <ScheduleModal 
                                activemod={this.state.showmodal}
                                setCustomerEmail={handleChange('email')}
                                setCustomerPhone={handleChange('phone')}  
                                setCustomerName={handleChange('customername')} 
                                //setCustomerAddress={handleChange('address')}
                                handleChangeObject={handleChangeObject}
                                setPreferred={handleChange('preferredcontact')} 
                                handleClose = {this.hideModal}
                                handleSubmit={this.submitSchedule} 
                                showinputflag={this.state.showinputflag}
                                title={'Schedule Job'} 
                                content={schedulecontent} 
                                email={values_customer.email}
                                email_regex={this.props.email_regex}
                                phone={values_customer.phone}
                                phone_regex={this.props.phone_regex}
                                customername={values_customer.customername}
                                preferredcontact={values_customer.preferredcontact}
                                address={values_customer.address}
                                emailsent={this.state.emailsent}
                                afterquote_link={values_business.afterquote_url}
                                values_business={values_business}
                                values_customer={values_customer}/>

        const getColorPreview = (title, color) => {
            //const description = [{title: title, content: <img className='x-large-icon' src = {url} alt={title} />}];
            const show = (this.state.showmodal === title);
            if(show){
                if (color.previews && color.previews.length > 0){
                return(
                    <CoatPreviewModal show={show} handleClose={this.hideModal} color={color} ismobile={is_mobile_display}/>
                )
                } else {
                    return (
                        <CoatIconModal show={show} handleClose={this.hideModal} color={color} ismobile={is_mobile_display}/>
                    )
                }
            }
        }
        const getDiscountAdjustedQuote = (discounted = false, package_obj) => { //gets the discount-adjusted quote for the current package
           //console.log("Getting calculated quote..")
            const {cats} = this.state;
            const phone = values_customer.branch.phone;
           //console.log("PHONE: " + phone)
            const range = values_business.quote_range;
            let index;

            if(!this.state.cats){
                return spinner;
            }

            const [displayed_full_quote, displayed_discounted_quote, ,] = this.getPackageQuotes(package_obj);
            if (displayed_full_quote === 'call'){
                return(
                    <div>
                        <p className='m-large inline no-margin' onClick={this.handlePhoneClick()}><a className='clickable' href={'tel:+1.' + phone}><FontAwesomeIcon icon={faPhoneAlt}/> Call Us!</a></p> {/*TODO: add immediate api email notification to company with all quote info, if user clicks Call now*/}
                    </div>
                )
            } else if (!discounted) {
                return(
                    <div>
                        <p className='small inline no-margin'>$</p>
                        <p className='m-large inline no-margin'>{displayed_full_quote}</p>
                    </div>
                )
            } else {
                if(range > 0){ //, more space required, format quotes stacked vertically
                    return(
                        <div>
                            <p className='small inline no-margin deselected'>$</p>
                            <p className='discounted m-large inline no-margin deselected'>{displayed_full_quote}</p>
                            <div>
                                <p className='small inline no-margin'>$</p>
                                <p className='m-large inline no-margin'>{displayed_discounted_quote}</p>
                            </div>
                        </div>
                    )
                } else {
                    return(
                        <div>
                            <p className='small inline no-margin deselected'>$</p>
                            <p className='discounted m-large inline no-margin deselected'>{displayed_full_quote}</p>
                            <p className='small inline no-margin'>&nbsp;&nbsp;$</p>
                            <p className='m-large inline no-margin'>{displayed_discounted_quote}</p>
                        </div>
                    )
                }
            }
        }
        const getRadioPrompt = (url) => {
            return (this.state.radio.icon === url);
        }
        const renderPackageItem = (item, package_title, isgrayed = false) => {
            //console.log("rendering package item: " + item.title)
            const {is_mobile_display} = this.props;
            const iscustom = (package_title === 'customize');
            let custom_item = null;
            let custom_price = null;
            let custom_checkbox = null;

            if (iscustom && item.flat === 'Included'){
                custom_price = <div className='x-small no-margin cost'>
                                <p>&nbsp;&nbsp;Included</p>
                                </div>
            } else if (iscustom && item.flat === 'Call Us'){
                custom_checkbox = <input type="checkbox" 
                                    className='addon-checkbox' 
                                    name={item.title}
                                    value={item.title}
                                    onClick={this.toggleCheckbox()}
                                    checked={this.isSelected(item.title)}
                                />
                custom_price = <div className='x-small no-margin cost'>
                                <p className='nowrap'>&nbsp;&nbsp;(Call Us)</p>
                                </div>
            } else if (iscustom && item.flat !== 'Included'){
                custom_checkbox = <input type="checkbox" 
                                    className='addon-checkbox' 
                                    name={item.title}
                                    value={item.title}
                                    onClick={this.toggleCheckbox()}
                                    checked={this.isSelected(item.title)}
                                />
                custom_price= <div className={(this.isSelected(item.title)) ? 'xx-small no-margin cost' : 'xx-small no-margin cost deselected'}>
                                    <p> +${new Number(parseFloat(item.price)).toFixed(2)} </p>
                                </div>
            }

            return (
                <div className='package-grid-row'>
                    <div className='item-long'>
                    {custom_checkbox}
                        <label className= {!isgrayed ? 'x-small no-margin' : 'x-small no-margin deselected'}
                            for={item.title}>{(!iscustom || item.flat === 'Included') ? (<FontAwesomeIcon icon={faCheck} className={isgrayed ? 'invis' : ''}/>) : ''}
                            {is_mobile_display && <p className={!isgrayed ? 'clickable inline bold' : 'inline deselected bold'} >&#9432;&nbsp;</p>}
                            <b className='item-title' id={package_title + item.title} onClick={() => this.setInfoModal(package_title,item)}>{item.title}</b> 
                        </label>
                    </div>
                    {custom_price}
                </div>
            )
        }
        const renderPackageItems = (package_title) => {
            console.log("rendering package ITEMS for: " + package_title)
            const package_obj = findObjectByProperty(this.state.cats, 'title', package_title);

            const incorrect_formatted_items = package_obj.items.filter(item => !item.category || ( item.category !== 'prep' && item.category !== 'application' && item.category !== 'finish' && item.category !== 'extras'));
            if (incorrect_formatted_items.length > 0){
                console.error("ERROR: package item(s) not formatted correctly")
                console.error(incorrect_formatted_items)
            }

            const included_prep_items = package_obj.items.filter(item => item.flat === "Included" && item.category === 'prep');
            const additional_prep_items = package_obj.items.filter(item => item.flat !== "Included" && item.category === 'prep');
            const grayed_prep_items = package_obj.grayed_items.filter(item => item.category === 'prep');
            const has_prep_items = (included_prep_items.length > 0 || additional_prep_items.length > 0 || grayed_prep_items.length > 0);
            
            const included_application_items = package_obj.items.filter(item => item.flat === "Included" && item.category === 'application');
            const additional_application_items = package_obj.items.filter(item => item.flat !== "Included" && item.category === 'application');
            const grayed_application_items = package_obj.grayed_items.filter(item => item.category === 'application');
            const has_application_items = (included_application_items.length > 0 || additional_application_items.length > 0 || grayed_application_items.length > 0);
            
            const included_finish_items = package_obj.items.filter(item => item.flat === "Included" && item.category === 'finish');
            const additional_finish_items = package_obj.items.filter(item => item.flat !== "Included" && item.category === 'finish');
            const grayed_finish_items = package_obj.grayed_items.filter(item => item.category === 'finish');
            const has_finish_items = (included_finish_items.length > 0 || additional_finish_items.length > 0 || grayed_finish_items.length > 0);
            
            const included_extras_items = package_obj.items.filter(item => item.flat === "Included" && item.category === 'extras');
            const additional_extras_items = package_obj.items.filter(item => item.flat !== "Included" && item.category === 'extras');
            const grayed_extras_items = package_obj.grayed_items.filter(item => item.category === 'extras');
            const has_extras_items = (included_extras_items.length > 0 || additional_extras_items.length > 0 || grayed_extras_items.length > 0);
            
            return (
                <div>
                    {has_prep_items &&
                        <div>
                            <p className='category-name inline'>Prep</p>
                            {included_prep_items.map((item)  => (
                                renderPackageItem(item, package_title)
                            ))}
                            {additional_prep_items.map((item)  => (
                                renderPackageItem(item, package_title)
                            ))}
                            {grayed_prep_items.map((item)  => (
                                renderPackageItem(item, package_title, true)
                            ))}
                        </div>
                    }
                    {has_application_items &&
                        <div>
                            <p className='category-name'>Application</p>
                            {included_application_items.map((item)  => (
                                renderPackageItem(item, package_title)
                            ))}
                            {additional_application_items.map((item)  => (
                                renderPackageItem(item, package_title)
                            ))}
                            {grayed_application_items.map((item)  => (
                                renderPackageItem(item, package_title, true)
                            ))}
                        </div>
                    }
                    {has_finish_items &&
                        <div>
                            <p className='category-name'>Finish</p>
                            {included_finish_items.map((item)  => (
                                renderPackageItem(item, package_title)
                            ))}
                            {additional_finish_items.map((item)  => (
                                renderPackageItem(item, package_title)
                            ))}
                            {grayed_finish_items.map((item)  => (
                                renderPackageItem(item, package_title, true)
                            ))}
                        </div>
                    }
                    {has_extras_items && 
                        <div>
                            <p className='category-name'>Extras</p>
                            {included_extras_items.map((item)  => (
                                renderPackageItem(item, package_title)
                            ))}
                            {additional_extras_items.map((item)  => (
                                renderPackageItem(item, package_title)
                            ))}
                            {grayed_extras_items.map((item)  => (
                                renderPackageItem(item, package_title, true)
                            ))}
                        </div>
                    }
                </div>
            )
        }
        
        const renderColorPrice = (price) => {
            const {colors} = values_customer;
            if (colors.length <= 0){
                return na;
            }
            let base = colors[0].price; //base = lowest price of any color choices
            for (let i = 0; i < colors.length; i++){
                if (colors[i].price < base){
                    base = colors[i].price;
                }
            }
            if (price - base === 0){
                return "";
            }
            let str = '+$' + new Number(parseFloat(price - base)).toFixed(2);
            return str;
        }

        const renderSinglePackage = (package_obj) => {
            console.log("rendering single package")
            console.log(package_obj.title);
            const isrecommended = ('recommended' === package_obj.title);
            const isdeluxe = ('deluxe' === package_obj.title);
            const isbase = ('base' === package_obj.title);
            const singlecolor = values_customer.colors.length === 1;
            const flagtext = (isdeluxe || (isrecommended && this.state.cats.length === 1)) ? 'Max Durability' : 'Most Popular';
           //console.log(this.state.cats)
           //console.log(this.state.current_package)
           //console.log(this.state.cats[this.state.current_package])
            const isselected = package_obj.title === this.state.cats[this.state.current_package].title;

            if (!package_obj.title) {return;}
            /*if (!api_is_complete){
                return (
                    <div className='package-main'>
                    {spinner}
                    </div>
                )
            }*/
            try {
                return(
                    <div 
                        className={isselected ? 'package-main active' : 'package-main deactive'}
                        id = {package_obj.title}
                        key = {this.state.cats}
                        onClick = {jumpToPackage()}
                        style={{width: itemWidth, marginRight: (itemHorizontalMargin/2), marginLeft: (itemHorizontalMargin/2)}}
                        >
                        {(isrecommended || isdeluxe) ? <p className='x-small flag no-margin'><b>{flagtext}</b></p> : ''}
                        <h1 className='medium no-margin'>{package_obj.title.charAt(0).toUpperCase() + package_obj.title.slice(1)} Package</h1>
                        {(package_obj.lead === 'save' ? getDiscountAdjustedQuote(false, package_obj) :  getDiscountAdjustedQuote(true, package_obj))}
                        {/* <div className='final-quote'>
                            <div className='quote-grid-row'>
                                <p className='medium item-long'>Final quote:</p>
                                <p className='medium cost'>${this.getQuote()}</p>
                            </div>
                        </div> */}
                        <div className='package-color'>
                            {values_customer.colors.map((color)  => (
                                <div className= {(singlecolor) ? 'package-single-color-row' : 'package-grid-row'}>
                                    {(singlecolor) ? <div/> : 
                                        <div className='color-radio'>
                                            <input type="radio" 
                                                name={package_obj.title + "color"}
                                                value={color.style + ' ' + color.title}
                                                id={color.style + ' ' + color.title}
                                                onClick={this.toggleRadio(color)}
                                                checked={getRadioPrompt(color.icon)}
                                                disabled={!values_customer.colors.length > 0}
                                                />
                                        </div>
                                    }
                                    <div className='colors'>  
                                        {(color.icon) ? 
                                            <div className='color-icon small-icon no-margin'
                                                id={color.icon} >
                                                
                                                <img
                                                    className='smallicon'
                                                    id={color.style + ' ' + color.title}
                                                    src = {color.icon}
                                                    alt={color.title} 
                                                    onClick={this.showModal()}
                                                />
                                            </div> : <div/>}
                                    </div>
                                    <div className='color-description'>
                                        <label className = {(getRadioPrompt(color.icon)) ? 'x-small no-margin' : 'x-small no-margin deselected'} 
                                            for={color.style + ' ' + color.title}> 
                                            {(this.isFlakeStyle(color.style)) ? <b>{color.style +  ' ' + color.title + ' ' + values_customer.flakesize + ' inch'}</b> : <b><b>{color.style +  ' ' + color.title}</b></b>}
                                        </label>
                                    </div>
                                    <div className={(getRadioPrompt(color.icon)) ? 'x-small no-margin cost' : 'x-small no-margin cost deselected'}>
                                        <p> {renderColorPrice(color.price)} </p>
                                    </div>
                                </div>
                                ))}
                        </div>
                
                        <div className='package-addon'>
                            {/*<p className='head small no-margin'>Includes</p>*/}
                            {renderPackageItems(package_obj.title)}
                        </div>

                        <div className='package-buttons'>
                            
                            <div className='lead-list'>
                                <div className={'save' === package_obj.lead ? 'lead-toggle active' : 'lead-toggle'} id='save' onClick={this.setLeadType(package_obj.title)}>
                                    <p className='small no-margin'>
                                        Save for later
                                    </p>
                                </div>
                                <div className={'schedule' === package_obj.lead ? 'lead-toggle active' : 'lead-toggle'} id='schedule' onClick={this.setLeadType(package_obj.title)}>
                                    <div className='column'>
                                    <p className='small no-margin'>
                                        Schedule Now
                                    </p>
                                    <p className='xx-small schedule-discount'>
                                        <FontAwesomeIcon icon={faFlag}/>&nbsp;Save<b> $100</b>
                                    </p>
                                    </div>
                                </div>
                            </div> 

                            <button type='button' 
                                /* className={isselected ? 'submitleadbtn active' : 'submitleadbtn deselected'} */
                                className='submitleadbtn'
                                    id={(package_obj.lead === 'save' ? savetitle : scheduletitle)}
                                    //disabled = {!isselected}
                                    onClick={this.showModal()}>
                                    Select
                            </button>
                            
                        </div>
                    </div>
                )
            } catch (err) {
                console.error("Single package rendering error: ", err)
            }

        }
        const renderPackages = () => {
           //console.log("render multiple packages");
           const {cats, scrollOffset, current_package} = this.state;
           //create new event whose id is the current package
            return (
                <div className='row' key={this.state.cats} style={{justifyContent:'center', maxWidth : button_row_max_width, minWidth: button_row_min_width,alignSelf: 'center', flexWrap: 'nowrap'}}>
                    {(!is_mobile_display && visiblePackagesCount !== 1) ? 
                        <div className='package-navicon-group'>
                            <FontAwesomeIcon 
                                className={(render_left_nav) ? 'navicon' : 'navicon invis'}
                                icon={faChevronLeft}
                                //size='3x'
                                onClick={this.scrollLeft}
                            />
                            <FontAwesomeIcon 
                                className={(render_left_nav) ? 'navicon' : 'navicon invis'}
                                icon={faChevronLeft}
                                //size='3x'
                                onClick={this.scrollLeft}
                            />
                        </div> : ''
                    }
                    <div className={(render_left_nav || render_right_nav) ? "choices-container scrolling" : "choices-container"} style={{width: button_row_min_width}}>
                        <div className={(render_left_nav || render_right_nav) ? "choices-wrapper" : "choices-wrapper no-margin"} style={{ transform: `translateX(${scrollOffset}px)`, transition: 'transform .5s ease-in-out' }}>
                            {cats.map (cat  => (
                                
                                    renderSinglePackage(cat)
                               
                            ))}
                        </div>
                    </div>
                    {(!is_mobile_display && visiblePackagesCount !== 1) ? 
                        <div className='package-navicon-group'>
                            <FontAwesomeIcon 
                                className={(render_right_nav) ? 'navicon' : 'navicon invis'}
                                icon={faChevronRight}
                                //size='3x'
                                onClick={this.scrollRight}
                            />
                            <FontAwesomeIcon 
                                className={(render_right_nav) ? 'navicon' : 'navicon invis'}
                                icon={faChevronRight}
                                //size='3x'
                                onClick={this.scrollRight}
                            />
                        </div> : ''
                    }
                </div>
            )
        }
        const jumpToPackage = () => e => {
            let i;
            let index = 1;
            //console.log('jumptoPackage');
            for (i = 0; i < this.state.cats.length; i++){
                //console.log(this.state.cats[i].title + ' ' + e.currentTarget.id);
                if (this.state.cats[i].title === e.currentTarget.id){
                    index = i;
                }
            }
            this.setPackage(index);
        }
        const renderPackageNav = () => {
            return (
                <div className='package-nav'>
                    {is_mobile_display || visiblePackagesCount === 1 ?
                    <div className='package-navicon-group'>
                        <FontAwesomeIcon 
                            className={(render_left_nav) ? 'navicon' : 'navicon invis'}
                            icon={faChevronLeft}
                            //size='3x'
                            onClick={this.scrollLeft}
                        />
                        <FontAwesomeIcon 
                            className={(render_left_nav) ? 'navicon' : 'navicon invis'}
                            icon={faChevronLeft}
                            //size='3x'
                            onClick={this.scrollLeft}
                        />
                    </div> : '' 
                    }
                    <div className='nav-list'>
                        
                        {this.state.cats.map(cat => (
                            <div className={cat.title === this.state.cats[this.state.current_package].title ? 'package-nav-btn active' : 'package-nav-btn'} id={cat.title} onClick={jumpToPackage()}>
                                <p className='xx-small no-margin'>
                                    {cat.title.charAt(0).toUpperCase() + cat.title.slice(1)}
                                </p>
                            </div>
                        ))}
                    </div> 
                    {is_mobile_display || visiblePackagesCount === 1 ?
                    <div className='package-navicon-group'>
                        <FontAwesomeIcon 
                            className={(render_right_nav) ? 'navicon' : 'navicon invis'}
                            icon={faChevronRight}
                            //size='3x'
                            onClick={this.scrollRight}
                        />
                        <FontAwesomeIcon 
                            className={(render_right_nav) ? 'navicon' : 'navicon invis'}
                            icon={faChevronRight}
                            //size='3x'
                            onClick={this.scrollRight}
                        />
                    </div> : '' 
                    }
                </div>
            )
        }

        return (
            <div className='parent-packagequotewrapper'>
                {getColorPreview(values_customer.colors[0].style + ' ' + values_customer.colors[0].title, values_customer.colors[0])}
                {this.showInfoModal()}
                {(!this.state.mounted || !this.state.cats) ? spinner : 
                        <div className='packagequotewrapper' key={this.state.cats}>
                            {schedulemodal}
                            {savemodal}
                            {/* <h1 className='large logo-text' > Your&nbsp;{logo}:  </h1> */}
                            <h1 className='large logo-text light'>Your {values_customer.areatype.title} quote:</h1>
                            {( (is_mobile_display && !singlepackage) || render_left_nav || render_right_nav) ? renderPackageNav() : '' }
                            <div className='package-list'>
                                { (singlepackage) ? renderSinglePackage(this.state.cats[this.state.current_package]) : renderPackages() }
                            </div> 
                            <FooterContact values_business={values_business} phone={values_customer.branch.phone} size='large'/>
                        </div>
                }
            </div>
        )
    }
}

export default QuotePackages_v2;