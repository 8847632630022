import React, { Component } from 'react';
import { GoogleMap, Marker, Polyline, Polygon, LoadScriptNext } from '@react-google-maps/api';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { CSSTransition } from 'react-transition-group';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTrashAlt, faUndo, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'
import Matomo from '../Matomo';
import ContinueFooter from '../questions/ContinueFooter';
import { buffer, polygon } from '@turf/turf';

//takes user's drawn polygon and adds an inset/outset boundaries to create a new polygon

class SquareFootageMapConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submit: false,
      mounted: false,
      latitude: null,
      longitude: null,
      zoom: 13,
      max_zoom: null,
      center : {
        lat: props.values_customer.jobLatLng.lat,
        lng: props.values_customer.jobLatLng.lng
      },
      errorMessage: null,
      nextpage: props.next,
      clickedLocations : props.values_customer.clicked_locations || [],
      currentVertexIndex: null, //polyline onclick vertex reference
      outer_poly_area: null,
      mid_poly_area: null,
      optimized_buffer: null,
      //actions: [], TODO: track actions to allow for undo/redo
    };
    this.mapRef = React.createRef();  // Using React.createRef() to manage map reference
  }

  componentDidMount = () => {
    /*-Matomo Analytics-*/
    const title = 'SquareFootageMap';
    Matomo(title);
    /*----End Matomo----*/
    console.log('componentDidMount')
    this.setState({ mounted: true });
    this.setPolyAreas();
  }

  setupNearmapTiles = () => {
    const map = this.mapRef.current.state.map; // Get the actual map instance from the ref

    if (!map || !map.overlayMapTypes) {
      console.error("Map instance or overlayMapTypes is not available.");
      return;
    }

    const nearmapTileUrl = (coord, zoom) => {
      return `https://api.nearmap.com/tiles/v3/Vert/${zoom}/${coord.x}/${coord.y}.jpg?apikey=${process.env.REACT_APP_NEARMAP_API_KEY}`;
      //'https://api.nearmap.com/tiles/v3/Vert/{zoom}/{x}/{y}.jpg?until={UNTIL_DATE}&apikey={NEARMAP_API_KEY}',
    };

    const nearmapTileLayer = new window.google.maps.ImageMapType({
      getTileUrl: nearmapTileUrl,
      tileSize: new window.google.maps.Size(256, 256)
    });

    map.overlayMapTypes.insertAt(0, nearmapTileLayer); // Overlay Nearmap tiles on Google Map
  }

  setPolyAreas = () => {
    //TODO: buffer should ultimitley be 10% more than customer measured sq footage
    //inefficient method: for loop to increase buffer size until sq footage is 10% more
    const { clickedLocations } = this.state;
    const targetAreaIncrease = 0.1; // 10% increase in area is target for outer buffer

    const objectiveFunction = (bufferSize) => {
      const outerPolyCoords = this.bufferPolygon(clickedLocations, bufferSize);
      const innerPolyCoords = this.bufferPolygon(clickedLocations, 0);
      const newOuterArea = this.getArea(outerPolyCoords);
      const newInnerArea = this.getArea(innerPolyCoords);
      const totalAreaIncrease = (newOuterArea - newInnerArea) / newInnerArea;
      console.log('bufferSize: ', bufferSize, 'totalAreaIncrease: ', totalAreaIncrease.toFixed(2))
      console.log('newOuterArea: ', newOuterArea, 'newInnerArea: ', newInnerArea)
      // Return the absolute difference between the total area increase and the target area increase
      return totalAreaIncrease - targetAreaIncrease;
    };
    const bufferSize = this.findOuterBuffer(objectiveFunction);
    const outer_poly_coords = this.bufferPolygon(clickedLocations, bufferSize);
    const mid_poly_coords = this.bufferPolygon(clickedLocations, 0); 
    this.setJobArea(outer_poly_coords, mid_poly_coords, bufferSize);
  }

  //given a polygon, use binary search to find the outer buffer that will increase the sq footage by a given percentage
  findOuterBuffer = (objectiveFunction) => {
    let minSize = 0;
    let maxSize = 10; // +5 meters is a reasonable upper bound
    let optimalBufferSize;
    const tolerance = 0.001; // .1% tolerance

    while (maxSize - minSize > tolerance) {
      console.log('minSize: ', minSize, 'maxSize: ', maxSize)
        const midSize = (minSize + maxSize) / 2;
        const midValue = objectiveFunction(midSize);
        if (midValue < 0) {
            minSize = midSize;
        } else {
            maxSize = midSize;
        }
        optimalBufferSize = (minSize + maxSize) / 2;
        console.log('minSize: ', minSize, 'maxSize: ', maxSize, maxSize - minSize)
    }

    // Return the optimal buffer size
    console.log('Optimal buffer size: ', optimalBufferSize.toFixed(2));
    return optimalBufferSize;
  }
    


  renderPolygons = () => {
    const { clickedLocations, optimized_buffer } = this.state;
    const outer_poly_coords = this.bufferPolygon(clickedLocations, optimized_buffer); 
    const mid_poly_coords = this.bufferPolygon(clickedLocations, optimized_buffer/2); 
    const inner_poly_coords = this.bufferPolygon(clickedLocations, - optimized_buffer/2); // Not currently represented in range, but assists with visualization
    // Ensure the inner polygon coordinates are in reverse order to form a hole
    const mid_poly_coords_reversed = mid_poly_coords.slice().reverse();

    const combined_poly_coords = [outer_poly_coords.concat([mid_poly_coords_reversed])];
  
    // const mid_poly_coords = this.offsetLatLngPolygon(clickedLocations, -1);
    const yellow = '#FFFF00';
    const blue = '#0000FF';
    const red = '#FF0000';
    const green = '#00FF00';
    const transparent = null;

    return (
      <>
        {/* {this.renderPolygon(outer_poly_coords, blue)}
        {this.renderPolygon(mid_poly_coords, red, 0)} */}
        {this.renderCombinedPolygon(outer_poly_coords, mid_poly_coords_reversed, blue, 0.15)}
        {this.renderCombinedPolygon(mid_poly_coords, null, blue,0.25)}
        {this.renderCombinedPolygon(clickedLocations, null, blue, 0.35)}
        {this.renderCombinedPolygon(inner_poly_coords, null, blue, 0.45)}
        {/* {this.renderPolyLine(clickedLocations, yellow)} */}
      </>
    )
  }

  bufferPolygon = (original_coords, buffer_meters) => {
      // Convert to an array of [lng, lat] pairs
      const coordinates = original_coords.map(loc => [loc.lng, loc.lat]);

      // Ensure the polygon is closed by repeating the first coordinate at the end
      if (coordinates.length > 0 && (coordinates[0] !== coordinates[coordinates.length - 1])) {
          coordinates.push(coordinates[0]);
      }
      const originalPolygon = polygon([coordinates]);
      const newPolygon = buffer(originalPolygon, buffer_meters, { units: 'meters' });
      //convert to google maps latlngconst 
      const bufferedCoordinates = newPolygon.geometry.coordinates[0]; // Get the first polygon ring (ignoring holes)
      const googleMapsCoordinates = bufferedCoordinates.map(coord => ({
        lat: coord[1],
        lng: coord[0]
      }));
      return googleMapsCoordinates;
  }

  renderPolygon = (locations_list, fillColor, fillOpacity=null) => {
    const options = {
      fillColor: fillColor,
      fillOpacity: (fillOpacity !== null)? fillOpacity : .35,
      strokeColor: "#FFFF00'",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      clickable: false,
      draggable: false,
      editable: true,
      geodesic: false,
      zIndex: 1
    }
    if (window.google && window.google.maps){
      return (
        <Polygon
          key={JSON.stringify(locations_list)} //update key with unique identifier
          //ref={(polyline) => (this.polyline = polyline)}
          paths={locations_list}
          options={options}
        />
      )
    }
  }

  renderCombinedPolygon = (outer_coords, inner_coords, fillColor, fillOpacity) => {
    const options = {
        fillColor: fillColor,
        fillOpacity: fillOpacity || 0.35,
        strokeColor: fillColor,
        strokeOpacity: fillOpacity || 0.35,
        strokeWeight: 0,
        clickable: false,
        draggable: false,
        editable: false,
        geodesic: false,
        zIndex: 1
    };

    if (window.google && window.google.maps) {
        return (
            <Polygon
                key={JSON.stringify(outer_coords) + JSON.stringify(inner_coords)}
                paths={(inner_coords) ? [outer_coords, inner_coords] : [outer_coords]}
                options={options}
            />
        );
    }
}

  renderPolyLine = (locations_list) => {
    const poly_options = {
      strokeColor: '#FFFF00',
      strokeOpacity: 0.5,
      strokeWeight: 3,
      fillColor: '#FFFF00',
      fillOpacity: 0.35,
      clickable: false,
      draggable: false,
      editable: false,
      visible: true,
      //radius: 30000,
      paths: this.state.clickedLocations,
      zIndex: 1
    };
    return(
      <Polyline
        key={JSON.stringify(locations_list)} //update key with unique identifier
        path={locations_list}
        options={poly_options}
        draggable={false}
        //ref={(polyline) => { this.polyline = polyline && polyline.getPath() }} //reference used to update polyline path
      />
    )
  }

  getArea = (path_coords) => {
    if (window.google.maps){
      let poly_area = window.google.maps.geometry.spherical.computeArea(path_coords);
      poly_area = parseFloat((poly_area * 10.7639).toFixed(2)); //convert to square feet
      return poly_area;
    } 
  }

  setJobArea = (outer_path, mid_path, buffer_size) => {
    console.log('setJobArea')
    const { handleSaveAreaRange } = this.props;
    let outer_poly_area;
    let mid_poly_area;
    if (window.google.maps){
      outer_poly_area = this.getArea(outer_path);
      mid_poly_area = this.getArea(mid_path);
      this.setState({ outer_poly_area: outer_poly_area, mid_poly_area: mid_poly_area, optimized_buffer : buffer_size }, handleSaveAreaRange(mid_poly_area, outer_poly_area));
    } 
  }

  setZoom = () => {
    if(window.google.maps){
      const new_zoom = window.google.maps.getZoom();
      console.log('current zoom: ', new_zoom)
      this.setState({ zoom: new_zoom });
    }
  }

  getMaxZoom = () => {
    const {center} = this.state;
    let max_zoom = new window.google.maps.MaxZoomService();
    //let coordinate = new window.google.maps.LatLng(this.state.center.lat, this.state.center.lng);
    if(max_zoom && center){
      max_zoom.getMaxZoomAtLatLng(center, (result) => {
        if (result.status !== "OK") {
          console.error("Error in MaxZoomService");
        } else {
          console.log("Max zoom: ", result.zoom);
          this.setState({ zoom: result.zoom, max_zoom: result.zoom });
        }
      });
    }
  }

  zoomIn = () => {
    let zoom = this.state.zoom;
    zoom = zoom + 1;
    this.setState({ zoom: zoom });
  }

  zoomOut = () => {
    let zoom = this.state.zoom;
    zoom = zoom - 1;
    this.setState({ zoom: zoom });
  }



  clearAll = () => {
    console.log('clear all')
    this.setState({ clickedLocations: [], poly_area: null }, this.setJobArea([]));
  }

  renderGoogleMap = (placement) => {
    const {is_mobile_display} = this.props;
    const { handleChangeNext, values_customer } = this.props;
    const { clickedLocations, latitude, longitude,center, zoom, max_zoom, errorMessage, nextpage, poly_area } = this.state;
    const containerStyle = {
      width: '100%',
      height: '100%',
      'zIndex': 1,
    };
    const mapOptions = {
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
      mapTypeId:'satellite'
    }
    const map_element = 
      // <LoadScriptNext googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
          <GoogleMap
            ref={this.mapRef}  // Setting ref to the GoogleMap instance
            key={values_customer.hd_mode}
            onLoad={() => {
              this.getMaxZoom();
              values_customer.hd_mode && this.setupNearmapTiles();
            }
            } //runs once?
            //onZoomChanged={this.setZoom}
            mapContainerStyle={containerStyle}
            center={center || {lat: 0, lng: 0}}
            zoom={zoom}
            tilt={0}         
            onClick={this.handleMapClick}
            mapTypeId='satellite'
            options={mapOptions}
          >                
            {/*{this.renderMapMarkers()}*/}
            {this.renderPolygons()}
          </GoogleMap>
      // </LoadScriptNext>

      return map_element;
  }

  offsetLatLngPolygon = (vertices, offsetDistance) => {
    const newVertices = [];
    const numVertices = vertices.length;
    const earthRadius = 6371000; // Earth's radius in meters

    for (let i = 0; i < numVertices; i++) {
        const p0 = vertices[i];
        const p1 = vertices[(i + 1) % numVertices];
        
        // Convert lat/long to radians
        const lat1 = p0.lat * Math.PI / 180;
        const lng1 = p0.lng * Math.PI / 180;
        const lat2 = p1.lat * Math.PI / 180;
        const lng2 = p1.lng * Math.PI / 180;

        // Calculate bearing from p0 to p1
        const y = Math.sin(lng2 - lng1) * Math.cos(lat2);
        const x = Math.cos(lat1) * Math.sin(lat2) -
                  Math.sin(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1);
        let bearing = Math.atan2(y, x);

        // Offset the bearing to get the perpendicular direction (90 degrees to the right)
        bearing += Math.PI / 2;

        // Calculate the new point offset by the desired distance
        const latRadians = Math.asin(Math.sin(lat1) * Math.cos(offsetDistance / earthRadius) +
                                     Math.cos(lat1) * Math.sin(offsetDistance / earthRadius) * Math.cos(bearing));
        const lngRadians = lng1 + Math.atan2(Math.sin(bearing) * Math.sin(offsetDistance / earthRadius) * Math.cos(lat1),
                                             Math.cos(offsetDistance / earthRadius) - Math.sin(lat1) * Math.sin(latRadians));

        // Convert the new point back to degrees
        const newLat = latRadians * 180 / Math.PI;
        const newLng = lngRadians * 180 / Math.PI;

        newVertices.push({ lat: newLat, lng: newLng });
    }

    return newVertices;
}

// Example usage
// const originalVertices = [
//     { lat: 40.712776, lng: -74.005974 },  // New York City
//     { lat: 42.360083, lng: -71.058880 },  // Boston
//     { lat: 39.952583, lng: -75.165222 }   // Philadelphia
// ];
// const offsetDistance = 1; // 1 meter

// const offsetVertices = offsetLatLngPolygon(originalVertices, offsetDistance);
// console.log(offsetVertices);


  render() {
    const { handleChangeNext, user_mode, original_quote, updated_quote, values_customer, is_mobile_display } = this.props;
    const { clickedLocations, latitude, longitude,center, zoom, max_zoom, errorMessage, nextpage, poly_area, mid_poly_area, outer_poly_area } = this.state;
    const containerStyle = {
      width: '100%',
      height: '100%',
      'zIndex': 1,
    };
    const main_header = (user_mode === 'customer') ? `This is only an approximate measurement` : 'Drag or add points to adjust the job area';
    const sub_header = `One of our team members will verify your exact square footage later.\nUntil then, we'll give the measurement accuracy some wiggle room & calculate your quote based on a range!`;
    const note = (user_mode === 'company') ? `To revert back to the user's original selection, refresh the page.` : null;
    const trashcan_disabled = clickedLocations.length === 0;
    const undoall_disabled = original_quote === updated_quote;
    const quote_difference = updated_quote - original_quote;
    const quote_diff_text = (quote_difference > 0) ? `(+$${quote_difference})` : (quote_difference < 0) ? `(-$${Math.abs(quote_difference)})` : '(No change)';
    const updated_quote_text = (updated_quote !== 'N/A') ? '$'+updated_quote : updated_quote;
    const zoomin_disabled = zoom === max_zoom;
    const zoomout_disabled = zoom === 8;
    const trashcan =  <FontAwesomeIcon
                        icon={faTrashAlt}
                        size='2x'
                        onClick={(trashcan_disabled) ? null : this.clearAll}
                        alt='Clear all points'
                        className={(trashcan_disabled)? 'disabled' : 'active'}
                      />
    const undoall =  <FontAwesomeIcon
                        icon={faUndo}
                        size='2x'
                        onClick={(undoall_disabled) ? null : this.refreshPage}
                        alt='Undo all changes'
                        className={(undoall_disabled)? 'disabled' : 'active'}
                      />
  
    //const title = "What is the street address for this job?"
    //console.log(center)
    return (
      <div className='surveywrapper'>
        <CSSTransition
                in={this.state.mounted}
                timeout={1000}
                classNames="fade"
                unmountOnExit 
                appear
        >
          <div className='mapwrapper'>
            <div className='map-directions-wrapper confirmation'>
              <div className='map-title'>
                  <h4 className='m-large'><FontAwesomeIcon icon={faExclamationTriangle}/> {main_header}</h4>
                  <h4 className={'x-small light left no-margin-left'}>{sub_header}</h4>
                  <p key={this.state.poly_area} className={'small left no-margin-left bold'}>Estimated sq ft: <p className='sq-ft-blue inline'>{(outer_poly_area && mid_poly_area)? `${mid_poly_area} - ${outer_poly_area}` : 'N/A\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0'}</p></p>
                  <h4 className='x-small light left no-margin-left'>{note}</h4>
              </div>
            </div>
            {this.renderGoogleMap('desktop')}
          </div>
        </CSSTransition>
        {this.props.nextPage !== false && 
          <ContinueFooter
              nextPage={this.props.nextPage}
              enable = {true}
              nextpage = {this.props.next}
              alternate_text = 'Acknowledge & Continue'
          />
        }
      </div>

    );
  }
}

export default SquareFootageMapConfirmation;


