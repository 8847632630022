import React, {Component} from 'react';
import { findIndexByProperty } from '../../Helpers';
import Matomo from '../Matomo';
import { CSSTransition } from 'react-transition-group';

export class CheckBoxesQuestion extends Component {
    
    //requires HandleChangeObject, showpage, pagetitle options (array), input_category (string), header_question (string) props, use_for_is_checked (array of objects)
    
    constructor(props) {
        super(props);
        this.state = {
            options : props.options,
            choices : props.values_inputs[props.input_category] || [],
            header_question : props.header_question, //question to ask in header
            input_category : props.input_category, //name of parent state variable input will modify 
            mounted: false
            //choices : [], //user chosen or default
        };
        this.setChoice = this.setChoice.bind(this);
    }

    componentDidMount = () => {
        const {showpage, pagetitle} = this.props;
        /*-Matomo Analytics-*/
        Matomo(pagetitle);
        /*----End Matomo----*/

        //Skip to next page
        if(!showpage){
            const event = new Event("service");
            //event.data=this.props.values_business.areatype_options[0];
            this.continue(this.props.nextpage)(event);
        }

        this.setState({ mounted: true });
    }

    continue = (next) => e =>{
        e.preventDefault();
        this.props.nextPage(next)
    };

    setChoice = (choice) => e => {
        if (this.props.customHandleChoice){
            this.props.customHandleChoice(choice);
        } else {
            const {choices, input_category} = this.state;
            const found_atindex = findIndexByProperty(choices,"title", choice.title);
        
            if ( found_atindex > -1 ){
                choices.splice(found_atindex, 1);
            } else {
                choices.push(choice);
            }
            console.log(input_category)
            console.log(choices)
            this.props.handleChangeObject(input_category, choices)
        }
    }

    isChecked = (option_title) => {
        const {input_category, values_inputs, use_for_is_checked} = this.props;
        console.log(values_inputs)
        console.log(input_category)
        console.log(values_inputs[input_category])
        if (use_for_is_checked){ //custom array of objects to check
            return findIndexByProperty(use_for_is_checked,"title", option_title) > -1
        } else if (values_inputs[input_category]){
            return findIndexByProperty(values_inputs[input_category],"title", option_title) > -1
        } else {
            return false;
        }
    }

    render() { 
            const {options, header_question, input_category, values_inputs} = this.state;
            
            return(
                <div className='surveywrapper'>
                    <CSSTransition
                        in={this.state.mounted}
                        timeout={1000}
                        classNames="fade"
                        unmountOnExit 
                        appear
                    >
                        <div className='multiplechoice'>
                            <h2 className='large'>{header_question}</h2>
                            <h3 className='medium'>Select all that apply.</h3>
                            {options.map((option) => (
                                <div className='boxgroupitem'>
                                    <input 
                                        className = "checkbox" 
                                        type="checkbox" 
                                        id={option.title || option} //if option is an object, use title property
                                        name={option.title || option} 
                                        value={option.title || option}  
                                        onChange={this.setChoice(option)} 
                                        checked={this.isChecked(option.title || option)}
                                        />
                                    <label for={option.title || option}>&nbsp;{option.title || option}</label>
                                </div>
                            ))}
                        </div>
                    </CSSTransition>
                </div>
            )

    }

}
    
export default CheckBoxesQuestion;