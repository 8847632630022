import { useState, useMemo, useEffect } from "react";
import { GoogleMap, useLoadScript, Marker, DistanceMatrixService } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSyncAlt, faArrowRight, faTimes} from '@fortawesome/free-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';
import Matomo from "../Matomo";

// Define libraries array outside the component to prevent re-creation on each render
const libraries = ["places"];

export default function AddressInputBar(props) {

  // const { isLoaded } = useLoadScript({
  //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  //   libraries,
  // });

  // if (!isLoaded) return <FontAwesomeIcon 
  //                         icon={faSyncAlt}
  //                         class='fa-spin spinner'
  //                         />

  return (
      <AddressValidation
        handleChangeObject = {props.handleChangeObject}
        customer_address = {props.customer_address}
        className = {props.className}
        ismodal = {props.ismodal}
      />
  )

}



function AddressValidation(props) {
  const [selected, setSelected] = useState(null); //lat lng
  const [address, setAddress] = useState(props.customer_address);
  const [errorMessage, setErrorMessage] = useState(null);
  const {nextpage, handleChangeNext, handleChangeObject, nextPage, customer_address, is_mobile_display, className, ismodal} = props;
  const addressRegex = /^\d.*?([^,]+,){3,}[^,]+$/
  ; //at least 3 commas (more than just city, state, country), and start with digit
  console.log(address)
  return (
      <PlacesAutocomplete addressRegex={addressRegex} className={className} address={customer_address} errorMessage={errorMessage} setSelected={setSelected} setAddress={setAddress} setErrorMessage={setErrorMessage} setParentAddress={handleChangeObject} ismodal={ismodal}/>
  );
}

const PlacesAutocomplete = ({ addressRegex, className, address, errorMessage, setSelected, setAddress, setErrorMessage, setParentAddress, ismodal }) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleSelect = async (address) => {
    console.log("handleSelect")
    console.log(address)
    setValue(address, false);
    clearSuggestions();
    console.log(address)
    if(addressRegex.test(address)){
      setErrorMessage(null);
      setAddress(address);
      setParentAddress('address', address);
      
    } else {
      setErrorMessage('Please select valid address (with street number)');
    }
  };

  const handleOnBlur = (e) => {
    const input = value;
    //clearSuggestions();
    console.log("handleOnBlur")

    if(addressRegex.test(input)){
      setErrorMessage(null);
      setAddress(input);
      setParentAddress('address', input);
    } else {
      setErrorMessage('Invalid address selection');
    }
  }

  const handleFocus = (e) => {
    console.log("handleFocus")
    if (value && errorMessage){
      // Re-trigger fetching suggestions if there's already a value, and there's an error message
      setValue(value + ' ', true);
    }
  };
  

  function clearAddress() {
    setAddress('');
    setSelected(null);
    setValue('');
    setErrorMessage(null);
    setParentAddress('address', '');
  }

  const getMiniPlaceholder = () => {
    if(errorMessage && errorMessage.includes('address')) {
      return <p className = {(value) ? 'miniplaceholder invalid' : 'miniplaceholder invis'}>{errorMessage}</p>
    } else {
      return <p className = {(address || value) ? 'miniplaceholder' : 'miniplaceholder invis'}>Job Address</p>
    }
  }

  const modal_class = (ismodal) ? 'ismodal' : '';

  console.log(value, address)
  console.log(data)
  console.log(status)

  return (
    <div className={className + ' ' + ismodal}>
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          value={value || address || ""}
          onChange={(e) => setValue(e.target.value)}
          onBlur={handleOnBlur}
          onFocus={handleFocus}
          disabled={!ready}
          className="combobox-input"
          placeholder={address || "Job Address"}
        />
        
        <ComboboxPopover className="combobox-popover">
          <ComboboxList>
            {status === "OK" &&
              data.map(({ place_id, description }) => (
                <ComboboxOption key={place_id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
        {getMiniPlaceholder()}
      </Combobox>
      <div className="clear">
        <FontAwesomeIcon 
            icon={faTimes} 
            className=".clear"
            onClick={()=> clearAddress()}
          />
      </div>
    </div>
  );
};