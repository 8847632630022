import React, {Component} from 'react';
import {AboutModal} from './Modals/AboutModal.js';
import { CSSTransition } from 'react-transition-group';
import { FooterLogo } from '../general_questions/FooterLogo.js';
import picA from './1_resized.jpg';
import picB from './2_resized.jpg';
import picC from './3_resized.jpg';
import picD from './4_resized.jpg';
import exampleSpalling from './spalling_concrete.webp';
import Matomo from '../Matomo.js';
import { FooterContact } from '../general_questions/FooterContact.js';


export class FloorCracking extends Component {
    constructor (props) {
        super(props);
        this.state = {
            showmodal : false,
            nextpage : props.next,
            mounted : false
        }
    }

    componentDidMount = () => {
        /*-Matomo Analytics-*/
        const title = 'FloorCracking';
        Matomo(title);
        /*----End Matomo----*/
        
        //Skip to next page if necessary
        if(!this.props.showpage){
            const event = new Event("emptyevent");
            this.continue(this.state.nextpage, true)(event);
        }

        this.setState({ mounted: true });
    }

    continue = (next, skip = false) => e =>{
        e.preventDefault();
        this.props.nextPage(next, skip);
    };

    showModal = () => {
        document.body.style.overflow = 'hidden';
        this.setState({ showmodal: true });
    };
    
    hideModal = () => {
        document.body.style.overflow = 'auto';
        this.setState({ showmodal: false });
    };

    getButtonActivity = val =>{
        var saved = this.props.values_customer.floorcracking;
        if (saved===val) {
            return 'imgbtn active';
        }
        return 'imgbtn';
    }

    getFloorNumericalValue = (cracking) => { //converts floorcondition string into numerical values to compare
        let value;
        switch (cracking){
            case "none":
                value = 0;
                break;
            case "light":
                value = 1;
                break;
            case "medium":
                value = 2;
                break;
            case "heavy":
                value = 3;
                break;
            default:
                console.error("ERROR:  no floorcondition value provided to getFloorNumericalValue. Recieved: ", cracking)
                break;
        }
        return value;
    }

    sendToInpersons = (selected_cracking) => {
        const inpersons = this.props.values_business.inpersons;
        const areatype = this.props.values_customer.areatype.title.toLowerCase();
        const servicetype = this.props.values_customer.servicetype.service.toLowerCase();
        const page = "FloorCracking";
        let is_inperson = true;
        let jobtype; //ultimitely set to either the servicetype, areatype, or default whatever is specifically identified in the Inpersons object
        
        //console.log("Inpersons: " + selected_floorcondition)
        //console.log(selected_floorcondition)
        
        if (inpersons[page]){                                   //if a inpersons exists for this page
            if (inpersons[page][servicetype]) {                    //and exists for the current selected servictype (or servicetype or default)
                jobtype = servicetype;
            }
            else if (inpersons[page][areatype]) {
                jobtype = areatype;
            }
            else if (inpersons[page]["default"]){
                jobtype = "default";
            }
            //console.log("jobtype: " + jobtype)
            //console.log(inpersons[page][jobtype])
            if (jobtype){
                for (const [condition, condition_value] of Object.entries(inpersons[page][jobtype])){  //check ONE condition at a time
                    switch (condition) {
                        case "inperson":
                            if (condition_value === "never"){  //immediately DO NOT send to in person quote page, regardless of other conditions
                                return false;
                            } else if (condition_value === "always"){ //immediately DO send to in person quote page, regardless of other conditions
                                return true;
                            }
                            break;
                        case "mincracking":
                            is_inperson = this.getFloorNumericalValue(selected_cracking) >= this.getFloorNumericalValue(condition_value);
                            console.log(is_inperson)
                            break;
                        case "maxcracking":
                            is_inperson = this.getFloorNumericalValue(selected_cracking) <= this.getFloorNumericalValue(condition_value);
                            break;
                    }
                    if (!is_inperson){                          //if ANY conditions are not met, do not send to in person quotepage
                        return false;
                    }
                }
                return true;
            }
        }
        else{
            return false;
        }
   
    }    

    render() { 
        const { handleChangeNext, handleChangeEstimateReason ,values_business, values_customer, is_mobile_display } = this.props;
        const aboutthis = [         
            {title: 'About concrete cracking', text: <p className="small spaced light">Cracking is an aesthetic deterioration of concrete that can lead to more significant structural problems if left unchecked. There are
            a few reasons why cracking can occur, but most commonly it can be traced back to how the concrete was cured, as well as temperature changes or old age.
            <br/>
            <br/>
            Before we lay a coating on your floor we will need to repair significant cracks, otherwise the floor will be 
            uneven and further deterioration could occur. This is not a very difficult task, but it does affect our timeline and is considered 
            in our quote.
            <br/>
            <br/>
            An example of cracking:
            <br/>
            <img src={exampleSpalling} alt="example of spalling"/>
            </p>},
        ]
        const none = 'none';
        const light = 'light';
        const medium = 'medium';
        const heavy =  'heavy';
        const reason = 'Customer has excessive cracking';

        return (
            <div className='surveywrapper'>
                <div className='multiplechoice-transitionbuffer'>
                    <CSSTransition
                            in={this.state.mounted}
                            timeout={1000}
                            classNames="fade"
                            unmountOnExit 
                            appear
                    >
                        <div className='multiplechoice'>
                            
                            <div className='textwrapper'>
                                <h2 className='large'> Which picture best matches the overall amount of cracking in your floor? </h2>
                                <h4 className='small'>Choose one.</h4>
                                <AboutModal show={this.state.showmodal} handleClose={this.hideModal} info={aboutthis}/>
                                <p className='clickable' onClick={this.showModal}> &#9432; What should I be looking for?</p>
                            </div>
                            <div className="row">
                                <div className="buttonwithtitle">
                                    <input 
                                        type="image" 
                                        src={picA} 
                                        class={this.getButtonActivity(none)}
                                        value={none}
                                        alt = 'Clean, new condition'
                                        onClick={(this.sendToInpersons(none)) ? () => this.props.handleChangeEstimateReason(reason, 'floorcracking', none) : handleChangeNext(this.state.nextpage,'floorcracking')}
                                        />
                                    <p className='small btntitle dark' id={none} onClick={(this.sendToInpersons(none)) ? () => this.props.handleChangeEstimateReason(reason, 'floorcracking', none) : handleChangeNext(this.state.nextpage,'floorcracking')}>Clean, new concrete</p>
                                </div>
                                <div className='buttonwithtitle'>
                                    <input 
                                        type="image" 
                                        src={picB} 
                                        class={this.getButtonActivity(light)}
                                        value={light}
                                        alt = 'Light cracking'
                                        onClick={(this.sendToInpersons(light)) ? () => this.props.handleChangeEstimateReason(reason, 'floorcracking', light) : handleChangeNext(this.state.nextpage,'floorcracking')}
                                        />
                                    <p className='small btntitle dark' id={light} onClick={(this.sendToInpersons(light)) ? () => this.props.handleChangeEstimateReason(reason, 'floorcracking', light) : handleChangeNext(this.state.nextpage,'floorcracking')}>Small or occasional cracking</p>
                                </div>
                                <div className='buttonwithtitle'>
                                    <input 
                                        type="image" 
                                        src={picC} 
                                        class={this.getButtonActivity(medium)}
                                        value={medium}
                                        alt = 'Moderate cracking'
                                        onClick={(this.sendToInpersons(medium)) ? () => this.props.handleChangeEstimateReason(reason, 'floorcracking', medium) : handleChangeNext(this.state.nextpage,'floorcracking')}
                                        />
                                    <p className='small btntitle dark' id={medium} onClick={(this.sendToInpersons(medium)) ? () => this.props.handleChangeEstimateReason(reason, 'floorcracking', medium) : handleChangeNext(this.state.nextpage,'floorcracking')}>Moderate or regular cracking</p>
                                </div>
                                <div className='buttonwithtitle'>
                                    <input 
                                        type="image" 
                                        src={picD} 
                                        class={this.getButtonActivity(heavy)}
                                        value={heavy}
                                        alt = 'Heavy cracking'
                                        onClick={(this.sendToInpersons(heavy)) ? () => this.props.handleChangeEstimateReason(reason, 'floorcracking', heavy) : handleChangeNext(this.state.nextpage,'floorcracking')}
                                        />
                                    <p className='small btntitle dark' id={heavy} onClick={(this.sendToInpersons(heavy)) ? () => this.props.handleChangeEstimateReason(reason, 'floorcracking', heavy) : handleChangeNext(this.state.nextpage,'floorcracking')}>Large or frequent cracking</p>
                                </div>
                            </div>
                            <div className='textwrapper'>
                                <h2 className='large invis'>.</h2>
                                <h4 className='small invis'>.</h4>
                                <p className='small btntitle dark'>.</p>
                            </div>
                            <div className="fillremainderflex"/>
                            
                        </div>
                    </CSSTransition>
                    <FooterContact values_business={values_business} phone={values_customer.branch.phone}/>
                </div>
            </div>
        )
    }
}

export default FloorCracking;