import React, { Component } from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faRedoAlt} from '@fortawesome/free-solid-svg-icons';


export class Header extends Component{

    previous = e =>{
        e.preventDefault();
        this.props.prevPage();
    }

    restart = e =>{
        e.preventDefault();
        this.props.restartSurvey();
    }

    getIndustryLink = () => {
        const {values_business} = this.props;
        switch (values_business.industry) {
            case 'concrete coatings':
                return 'https://thefairquote.com/pricing-concrete-coatings/';
            case 'piano-moving':
                return 'https://thefairquote.com/piano-moving/';
            case 'asphalt':
                return 'https://thefairquote.com';
            default:
                return 'https://thefairquote.com/software/';
        }
    }

    render () {
        const { values_general, values_business, nav_type, prevPage} = this.props;
        const businesslogo = (values_business.businesslogo_horizontal) ? values_business.businesslogo_horizontal : values_business.businesslogo;
        const isdemo = (values_business.businessid === 'demo')
        const is_mobile_display = this.props.is_mobile_display || values_general.is_mobile_display;


        const renderLogo = () => {
            if(this.props.values_business && businesslogo){
                const {homepage} = this.props.values_business
                const img_url = 'https://fq-concrete.s3.us-east-2.amazonaws.com/' + businesslogo;
                return <a href={homepage} target="_blank" ><img className='header-logo-img' src={img_url}/></a>
            } else{
                return '';
            }
        }

        const renderNav = () => {
            if (nav_type === 'restart') {
                return(
                    <button className="prev" onClick={this.restart}>
                        <FontAwesomeIcon icon={faRedoAlt}/>
                        &nbsp;Restart Quote
                    </button>
                )
            }  else if (nav_type === 'none') {
                return(
                    null
                )
            }
            
            else {
                if (prevPage !== false && values_general.pages.length > 1) {
                    return(
                        <button className="prev" onClick={this.previous} disabled={values_general.pages.length <= 1}>
                            <FontAwesomeIcon icon={faChevronLeft}/>
                            &nbsp;Back
                        </button>
                    )
                } else {
                    return null;
                }
            }
        };

        const trackMatamoGoal = () => {
            console.log('track goal: demo button clicked');
            window._paq.push(['trackGoal', 10]);
        }

        return (
                <div className= "header">
                    {renderNav()}
                    {renderLogo()}
                    {/*<h1 className="small title no-margin">{pagetitle}</h1>*/}
                    {isdemo && 
                        <a href={this.getIndustryLink()} target='_blank' >
                            <div className="demo-button" onClick={trackMatamoGoal}>
                                {!is_mobile_display && <p className='xx-small grey'>This is a demo version!</p>}
                                <p className='demo-gradient bold'>Get My Own FairQuoter</p>
                            </div>
                        </a>
                    }
                </div>
        )
    }

}

export default Header;