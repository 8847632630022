import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowRight} from '@fortawesome/free-solid-svg-icons'
import fairquote_logo from './questions/fairquote_logo.png'
import { CSSTransition } from 'react-transition-group'
import Matomo from './Matomo';
import CollectAddressInputBar from './general_questions/CollectAddressInputBar';
import Places from './general_questions/CollectAddressHook';
import { tallyServiceQuote, updateThemeColors } from '../Helpers';
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import SquareFootageMap from './asphalt_questions/SquareFootageMap';
import Loading from './general_questions/components/LoadingIcon';
import Header from './Header';
import SquareFootageMapHighRes from './asphalt_questions/SquareFootageMapHighRes';

export default function MapReDraw(props) {
    const [companyInfo, setCompanyInfo] = useState({
        businessname: '',
        businesslogo: '',
        businessid: '',
        homepage: '',
        marketing_tag: null,
        custom_theme: null,
        packages: [],
    });
    const [quoteInfo, setQuoteInfo] = useState({
        quoteid: '',
        jobLatLng: null,
        clicked_locations: [],
        sqfootage: null,
        totalquote_exact: null,
        totalquote_shown: null,
        jobtype: null,
        driveway_status: null,
        driveway_condition: null,
        address: null,
        sealed_before: null,
        selected_package: null,
    });
    const [originalQuote, setOriginalQuote] = useState(quoteInfo.totalquote_shown);
    const [has_updated_quote, setHasUpdatedQuote] = useState(false);
    const [hd_mode, setHDMode] = useState(true);

    const {subdomain, is_mobile_display} = props;
    const { industry, quoteid, center, clicked_locations } = useParams();
    const get_quoteinfo_api = `https://fok7u3r3f7.execute-api.us-east-2.amazonaws.com/dev/genQuoteFunction/savequote?requested=${quoteid}&industry=${industry}`; // CURRENT PROD!
    //const get_quoteinfo_api = `https://fok7u3r3f7.execute-api.us-east-2.amazonaws.com/prod/genQuoteFunction/savequote?requested=${quoteid}&industry=${industry}`; // CURRENT DEV!

    useEffect(() => {
        /* Matomo Analytics */
        const title = 'MapReDraw';
        Matomo(title);
        /* End Matomo */
        getQuoteInfo(quoteid);
    }, []);

    useEffect(() => {
        getUpdatedQuote(quoteInfo.sqfootage);
    }, [quoteInfo.sqfootage]);

    const getQuoteInfo = useCallback(async (id) => {
        console.log("Requesting quote info...", id);
        console.log(get_quoteinfo_api);
        const response = await axios.get(get_quoteinfo_api);
        const data = response.data
        console.log(data);

        const custom_theme = data.custom_theme;
        setCompanyInfo({
            businessname: data.name,
            businessid: data.businessid,
            businesslogo: data.businesslogo,
            homepage: data.homepage,
            custom_theme: custom_theme, // better future practice: pull custom_theme from getCompnayInfo using the url's subdomain, or from the quote info
            packages: data.Packages,
        });
        setQuoteInfo({
            quoteid: data.id,
            industry: data.industry,
            jobLatLng: data.jobLatLng,
            clicked_locations: data.clicked_locations_verified || data.clicked_locations, //verified is updated by company, !verified is original customer measurement
            sqfootage: data.sqfootage_user,
            totalquote_exact: data.totalquote_verified || data.totalquote_exact, //verified is updated by company, !verified is original customer 
            totalquote_shown: data.totalquote_shown,
            jobtype: data.jobtype,
            driveway_status: data.driveway_status,
            driveway_condition: data.driveway_condition,
            address: data.address,
            sealed_before: data.sealed_before,
            selected_package: data.selected_package,
            //customer info
            customername: data.customername,
            email: data.email,
            phone: data.phone,
            address: data.streetaddress,
        });
        if (!originalQuote || typeof originalQuote !== 'number') {
            setOriginalQuote(data.totalquote_shown);
        }
        if (custom_theme) {
            console.log("Updating color");
            updateThemeColors(custom_theme);
        }
    }, [industry]);

    const handleSaveMapClicks = (clicks, sqfootage) => {
        console.log("New square footage: ", sqfootage);
        const prev_sqfootage = quoteInfo.sqfootage;
        setQuoteInfo({
            ...quoteInfo,
            clicked_locations: clicks,
            sqfootage: sqfootage,
        });
        // if (sqfootage !== prev_sqfootage) {
        //     console.log("Quote has changed from ", prev_sqfootage, " to ", sqfootage, "...");
        //     getUpdatedQuote(sqfootage);
        // }
    }


    const getUpdatedQuote = useCallback(async (sqfootage) => {
        console.log("getUpdatedQuote called")
        console.log("quoteinfo:",quoteInfo);
        if (!quoteInfo.totalquote_shown) return; // don't send request if quote hasn't been loaded yet
        console.log("Sending updated quote info...", sqfootage);
        if (!sqfootage && !quoteInfo.sqfootage) { // clear button pressed
            setQuoteInfo({
                ...quoteInfo,
                totalquote: 'N/A',
            });
            return;
        }
        const response = await axios.post(
            //'https://h0a0i2dw7h.execute-api.us-east-2.amazonaws.com/dev/generalQuoteFunction/asphaltQuotes', // PROD!
            'https://h0a0i2dw7h.execute-api.us-east-2.amazonaws.com/prod/generalQuoteFunction/asphaltQuotes', // DEV!
            { 
                business: companyInfo.businessid,
                jobtype: quoteInfo.jobtype,
                sqfootage_company: sqfootage, //only parameter that changes
                driveway_status : quoteInfo.driveway_status,
                driveway_condition: quoteInfo.driveway_condition,
                address: quoteInfo.address,
                sealed_before: quoteInfo.sealed_before,
                packages: [quoteInfo.selected_package],
                jobLatLng: quoteInfo.jobLatLng,
            }
        );
        //console.log(response);
        const data = JSON.parse(response.data);
        console.log(data);
        const new_quote = tallyServiceQuote(data.packages[0].items, data.other_fees)[2]; //exact quote
        console.log("New quote: ", new_quote);
        if (new_quote !== quoteInfo.totalquote_exact) {
            console.log("Updating quote from ", quoteInfo.totalquote_shown, " to ", new_quote, "...");
            setQuoteInfo({
                ...quoteInfo,
                totalquote_exact: new_quote.toFixed(0),
            });
            if (!has_updated_quote){
                setHasUpdatedQuote(true);
            }
        }
    });


    // Assuming handleChangeNext, handleChangeObject, handleUpdateDistance, nextPage, and other referenced functions and variables are defined

    if (quoteInfo.jobLatLng && quoteInfo.clicked_locations){
        quoteInfo.hd_mode = hd_mode;
        console.log("values customer hd_mode: ", hd_mode)
        return (
            <div className='container'>
                <Header
                    prevPage={false} 
                    values_general={companyInfo}
                    values_business={companyInfo}   
                />
                <SquareFootageMapHighRes
                    key={hd_mode}
                    values_customer={quoteInfo}
                    nextPage={false}
                    handleSaveMapClicks={handleSaveMapClicks} //save map clicks anytime polygon is created
                    handleChangeObject={(var_name, value) => {console.log("setting hd_mode to" + value); setHDMode(value)}} //used only for updating hd mode
                    user_mode={'company'}
                    original_quote={originalQuote}
                    updated_quote={(has_updated_quote) ? quoteInfo.totalquote_exact : "N/A"}
                    updated_quote_info={quoteInfo}
                />
            </div>
    ) } else {
        return (
            <div className='center-absolute'>
            <Loading/>
            </div>
        )
    }
}