import React, { Component } from 'react';
import { GoogleMap, LoadScript, Marker, DistanceMatrixService } from '@react-google-maps/api';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowRight, faSyncAlt} from '@fortawesome/free-solid-svg-icons'
import { CSSTransition } from 'react-transition-group';
import Matomo from '../Matomo';

class CollectAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: '',
      city: '',
      state: '',
      zipcode: '',
      submit: false,
      mounted: false,
      latitude: null,
      longitude: null,
      zoom: 11,
      errorMessage: null,
      nextpage: props.next,
      is_mobile_display: props.is_mobile_display
    };
    this.onAddressChange = this.onAddressChange.bind(this);
    this.onZoomIn = this.onZoomIn.bind(this);
    this.calculateDistance = this.calculateDistance.bind(this);
    this.renderGoogleMap = this.renderGoogleMap.bind(this);
  }

  componentDidMount = () => {
    /*TODO Functionalities:
    - Include email collection? getDBQuote issues?
    - autocomplete/prompt address
    - disable streetview? and other demanding features
    */
    
    /*-Matomo Analytics-*/
    const title = 'CollectAddress';
    Matomo(title);
    /*----End Matomo----*/

    //Skip to next page:
    if(!this.props.showpage){
      const event = new Event("service");
      this.continue(this.props.next, true)(event);
      console.log('skipping CollectAddress')
    }

    this.setState({ mounted: true });
  }

  continue = (next, skip=false) => e =>{
    e.preventDefault();
    this.props.nextPage(next, skip);
  };

  setSubmitQue = () => {
    this.setState({ submit: true });
  }

  onAddressChange(event) {
    this.setState({ [event.target.id]: event.target.value }, this.onZoomIn);
    //TODO: update Survey state.address in realtime vs. on Continue button press?
  }

  onZoomIn() {
    
    const {address, city, state, zipcode} = this.state;
    const {handleChangeObject} = this.props;;
    if ( !(address && city && state && zipcode)) {
      return;
    }
    const fullAddress = `${address}, ${city}, ${state} ${zipcode}`;
    console.log('onZoomIn: ' + fullAddress)
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode( { 'address' : fullAddress }, (results, status) => {
      if (status === 'OK') {
        const { location } = results[0].geometry;
        const lat = location.lat();
        const lng = location.lng();
        const latLng = {lat: lat, lng: lng};
        this.setState({
          latitude: lat,
          longitude: lng,
          zoom: 15,
          errorMessage: null
        }, handleChangeObject('jobLatLng',latLng));
      } else {
        this.setState({
          errorMessage: 'Invalid or incomplete address'
        });
      }
    });
  }

  calculateDistance(response) {
    const { distance} = response.rows[0].elements[0];
    const meters_in_a_mile = 1609.344;
    console.log(distance)

    const miles = distance.value / meters_in_a_mile; // convert meters to miles

    console.log("Distance calculated: " + miles);
    this.props.handleChangeObject('travel_distance', miles)
  }

  renderGoogleMap(placement) {
    console.log('getGoogleMap: '+placement+ ' '+this.state.is_mobile_display)
    const { latitude, longitude, zoom, is_mobile_display, submit } = this.state;
    const {address} = this.state || this.props.values_customer.branch.address;

    const containerStyle = {
      width: '100%',
      height: '100%'
    };
    const center = {
      lat: latitude || 47.7180, //TODO: Hardcoded in, figure out how to initialize dynamically using branch.address
      lng: longitude || -116.9516
    };
    const map_element = 
      <div className='mapwrapper'>
        {/* <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}> */}
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={zoom}
          >
            {latitude && longitude && <Marker position={{ lat: latitude, lng: longitude }} />}
            {this.props.values_customer.branch.address && address.length && <DistanceMatrixService
              options={{
                destinations: [address],
                origins: [this.props.values_customer.branch.address],
                travelMode: "DRIVING",
              }}
              callback={(response) => {
                if(response.rows[0].elements[0].status === "OK"){
                  this.calculateDistance(response)
                } else {
                  console.log(response)
                }
              }}  
            />}
          </GoogleMap>
        {/* </LoadScript>  */}
      </div>
    
    if(placement === 'mobile' && is_mobile_display){
      return map_element;
    } else if (placement === 'desktop' && !is_mobile_display){
      return map_element;
    }
  }

  render() {
    const { handleChangeNext, handleChangeObject, is_mobile_display } = this.props;
    const { address, city, state, zipcode, latitude, longitude, zoom, errorMessage, nextpage } = this.state;
  
    const title = "What is the address of this job?"
    const spinner = <FontAwesomeIcon 
                      icon={faSyncAlt}
                      class='fa-spin spinner'
                    />

    if (this.props.showpage){
      return (
        <div className ='surveywrapper'>
          {this.renderGoogleMap('desktop')}
          <CSSTransition
                        in={this.state.mounted}
                        timeout={1000}
                        classNames={is_mobile_display ? "fade" : "slide-to-left"}
                        unmountOnExit 
                        appear
                >
            <div className='questionwrapper'>
              <section className="collect-email">
                  <h2 className='large'>{title}</h2>
                  <p className='description m-small'>This is used to calculate travel costs.</p>
                  <div className="formwrapper">
                      <input type="text" id="address" value={address} placeholder={'Street Address'} autoComplete='street-address' onChange={this.onAddressChange} />
                      <p className = {(address) ? 'miniplaceholder' : 'miniplaceholder invis'}>Street Address</p>
                  </div>
                  <div className="formwrapper">
                      <input type="text" id="city" value={city} placeholder='City' autoComplete='address-level2' onChange={this.onAddressChange} />
                      <p className = {(city) ? 'miniplaceholder' : 'miniplaceholder invis'}>City</p>
                  </div>    
                  <div className='formwrapper'>
                      <input type="text" id="state" value={state} placeholder='State' autoComplete='address-level1' onChange={this.onAddressChange} />
                      <p className = {(state) ? 'miniplaceholder' : 'miniplaceholder invis'}>State</p>
                  </div>    
                  <div className='formwrapper'>   
                      <input type="text" id="zipcode" value={zipcode} placeholder='Zip Code' autoComplete='postal-code' onChange={this.onAddressChange} />
                      <p className = {(zipcode) ? 'miniplaceholder' : 'miniplaceholder invis'}>Zip Code</p>
                  </div>
                  {errorMessage && <div className="invalid">{errorMessage}</div>}
              </section>
              <button type='button' 
                                      disabled = { errorMessage || !address }
                                      class='formsubmitbtn'
                                      id={this.state.address}
                                      onClick={handleChangeNext(nextpage, 'address')}>
                                      Continue
                                      {(!errorMessage && address) ? 
                                          <FontAwesomeIcon 
                                              icon={faArrowRight}
                                          /> 
                                          : ''
                                      }
                              </button>
                {this.renderGoogleMap('mobile')}
            </div>
          </CSSTransition>
        </div>

      );
    } else {
      return spinner;
    }
  } 
}

export default CollectAddress;
