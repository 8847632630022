import React, {Component} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

//props: values_customer, state_var, choice, handleClick
export class ButtonWithImageIcon extends Component {

    constructor (props) {
        super(props);
        this.state = {
            hover_btn: '',
        }
    }

    getButtonActivity = val =>{ 
        var saved = this.props.values_customer[this.props.state_var];
        if (saved===val && saved !== undefined) {
            return 'rowbtn active';
        }
        return 'rowbtn';
    }

    
    render() { 
        const {choice, handleClick} = this.props;

        return (
            <button
                type='button' 
                className={this.getButtonActivity(choice.title)}
                id={choice.title}
                onClick={ handleClick }>
                <div className='btn-with-preview' >
                    {(choice.image_icon) ? <img src={choice.image_icon} alt={choice.title} className='preview' /> : null}
                    <p className='title hover m-large' id={choice.title}>{choice.title}</p>
                </div>
            </button> 
        )
    }
}

export default ButtonWithImageIcon;