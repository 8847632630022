//modal that interfaces with custom GPT assistant, with prompts for user input
//components: GPTmodal, GPTQuestionPrompts

import React, { Component } from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes, faExclamationTriangle, faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons'
import { CSSTransition } from 'react-transition-group';
import Matomo from '../../Matomo';
import SingleSelectionPreview from '../components/SingleSelectionPreview';

class CoatIconModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            mounted: false,
            color: this.props.color,
            preview_index: 0,
            image_loaded: false,
        };
    }

    async componentDidMount() {
        /*-Matomo Analytics-*/
        const title = 'ColorPreviewModal';
        await Matomo(title);
        /*----End Matomo----*/
        //this.getCenter().scrollIntoView();
        this.setState({mounted: true})
        if (this.props.show){
            this.handleShow();
        }
    }

    //before unmounting, set mounted to false so that the modal fades out
    componentWillUnmount() {
        this.setState({mounted: false})
    }
    

    handleShow = () => {
        this.setState({ show: true });
    };


    render() {
        const {handleClose, show, color} = this.props;
        const showHideClassName = show ? "modal display-block" : "modal display-none";
        const header = (color) ? color.title + ' ' + color.style + ' Preview' : 'Preview';
        const directions = `Don't stress this part too much. You can always change your color later.`;
        return show && color ? (
            <CSSTransition in={this.state.mounted} timeout={300} classNames="fade-fast" unmountOnExit>
                <div className="modal display-block">
                    <section className="modal-preview">
                        <div className='right'>
                            <div className="close-modal-icon" onClick={handleClose}>
                                <FontAwesomeIcon 
                                    className='large no-margin'
                                    icon={faTimes}
                                    color='black'
                                />
                            </div>
                        </div>
                        <img className='' src={color.icon} alt={color.title}/>
                    </section>
                </div>
            </CSSTransition>
        ) : null;
    }
}

export default CoatIconModal;
