import React, {Component} from 'react';
//import floorPic from ''
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faComments, faExclamationCircle, faImage} from '@fortawesome/free-solid-svg-icons'
import { CSSTransition } from 'react-transition-group'
import {AboutModal} from './Modals/AboutModal.js'
import { FooterLogo } from '../general_questions/FooterLogo.js';
import obstructionPic from './obstruction.jpeg';
import gatePic from './obstruction_gate.jpeg';
import Matomo from '../Matomo';
import { FooterContact } from '../general_questions/FooterContact.js';
import GPTModal from '../general_questions/modals/GPTmodal.js';
import AI_Assistant_Icon from '../general_questions/images/J.png'

export class Obstructions extends Component {

    constructor (props) {
        super(props);
        this.state = {
            showabout : false,
            nextpage: props.next,
            mounted: false
        }    
    }

    componentDidMount = () => {
        /*-Matomo Analytics-*/
        const title = 'Obstructions';
        Matomo(title);
        /*----End Matomo----*/
        //Skip to next page:
        if(!this.props.showpage){
            const event = new Event("emptyevent");
            this.continue(this.state.nextpage, true)(event);
        }
        this.setState({ mounted: true });
    }

    continue = (next, skip = false) => e =>{
        e.preventDefault();
        this.props.nextPage(next, skip);
    };

    showAboutModal = () => { // ?when passed in a specific state value to change, exceeds max update depth..?
        this.setState({ showabout: true });
    }
    
    hideAboutModal = () => {
        this.setState({ showabout: false });
    }

    getButtonActivity = val =>{
        /*var saved = this.props.values_customer.existingcoat;
        if (saved===val) {
            return 'columnbtn active';
        }*/
        return 'columnbtn';
    }
    nextPage = (value) => e => {
        const reason = 'Customer has obstructions leading to their job area.';
        if (value==='none'){
            this.props.handleChangeNext(this.state.nextpage,'obstructions')(e)
        }
        else if (this.sendToInpersons(value)){
            this.props.handleChangeEstimateReason(reason,'obstructions', 'true')
        }
        else {
            this.props.handleChangeNext(this.state.nextpage,'obstructions')(e)
        }
    }

    sendToInpersons = (has_obstructions) => {
        if (has_obstructions === 'yes'){
            return true;
        } else {
            return false;
        }
        /*const inpersons = this.props.values_business.inpersons;
        const areatype = this.props.values_customer.areatype.title.toLowerCase();
        const servicetype = this.props.values_customer.servicetype.service.toLowerCase();
        const page = "ExistingCoat";
        let is_inperson = true;
        let jobtype; //ultimitely set to either the servicetype, areatype, or default whatever is specifically identified in the Inpersons object
        
        console.log("Inpersons: " + selected_coat)
        //console.log(selected_floorcondition)
        
        if (inpersons[page]){                                   //if a inpersons exists for this page
            if (inpersons[page][servicetype]) {                    //and exists for the current selected servictype (or servicetype or default)
                jobtype = servicetype;
            }
            else if (inpersons[page][areatype]) {
                jobtype = areatype;
            }
            else if (inpersons[page]["default"]){
                jobtype = "default";
            }
            console.log("jobtype: " + jobtype)
            console.log(inpersons[page][jobtype])
            if (jobtype){
                for (const [condition, condition_value] of Object.entries(inpersons[page][jobtype])){  //check ONE condition at a time
                    switch (condition) {
                        case "inperson":
                            if (condition_value === "never"){  //immediately DO NOT send to in person quote page, regardless of other conditions
                                return false;
                            } else if (condition_value === "always"){ //immediately DO send to in person quote page, regardless of other conditions
                                return true;
                            }
                            break;
                        case "existingcoat":
                            is_inperson = (condition_value === selected_coat)
                            break;
                    }
                    if (!is_inperson){                          //if ANY conditions are not met, do not send to in person quotepage
                        return false;
                    }
                }
                return true;
            }
        }
        else{
            return false;
        }
        */
    }
    getObstructionPic = () => {
        const area = this.props.values_customer.areatype.title.toLowerCase();
        if (area.includes('garage') || area.includes('interior')){
            return obstructionPic;
        } else {
            return gatePic;
        }
    }

    render() { 
        const { handleChangeNext, handleChangeEstimateReason, values_business, values_customer, is_mobile_display} = this.props;
        
        const yes = 'yes';
        const aboutthis = [         
            {title: 'What is meant by "obstructions"?', text: <div><p className="small spaced light">Obstructions are any features of the home or area that make it difficult to move our equipment, which is sometimes heavy, to the area. We can typically work around most obstructions like stairs, gates, Tight Turns etc., but it is factored into our final estimate.</p></div>},
        ]
        const faq_prompts = [
            {
                display_text : 'What are common obstructions I should consider?',
                gpt_query : 'What are common obstructions I should consider?',
            }
        ]
    
        return (
                <div className ='surveywrapper'>
                    <div className='largeimagewrapper'>
                        <img class='coverphoto' src={this.getObstructionPic()}/>
                    </div>
                    <div className='questionwrapper'>
                        <CSSTransition
                            in={this.state.mounted}
                            timeout={1000}
                            classNames={is_mobile_display ? "fade" : "slide-to-left"}
                            unmountOnExit 
                            appear
                        >
                            <div>
                                <div className='textwrapper'>
                                    <h2 className='large'> Are there any obstructions that would make it difficult to get equipment to the job area? </h2>
                                    {this.state.showabout ? <GPTModal handleClose={this.hideAboutModal} prompts={faq_prompts} displayclass={"modal display-block"} intro_query={`What's considered a job obstruction?`} company_name={this.props.values_business.businessname} industry='concrete coatings'/> : null}
                                    <p className='clickable display-flex-row' onClick={this.showAboutModal}><img className='assistant-icon' src={AI_Assistant_Icon} alt='chat icon'/>&nbsp;What's considered an obstruction?</p>
                                </div>
                            
                                <div className='buttonwrapper'>
                                    <button
                                        type='button' 
                                        class={this.getButtonActivity('none')}
                                        value='none'
                                        onClick={(e) =>this.nextPage('none')(e)}>
                                        No major obstructions
                                    </button>
                                    <button
                                        type='button' 
                                        class={this.getButtonActivity('yes')}
                                        value='yes'
                                        onClick={this.nextPage('yes')}>
                                        <p className= 'btn-with-note note' id={yes} >&nbsp;</p>
                                            <p className= 'btn-with-note main' id={yes} >Yes, some obstruction(s)</p>
                                        <p className= 'grey btn-with-note note ' id={yes}>
                                            <FontAwesomeIcon 
                                                    icon={faExclamationCircle}
                                                    //size='3x'
                                            /> May require in-person estimate</p>
                                    </button>
                                </div>
                            </div>
                        </CSSTransition>
                        <FooterContact values_business={values_business} phone={values_customer.branch.phone}/>
                    </div>
                </div>
        )
    }
}

export default Obstructions;