import React, {Component} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSyncAlt} from '@fortawesome/free-solid-svg-icons'


export class Loading extends Component {

    render() { 
        const spinner = <FontAwesomeIcon 
                            icon={faSyncAlt}
                            class='fa-spin spinner'
                            />

        //const atype = this.props.values.areatypessrc/components/questions/Loading.js

        return (
            <div className={this.props.classname}>
                {spinner}
            </div>
        )
    }
}

export default Loading;