//Displays statement as a pulsing laod screen. Duration is calculated based on the length of the statement, or on "duration" prop if provided.

import React, {Component} from 'react';
import {AboutModal} from '../questions/Modals/AboutModal.js';
import { CSSTransition } from 'react-transition-group';
import { FooterLogo } from './FooterLogo.js';
import Matomo from '../Matomo.js';
import FadeInItem from './components/animations/FadeInItem.js';

export class Instructions extends Component {
    constructor (props) {
        super(props);
        this.state = {
            showmodal : false,
            nextpage : props.next,
            mounted : false
        }
    }

    componentDidMount = () => {
        /*-Matomo Analytics-*/
        const title = this.props.page_title;
        Matomo(title);
        /*----End Matomo----*/
        
        //Skip to next page if necessary
        if(!this.props.showpage){
            this.continue(true);
        }
        this.setState({ mounted: true });
    }

    componentWillUnmount() {
        clearTimeout(this.timeoutId);
    }

    continue = (skip=false) =>{
        this.props.nextPage(this.state.nextpage, skip);
    };

    getAnimationDelay = (index) => {
        const { header_statements } = this.props;
        let previous_non_empty_statement = header_statements[0];
        for (let i = index - 1; i >= 0; i--) {
            if (header_statements[i] && header_statements[i].length > 0) {
                previous_non_empty_statement = header_statements[i];
                break;
            }
        }
        const delay = (index !== 0) ? (index * 750) + (15 * previous_non_empty_statement.length) : 0;
        if (index === header_statements.length - 1) {
            this.signalInstructionsComplete(delay);
        }
        return delay;
    }

    signalInstructionsComplete = (final_delay) => {
        // wait for the final delay, then signal that the instructions are complete
        const { instructions_complete } = this.props;
        this.timeoutId = setTimeout(() => {
            this.props.instructionsComplete();
        }, final_delay); 
    }

    render() { 
        const { header_statements, instructions_complete} = this.props;

        return (
            <div className='surveywrapper'>
                    <div className='column make-statement'>
                        {/* <div className='pulse-text-fullscreen'> */}
                            {header_statements.map((statement, index) => {
                                return (!instructions_complete) ?
                                (
                                    <FadeInItem item={<h2 className='m-large' key={index}>{statement}</h2>} key={index} animation_delay={this.getAnimationDelay(index)} />
                                ) : (
                                    <h2 className='m-large' key={index}>{statement}</h2>
                                )
                            }
                            )}
                        {/* </div> */}
                    </div>
            </div>
        )
    }
}

export default Instructions;