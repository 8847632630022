import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';
import Matomo from '../../Matomo';
import { findObjectByProperty } from '../../../Helpers';

class DropdownSectionsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            mounted: false,
            showDropdowns: [],
        };
    }

    componentDidMount() {
      const { delay_show } = this.props;
    
      if (delay_show) {
        // Delay setting mounted to true if delay_show prop is provided
        setTimeout(() => {
          this.setState({mounted: true});
          this.expandDropdownsInitially();
        }, 500); // Use 1000 ms for 1 second delay
      } else {
        // If no delay_show prop, set mounted to true immediately
        this.setState({mounted: true});
        this.expandDropdownsInitially();
      }
    }
    
    expandDropdownsInitially = () => {
      // Expands any dropdowns that should be initially expanded
      setTimeout(() => {
        for (let i = 0; i < this.props.info.length; i++) {
          const { render_expanded, key } = this.props.info[i];
          if (render_expanded) {
            this.setState(prevState => ({
              showDropdowns: [...prevState.showDropdowns, key]
            }));
          }
        }
      }, 500);
    }

    componentWillUnmount() {
        this.setState({mounted: false});
    }

    handleDropdown = (id) => e => {
      console.log('handleDropdown', id);
        const { showDropdowns } = this.state;
        const isDropdownShown = showDropdowns.includes(id);
        this.setState({
            showDropdowns: isDropdownShown ? showDropdowns.filter(obj => obj !== id) : [...showDropdowns, id],
        });
    };

    render() {
        const { handleClose, info } = this.props;
        //if delay_show prop, wait 1/2 second before showing modal
        return (
            <CSSTransition in={this.state.mounted} timeout={300} classNames="fade-fast" unmountOnExit>
                <div className={"modal display-block"}>
                    <section className="modal-main">
                        <div className="right">
                            <div className="close-modal-icon" onClick={handleClose}>
                                <FontAwesomeIcon className='large no-margin' icon={faTimes} color='black' />
                            </div>
                        </div>
                        {info.map(({ title, short, text, key, img_src = null }) => (
                          <div key={key} className={`dropdown-item ${this.state.showDropdowns.includes(key) ? 'expanded' : ''}`}>
                              {title}
                              <div className='dropdown-row' onClick={e => this.handleDropdown(key)(e)}>
                                  <div className='dropdown-title'>
                                      {short}
                                  </div>
                                  <FontAwesomeIcon
                                      icon={faChevronRight}
                                      size='lg'
                                      className={`dropdown-chevron light ${this.state.showDropdowns.includes(key) ? 'rotate' : ''}`}
                                  />
                              </div>
                              <div className="dropdown-content">
                                  {this.state.showDropdowns.includes(key) ? (
                                      <>
                                          {text}
                                          {img_src && <img src={img_src} alt={title} />}
                                      </>
                                  ) : null}
                              </div>
                          </div>
                      ))}


                    </section>
                </div>
            </CSSTransition>
        );
    }
}

export default DropdownSectionsModal;
