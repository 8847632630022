import React from 'react';
import './App.css';
import { MasterSurvey } from './components/MasterSurvey';

const App = () => {
    return (
        <div className="App">    
            <MasterSurvey/> 
        </div>
    );
  }

export default App;
