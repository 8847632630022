import React, {Component} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import ImageButton from './ImageButton';

//props: values_customer, state_var, choice, handleClick
export class ImageButtonWithDropdown extends Component {

    constructor (props) {
        super(props);
        this.state = {
            hover_btn: '',
        }
    }

    getButtonActivity = val =>{ 
        var saved = this.props.values_customer[this.props.state_var];
        if (saved===val && saved !== undefined) {
            return 'imgbtn active';
        }
        return 'imgbtn';
    }

    handleSelection = (e) => {
        console.log(e.target.value)
        //find the option within the dropdown_options that matches the value of the selected option
        const selected_option = this.props.choice.dropdown_options.find(option => option.value === e.target.value);
        //call the handleClick function with the selected option
        if (!selected_option){
            console.error('No option found for value: ', e.target.value);
            return;
        }
        const go_to_next = this.props.handleClick(selected_option)(e);
        if(go_to_next) { //the handlClick function may send to inperson page itself. Maybe worth moving this logic to handleClick
            this.props.nextPage(selected_option.nextpage);
        }
    }

    
    render() { 
        const {values_customer, state_var, level, choice, handleClick} = this.props;
        const show_dropdown = values_customer[state_var] === choice.value;
        console.log(values_customer[state_var])
        console.log(choice.value)
        return (
            <div className='buttonwithdropdown-group' key={choice.value}>
                <div 
                    className='buttonwithtitle-group'
                    id={choice.value}
                    onClick={handleClick(choice)}
                >
                    <div
                        className='buttonwithtitle'
                        >
                    <img src={choice.picture} alt={choice.value} className={this.getButtonActivity(choice.value)}/>
                    </div>
                    {!show_dropdown && <p className='small' id={choice.value}>{choice.description}</p>}
                </div>
                {show_dropdown && <select className='button-dropdown' value={values_customer[state_var].value} onChange={this.handleSelection}>
                        <option value={null}>Choose a size...</option>
                        {choice.dropdown_options.map((option, index) => {
                            return(
                                <option key={index} id={option.value} value={option.value}>{option.title}</option>
                            )
                        })}
            
                    </select>
                }
        </div>
        )
    }
}

export default ImageButtonWithDropdown;