import React, {Component} from 'react'
import Matomo from '../Matomo';
import { CSSTransition } from 'react-transition-group';
import { FooterLogo } from './FooterLogo';


export class Branch extends Component {
    constructor (props) {
        super(props);
        this.state = {
            nextpage : props.next,
            mounted: false,
        }
        this.getButtonActivity = this.getButtonActivity.bind(this);
    }

    getButtonActivity = val =>{ 
        var saved = this.props.values_customer.branch;
        if (saved && (saved.name === val)) {
            return 'rowbtn active';
        }
        return 'rowbtn';
    }

    componentDidMount = () =>{
        /*-Matomo Analytics-*/
        const title = 'Branch';
        Matomo(title);
        /*----End Matomo----*/

        //Skip to next page if necessary
        console.log('showpage: ' + this.props.showpage)
        if(!this.props.showpage){
            //console.log('skipping Branches, branch options: ', this.props.valubranch_options)
            const event = new Event("emptyevent");
            this.continue(this.state.nextpage, true)(event);
        }

        this.setState({ mounted: true });
    };

    continue = (next, skip = false) => e =>{
        e.preventDefault();
        this.props.nextPage(next, skip);
    };

    componentWillUnmount = () => {
        this.setState({ mounted: false });
    }

    render() { 
        const { handleChangeObjectNext, values_business, is_mobile_display} = this.props;

        return (
            <div className='surveywrapper'>
                <CSSTransition
                        in={this.state.mounted}
                        timeout={1000}
                        classNames="fade"
                        unmountOnExit 
                        appear
                >
                    <div className='multiplechoice'>
                        <div className = 'bottom-logo-img never-mobile'/>
                        <div className='textwrapper'>
                            <h2 className='large'> Which of our locations is this job for? </h2>
                        </div>
                        <div className="row">
                            {values_business.branch_options.map((branch)  => (                           
                                <button
                                    type='button' 
                                    className={this.getButtonActivity(branch.name)}
                                    id={branch.name}
                                    onClick={ () => {handleChangeObjectNext(this.state.nextpage,'branch', branch)} }>
                                        <div className='buttontext' >
                                            <p id={branch.name} onClick={ () => {handleChangeObjectNext(this.state.nextpage,'branch', branch)} }>{branch.name}</p>
                                        </div>
                                </button> 
                            ))}               
                        </div>
                        <div className='textwrapper'>
                            <h2 className='large invis'>.</h2>
                        </div>
                        <div className="fillremainderflex"/>
                        
                    </div>
                </CSSTransition>
            </div>
        )
    }
}

export default Branch;