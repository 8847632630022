import React, {Component} from 'react';
import { CSSTransition } from 'react-transition-group';
import {AboutModal} from './Modals/AboutModal.js';
import { PreviewModal } from './Modals/PreviewModal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faComments, faImage} from '@fortawesome/free-solid-svg-icons'
import { FooterLogo } from '../general_questions/FooterLogo.js';
import pittingPic from './spalled-concrete.jpg';
import exampleSpalling from './spalling_concrete.webp';
import Matomo from '../Matomo';
import { FooterContact } from '../general_questions/FooterContact.js';
import GPTModal from '../general_questions/modals/GPTmodal.js';
import AI_Assistant_Icon from '../general_questions/images/J.png'

export class FloorPitting extends Component {

    constructor (props) {
        super(props);
        this.state = {
            showabout : false,
            showmodal : '',
            nextpage: props.next,
            mounted: false,
        }    
        this.getButtonActivity = this.getButtonActivity.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    componentDidMount = () => {
        /*-Matomo Analytics-*/
        const title = 'FloorPitting';
        Matomo(title);
        /*----End Matomo----*/

        //Skip to next page
        if(!this.props.showpage){
            const event = new Event("emptyevent");
            this.continue(this.state.nextpage, true)(event);
        }

        this.setState({ mounted: true });
    }

    continue = (next, skip = false) => e =>{
        e.preventDefault();
        this.props.nextPage(next, skip);
    };

    showAboutModal = () => { 
        this.setState({ showabout: true });
    };
    
    hideAboutModal = () => {
        this.setState({ showabout: false });
    };

    showModal = () => e => {
        document.body.style.overflow = 'hidden';
        this.setState({ showmodal: e.currentTarget.id});
    };
    
    hideModal = () => {
        document.body.style.overflow = 'auto';
        this.setState({ showmodal: '' });
    };

    getPreviewModal = () => {
        const title = 'Severe chipping (also known as pitting)';
        const content = {title: title, src :pittingPic};
        const show = (this.state.showmodal === title);
        return(
            <PreviewModal 
                show={show} 
                handleClose={() => this.hideModal()} 
                info={content}>
            </PreviewModal>
        )
    }

    getButtonActivity = val =>{
        var saved = this.props.values_customer.floorpitting;
        if (saved===val) {
            return 'columnbtn active';
        }
        return 'columnbtn';
    };

    getFloorNumericalValue = (pitting) => { //converts floorcondition string into numerical values to compare
        let value;
        switch (pitting){
            case "none":
                value = 0;
                break;
            case "light":
                value = 1;
                break;
            case "medium":
                value = 2;
                break;
            case "heavy":
                value = 3;
                break;
            default:
                console.error("ERROR:  no floorcondition value provided to getFloorNumericalValue. Recieved: ", pitting)
                break;
        }
        return value;
    }

    sendToInpersons = (selected_pitting) => {
        const inpersons = this.props.values_business.inpersons;
        const areatype = this.props.values_customer.areatype.title.toLowerCase();
        const servicetype = this.props.values_customer.servicetype.service.toLowerCase();
        const page = "FloorPitting";
        let is_inperson = true;
        let jobtype; //ultimitely set to either the servicetype, areatype, or default whatever is specifically identified in the Inpersons object
        
        //console.log("Inpersons: " + selected_floorcondition)
        //console.log(selected_floorcondition)
        
        if (inpersons[page]){                                   //if a inpersons exists for this page
            if (inpersons[page][servicetype]) {                    //and exists for the current selected servictype (or servicetype or default)
                jobtype = servicetype;
            }
            else if (inpersons[page][areatype]) {
                jobtype = areatype;
            }
            else if (inpersons[page]["default"]){
                jobtype = "default";
            }
            //console.log("jobtype: " + jobtype)
            //console.log(inpersons[page][jobtype])
            if (jobtype){
                for (const [condition, condition_value] of Object.entries(inpersons[page][jobtype])){  //check ONE condition at a time
                    switch (condition) {
                        case "inperson":
                            if (condition_value === "never"){  //immediately DO NOT send to in person quote page, regardless of other conditions
                                return false;
                            } else if (condition_value === "always"){ //immediately DO send to in person quote page, regardless of other conditions
                                return true;
                            }
                            break;
                        case "minpitting":
                            is_inperson = this.getFloorNumericalValue(selected_pitting) >= this.getFloorNumericalValue(condition_value);
                            console.log(is_inperson)
                            break;
                        case "maxpitting":
                            is_inperson = this.getFloorNumericalValue(selected_pitting) <= this.getFloorNumericalValue(condition_value);
                            break;
                    }
                    if (!is_inperson){                          //if ANY conditions are not met, do not send to in person quotepage
                        return false;
                    }
                }
                return true;
            }
        }
        else{
            return false;
        }
   
    }    

    render() { 
        const { handleChangeNext, values_business, values_customer, is_mobile_display} = this.props;
        const faq_prompts = [
            {   
                display_text: 'What is pitting?',
                gpt_query: 'What is concrete pitting?',
            },
            {   
                display_text: `What causes chipping and pitting?`,
                gpt_query: `What causes chipping and pitting?`,
            },
            {
                display_text: `How long can I wait to repair pitting?`,
                gpt_query: `How long can I wait to repair pitting?`,
            },
            /*{
                display_text: `Show me an example of pitting.`,
                gpt_query: `Show me an example of concrete pitting`,
            }*/
        ]
        const aboutthis = [         
            {title: 'About concrete chipping, scaling, and pitting', text: <p className="small spaced light">Pitting (also called scaling or chipping) is an aesthetic deterioration of concrete that can lead to more significant structural problems if left unchecked. There are
            a few reasons why chipping can occur, but most commonly it can be traced back to how the concrete was cured. If air or water gets 
            trapped in concrete, over time and with change of temperatures chipping occurs. One cause of pitting if often brought the corrosive salts 
            that gets tracked onto  concrete surfaces during the winter. Old age and improper mixtures of concrete can also be causes of chipping and 
            pitting. 
            <br/>
            <br/>
            Before we lay a coating on your floor we will need to repair significant spots of chipping and pitting, otherwise the floor will be 
            uneven and further deterioration could occur. This is not a very difficult task, but it does affect our timeline and is considered 
            in our quote.
            <br/>
            <br/>
            An example of pitting:
            <br/>
            <img src={exampleSpalling} alt="example of spalling"/>
            </p>},
        ]
        const preview_coverphoto = (is_mobile_display) ?
            <p className='m-small clickable mobile-only inline' id='Severe chipping (also known as pitting)' onClick={this.showModal()}>                                
                <FontAwesomeIcon 
                icon={faImage}
                />
                &nbsp;image
            </p> : 'image'
        const none = 'none';
        const light = 'light';
        const medium = 'medium';
        const heavy =  'heavy';
        const reason = 'Customer has excessive pitting';
    
        return (
                <div className ='surveywrapper'>
                    <div className='largeimagewrapper'>
                        <img class='coverphoto' src= {pittingPic}/>
                    </div>
                    <div className='questionwrapper'>
                        <CSSTransition
                            in={this.state.mounted}
                            timeout={1000}
                            classNames={is_mobile_display ? "fade" : "slide-to-left"}
                            unmountOnExit 
                            appear
                        >
                            <div>
                                <div className='textwrapper'>
                                    <h2 className='large'>How much chipping or pitting is present in your concrete?</h2>
                                    <p className='description m-small'>(This {preview_coverphoto} would be considered severe)</p>
                                    {this.state.showabout ? <GPTModal handleClose={this.hideAboutModal} prompts={faq_prompts} displayclass={"modal display-block"} company_name={this.props.values_business.businessname} industry='concrete coatings'/> : null}
                                    <p className='clickable display-flex-row' onClick={this.showAboutModal}><img className='assistant-icon' src={AI_Assistant_Icon} alt='chat icon'/>&nbsp;About this question</p>
                                </div>
                                {this.getPreviewModal()}
                                <div className='buttonwrapper'>
                                    <button
                                        type='button' 
                                        class={this.getButtonActivity(none)}
                                        value={none}
                                        onClick={(this.sendToInpersons(none)) ? () => this.props.handleChangeEstimateReason(reason, 'floorpitting', none) : handleChangeNext(this.state.nextpage,'floorpitting')}>
                                        None, it's a smooth surface
                                    </button>
                                    <button
                                        type='button' 
                                        class={this.getButtonActivity(medium)}
                                        value={medium}
                                        onClick={(this.sendToInpersons(medium)) ? () => this.props.handleChangeEstimateReason(reason, 'floorpitting', medium) : handleChangeNext(this.state.nextpage,'floorpitting')}>
                                        Little to moderate amount
                                    </button>
                                    <button
                                        type='button' 
                                        class={this.getButtonActivity(heavy)}
                                        value={heavy}
                                        onClick={(this.sendToInpersons(heavy)) ? () => this.props.handleChangeEstimateReason(reason, 'floorpitting', heavy) : handleChangeNext(this.state.nextpage,'floorpitting')}>
                                        Severe, looks like the picture or worse
                                    </button>
                                </div>
                            </div>
                        </CSSTransition>
                    <FooterContact values_business={values_business} phone={values_customer.branch.phone}/>
                    </div>
                </div>
        )
    }
}

export default FloorPitting;