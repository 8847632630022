import React, {Component} from 'react';
import { findIndexByProperty } from '../../../Helpers';
import Matomo from '../../Matomo';
import { CSSTransition } from 'react-transition-group';

// This component renders a numerical input choice that maps to a .coef property in the parent component

export class TitleInput extends Component {
    
    render() { 
        const {option, handleOnChange} = this.props;
        
        return(
            <div className='boxgroupitem'>
                <label for={option.key}>&nbsp;{option.key}&nbsp;</label>
                <input 
                    key = {option.key}
                    className = "" 
                    type="text"
                    id={option.title} //if option is an object, use title property
                    name={option.title} 
                    value={option.title}
                    onChange={handleOnChange(option, "title")} 
                    />
            </div>
        )
    }

}
    
export default TitleInput;