import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPenSquare,faTrash} from '@fortawesome/free-solid-svg-icons'
import { faClone } from '@fortawesome/free-regular-svg-icons'
import DragDropItem from './question_childrencomponents/DragDropItem';

// Helper function to reorder list
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const initDroppableCategories = (props) => {
    console.log("initDroppableCategories")
    console.log(props)
    const categories = props.drop_categories;
    const dropped_items = props.dropped_items;
    console.log(dropped_items)
    const droppables = {};
    categories.forEach((category) => { //sort items into appropriate packages based on saved state
        droppables[category] = [];
        
        let package_name = category;
        if (category.toLowerCase().includes('basic')){
            package_name = 'base';
        } else if (package_name.toLowerCase().includes('recommended')){
            package_name = 'recommended';
        } else if (package_name.toLowerCase().includes('deluxe')){
            package_name = 'deluxe';
        } else if (package_name.toLowerCase().includes('customize')){
            package_name = 'customize';
        }

        const items = dropped_items[package_name]
        if (items){
            items.forEach((item) => {
                droppables[category].push(item);
            });
        }
    });
    console.log(droppables)
    return droppables;
}
  

export class PackageCreator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: props.items, // Items to drag
      droppables: initDroppableCategories(props), // Categories to drop into
    };
  }

    onDragEnd = (result) => {
        const { source, destination } = result;
        const {handleAddItemToCategory, handleRemoveItemFromCategory} = this.props;
        console.log("onDragEnd")
        console.log(result)
        // Dropped outside any list
        if (!destination) {
        return;
        }
    
        // Moving within the same list
        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                this.getList(source.droppableId),
                source.index,
                destination.index
            );
            
                // Update state according to source and destination
                if (source.droppableId === 'items') {
                this.setState({ items });
            } else {
                this.setState({
                droppables: {
                    ...this.state.droppables,
                    [source.droppableId]: items,
                },
                });
            }
        } else {
            // Moving between different lists - ensure no duplicates across the entire state
            const start = this.getList(source.droppableId);
            const finish = this.getList(destination.droppableId);
            const [removed] = start.splice(source.index, 1);
            console.log(removed)
            // Add to the new list
            finish.splice(destination.index, 0, removed);
        
            // Update state according to source and destination
            if (source.droppableId === 'items') {
                this.setState({
                    items: start,
                    droppables: {
                        ...this.state.droppables,
                        [destination.droppableId]: finish,
                    },
                }, handleAddItemToCategory(destination.droppableId, removed));
            } else if (destination.droppableId === 'items') {
                this.setState({
                    items: finish,
                    droppables: {
                        ...this.state.droppables,
                        [source.droppableId]: start, // Update the original list without the removed item
                        // No need to update the destination here since it's the 'items' list
                    },
                }, handleRemoveItemFromCategory(source.droppableId, removed));
            } else {
                this.setState({
                    droppables: {
                        ...this.state.droppables,
                        [source.droppableId]: start,
                        [destination.droppableId]: finish,
                    },
                }, handleAddItemToCategory(destination.droppableId, removed), handleRemoveItemFromCategory(source.droppableId, removed));
            }
        }
    }
    
    getList(id) {
        if (id === 'items') {
        return this.state.items;
        } else {
        // Ensure the droppableId always maps to an existing array
        return this.state.droppables[id] || [];
        }
    }

    handleEditItem = (item_key) => {
        console.log("handleEditItem")
        console.log(item_key)
    }


    //TODO call handleAddItemToCategory and handleRemoveItemFromCategory like in onDragEnd

    handleCopyItem = (item_key) => { //create a copy of the item (with a unique key) and add it to the items list
        console.log("handleCopyItem")
        console.log(item_key)
        const {handleAddItemToCategory} = this.props;

        //find the key in items array, or in one of the droppables
        let item = this.state.items.find(item => item.key === item_key);
        let owning_list = 'items'
        if (!item){
            for (const key in this.state.droppables) {
                item = this.state.droppables[key].find(item => item.key === item_key);
                if (item){
                    owning_list = key;
                    break;
                }
            }
        }
        if (item){
            const new_key = this.generateUniqueKey();
            const new_item = {...item, key: new_key};
            console.log(new_item)
            //update owning_list to include the new item
            if (owning_list === 'items'){
                console.log("owning_list is items")
                this.setState({
                    items: [...this.state.items, new_item]
                }, handleAddItemToCategory(owning_list, new_item));
            } else {
                console.log("owning_list is " + owning_list)
                this.setState({
                    droppables: {
                        ...this.state.droppables,
                        [owning_list]: [...this.state.droppables[owning_list], new_item]
                    }
                }, handleAddItemToCategory(owning_list, new_item));
            }
        } else {
            console.error("Item not found in any lists: " + item_key)
        }
    }

    generateUniqueKey = () => {
        // Generate a unique key for the new item
        let new_key = Math.random().toString(36).substring(7);
        return new_key;
    }

    handleDeleteItem = (item_key) => {
        console.log("handleDeleteItem")
        console.log(item_key)
        const {handleAddItemToCategory} = this.props;
        //find the key in items array, or in one of the droppables
        let item = this.state.items.find(item => item.key === item_key);
        let owning_list = 'items'
        if (!item){
            for (const key in this.state.droppables) {
                item = this.state.droppables[key].find(item => item.key === item_key);
                if (item){
                    owning_list = key;
                    break;
                }
            }
        }
        if (item){
            if (owning_list === 'items'){
                console.log("owning_list is items")
                this.setState({
                    items: this.state.items.filter(item => item.key !== item_key)
                }, handleAddItemToCategory(owning_list, item));
            } else {
                this.setState({
                    droppables: {
                        ...this.state.droppables,
                        [owning_list]: this.state.droppables[owning_list].filter(item => item.key !== item_key)
                    }
                }, handleAddItemToCategory(owning_list, item));
            }
        } else {
            console.error("handleDeleteItem - Item not found in any lists: " + item_key)
        }
    }

  

    render() {
        const { drop_categories, item_bank_name, header_question, header_directions } = this.props;

        return (
            <div>
                <h2 className='center-horizontal large'>{header_question}</h2>
                <h4 className='center-horizontal m-small'>{header_directions}</h4>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <div className='package-creator-main'>
                    <Droppable droppableId="items">
                        {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            className='item-bank'
                            style={{ backgroundColor: snapshot.isDraggingOver ? 'lightblue' : 'lightgrey'}}
                            {...provided.droppableProps}
                        >
                            <div style={{ textAlign: 'center', padding: 4 }}>{item_bank_name}</div>
                            {this.state.items.map((item, index) => (
                            <Draggable key={item.key} draggableId={item.key} index={index}>
                                {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{ ...provided.draggableProps.style, margin: '0 0 8px 0', borderRadius: 5, backgroundColor: snapshot.isDragging ? 'lightgreen' : 'grey' }}
                                >
                                    <DragDropItem
                                        item={item}
                                        handleCopyItem={this.handleCopyItem}
                                        handleDeleteItem={this.handleDeleteItem}
                                        handleEditItem={this.handleEditItem}
                                    />
                                </div>
                                )}
                            </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                        )}
                    </Droppable>
                    <div className="packages">
                        {drop_categories.map((key) => (
                        <Droppable key={key} droppableId={key}>
                            {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className='package'
                                style={{ backgroundColor: snapshot.isDraggingOver ? 'lightblue' : 'lightgrey' }}
                            >
                                <div style={{ textAlign: 'center', padding: 4 }}>{key.replace(/([A-Z])/g, ' $1').trim()}</div>
                                {this.state.droppables[key].map((item, index) => (
                                <Draggable key={item.key} draggableId={item.key} index={index}>
                                    {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className='drag-drop-item'
                                        style={{ ...provided.draggableProps.style, borderRadius: 5, margin: '0 0 8px 0', backgroundColor: snapshot.isDragging ? 'lightgreen' : 'grey' }}
                                    >
                                        <DragDropItem
                                            item={item}
                                            handleCopyItem={this.handleCopyItem}
                                            handleDeleteItem={this.handleDeleteItem}
                                            handleEditItem={this.handleEditItem}
                                        />
                                    </div>
                                    )}
                                </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                            )}
                        </Droppable>
                        ))}
                    </div>
                    </div>
                </DragDropContext>
            </div>
        );
    }
}

export default PackageCreator;
