import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {useParams} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowRight} from '@fortawesome/free-solid-svg-icons'
import fairquote_logo from './questions/fairquote_logo.png'
import { CSSTransition } from 'react-transition-group'
import Matomo from './Matomo';
import CollectAddressInputBar from './general_questions/CollectAddressInputBar';


export default function AnalyticsHook(props) {

    const pagetype = (props.pagetype) ? props.pagetype : 'job'; //can be estimate, call, or job. estimate is passed from inperson estimate, job is default
    const [showinputflag, setShowInputFlag] = useState(false);

    let {action, quoteid, businessphone,industry} = useParams();
    const lambda_endpoint = 'https://4oof0uwt19.execute-api.us-east-2.amazonaws.com/'
    industry = industry.replace(/_/g, ' '); //replace underscores with spaces, which were used in the url to represent spaces
    industry = encodeURIComponent(industry); //encode the industry string to be passed to the lambda function

    useEffect(() => {
        if(action === 'A1'){ //phone number clicked in a quote viewed follow up email
            axios.get(`${lambda_endpoint}?action=${action}&id=${quoteid}&phone=${businessphone}&industry=${industry}`)
                 .then(response => {
                     // Assuming the response contains the phone number
                     const phoneNumber = response.data.phoneNumber;
                     // Programmatically redirect to the tel: link
                     window.location.href = `tel:${phoneNumber}`;
                 })
                 .catch(error => console.error('Error:', error));
        }
    }, [action, quoteid, businessphone, industry, lambda_endpoint]);
    

    

    return (
        <div/>
    );
    
}