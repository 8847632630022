import React, { Component } from 'react';

class GrowDownItem extends Component {
  constructor(props) {
    super(props);
    this.state = { 
        item: props.item,
        key: props.key,
    };
  }

  componentDidMount() {
    // Trigger the fade-in effect after the component mounts with a slight delay
    // setTimeout(() => {
    //   this.setState({ isVisible: true });
    // }, 10); // A delay of 10 milliseconds
  }
  

  render() {
    const { item, key } = this.state;
    const { animation_delay } = this.props;
    const delay_time = (animation_delay) ? `${animation_delay}ms` : '0ms';
    return (
      <div key={key} className={`grow-down`} style={{ '--grow-down-animation-delay': delay_time }}>
        {item}
      </div>
    );
  }
}

export default GrowDownItem;
