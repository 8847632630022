import React, {Component} from 'react';
import Matomo from '../Matomo';
import { CSSTransition } from 'react-transition-group';

export class TextInputQuestion extends Component {
    
    //requires HandleChange, input_category (string), header_question, prompt props
    
    constructor(props) {
        super(props);
        this.state = {
            mounted: false
        };
    }

    componentDidMount = () => {
        const {showpage, pagetitle} = this.props;
        /*-Matomo Analytics-*/
        Matomo(pagetitle);
        /*----End Matomo----*/
        
        //Skip to next page
        if(!showpage){
            const event = new Event("service");
            //event.data=this.props.values_business.areatype_options[0];
            this.continue(this.props.nextpage)(event);
        }

        this.setState({ mounted: true });
    }

    continue = (next) => e =>{
        e.preventDefault();
        this.props.nextPage(next)
    };

    render() { 
            const {handleChange, header_question, prompt, input_category} = this.props;
            
            return(
                <div className='surveywrapper'>
                    <CSSTransition
                        in={this.state.mounted}
                        timeout={1000}
                        classNames="fade"
                        unmountOnExit 
                        appear
                    >
                        <div className='multiplechoice'>
                            <h2 className='large'>{header_question}</h2>
                            <input 
                                className='inputbarfullscreen'
                                type="text" 
                                placeholder= {prompt}
                                alt = {header_question}
                                onChange= {handleChange(input_category)}
                            />
                        </div>
                    </CSSTransition>
                </div>
            )

    }

}
    
export default TextInputQuestion;