import React, { Component } from 'react';

class RollUpItem extends Component {
  constructor(props) {
    super(props);
    this.state = { 
        isVisible: false,
        item: props.item,
    };
  }

  componentDidMount() {
    // Trigger the fade-in effect after the component mounts with a slight delay
    setTimeout(() => {
      this.setState({ isVisible: true });
    }, 10); // A delay of 10 milliseconds
  }
  

  render() {
    const { item } = this.state;
    const { index, onClick, total_duration } = this.props;
    const container_class = (this.props.container_class) ? this.props.container_class : '';
    const animation_delay = (this.props.delay_time) ? `${this.props.delay_time}ms` : '100ms';
    console.log('delay: ' + animation_delay)
    const animation_duration = `${total_duration}ms`;

    return (
      <div key={index} onClick={onClick} className={`${container_class} roll-up-animation`} style={{ '--rollup-animation-duration':  animation_duration, '--rollup-animation-delay': animation_delay}}>
        {item}
      </div>
    );
  }
}

export default RollUpItem;
