import React, {Component} from 'react'
import { CSSTransition } from 'react-transition-group';

import saw_cut_joint from './saw_cut_joint.jpg'
import no_joint from './no_joint.jpg'
import tooled_joint from './tooled_joint_resized.jpg'
import Matomo from '../Matomo'
import { FooterContact } from '../general_questions/FooterContact';


export class FloorJoints extends Component {
    constructor (props) {
        super(props);
        this.state = {
            nextpage : this.props.next,
            mounted: false
        }
        this.getButtonActivity = this.getButtonActivity.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.continue = this.continue.bind(this);
    }

    componentDidMount = () => {
        /*-Matomo Analytics-*/
        const title = 'FloorJoints';
        Matomo(title);
        /*----End Matomo----*/

        //Skip to next page:
        if(!this.props.showpage){
            const event = new Event("emptyevent");
            this.continue(this.state.nextpage, true)(event);
        }
        this.setState({ mounted: true });
    }

    /*componentWillUnmount = () => {
        this.props.getDBQuote();
    }*/
    continue = (next, skip = false) => e =>{
        e.preventDefault();
        this.props.nextPage(next, skip);
    };

    showModal = () => {
        document.body.style.overflow = 'hidden';
        this.setState({ showmodal: true });
    };
    
    hideModal = () => {
        document.body.style.overflow = 'auto';
        this.setState({ showmodal: false });
    };

    getButtonActivity = val =>{
        var saved = this.props.values_customer.floorjoints;
        if (saved===val) {
            return 'imgbtn active';
        }
        return 'imgbtn';
    }

    render() { 
        const { handleChangeNext, values_business, values_customer, is_mobile_display } = this.props;
        const saw = 'saw';
        const tooled = 'tooled';
        const none ='none';

        return (
            <div className='surveywrapper'>
                <div className='multiplechoice-transitionbuffer'>
                    <CSSTransition
                            in={this.state.mounted}
                            timeout={1000}
                            classNames="fade"
                            unmountOnExit 
                            appear
                    >
                        <div className='multiplechoice'>
                            
                            <div className='textwrapper'>
                                <h2 className='large'> Which picture best matches your floor joints? </h2>
                            </div>
                            <div className="row">
                                <div className='buttonwithtitle'>
                                    <input 
                                        type="image" 
                                        src={saw_cut_joint} 
                                        class={this.getButtonActivity(saw)}
                                        value={saw}
                                        alt = 'Saw Cut Joint' 
                                        onClick={handleChangeNext(this.state.nextpage,'floorjoints')}
                                        />
                                    <p className='medium btntitle dark' onClick={handleChangeNext(this.state.nextpage,'floorjoints')}>Saw Cut Joint</p>
                                </div>
                                <div className='buttonwithtitle'>
                                    <input 
                                        type="image" 
                                        src={tooled_joint} 
                                        class={this.getButtonActivity(tooled)}
                                        value={tooled}
                                        alt = 'Tooled Joint'
                                        onClick={handleChangeNext(this.state.nextpage,'floorjoints')}
                                        />
                                    <p className='medium btntitle dark' onClick={handleChangeNext(this.state.nextpage,'floorjoints')}>Tooled Joint</p>
                                </div>
                                <div className='buttonwithtitle'>
                                    <input 
                                        type="image" 
                                        src={no_joint} 
                                        class={this.getButtonActivity(none)}
                                        value={none}
                                        alt = 'No Joint (Seamless)'
                                        onClick={handleChangeNext(this.state.nextpage,'floorjoints')}
                                        />
                                    <p className='medium btntitle dark' onClick={handleChangeNext(this.state.nextpage,'floorjoints')}>No Joint (Seamless)</p>
                                </div>
                            </div>
                            {/* <div className='textwrapper'>
                                <h2 className='large invis'>.</h2>
                                <h4 className='small invis'>.</h4>
                                <p className='small btntitle dark'>.</p>
                            </div> */}
                            <div className="fillremainderflex"/>
                            
                        </div>
                    </CSSTransition>
                    <FooterContact values_business={values_business} phone={values_customer.branch.phone}/>
                </div>
            </div>
        )
    }
}

export default FloorJoints;