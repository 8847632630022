import React, {Component} from 'react';
import axios from 'axios';
import Header from './Header';
import AreaType from './questions/AreaType';
import ContinueFooter from './questions/ContinueFooter';
import SquareFootage from './questions/SquareFootage';
import GarageSize from './questions/GarageSize';
import FloorCondition from './questions/FloorCondition';
import FloorStaining from './questions/FloorStaining';
import FloorCracking from './questions/FloorCracking';
import FloorCrackLength from './questions/FloorCrackLength';
import FloorPitting from './questions/FloorPitting';
import Sidewalls from './questions/Sidewalls';
import ColorPicker from './questions/ColorPicker';
import FloorJoints from './questions/FloorJoints';
import ProgressBar from './questions/ProgressBar';
import Branch from './general_questions/Branch';
import { CollectContact } from './questions/CollectContact';
import ColorPreview from './questions/ColorPreviewQuestion.js';
import QuotePackages from './questions/QuotePackages';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSyncAlt} from '@fortawesome/free-solid-svg-icons';
import ScheduleContact from './ScheduleContact';
import ExistingCoat from './questions/ExistingCoat';
import ServiceType from './questions/ServiceType';
import Sealant from './questions/Sealant';
import LoadingQuote from './questions/LoadingQuote';
import FinishType from './questions/FinishType';
import Obstructions from './questions/Obstructions';
import Stairs from './questions/Stairs';
import CollectAddress  from './general_questions/CollectAddress';
import {capatilizeFirstLetters, findIndexByProperty, findObjectByProperty, isShownPage, sendToInpersons, setMobileStyling} from '../Helpers.js'
import SquareFootageStriping from './questions/SquareFootageStriping';
import MakeStatement from './general_questions/MakeStatement';
import flake_one_fourth from './questions/1-4inch_flake.png';
import flake_one_eighth from './questions/1-8inch_flake.png';
import flake_one_sixteenth from './questions/1-16inch_flake.png';
import ConditionChooser from './general_questions/ConditionChooser';
import Places from './general_questions/CollectAddressHook';
import Home from './general_questions/CollectAddressHook';
import MultipleChoiceText from './general_questions/MultipleChoiceText';
import SurveyContext from './SurveyContext';
import MakeMultiStatement from './general_questions/MakeMultiStatement.js';
import gpt_icon from './general_questions/images/fq_assistant_chaticon.png';
import assistant_icon from './general_questions/images/J_200x.png';
import QuotePackages_v2 from './questions/QuotePackages_v2.js';
import ErrorBoundary from './general_questions/components/ErrorBoundry.js';
import LongTextPage from './general_questions/LongTextPage.js';
import { LoadScript } from '@react-google-maps/api';
import MakeStaticStatement from './general_questions/MakeStaticStatement.js';
import DropdownSections from './general_questions/components/DropdownSections.js';

export class ConcreteCoatingSurvey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pages: [], //array history of pages
            //is_mobile_display: props.is_mobile_display,
            //---------------------//
            //** Customer Inputs **//
            areatype: '', //Garage, Commerical Space, or Shop
            servicetype: null, //coat material or service type (i.e. epoxy, polish, ...)
            finishtype: null,
            styletype: null, //style of coat (i.e. solid, flake, ...)
            knowssqfootage: '', //true or false as a string to avoid === bugs from comparing different types
            sqfootage: null, //square footage of work space
            garagesize: null, //measured in cars
            floorcondition: null, //none, light, medium, heavy
            floorpitting: null, //none, light, medium, heavy
            floorstaining: null, //none, light, medium, heavy
            floorcracking: null, //none, light, medium, heavy
            floorcracklength: null, //linear feet of customer floor crack
            sidewallheight: null, //measured in inches
            hassidewall: '', //true or false as a string to avoid === bugs from comparing different types
            stairs: null, //number of stairs to coat, number
            hasstairs: null, //true or false as a string to avoid === bugs from comparing different types
            colors: [], //array of color objects containing alt, hex, and style
            flakesize: null, //flake size in inches, if applicable
            floorjoints: '', //A,B,C,D
            email: null, //customer email
            phone: "", //customer phone
            customername: null, //customer name
            preferredcontact: null, //preferred contact method
            address: null, //customer street address
            branch: null, // company branch
            hasexistingcoat: null, //true or false as a string
            existingcoat: null, //existing coat type
            sealant: null, //sealant object
            estimate_reason: null, //reason needed for an in-person estimate
            travel_distance: null, //distance in miles from branch address to job address
            //--------------------------//
            //** Business Information **//
            businessid: props.subdomain, //table ref
            businesslogo: null, //s3 logo key
            businesslogo_horizontal: null, //s3 logo key
            businessname: '', //company name
            businessaddress: '', 
            branch_options: [], //array of objects with .name, .phone, .email, .address (in format `${street}, ${city}, ${state} ${zipCode}` for GoogleMapsAPI)
            marketing_tag: null, //string and or link to display in footer
            areatype_options: null, //array of objects with .title,(coating) .services[]
            servicetype_options: null, //array of objects with .service, .Finishes[]
            base_items: [], //array of objects with .title,  .short, .long, .coef (num), .flat (num)
            addon_items: [], //array of objects with .title,  .short, .long, .coef (num), .flat (num)
            coat_options: [], //.service, .style[], .coef, .short, .colors(.title,.icon,.preview)
            inpersons: {}, //object with state conditions, sorted by survey Page, to send to contact page if true
            nodisplays: {}, //pages to hide, with conditions to do so
            quote_range: null, //display final quote in a range of +/- this % value
            homepage: '', //link back to company's homepage
            singlepackage: null, //if true, only custom package will be quoted
            flake_sizes: null, //array of flake size objects
            afterquote_url : null, //url to redirect to after quote is viewed (optional)
            get_specific_address : null, //if false, request city only
            custom_flows: null, //array of custom flows for specific companies
            affiliate: null, //single affiliate company to notify. If demand requires, make into array in the future
            industry: 'concrete coatings', //industry of company
            custom_theme: null, //custom theme for company
            //-----------------------//
            //** Quote Information **//
            quoteid: '', //table key
            sidewallquote: {flake: null, solid: null},
            quote_loaded: false,
            fees: null, //extra costs for job not explicity broken down in package
            //-----------------------//
            //** General State Conditions **//
            haschatted: false, //true if user has chatted with GPT
            EMAIL_REGEX : new RegExp(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/
            ),
            PHONE_REGEX : new RegExp(  //1-xxx-xxx-xxxx, xxx-xxx-xxxx, xxxxxxxxxx, xxx xxx xxxx etc.
            /^(?:1(?:[ -]?\(\d{3}\)[ -]?\d{3}[ -]?\d{4}|\d{3}[ -]?\(\d{3}\)[ -]?\d{4})|\(\d{3}\)[ -]?\d{3}[ -]?\d{4}|\d{10}|\d{11}|\d{3}[ -]?\d{3}[ -]?\d{4})$/
            ),
            selected_customflow: null, //selected array for a specific company's custom flow, if applicable
            map_loaded: false,
        };
    }

    componentDidMount = () => {
        const id = this.props.subdomain;
        const {businessid} = this.state;
        if(!businessid){this.initBizID(id)};
        if(businessid){this.getCompanyInfo(businessid)};
        setMobileStyling(this.props.embedded || this.props.is_mobile_display);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.is_mobile_display !== this.props.is_mobile_display || prevProps.embedded !== this.props.embedded){
            setMobileStyling(this.props.embedded || this.props.is_mobile_display);
        }
    }

    initServiceType = (service) => {
        this.setState({
            servicetype: service,
        });
    }

    setMapLoaded = () => {
        console.log("Map loaded");
        this.setState({map_loaded: true});
    }

    initBizID = (id) => {
        this.setState({
            businessid: id,
        });
    }

    updateWindowDimensions = () => {
        this.setState({ win_width: window.innerWidth, win_height: window.innerHeight }); 
    }

    nextPage = (next, skip_current = null, override_logic=false) => { //next is the page to go to, skip_current is the page to skip, override_logic allows for custom flows to have custom pages before entering the default flow
        const {pages, areatype, estimate_reason} = this.state;
        const updated_pages = [...pages];
        let updated_estimate_reason = estimate_reason;
        console.log("skip_current: " + skip_current)
        console.log("override_logic: " + override_logic)
        // if in a custom flow as dicated by custom_flow.Flow, override all input next page and go pagetitle at next index in .Flow array
        // find next index by checking last item in pages, finding index of it in custom_flow.Flow, and then adding 1 to index if not last page
        // this method is better than tracking a seperate index value, because it will support non-linear custom flows if we ever get there
        // override_logic allows for custom flows to have custom pages before entering the default flow
        if ( !override_logic && areatype) { //custom flows are not entered before areatype is selected
            const customflow = findObjectByProperty(this.state.custom_flows, 'title', areatype.title);
            const has_nonstandard_flow = (customflow && customflow.Flow && customflow.Flow.length > 0) ? true : false;
            if(has_nonstandard_flow){
                //find index of a string in array of strings
                const index = customflow.Flow.indexOf(pages[pages.length - 1]);
                console.log("index of " + pages[pages.length -1] + ": " + index)
                if(index !== -1){
                    if(index === customflow.Flow.length - 1){ //If last page in custom flow, do nothing (Last page should usually be schedule or quotes, but just in case)
                        console.log("You've reached the end of the custom flow line!")
                        return;
                    }
                    else { //else go to next page in custom flow
                        next = customflow.Flow[index + 1];
                        if(next === "Schedule Estimate" || next === "Schedule Call"){
                            updated_estimate_reason = `Customer is interested in a ${areatype.title} quote`;
                        }
                    }
                } 
                // else, use default next page
            }

        }
        if (skip_current){
            updated_pages.pop()
        }
        console.log('Next page: ' + next)
        updated_pages.push(next);
        this.setState({
            pages: [...updated_pages],
            estimate_reason: updated_estimate_reason,
        }, this.checkValues());
    };

    prevPage = () => {
        const {pages} = this.state;
        pages.pop()
        this.setState({
            pages: [...pages]
        }, this.checkValues());
        
    };

    updateHasChatted = (value) => {
        this.setState({haschatted: value});
    }

    hasPreview() {
        //console.log('urlvalue: ' + urlvalue); 
        //console.log('typevalue: ' + typevalue);
        for(var i = 0; i < this.state.colors.length; i += 1) {
            if(this.state.colors[i].previews && this.state.colors[i].previews.length > 0) {
                //console.log(this.state.values.colors[i] + " has preview: " + this.state.values.colors[i].preview)
                return true;
            }
        }
        return false;
    }

    handleChange = input => e => {
        //console.log(e.target.id);
        console.log(e.currentTarget.id);
        let st = e.currentTarget.id;
        let val = e.target.value;
        if (val !== null && val !== '' && typeof val !== 'undefined'){
            st = e.target.value;
        }
        console.log("Handlechange")
        console.log(st)
        this.setState({[input]: st});
    };

    handleChangeNext = (next,input) => e => {
        console.log("HandleChangeNext")
        console.log(input);
        let st = e.currentTarget.id;
        console.log("id: " + st)
        let val = e.target.value;
        console.log("value: " + val)
        if (val !== null && val !== '' && typeof val !== 'undefined'){
            st = e.target.value;
        }
        console.log("changing " + input + " to " + st)
        //console.log(st)
        console.log(next)
        this.setState({[input]: st}, this.nextPage(next));
    };

    handleChangeObject = (input, ob) => {
        console.log("HandleChangeObject")
        console.log('setting ' + input + ' to ' + ob);
        this.setState({[input]: ob});
        console.log(this.state[input]);
        console.log(this.state.travel_distance);
    };

    handleChangeObjectNext = (next, input, ob, skip=false, override_logic=false) => {
        console.log("skipping: " + skip)
        this.setState({[input]: ob}, this.nextPage(next, skip, override_logic));
    };

    handleChangeEstimateReason = (reason, state_name=null, state_val=null, skip=false) => {
        if(state_name && state_val){ //update estimate reason plus another state element
            this.setState({estimate_reason: reason, [state_name]: state_val}, this.nextPage('Schedule Estimate', skip));
        }
        else { //just update the estimate reason
            this.setState({estimate_reason: reason}, this.nextPage('Schedule Estimate', skip));
        }
    };

    /*handleChangeToggle = () => e => {  //use for selecting/deselecting multiple options
        const {areatypes} = this.state;
        const val = e.target.value;

        if (!areatypes.includes(val) && val !== undefined){
            this.setState({areatypes: [...areatypes,val]});
        } else {
            const index = areatypes.indexOf(val);
            if (index > -1) {
                areatypes.splice(index, 1);
                this.setState({areatypes: [...areatypes]})
            }
        }
    };*/
    handleSaveQuote = async (tallied, services) => {
        /**MATOMO ANALYTICS**/
        //_paq.push(['trackEvent', 'Contact', 'Email Link Click', 'name@example.com']);
        window._paq.push(['trackGoal', 1]);
        /**END MATOMO **/

        //var id = Math.random().toString(16).slice(2);
        const { quoteid, email , customername, industry} = this.state;
        var addons = 'none';
        var base = '';
        for (var i = 0; i < services.length; i++){ //"Additional services"
            if (services[i].flat !== 'Included'){
                if (addons === 'none'){
                    addons = services[i].title;
                } else {
                    addons = addons + '; ' + services[i].title;
                }
            } else { //"Included services"
                if (i > 0){
                    base = base + '; ' + services[i].title;
                } else if (i === 0) {
                    base = services[i].title;
                }
            }
        }
        console.log(services);
        console.log(addons);
        console.log(base);
        const response = await axios.post(
            //'https://dilqskv43l3zmifawahozxuq4m0zqmbo.lambda-url.us-east-2.on.aws/', // !for testing purposes only!
          'https://h0a0i2dw7h.execute-api.us-east-2.amazonaws.com/prod/concreteQuoteFunction/savequote',
          { 
              id: quoteid,             
              email: email,
              industry: industry,
              //phone: phone, 
              totalquote: tallied,
              addons: addons,
              included: base,
              customername: customername,
            }
        );
        console.log(response);
    }

    handleScheduleJob = async (tallied, services) => {
        /**MATOMO ANALYTICS**/
        //_paq.push(['trackEvent', 'Contact', 'Email Link Click', 'name@example.com']);
        window._paq.push(['trackGoal', 2]);
        /**END MATOMO **/

        const { quoteid, email, phone, customername, preferredcontact,  address, industry } = this.state;
        var addons = 'none';
        var base = '';
        for (var i = 0; i < services.length; i++){ //"Additional services"
            if (services[i].flat !== 'Included'){
                if (addons === 'none'){
                    addons = services[i].title;
                } else {
                    addons = addons + '; ' + services[i].title;
                }
            } else { //"Included services"
                if (i > 0){
                    base = base + '; ' + services[i].title;
                } else if (i === 0) {
                    base = services[i].title;
                }
            }
        }
        console.log(services);
        console.log(addons);
        console.log(base);
        const response = await axios.post(
          'https://h0a0i2dw7h.execute-api.us-east-2.amazonaws.com/prod/concreteQuoteFunction/schedulejob',
          { 
              id: quoteid,
                industry: industry,
              email: email,
              phone: phone, 
              totalquote: tallied,
              addons: addons,
              included: base,
              customername: customername,
              preferredcontact: preferredcontact,
              streetaddress: address,
            }
        );
        console.log(response);
    }

    handleViewedQuote = async (finalcolor, tallied, services) => {
        console.log("handleViewedQuote");
       /**MATOMO ANALYTICS**/
        //_paq.push(['trackEvent', 'Contact', 'Email Link Click', 'name@example.com']);
        window._paq.push(['trackGoal', 4]);
        /**END MATOMO **/

        //var id = Math.random().toString(16).slice(2);
        const { areatype, sqfootage,garagesize,floorcondition,floorpitting, custom_theme, floorstaining, floorcracking,floorcracklength,sidewallheight, flakesize, stairs, floorjoints, businessid, quoteid, email , customername, phone, businessphone, branch, existingcoat, servicetype, businessname, sealant, businesslogo, address, affiliate, industry} = this.state;
        var colorobj = JSON.stringify(finalcolor);
        var f_size = (finalcolor.style.toLowerCase() === 'flake' && flakesize) ? ', ' + flakesize.value + ' inch' : '';
        var base = '';
        var addons = 'none';
        for (var i = 0; i < services.length; i++){ //"Additional services"
            if (services[i].flat !== 'Included'){
                if (addons === 'none'){
                    addons = services[i].title;
                } else {
                    addons = addons + '; ' + services[i].title;
                }
            } else { //"Included services"
                if (i > 0){
                    base = base + '; ' + services[i].title;
                } else if (i === 0) {
                    base = services[i].title;
                }
            }
        }
        console.log(services);
        console.log(addons);
        console.log(base);
        const response = await axios.post(
          'https://h0a0i2dw7h.execute-api.us-east-2.amazonaws.com/prod/concreteQuoteFunction/save-lead',
          { 
              id: quoteid,
              industry: industry,
              areatype: areatype.title,
              coating: finalcolor.style + ' ' + finalcolor.title + f_size,
              floorcondition: floorcondition,
              floorpitting: floorpitting,
              floorstaining: floorstaining,
              floorcracking: floorcracking,
              floorcracklength: floorcracklength,
              floorjoints: floorjoints, 
              garagesize: garagesize,
              sidewallheight: sidewallheight,
              stairs: stairs, 
              sqfootage: sqfootage, 
              email: email,
              phone: phone, 
              streetaddress: address,
              businessname: businessname,
              businessid: businessid,
              businessphone: branch.phone,
              businessemail: branch.email,
              businesslogo: businesslogo,
              custom_theme: custom_theme,
              //basecost: tallied,
              totalquote: tallied, //in this case, will be last quote selected by customer
              addons: addons,
              included: base,
              customername: customername,
              branch: branch.name,
              existingcoat: existingcoat,
              servicetype: servicetype.service,
              sealant: (sealant) ? sealant.title : '',
              affiliate: (affiliate && affiliate.should_refer) ? affiliate : '',
            }
        );
        console.log(response);
        if (response.data && response.data.id){
            console.log("Quote ID: " + response.data.id);
            this.setState({quoteid: response.data.id});
        }
    }

    handleScheduleEstimate = async (contact, leadtype) => {
        /**MATOMO ANALYTICS**/
        //_paq.push(['trackEvent', 'Contact', 'Email Link Click', 'name@example.com']);
        //window._paq.push(['trackGoal', 3]);
        /**END MATOMO **/

        const { estimate_reason, areatype, sqfootage,garagesize,floorcondition,floorpitting,floorstaining,floorcracking,floorcracklength,sidewallheight,stairs, floorjoints, businessid, industry, businessname, address, branch, existingcoat, servicetype, sealant, businesslogo } = this.state;
        var colors = null;
        if(this.state.colors){
            colors = '';
            for (var i = 0; i < this.state.colors.length; i++){
                colors = colors + this.state.colors[i].style + ' ' + this.state.colors[i].title + '; '
            }
            console.log(colors);
        }
        const response = await axios.post(
          'https://h0a0i2dw7h.execute-api.us-east-2.amazonaws.com/dev/concreteQuoteFunction/scheduleestimate', //(current prod)
          //'https://fok7u3r3f7.execute-api.us-east-2.amazonaws.com/dev/genquotefunction/scheduleestimate', //(dev)
            { 
                leadtype: leadtype, //i.e. call, estimate, job, error
                industry: industry,
                areatype: areatype.title,
                coating: colors,
                floorcondition: floorcondition,
                floorpitting: floorpitting,
                floorstaining: floorstaining,
                floorcracking: floorcracking,
                floorcracklength: floorcracklength,
                floorjoints: floorjoints, 
                garagesize: garagesize,
                sidewallheight: sidewallheight, 
                stairs: stairs,
                sqfootage: sqfootage, 
                email: contact.email,
                phone: contact.phone, 
                businessname: businessname,
                businessid: businessid,
                businessphone: branch.phone,
                businessemail: branch.email,
                businesslogo: businesslogo,
                customername: contact.customername,
                preferredcontact: contact.preferredcontact,
                streetaddress: contact.streetaddress,
                existingcoat: existingcoat,
                estimate_reason: estimate_reason,
                servicetype: (servicetype) ? servicetype.service : null,
                sealant: (sealant) ? sealant.title : null,
                affiliate: (this.state.affiliate && this.state.affiliate.should_refer) ? this.state.affiliate : null,
            }
        );
        console.log(response);
    }
    /*convertIncludeIfToMap = (addons) => {
        for(let i=0; i<addons.length; i++){
            if(addons[i].IncludeIf){
                const IncludeIf = new Map();
                for(let j=0; j < addons[i].IncludeIf.length; j++){
                    IncludeIf.set(addons[i].IncludeIf[])
                }
            }
        }
    }*/

    getCompanyInfo = async (id) => {
        //const {businessid, businessname, branch_options, areatype_options, base_items, addon_items, coat_options } = this.state;
        console.log("requesting company info...");
        console.log(id)
        const response = await  axios.get(
            'https://hg480kughk.execute-api.us-east-2.amazonaws.com/dev/companyInfoFunction',
            { 
                params: { business: id } 
            }
        );
        console.log(response);
        const data = JSON.parse(response.data);
        //console.log(data);
        //console.log(data.services);
        //console.log(data.Inpersons)
        const coats = JSON.parse(data.styles);
        const marketing_footer = (data.marketing_tag) ? JSON.parse(data.marketing_tag) : null;
        const flakes = (data.flake_sizes) ? JSON.parse(data.flake_sizes) : [];
        //console.log(coats);
        /*for (let j = 0; j < coats.length; j++){
            let currentcoat = coats[j];
            currentcoat.Styles = JSON.parse(currentcoat.Styles);
            for (let i = 0;  i < currentcoat.Styles.length; i++){
                let currentstyle = currentcoat.Styles[i];
                currentstyle.Colors = JSON.parse(currentstyle.Colors);
            }
        }*/
        /*let inpersons = JSON.parse(data.inpersons);
        for(let i=0; i<inpersons.length; i++){
            inpersons[i].condition = JSON.parse(inpersons[i].condition);
        }*/
        this.setState({
            businessname: data.name,
            businesslogo: data.logo,
            businesslogo_horizontal: data.logo_horizontal,
            branch_options: JSON.parse(data.branches),
            marketing_tag: marketing_footer,
            areatype_options: JSON.parse(data.areas),
            servicetype_options: JSON.parse(data.services),
            base_items: JSON.parse(data.base),
            addon_items: JSON.parse(data.addons),
            coat_options: coats,
            inpersons: JSON.parse(data.inpersons),
            nodisplays: JSON.parse(data.nodisplays),
            quote_range: data.quote_range,
            homepage: data.homepage,
            singlepackage: data.singlepackage,
            flake_sizes: flakes,
            afterquote_url: data.afterquote_url,
            get_specific_address: (data.travel_coef && data.travel_coef > 0) ? true : false,
            custom_flows: (data.custom_flows) ? JSON.parse(data.custom_flows) : null,
            affiliate: (data.affiliate) ? JSON.parse(data.affiliate) : null
        });
        console.log(this.state.colors);
    }

    getDBQuote = async () => {

        const { areatype, sqfootage,garagesize,floorcondition,floorpitting,floorstaining,floorcracking,floorcracklength,sidewallheight, stairs, colors, floorjoints, businessid, addon_items, base_items, sealant, travel_distance, fees, flakesize, existingcoat} = this.state;
        var colorset = JSON.stringify(colors);
        console.log("requesting quote...")
        const response = await axios.post(
            'https://h0a0i2dw7h.execute-api.us-east-2.amazonaws.com/prod/concreteQuoteFunction', //prod is pointing to v6 as of this note
            { 
                business: businessid,
                areatype: areatype, 
                sqfootage: sqfootage, 
                garagesize: garagesize, 
                floorcondition: floorcondition,
                floorpitting: floorpitting,
                floorstaining: floorstaining,
                floorcracking: floorcracking, 
                floorcracklength: floorcracklength,
                sealant: sealant,
                sidewallheight: sidewallheight, 
                stairs: stairs,
                colors: colorset,
                floorjoints: floorjoints,
                addons : addon_items,
                base : base_items,
                travel_distance: travel_distance,
                flakesize: flakesize,
            }
        );
        const data = JSON.parse(response.data);
        console.log(data);
        /*let swfloat = JSON.parse(data.sidewallquote);
        swfloat.flake = parseFloat(swfloat.flake);
        swfloat.solid  = parseFloat(swfloat.solid);*/
        for (let i = 0; i < data.colors.length; i++){
            console.log(data.colors[i])
            data.colors[i].price = parseFloat(data.colors[i].price);
        }
        this.setState({
            quoteid: data.id,
            floorquote: parseFloat(data.floorquote),
            jointquote: parseFloat(data.jointquote),
            //wallpaintquote: parseFloat(data.wallpaintquote),
            //sidewallquote: swfloat,
            colors: data.colors, //BUG when single flake color is selected, and after setstate colors.price is null. no impact (yet)
            sqfootage: data.sqfootage,
            addon_items: data.addons,
            base_items: data.base,
            fees: data.other_fees,
            quote_loaded: true
            });
        console.log(this.state.colors);
      }
      removeNotMatchingCoats = () => { //reset colors to only be valid ones given the current servicetype BUG: Does not update immediately (ex: home ext>quartz>home ext> flake >home ext> flake)
        /*let coat_index;
        const {coat_options, colors, servicetype} = this.state;
        for(var i = 0; i < coat_options.length; i += 1) {                                           //for all coat options
            if(servicetype && coat_options[i].service.toLowerCase() === servicetype.service.toLowerCase()){               //for coat options that match with current service
                for(var j = 0; j < coat_options[i].Styles.length; j++){                             //for all Styles
                    for(var k = 0; k < colors.length; k++){                                         //for all customer chosen colors
                        coat_index = coat_options[i].Styles[j].Colors.findIndex(function (color) { //color_index = index of color that matches a customer color
                            return color.icon === colors[k].icon;
                        });
                        console.log("index of " + colors[k].title + " is " + coat_index + " in " + coat_options[i].Styles[j].style)
                        if(coat_index === -1){                                                       //if not found (not valid), remove that customer color
                            colors.splice(k,1);
                        }
                    }
                }
            }
            this.setState ({colors: colors});
        }*/

    }
    hasAddon = (title) => {
        console.log("hasAddon searching for " + title)
        if(this.state.addon_items.some(a => a.title.toLowerCase() === title.toLowerCase())){
            console.log("..found")
            return true;
        }
        return false;
    }

    checkValues= () => { //ensures no conflicting user answers are submitted to DB
        const {inpersons} = this.state;
        if (!this.state.branch && this.state.branch_options && this.state.branch_options.length === 1){ //if only one branch option, set state to reflect current branch
            this.setState ({branch: this.state.branch_options[0]}); 
        }
        /*if (this.state.areatype && this.state.areatype.Services.length === 1){ //if only one service option, set state to reflect current servicetype
            const coats = this.state.coat_options;
            let service;
            for(let i = 0; i < coats.length; i++){
                if(coats[i].service.toLowerCase() === this.state.areatype.Services[0].service.toLowerCase()){
                    service = coats[i];
                }
            }
            this.initServiceType(service);
        }*/
        /*if (this.state.pages[this.state.pages.length - 1] !== 'Email Address' && this.state.quote_loaded){ 
            this.setState ({quote_loaded: false}); //refresh quote before loading
        }
        if (this.state.pages[this.state.pages.length - 1] !== 'Phone Number' && this.state.quote_loaded){ 
            this.setState ({quote_loaded: false}); //refresh quote before loading
        }*/
        if (this.state.pages[this.state.pages.length - 1] === 'Garage Size' && this.state.sqfootage !== null){ 
            this.setState ({knowssqfootage: 'false', sqfootage: null}); 
        }
        if (this.state.knowssqfootage === 'true' && this.state.sqfootage !== null && this.state.garagesize !== null){
             this.setState ({garagesize: null }); 
        }
        if (this.state.pages[this.state.pages.length - 1] === 'Floor Joints' && this.state.floorquote !== null){ 
            this.setState ({floorquote: null}); 
        }
        if (this.state.pages[this.state.pages.length - 1] === 'Services'){ //if user has changed area type or service, reset colors
            this.removeNotMatchingCoats();
        } 
        if (this.state.hasexistingcoat === 'false' && this.state.existingcoat !== null){
            this.setState ({existingcoat: null}); 
        }
        if (typeof this.state.sidewallheight === 'string' && this.state.sidewallheight !== null){
            const height = parseInt(this.state.sidewallheight);
            this.setState ({sidewallheight: height}); 
        }
        if (this.state.pages[this.state.pages.length - 1] === 'Finishes'){ //clear color/style selections if user changes service type
            this.setState ({colors: [], styletype: null}); 
        }
    };

    reRoute = (nextpage, state_var_arr) =>{ //used by custom flows to re-route to a new page after modifying some parent state values (passed in as array), as applicable.
        const newState = {};
        state_var_arr.forEach(item => {
            newState[item.title] = item.value;
        });

        // // modify pages and navigate to the next page
        // const updatedPages = [...this.state.pages];
        // updatedPages.pop(); // Remove the current page
        // newState['pages'] = updatedPages
    
        // Update state once with all changes
        this.setState(newState, () => this.nextPage(nextpage, true, true));
    };

    checkReferWhen = (values_customer) => {
        const {affiliate} = this.state
        console.log(affiliate)
        if (!affiliate){
            return false;
        }
        for (const [key, value] of Object.entries(affiliate.refer_when)){ 
            console.log("Checking refer_when " + key + ": " + value)
            let state_var;
            //check if key string ends in > or <, then check if value is greater or less than
            let is_greaterthan = false;
            let is_lessthan = false;
            if(key.includes(">")){
                is_greaterthan = true;
                state_var = values_customer[key.substring(0, key.length-1)]
            } else if (key.includes("<")){
                is_lessthan = true;
                state_var = values_customer[key.substring(0, key.length-1)]
            } else {
                state_var = values_customer[key]
            }

            const actual_value = state_var.title || state_var  //if item is object, check against .title for comparison value
            if (is_greaterthan){
                if(actual_value > value){ //if identical state property value does matches the excludeif condition
                    console.log(actual_value + ' isgreaterthan' + value)
                    return true;
                }
            } else if (is_lessthan){
                if(actual_value < value){ //if identical state property value does matches the excludeif condition
                    console.log(actual_value + ' islessthan' + value)
                    return true;
                }
            } else{ 
                if(actual_value === value){ //if identical state property value does matches the excludeif condition
                    return true;
                }
            }
        }
        return false;
    }

    prepareFinishChoices = () => {
        const { finishtype, coat_options } = this.state;
    
        if (!finishtype || !finishtype.styles) return [];
    
        return finishtype.styles.map(style => {
            const coats = findObjectByProperty(coat_options, "style", style, false);
            return {
                title: capatilizeFirstLetters(style),
                value: style,
                state_var: "styletype",
                image_icon: coats.sort_type === 'tiered' ? coats.Colors.tier1[0].icon : coats.Colors[0].icon,
            };
        });
    }

    render() {
        const is_mobile_display = (this.props.is_mobile_display || this.props.embedded) ? true : false;
        const { pages, win_width} = this.state;
        const {areatype, servicetype, finishtype, knowssqfootage, sqfootage, garagesize, floorcondition, floorpitting,floorstaining,floorcracking,floorcracklength, sidewallheight, hassidewall, stairs, hasstairs, colors, flakesize, floorjoints, businessid, quoteid, floorquote, jointquote, email, phone, customername, address, preferredcontact, sidewallquote, wallpaintquote, branch, hasexistingcoat, existingcoat, sealant} = this.state;
        const {businessname, businesslogo, businesslogo_horizontal, branch_options, marketing_tag, areatype_options, servicetype_options, base_items, addon_items, coat_options, inpersons, quote_range, homepage, nodisplays, singlepackage, flake_sizes, afterquote_url, get_specific_address} = this.state;
        const { fees, selected_customflow, custom_flows, affiliate, EMAIL_REGEX, PHONE_REGEX, industry, styletype } = this.state;
        const values_general = { 
            pages,
            EMAIL_REGEX,
            PHONE_REGEX
        };
        const values_customer = { //Customer Modified
            branch,
            areatype,
            servicetype,
            finishtype,
            styletype,
            knowssqfootage,
            sqfootage, 
            garagesize, 
            floorcondition,
            floorpitting,
            floorstaining,
            floorcracking,
            floorcracklength, 
            sidewallheight, 
            hassidewall, 
            stairs,
            hasstairs,
            colors, 
            flakesize,
            floorjoints, 
            email,
            phone,
            customername,
            address,
            preferredcontact,
            hasexistingcoat,
            existingcoat,
            sealant
        };
        const values_business = { //Company-specific Information
            businessid,
            businessname,
            businesslogo,
            businesslogo_horizontal,
            branch_options,
            marketing_tag,
            areatype_options,
            servicetype_options,
            base_items,
            addon_items,
            coat_options,
            inpersons,
            quote_range,
            homepage,
            singlepackage,
            flake_sizes,
            afterquote_url,
            get_specific_address,
            custom_flows,
            affiliate,
            industry,
            embedded: this.props.embedded //used by FooterContact
        };
        const values_quote = {  //modified by Quote API
            quoteid, 
            floorquote, 
            jointquote,
            sidewallquote,
            wallpaintquote, 
            fees
        };
        const values = 0;
        const hascolors = (values_customer.colors.length > 0 );

        let returnedPage = null;

        let currentpage = pages[pages.length -1];
        try{
            if(currentpage === "Schedule Estimate"){
                returnedPage = (
                    <div className='container'>
                        <Header
                            prevPage={this.prevPage} 
                            values_general={values_general}
                            values_business={values_business}
                            is_mobile_display = {is_mobile_display}
                        />
                        <ProgressBar
                            progress= '100'
                        />
                        <ScheduleContact
                            pagetype={'estimate'} 
                            handleScheduleEstimate={this.handleScheduleEstimate}
                            handleChangeObject={this.handleChangeObject} 
                            values_customer={values_customer}
                            values_business={values_business}
                            win_width = {win_width}        
                            is_mobile_display = {is_mobile_display}      
                            email_regex = {this.state.EMAIL_REGEX}
                            phone_regex = {this.state.PHONE_REGEX}     
                        />
                    </div>
                )
            }
            else if(currentpage === "Schedule Job"){
                returnedPage = (
                    <div className='container'>
                        <Header
                            prevPage={this.prevPage} 
                            values_general={values_general}
                            values_business={values_business}
                            is_mobile_display = {is_mobile_display}
                        />
                        <ProgressBar
                            progress= '100'
                        />
                        <ScheduleContact
                            pagetype={'job'} 
                            handleScheduleEstimate={this.handleScheduleEstimate}
                            handleChangeObject={this.handleChangeObject} 
                            values_customer={values_customer}
                            values_business={values_business}
                            win_width = {win_width}        
                            is_mobile_display = {is_mobile_display}      
                            email_regex = {this.state.EMAIL_REGEX}
                            phone_regex = {this.state.PHONE_REGEX}     
                        />
                    </div>
                )
            }
            else if(currentpage === "Schedule Call"){
                returnedPage = (
                    <div className='container'>
                        <Header
                            prevPage={this.prevPage} 
                            values_general={values_general}
                            values_business={values_business}
                            is_mobile_display = {is_mobile_display}
                        />
                        <ProgressBar
                            progress= '100'
                        />
                        <ScheduleContact
                            pagetype={'call'} 
                            handleScheduleEstimate={this.handleScheduleEstimate}
                            handleChangeObject={this.handleChangeObject} 
                            values_customer={values_customer}
                            values_business={values_business}
                            win_width = {win_width}        
                            is_mobile_display = {is_mobile_display}      
                            email_regex = {this.state.EMAIL_REGEX}
                            phone_regex = {this.state.PHONE_REGEX}     
                        />
                    </div>
                )
            }
            /*case "Initial":
                if(this.state.areatype_options){
                    return(
                        <div className='container'>
                            <MakeStatement
                                page_title = {'Initial Loading'}
                                handleChangeNext={this.handleChangeNext}
                                showpage = {true}
                                nextPage={this.nextPage}
                                next = {'Branch'}
                                pages={pages}
                                img_src = {'https://fq-concrete.s3.us-east-2.amazonaws.com/' + values_business.businesslogo}
                                header_statement = {`Get a no-fuss quote to your inbox in minutes, not days.`}
                                duration = {5000}
                            />
                        </div>
                    )
                }*/
            else if (currentpage === "Branch"){
                if (!this.state.areatype_options){
                    returnedPage = (
                        <div className='container'>
                            <Header
                                prevPage={this.prevPage} 
                                values_general={values_general}
                                values_business={values_business}  
                                is_mobile_display = {is_mobile_display}
                            />
                            <FontAwesomeIcon 
                                icon={faSyncAlt}
                                class='fa-spin spinner'
                            />
                        </div>
                    )
                } else {
                    returnedPage = (
                        <div className='container'>
                            <Header
                                prevPage={this.prevPage} 
                                values_general={values_general}
                                values_business={values_business}
                                is_mobile_display = {is_mobile_display}
                            />
                            <ProgressBar
                                progress= '0'
                            />
                            <Branch
                                handleChangeObjectNext={this.handleChangeObjectNext}
                                showpage = {this.state.branch_options.length > 1}
                                nextPage={this.nextPage}
                                pages={pages}
                                next = {'Job Type'}
                                values_customer={values_customer} 
                                values_business={values_business}  
                                is_mobile_display = {is_mobile_display}   
                            />
                        </div>
                    )
                }
            }
            else if (currentpage === "Job Type"){
                returnedPage = (
                    <div className='container'>
                        <Header
                            prevPage={this.prevPage} 
                            values_general={values_general}
                            values_business={values_business}
                            is_mobile_display = {is_mobile_display}
                        />
                        <ProgressBar
                            progress= '3'
                        />
                        <AreaType
                            handleChangeObjectNext={this.handleChangeObjectNext}
                            nextPage={this.nextPage}
                            pages={pages}
                            values_business={values_business}  
                            values_customer={values_customer}
                            is_mobile_display = {is_mobile_display}    
                            next = {'Services'}                    
                        />
                    </div>
                )
            }
            else if (currentpage === "Services"){
                returnedPage = (
                    <div className='container'>
                        <Header
                            prevPage={this.prevPage} 
                            values_general={values_general}
                            values_business={values_business} 
                            is_mobile_display = {is_mobile_display}
                        />
                        <ProgressBar
                            progress= '14'
                        />
                        <ServiceType
                            page_title = {'Services'}
                            handleChangeObjectNext={this.handleChangeObjectNext}
                            handleChangeEstimateReason={this.handleChangeEstimateReason}
                            pages={pages}
                            values_business={values_business}  
                            values_customer={values_customer} 
                            is_mobile_display = {is_mobile_display}     
                            next = {'Square Footage'}                    
                        />
                    </div>
                )
            }
            /*case "Sealant":
                let sealant_options = this.getSealants() //DEPRECIATED: getSealants needs to be updated to match new DB strucure
                if(sealant_options.length > 0){
                    return(
                        <div className='container'>
                            <Header
                                prevPage={this.prevPage}
                                values_general={values_general}
                            values_business={values_business} 
                            />
                            <ProgressBar
                                progress= '37'
                            />
                            <Sealant
                                handleChangeObject={this.handleChangeObject}
                                values_customer={values_customer} 
                                next = 'Square Footage'
                                sealants = {sealant_options}            
                            />
                            <ContinueFooter
                                nextPage={this.nextPage}
                                enable = {this.state.sealant}
                                nextpage = 'Square Footage'
                            />
                        </div>
                    )
                } else {
                    this.skipPage("Sealant", "Square Footage");
                }*/
            else if (currentpage === "Square Footage"){
                returnedPage = (
                    <div className='container'>
                        <Header
                            prevPage={this.prevPage}
                            values_general={values_general}
                            values_business={values_business}  
                            is_mobile_display = {is_mobile_display}
                        />
                        <ProgressBar
                            progress= '22'
                        />
                        <SquareFootage
                            nextPage={this.nextPage}
                            next = 'Sidewalls'
                            handleChange={this.handleChange}
                            handleChangeEstimateReason={this.handleChangeEstimateReason}
                            values_customer={values_customer}     
                            values_business={values_business} 
                            is_mobile_display = {is_mobile_display}      
                            pages={pages}             
                        />
                    </div>
                )
            }
            else if (currentpage === 'Striping') { //accessed through "square footage" page
                returnedPage = (
                    <div className='container'>
                        <Header
                            prevPage={this.prevPage}
                            values_general={values_general}
                            values_business={values_business}  
                            is_mobile_display = {is_mobile_display}
                        />
                        <ProgressBar
                            progress= '22'
                        />
                        <SquareFootageStriping
                            nextPage={this.nextPage}
                            next = 'Sidewalls'
                            handleChange={this.handleChange}
                            handleChangeEstimateReason={this.handleChangeEstimateReason}
                            values_customer={values_customer}     
                            values_business={values_business} 
                            is_mobile_display = {is_mobile_display}           
                        />
                    </div>
                )
            }
            else if (currentpage === "Garage Size"){
                returnedPage = (
                    <div className='container'>
                        <Header
                            prevPage={this.prevPage}
                            values_general={values_general}
                            values_business={values_business} 
                            is_mobile_display = {is_mobile_display}
                        />
                        <ProgressBar
                            progress= '26'
                        />
                        <GarageSize
                            nextPage={this.nextPage}
                            next = {"Sidewalls"}
                            handleChangeNext={this.handleChangeNext}
                            values_customer={values_customer}
                            values_business={values_business} 
                            is_mobile_display = {is_mobile_display}                       
                        />
                    </div>
                )
            }
            else if (currentpage === "Sidewalls"){
                returnedPage = (
                    <div className='container'>
                        <Header
                            prevPage={this.prevPage}
                            values_general={values_general}
                            values_business={values_business}  
                            is_mobile_display = {is_mobile_display}
                        />
                        <ProgressBar
                            progress= '30'
                        />
                        <Sidewalls
                            nextPage={this.nextPage}
                            next = {"Existing Coats"}
                            handleChangeNext={this.handleChangeNext}
                            handleChange={this.handleChange}
                            handleChangeEstimateReason={this.handleChangeEstimateReason}
                            values_customer={values_customer} 
                            values_business={values_business} 
                            is_mobile_display = {is_mobile_display}
                            pages={pages}                      
                        />
                    </div>
                )
            }
            else if (currentpage === "Existing Coats"){
                    returnedPage = (
                        <div className='container'>
                            <Header
                                prevPage={this.prevPage}
                                values_general={values_general}
                                values_business={values_business}  
                                is_mobile_display = {is_mobile_display}
                            />
                            <ProgressBar
                                progress= '38'
                            />
                            <ExistingCoat
                                handleChangeNext={this.handleChangeNext}
                                handleChange={this.handleChange}
                                handleChangeEstimateReason={this.handleChangeEstimateReason}
                                next = {'Floor Condition'}
                                values_customer={values_customer}  
                                values_business={values_business} 
                                is_mobile_display = {is_mobile_display}                     
                            />
                            {(existingcoat) ?                   
                            
                                <ContinueFooter
                                    nextPage={this.nextPage}
                                    enable = {true}
                                    nextpage = 'Floor Condition'
                                /> : ''
                            }
                        </div>
                    )
            }
            else if (currentpage === "Floor Condition"){
                returnedPage = (
                    <div className='container'>
                        <Header
                            prevPage={this.prevPage}
                            values_general={values_general}
                            values_business={values_business}  
                            is_mobile_display = {is_mobile_display}
                        />
                        <ProgressBar
                            progress= '42'
                        />
                        <FloorCondition
                            handleChangeObjectNext={this.handleChangeObjectNext}
                            handleChangeEstimateReason={this.handleChangeEstimateReason}
                            showpage = {isShownPage(nodisplays,'Floor Condition', areatype.title, values_customer)}
                            nextPage={this.nextPage}
                            next =  {'Floor Pitting'}
                            values_customer={values_customer}
                            values_business={values_business}
                            is_mobile_display = {is_mobile_display}
                            pages={pages}                             
                        />
                    </div>
                )
            }
            else if (currentpage === "Floor Pitting"){
                returnedPage = (
                    <div className='container'>
                        <Header
                            prevPage={this.prevPage}
                            values_general={values_general}
                            values_business={values_business}  
                            is_mobile_display = {is_mobile_display}
                        />
                        <ProgressBar
                            progress= '46'
                        />
                        <FloorPitting
                            handleChangeNext={this.handleChangeNext}
                            handleChangeEstimateReason={this.handleChangeEstimateReason}
                            showpage = {isShownPage(nodisplays,'Floor Pitting', areatype.title, values_customer)}
                            nextPage={this.nextPage}
                            next =  {'Floor Staining'}
                            values_customer={values_customer}
                            values_business={values_business}
                            is_mobile_display = {is_mobile_display}
                            pages={pages}                             
                        />
                    </div>
                ) 
            }
            else if (currentpage === "Floor Staining"){
                returnedPage = (
                    <div className='container'>
                        <Header
                            prevPage={this.prevPage}
                            values_general={values_general}
                            values_business={values_business}  
                            is_mobile_display = {is_mobile_display}
                        />
                        <ProgressBar
                            progress= '46'
                        />
                        <FloorStaining
                            handleChangeNext={this.handleChangeNext}
                            handleChangeEstimateReason={this.handleChangeEstimateReason}
                            showpage = {isShownPage(nodisplays,'Floor Staining', areatype.title, values_customer)}
                            nextPage={this.nextPage}
                            next =  {'Floor Cracking'}
                            values_customer={values_customer}
                            values_business={values_business}
                            is_mobile_display = {is_mobile_display}
                            pages={pages}                             
                        />
                    </div>
                )
            }
            else if (currentpage === "Floor Cracking"){
                returnedPage = (
                    <div className='container'>
                        <Header
                            prevPage={this.prevPage}
                            values_general={values_general}
                            values_business={values_business}  
                            is_mobile_display = {is_mobile_display}
                        />
                        <ProgressBar
                            progress= '46'
                        />
                        <FloorCracking
                            handleChangeNext={this.handleChangeNext}
                            handleChangeEstimateReason={this.handleChangeEstimateReason}
                            showpage = {isShownPage(nodisplays,'Floor Cracking', areatype.title, values_customer)}
                            nextPage={this.nextPage}
                            next =  {'Floor Crack Length'}
                            values_customer={values_customer}
                            values_business={values_business}
                            is_mobile_display = {is_mobile_display}
                            pages={pages}                             
                        />
                    </div>
                )
            }
            else if (currentpage === "Floor Crack Length"){
                returnedPage = (
                    <div className='container'>
                        <Header
                            prevPage={this.prevPage}
                            values_general={values_general}
                            values_business={values_business}  
                            is_mobile_display = {is_mobile_display}
                        />
                        <ProgressBar
                            progress= '54'
                        />
                        <FloorCrackLength
                            nextPage={this.nextPage}
                            next = {"Floor Joints"}
                            showpage = {isShownPage(nodisplays,'Floor Crack Length', areatype.title, values_customer)}
                            handleChangeNext={this.handleChangeNext}
                            handleChange={this.handleChange}
                            handleChangeEstimateReason={this.handleChangeEstimateReason}
                            values_customer={values_customer} 
                            values_business={values_business} 
                            is_mobile_display = {is_mobile_display}
                            pages={pages}                      
                        />
                    </div>
                )
            }
            else if (currentpage === "Floor Joints"){
                returnedPage = (
                    <div className='container'>
                        <Header
                            prevPage={this.prevPage}
                            values_general={values_general}
                            values_business={values_business} 
                            is_mobile_display = {is_mobile_display}
                        />
                        <ProgressBar
                            progress= '56'
                        />
                        <FloorJoints
                            nextPage={this.nextPage}
                            handleChangeNext={this.handleChangeNext}
                            next = {'Obstructions'}
                            showpage = {this.hasAddon("seamless floor")}
                            values_customer={values_customer} 
                            values_business={values_business}  
                            is_mobile_display = {is_mobile_display}   
                            pages={pages}               
                        />
                    </div>
                )
            }
            else if (currentpage === "Obstructions"){
                returnedPage = (
                    <div className='container'>
                        <Header
                            prevPage={this.prevPage}
                            values_general={values_general}
                            values_business={values_business}  
                            is_mobile_display = {is_mobile_display}
                        />
                        <ProgressBar
                            progress= '61'
                        />
                        <Obstructions
                            nextPage={this.nextPage}
                            handleChangeNext={this.handleChangeNext}
                            handleChangeEstimateReason={this.handleChangeEstimateReason}
                            showpage = {isShownPage(nodisplays,'Obstructions', areatype.title, values_customer)}
                            pages={pages}      
                            next = {'Stairs'}
                            values_customer={values_customer}  
                            values_business={values_business}     
                            is_mobile_display = {is_mobile_display}                 
                        />
                    </div>
                )
            }
            else if (currentpage === "Stairs"){
                returnedPage = (
                    <div className='container'>
                        <Header
                            prevPage={this.prevPage}
                            values_general={values_general}
                            values_business={values_business}  
                            is_mobile_display = {is_mobile_display}
                        />
                        <ProgressBar
                            progress= '63'
                        />
                        <Stairs
                            nextPage={this.nextPage}
                            next = {"Finishes"}
                            handleChange={this.handleChange}
                            handleChangeNext={this.handleChangeNext}
                            showpage = {isShownPage(nodisplays,'Stairs', areatype.title, values_customer)}
                            values_customer={values_customer} 
                            values_business={values_business}
                            is_mobile_display = {is_mobile_display} 
                            pages={pages}                      
                        />
                    </div>
                )
            }
            // else if (currentpage === "Finishes Info"){ 
            //     const finishes_with_info = this.getFinishesWithInfo();
            //     console.log(finishes_with_info)
            //     returnedPage = (
            //         <div className='container'>
            //             <Header
            //                 prevPage={this.prevPage} 
            //                 values_general={values_general}
            //                 values_business={values_business}
            //                 is_mobile_display = {is_mobile_display}
            //             />
            //             <ProgressBar
            //                 progress= '64'
            //             />
            //             <MakeStaticStatement
            //                 page_title = {'Finishes Info'}
            //                 handleChangeNext={this.handleChangeNext}
            //                 showpage = {finishes_with_info.length > 1}
            //                 nextPage={this.nextPage}
            //                 next = {'Finishes'}
            //                 pages={pages}
            //                 header_statement = {`Here's the types of coats we offer for your ${areatype.title} ${servicetype.service} project`}
            //                 subheader_statement = {`..and a few of their differences.`}
            //                 custom_component = {this.getFinishEducationComponent()}
            //                 values_customer={values_customer}
            //                 values_business={values_business}  
            //                 is_mobile_display = {is_mobile_display}
            //                 //gpt_prompts = {suggested_gpt_prompts}
            //             />
            //             <ContinueFooter
            //                 nextPage={this.nextPage}
            //                 enable = {true}
            //                 nextpage = 'Finishes'
            //                 //skip_history = {true}
            //                 alternate_text = {`I'm Ready To Choose`}
            //             />
            //         </div>
            //     )
            // }
            else if (currentpage === "Finishes"){ 
                returnedPage = (
                    <div className='container'>
                        <Header
                            prevPage={this.prevPage} 
                            values_general={values_general}
                            values_business={values_business}
                            is_mobile_display = {is_mobile_display}
                        />
                        <ProgressBar
                            progress= '67'
                        />
                        <FinishType
                            handleChangeObjectNext={this.handleChangeObjectNext}
                            handleChangeEstimateReason={this.handleChangeEstimateReason}
                            nextPage={this.nextPage}
                            pages={pages}
                            values_business={values_business}  
                            values_customer={values_customer}   
                            is_mobile_display = {is_mobile_display}   
                            next = {'Styles'}                    
                        />
                    </div>
                )
            }
            else if (currentpage === "Styles"){
                console.log("StyleType")
                console.log(this.state.styletype)
                console.log(finishtype)
                returnedPage = (
                    <div className='container'>
                        <Header

                            prevPage={this.prevPage}
                            values_general={values_general}
                            values_business={values_business}
                            is_mobile_display = {is_mobile_display}
                        />
                        <ProgressBar
                            progress= '70'
                        />
                        <MultipleChoiceText
                            key={currentpage}
                            page_title = {'Styles'}
                            handleChangeObjectNext={this.handleChangeObjectNext}
                            handleChangeEstimateReason={this.handleChangeEstimateReason}
                            showpage = {finishtype && finishtype.styles.length > 1}
                            nextPage={this.nextPage}
                            default_next = {"Colors"}
                            header_question = {`What color style of ${finishtype.finish} would you like?`}
                            default_state_var = {'styletype'}
                            choices = {this.prepareFinishChoices()}
                            values_customer={values_customer} 
                            values_business={values_business}
                            is_mobile_display={is_mobile_display}
                            selection_required = {true}
                        />
                    </div>
                )
            }
            else if (currentpage === "Colors"){
                returnedPage = (
                    <div className='container'>
                        <Header
                            prevPage={this.prevPage} 
                            values_general={values_general}
                            values_business={values_business}  
                            is_mobile_display = {is_mobile_display}
                        />
                        <ProgressBar
                            progress= '75'
                        />
                        <ColorPicker
                            handleChangeObject={this.handleChangeObject}
                            nextPage={this.nextPage}
                            nextpage = 'Color Statement'     
                            values_customer={values_customer}  
                            values_business={values_business} 
                            is_mobile_display = {is_mobile_display}
                            colors={values_customer.colors}      
                            styletype = {this.state.styletype}     
                            coat_options = {this.state.coat_options}   
                            pages = {pages}      
                        />
                        <ContinueFooter
                            nextPage={this.nextPage}
                            enable = {hascolors}
                            nextpage = 'Color Statement'
                        />
                    </div>
                )
            }
            else if (currentpage === "Color Statement"){
                returnedPage = (
                    <div className='container'>
                        <MakeStatement
                            page_title = {'Coating Choice'}
                            handleChangeNext={this.handleChangeNext}
                            showpage = {this.state.businessid === 'croccoatings'}
                            nextPage={this.nextPage}
                            next = {'Flake Size'}
                            pages={pages}
                            img_src = {(businessid==='croccoatings') ? 'https://fq-concrete.s3.us-east-2.amazonaws.com/CrocCoatings/warranty-icon.png' : null }
                            header_statement = {`Good choice! Our Polyurea coatings are 4x more durable than our competitors' epoxy coatings...`}
                            values_customer={values_customer}
                            values_business={values_business}  
                        />
                    </div>
                )
            }
            else if (currentpage === "Flake Size"){
                const style = colors[0].style.toLowerCase();
                returnedPage = (
                    <div className='container'>
                        <Header
                            prevPage={this.prevPage} 
                            values_general={values_general}
                            values_business={values_business}
                            is_mobile_display = {is_mobile_display}
                        />
                        <ProgressBar
                            progress= '80'
                        />
                        <MultipleChoiceText
                            page_title = {'Flake Size'}
                            handleChangeObjectNext={this.handleChangeObjectNext}
                            showpage = { ( style.includes("flake (single-size)") || style === 'flake') && flake_sizes && flake_sizes.length > 1 } //display if company offers differentiated flake pricing and if flakes are selected. "Single-size" differentiates by color, not size from hybrid flake sizes.
                            nextPage={this.nextPage}
                            default_next = {'Affiliate'}
                            pages={pages}
                            header_question = {'What flake size would you like?'}
                            default_state_var = {'flakesize'}
                            choices = {flake_sizes}
                            aboutthis = {[         
                                {title: 'What should I consider when choosing?', text: <p className="small spaced light">Many people choose their flake size based on the aesthetic they most desire, but 
                                a larger number of flakes means more floor texture, which increases floor traction and sound dampening.</p>
                                }
                            ]}
                            values_customer={values_customer} 
                            values_business={values_business}  
                            is_mobile_display = {is_mobile_display}
                        />
                    </div>
                )
            }
            else if (currentpage === 'Affiliate'){
                const aff_true = { ...affiliate, should_refer: true };
                const aff_false = { ...affiliate, should_refer: false };
                
                returnedPage = (

                    <div className='container'>
                        <Header
                            prevPage={this.prevPage} 
                            values_general={values_general}
                            values_business={values_business}
                            is_mobile_display = {is_mobile_display}
                        />
                        <ProgressBar
                            progress= '85'
                        />
                        <MultipleChoiceText
                            key = {'Affiliate'}
                            page_title = {'Affiliate'}
                            handleChangeObjectNext={this.handleChangeObjectNext}
                            showpage = { this.checkReferWhen(values_customer) } 
                            nextPage={this.nextPage}
                            default_next = {'Job Address'}
                            pages={pages}
                            header_question = {(affiliate) ? `Are you interested in getting a separate quote for ${affiliate.industry} from our partner, ${affiliate.title}?` : null}
                            default_state_var = {'affiliate'}
                            choices = {[
                                {title: "Yes", value: aff_true},
                                {title: "No", value: aff_false}
                            ]}
                            values_customer={values_customer} 
                            values_business={values_business} 
                            is_mobile_display = {is_mobile_display} 
                        />   
                    </div>
                )
            }
            /*case "Preview Coatings": 
                    returnedPage = (
                        <div className='container'>
                            <Header
                                prevPage={this.prevPage} 
                                values_general={values_general}
                                values_business={values_business}  
                                is_mobile_display = {is_mobile_display}
                            />
                            />
                            <ProgressBar
                                progress= '83'
                            />
                            <ColorPreview
                                handleChangeObject={this.handleChangeObject}
                                nextPage={this.nextPage}
                                showpage = {this.hasPreview()}
                                pages={pages}      
                                nextpage = 'Job Address'
                                colors={values_customer.colors} 
                                values_business={values_business} 
                                is_mobile_display = {is_mobile_display}                      
                            />
                            <ContinueFooter
                                nextPage={this.nextPage}
                                enable = {true}
                                nextpage = 'Job Address'
                            />
                        </div>
                    )
                    break;*/
            else if (currentpage === "Job Address"){
                returnedPage = (
                    <div className='container'>
                        <Header
                            prevPage={this.prevPage}
                            values_general={values_general}
                            values_business={values_business}  
                            is_mobile_display = {is_mobile_display}
                        />
                        <ProgressBar
                            progress= '87'
                        />
                        {/*<CollectAddress
                            handleChangeObject={this.handleChangeObject}
                            handleChangeNext={this.handleChangeNext}
                            nextPage={this.nextPage}
                            next = {'Email Statement'}
                            showpage = {isShownPage(nodisplays,'Collect Address', areatype.title, values_customer)}
                            values_customer={values_customer}   
                            values_business={values_business}
                            is_mobile_display = {is_mobile_display}    
                            pages = {pages}                                        
                />*/}
                        <Places
                            handleChangeNext = {this.handleChangeNext}
                            handleChangeObject = {this.handleChangeObject}
                            handleUpdateDistance = {this.handleChangeObject}
                            nextPage={this.nextPage}
                            next = {'Email Statement'}
                            showpage = {isShownPage(nodisplays,'Collect Address', areatype.title, values_customer)}
                            values_customer={values_customer}   
                            values_business={values_business}
                            is_mobile_display = {is_mobile_display}    
                            pages = {pages}
                            destination_state_var = {'address'}
                            source_stat_val = {values_customer.branch.address}     
                        />
                    </div>
                )
            }
            else if (currentpage === "Email Statement"){
                returnedPage = (
                    <div className='container'>
                        <MakeStatement
                            page_title = {'Email Statement'}
                            handleChangeNext={this.handleChangeNext}
                            showpage = {!values_customer.email}
                            nextPage={this.nextPage}
                            next = {(values_business.businessid !== 'croccoatings') ? 'Email Address' : 'Phone Number'}
                            pages={pages}
                            header_statement = {`Your quote is ready! We just need some information first to prove you're not a robot...`}
                            values_customer={values_customer}
                            values_business={values_business}  
                        />
                    </div>
                )  
            }
            else if (currentpage === "Email Address"){
                returnedPage = (
                    <div className='container'>
                        <Header
                            prevPage={this.prevPage}
                            values_general={values_general}
                            values_business={values_business}  
                            is_mobile_display = {is_mobile_display}
                        />
                        <ProgressBar
                            progress= '96'
                        />
                        <CollectContact
                            handleChange={this.handleChange}
                            nextPage={this.nextPage}
                            next = {'Quote'}
                            showpage = {true}
                            page_title = {"Email Address"}
                            pages={pages}
                            values_customer={values_customer} 
                            values_business={values_business}
                            is_mobile_display = {is_mobile_display}
                            getDBQuote={this.getDBQuote}
                            contact_placeholder = 'Email'
                            regex = {this.state.EMAIL_REGEX}  
                            enable = {true}                                                  
                        />
                    </div>
                )
            }
            else if (currentpage === "Phone Number"){
                returnedPage = (
                    <div className='container'>
                        <Header
                            prevPage={this.prevPage}
                            values_general={values_general}
                            values_business={values_business}  
                            is_mobile_display = {is_mobile_display}
                        />
                        <ProgressBar
                            progress= '96'
                        />
                        <CollectContact
                            handleChange={this.handleChange}
                            nextPage={this.nextPage}
                            next = {'Quote'}
                            showpage = {true}
                            page_title = {"Phone Number"}
                            pages={pages}
                            values_customer={values_customer} 
                            values_business={values_business}
                            is_mobile_display = {is_mobile_display}
                            getDBQuote={this.getDBQuote}
                            contact_placeholder = 'Phone'
                            regex = {this.state.PHONE_REGEX}    
                            enable = {true}                                               
                        />
                    </div>
                )
            }
            else if (currentpage === "Quote"){
                if(this.state.quote_loaded){
                    returnedPage = (
                        <div className='container'>
                            <Header
                                prevPage={this.prevPage}
                                values_general={values_general}
                                values_business={values_business}  
                                is_mobile_display = {is_mobile_display}
                            />
                            <ProgressBar
                                progress= '100'
                            />
                            <QuotePackages_v2
                                handleChange={this.handleChange}
                                handleChangeObject={this.handleChangeObject}
                                handleSaveQuote={this.handleSaveQuote}
                                handleScheduleJob={this.handleScheduleJob}
                                handleViewedQuote={this.handleViewedQuote}
                                values_customer={values_customer}
                                values_business={values_business}
                                values_quote={values_quote} 
                                addon_items={values_business.addon_items}
                                singlepackage={values_business.singlepackage}
                                colors={(this.state.colors) ? this.state.colors : null}    
                                is_mobile_display = {is_mobile_display}    
                                email_regex = {this.state.EMAIL_REGEX}
                                phone_regex = {this.state.PHONE_REGEX}
                            />
                        </div>
                    )            
                } else {
                    returnedPage = (
                        <div className='container'>
                            <Header
                                prevPage={this.prevPage}
                                values_general={values_general}
                                values_business={values_business}  
                                is_mobile_display = {is_mobile_display}
                            />
                            <LoadingQuote/>
                        </div>
                    )
                }
            }

            /*************************** */
            /*** CUSTOM FLOW PAGES ONLY */ 
            // Custom flows are company specific flows, intiated from AREAS page. They are not typical to the "standard" flow, though they may ask some questions and route back there. //
            else if (currentpage && currentpage.includes("Custom Flow")){ 
                // *** Grinder Rental: ask if homeowner or contractor, then send to in person estimate page ***
                if (currentpage.includes("Grinder Rental")){ 
                    returnedPage = (
                        <div className='container'>
                            <Header
                                prevPage={this.prevPage}
                                values_general={values_general}
                                values_business={values_business}  
                                is_mobile_display = {is_mobile_display}
                            />
                            <ProgressBar
                                progress= '85'
                            />
                            <MultipleChoiceText
                                page_title = {'Grinder Rental'}
                                handleChangeObjectNext={this.handleChangeObjectNext}
                                showpage = {true}
                                nextPage={this.nextPage}
                                default_next = {'Schedule Call'}
                                pages={pages}
                                header_question = {'Which best describes you?'}
                                default_state_var = {'estimate_reason'}
                                choices = {[
                                    {title: 'Homeowner', value: 'Homeowner requested an estimate for Grinder Rental.'},
                                    {title: 'Contractor', value: 'Contractor requested an estimate for Grinder Rental.'}
                                ]}
                                values_customer={values_customer} 
                                values_business={values_business}
                                is_mobile_display = {is_mobile_display}
                            />
                        </div>
                    )
                }  
                // *** Grind Prep Services: ask if homeowner or contractor, send to inperson if contractor, otherwise go to "Price Statement" page ***
                else if (currentpage.includes("Grind Prep Service (Garage)")){
                    returnedPage = (
                        <div className='container'>
                            <Header
                                prevPage={this.prevPage}
                                values_general={values_general}
                                values_business={values_business}  
                                is_mobile_display = {is_mobile_display}
                            />
                            <ProgressBar
                                progress= '85'
                            />
                            <MultipleChoiceText
                                page_title = {'Custom Flow: Grind Prep Service (Garage)'}
                                handleChangeObjectNext={this.handleChangeObjectNext}
                                showpage = {true}
                                nextPage={this.nextPage}
                                default_next = {'Price Statement'}
                                pages={pages}
                                header_question = {'Which best describes you?'}
                                default_state_var = {'area'}
                                choices = {[
                                    {title: 'Homeowner', 
                                        value: findObjectByProperty(areatype_options, "title", "Garage"), 
                                        nextpage: "Custom Flow: Grinding Price Statement", 
                                        state_var: "areatype"
                                    },
                                    {title: 'Contractor', 
                                        value: 'Contractor requested an estimate for Grinder Rental.', 
                                        nextpage: "Schedule Call", 
                                        state_var: "estimate_reason"
                                    }
                                ]}
                                values_customer={values_customer} 
                                values_business={values_business}
                                is_mobile_display = {is_mobile_display}
                            />
                        </div>
                    )
                }
                else if (currentpage.includes("Grinding Price Statement")){ //declare a price and ask if customer wants to continue
                    returnedPage = (
                        <div className='container'>
                            <Header
                                prevPage={this.prevPage}
                                values_general={values_general}
                                values_business={values_business}  
                                is_mobile_display = {is_mobile_display}
                            />
                            <ProgressBar
                                progress= '85'
                            />
                            <MultipleChoiceText
                                key={'Grinding Price Statement'}
                                page_title = {'Custom Flow: Grinding Price Statement'}
                                handleChangeObjectNext={(next, state_var, state_value) => this.handleChangeObjectNext(next, state_var, state_value, null, true)}
                                showpage = {true}
                                nextPage={this.nextPage}
                                default_next = {'Schedule Call'}
                                pages={pages}
                                header_question = {'We charge $4.00 per square foot for professional grinding prep. Would you like to continue?'}
                                default_state_var = {'area'}
                                choices = {[
                                    {title: 'I have more questions', 
                                        value: findObjectByProperty(areatype_options, "title", "Garage"), 
                                        nextpage: "Schedule Call", 
                                        state_var: "areatype"
                                    },
                                    {title: `Sounds great, let's talk`, 
                                        value: 'Homeowner requested a new Grinding Prep Services job.', 
                                        nextpage: "Schedule Call", 
                                        state_var: "estimate_reason"
                                    }
                                ]}
                                values_customer={values_customer} 
                                values_business={values_business}
                                is_mobile_display = {is_mobile_display}
                            />
                        </div>
                    )
                }
                // *** DIY Pro Package, show itemized list, then go down custom flow ***
                else if (currentpage.includes("Custom Flow: DIY Itemized")){
                    returnedPage = (
                        <div className='container'>
                            <Header
                                prevPage={this.prevPage}
                                values_general={values_general}
                                values_business={values_business}
                                is_mobile_display = {is_mobile_display} 
                            />
                            <ProgressBar

                                progress= '85'
                            />
                            <LongTextPage
                                page_title = {'Custom Flow: DIY Itemized'}
                                hide_gpt = {true}
                                values_business={values_business}
                                values_customer={values_customer}
                                header_text = {`The DIY Pro Package includes everything you need:`}
                                text_items = {[
                                    {
                                        header: "All Materials Required For Your Project",
                                        bullets: [
                                            "Professional industrial-grade materials from Slab Line with Hi-Tech Certified systems"
                                        ]
                                    },
                                    {
                                        header: "All Sundries Needed for Installation",
                                        bullets: [
                                            "Frames",
                                            "Sleeves",
                                            "Poles",
                                            "Brushes",
                                            "Paint Block Tapes",
                                            "Duct Tape",
                                            "Acetone",
                                            "Rags",
                                            "Buckets",
                                            "Stir Sticks",
                                            "Measuring Cups"
                                        ]
                                    },
                                    {
                                        header: "Rental Tools (free use with deposit returned on tool return)",
                                        bullets: [
                                            "Drill",
                                            "Blower",
                                            "Vacs",
                                            "Magic Trowel",
                                            "Spike Shoes",
                                            "Mixing Paddle"
                                        ]
                                    },
                                    {
                                        header: "Professional Training Videos & Help",
                                        bullets: [
                                            "24/7 Access to our online training videos, specific to your system",
                                            "On call help or video chat with our experienced Hi-Tech Certified Technicians"
                                        ]
                                    }
                                ]}
                            />
                            <ContinueFooter
                                nextPage={this.nextPage}
                                enable = {true}
                                nextpage = 'Services'
                                //alternate_text = "Next"
                            />
                        </div>
                    )
                }
                else if (currentpage.includes("DIY Project")){
                    returnedPage = (
                        <div className='container'>
                            <Header
                                prevPage={this.prevPage}
                                values_general={values_general}
                                values_business={values_business}  
                                is_mobile_display = {is_mobile_display}
                            />
                            <ProgressBar
                                progress= '85'
                            />
                            <MultipleChoiceText
                                key={'Custom Flow: DIY Project'}
                                page_title = {'Custom Flow: DIY Project'}
                                handleChangeObjectNext={(next, state_var, value) => this.handleChangeObjectNext(next, state_var, value, null, true)}
                                showpage = {true}
                                default_next = {'Services'}
                                pages={pages}
                                header_question = {'What kind of DIY help are you looking for?'}
                                default_state_var = {'customflowtype'}
                                choices = {[
                                    {title: 'Professional Grind Prep (Garage)', 
                                        value: {title: "Grind Prep Service (Garage)", value: "Grind Prep Service (Garage)"}, 
                                        nextpage: "Custom Flow: Grind Prep Service (Garage)", 
                                        state_var: "areatype"
                                    },
                                    {title: 'DIY Pro Package', 
                                        value: findObjectByProperty(custom_flows, "title", "DIY Project"), 
                                        nextpage: "Custom Flow: DIY Itemized",
                                        state_var: "areatype"
                                    }
                                ]}
                                values_customer={values_customer} 
                                values_business={values_business}
                                is_mobile_display = {is_mobile_display}
                            />
                        </div>
                    )
                }

            }
            /*** END CUSTOM FLOW PAGES */
            /*************************** */

    
            else {
                /* return (
                    <div className='container'>
                        <Header
                            prevPage={this.prevPage} 
                            values_general={values_general}
                            values_business={values_business}  
                            is_mobile_display = {is_mobile_display}
                        />
                        <FontAwesomeIcon 
                            icon={faSyncAlt}
                            class='fa-spin spinner'
                        />
                    </div>
                )*/
                const statements = [
                    {
                        statement : `Hi! Let's get you a personalized quote in minutes.`,
                        //top_img_src : gpt_icon,
                        duration: 3000
                    },
                    {
                        statement : `No appointments necessary.`,
                        //top_img_src : gpt_icon,
                        duration: 2000
                    },
                    /*{
                        statement : `Anytime you have a question for me, just find this icon:`,
                        top_img_src : gpt_icon,
                        bottom_img_src : assistant_icon,
                        duration: 3000
                    },*/
                ]
                returnedPage = (
                    <div className='container'>
                        <MakeMultiStatement
                            page_title = {'Initial Load Page'}
                            handleChangeNext={this.handleChangeNext}
                            showpage = {true}
                            nextPage={this.nextPage}
                            next = {'Branch'}
                            pages={pages}
                            statements = {statements}
                            duration = {5000}
                        />

                    </div>
                )
            }
        } catch (err) {
            console.log("Error in ConcreteCoatingSurvey.js: ")
            console.log(err);
            returnedPage = (
                <div className='container'>
                    <Header
                        prevPage={this.prevPage} 
                        values_general={values_general}
                        values_business={values_business}
                        is_mobile_display = {is_mobile_display}
                    />
                    <ProgressBar
                        progress= '100'
                    />
                    <ScheduleContact
                        pagetype={'error'} 
                        handleScheduleEstimate={this.handleScheduleEstimate}
                        handleChangeObject={this.handleChangeObject} 
                        values_customer={values_customer}
                        values_business={values_business} 
                        is_mobile_display = {is_mobile_display}      
                        email_regex = {this.state.EMAIL_REGEX}
                        phone_regex = {this.state.PHONE_REGEX}     
                    />
                </div>
            )
        }
    
        return(
            <SurveyContext.Provider value={{ haschatted: this.state.haschatted, updateHasChatted : this.updateHasChatted }}>
                <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} onLoad={this.setMapLoaded} libraries={this.props.google_maps_libraries}>
                    {this.state.map_loaded ? //wait for Error Boundary to render until map is loaded
                        <ErrorBoundary
                            key={pages}
                            handleScheduleEstimate={this.handleScheduleEstimate}
                            handleChangeObject={this.handleChangeObject} 
                            prevPage={this.prevPage}
                            values_customer={values_customer}
                            values_business={values_business}  
                            values_general={values_general}
                            is_mobile_display = {is_mobile_display}      
                            email_regex = {this.state.EMAIL_REGEX}
                            phone_regex = {this.state.PHONE_REGEX}  
                        >   
                            {returnedPage}
                        </ErrorBoundary> 
                        :{returnedPage}
                    }
                </LoadScript>
            </SurveyContext.Provider>
            )
    }
}

export default ConcreteCoatingSurvey;
