//Displays statement as a pulsing laod screen. Duration is calculated based on the length of the statement, or on "duration" prop if provided.

import React, {Component} from 'react';
import {AboutModal} from '../questions/Modals/AboutModal.js';
import { CSSTransition } from 'react-transition-group';
import { FooterLogo } from './FooterLogo.js';
import Matomo from '../Matomo.js';

export class MakeStatement extends Component {
    constructor (props) {
        super(props);
        this.state = {
            showmodal : false,
            nextpage : props.next,
            mounted : false
        }
    }

    componentDidMount = () => {
        /*-Matomo Analytics-*/
        const title = this.props.page_title;
        Matomo(title);
        /*----End Matomo----*/
        
        //Skip to next page if necessary
        if(!this.props.showpage){
            this.continue(true);
        } else {
            this.setState({ mounted: true });
            console.log("setting timeout");
            const duration = this.props.duration || this.props.header_statement.length * 40;
            console.log(duration)
            setTimeout(() => this.continue(true), duration); // Use arrow function syntax to pass the function reference
        }
        this.setState({ mounted: true });
    }

    continue = (skip=false) =>{
        this.props.nextPage(this.state.nextpage, skip);
    };

    render() { 
        const { img_src} = this.props;
        const { state_var, header_statement, header_class_size, animation_effect} = this.props;
        const image = (img_src) ? <img src={img_src} className='pulse-img' /> : null;
        let animation_class = (animation_effect) ? animation_effect : 'pulse-text';
        console.log('header statement: ' + header_statement)
        return (
            <div className='surveywrapper'>
                <CSSTransition
                        in={this.state.mounted}
                        timeout={1000}
                        classNames="fade"
                        unmountOnExit 
                        appear
                >
                    <div className='column make-statement'>
                        <div className='pulse-text-fullscreen'>
                            {image}
                            <p className='invis x-small'>.</p>
                            <h2 className={'center ' + animation_class + ' ' + header_class_size}>{header_statement} </h2>
                        </div>
                    </div>
                </CSSTransition>
            </div>
        )
    }
}

export default MakeStatement;