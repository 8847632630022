import React, {Component} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faWarehouse, faBuilding, faHome, faSwimmer, faHouseUser, faRoadSpikes, faGem, faRoad, faUser, faTruckPickup, faHandshake, faChevronRight, faChevronLeft} from '@fortawesome/free-solid-svg-icons'
import Matomo from '../Matomo';
import { CSSTransition, Transition } from 'react-transition-group';

import { values } from 'lodash';
import { FooterContact } from '../general_questions/FooterContact';
import { findIndexByProperty, findObjectByProperty } from '../../Helpers';
import MultipleChoiceText from '../general_questions/MultipleChoiceText';


export class AreaType extends Component {

    constructor () {
        super();
        this.state = {
            mounted: false,
            all_choices: [],
        }
    }

    componentDidMount = () => {
        // /*-Matomo Analytics-*/
        // const title = 'AreaType';
        // Matomo(title);
        // /*----End Matomo----*/

        //Skip to next page if only one option:
        if(this.props.values_business && this.props.values_business.areatype_options.length === 1){
            const event = new Event("service");
            event.data=this.props.values_business.areatype_options[0];
            this.continue(this.props.next, true)(event);
        }
        
        let all_choices = this.props.values_business.areatype_options.concat(
            Array.isArray(this.props.values_business.custom_flows) ? this.props.values_business.custom_flows : []
        );
        this.setState({ mounted: true, all_choices: [...all_choices] });
    }

    getFaIcon = (title) => {

        switch(title.toLowerCase()){
            case "garage":
            case "traditional epoxy":
                return faWarehouse;
            case "commercial space":
                return faBuilding;
            case "pool deck":
                return faSwimmer;
            case "home exterior":
            case "exterior project":
                return faHome;
            case "home interior":
            case "interior project":
                return faHouseUser;
            case "concrete polishing":
                //return faHandSparkles;
                return faGem;
            case "epoxy floor striping":
            case "line striping":
                return faRoad;
            case "diy project":
            case "diy garage":
                return faUser;
            case "grinder rental":
                return faTruckPickup;
            case "grind prep service (garage)":
                //return faRoadSpikes;
                return faHandshake;

        }
    }
    
    getNextPageTitle = (area_title) => {
        //if area title is included anywhere in custom_flow array, return the next page title
        const {custom_flows} = this.props.values_business;
        console.log(area_title + " clicked")
        if(custom_flows && custom_flows.length > 0) {
            const iscustom = findIndexByProperty(custom_flows, 'title', area_title);
                if(iscustom > -1){
                    return "Custom Flow: " + area_title;
                }
        }
        //else return the default next page title
        return this.props.next;
    }

    render() { 
        const { handleChangeObjectNext, nextPage, next, values_business, values_customer, is_mobile_display, pages } = this.props;
        const { all_choices, visibleItemsCount, scrollPosition, scrollOffset, itemWidth } = this.state;

        return (
            
            <MultipleChoiceText
                key={this.state.all_choices}
                page_title = {'Area Type'}
                handleChangeObjectNext={handleChangeObjectNext}
                showpage = {true}
                nextPage={nextPage}
                default_next = {next}
                header_question = {'What kind of work are you interested in?'}
                default_state_var = {'areatype'}
                choices = {all_choices.map((area) => (
                    {title: area.title,
                        icon: this.getFaIcon(area.title),
                        value: findObjectByProperty(values_business.areatype_options, "title", area.title), 
                        nextpage: this.getNextPageTitle(area.title), 
                        state_var: "areatype"
                    }
                ))}
                values_customer={values_customer} 
                values_business={values_business}
                is_mobile_display={is_mobile_display}
            />
        )
    }
}

export default AreaType;