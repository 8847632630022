import React, {Component} from 'react';
import { CSSTransition } from 'react-transition-group';

import housePic from './housegarage.jpg'
import Matomo from '../Matomo';
import { FooterContact } from '../general_questions/FooterContact';

export class GarageSize extends Component {

    constructor (props) {
        super(props);
        this.state = {
            showabout : false,
            showfaq : false,
            nextpage: props.next,
            mounted: false,
        }    
        this.getButtonActivity = this.getButtonActivity.bind(this);
        this.showAboutModal = this.showAboutModal.bind(this);
        this.hideAboutModal = this.hideAboutModal.bind(this);
        this.showFaqModal = this.showFaqModal.bind(this);
        this.hideFaqModal = this.hideFaqModal.bind(this);
    }

    componentDidMount = () => {
        /*-Matomo Analytics-*/
        const title = 'GarageEstimate';
        Matomo(title);
        /*----End Matomo----*/
        this.setState({ mounted: true });
    }

    showAboutModal = () => { // ?when passed in a specific state value to change, exceeds max update depth..?
        this.setState({ showabout: true });
    };
    
    hideAboutModal = () => {
        this.setState({ showabout: false });
    };

    showFaqModal = () => {
        this.setState({ showfaq: true });
    };
    
    hideFaqModal = () => {
        this.setState({ showfaq: false });
    };

    getButtonActivity = val =>{
        var saved = this.props.values_customer.garagesize;
        if (saved===val) {
            return 'columnbtn active';
        }
        return 'columnbtn';
    };


    render() { 
        const { handleChangeNext, values_business, values_customer, is_mobile_display} = this.props;
        //const garage = 'Garage Size';
    
        return (
                <div className ='surveywrapper'>
                    <div className='largeimagewrapper'>
                        <img class='coverphoto' src= {housePic}/>
                    </div>
                    <div className='questionwrapper'>
                        <CSSTransition
                            in={this.state.mounted}
                            timeout={1000}
                            classNames={is_mobile_display ? "fade" : "slide-to-left"}
                            unmountOnExit 
                            appear
                        >
                            <div>
                                <div className='textwrapper'>
                                    <h2 className='large'> Please select your garage size. </h2>
                                </div>
                                <div className='buttonwrapper'>
                                    <button
                                        type='button' 
                                        class={this.getButtonActivity('1')}
                                        value='1'
                                        onClick={handleChangeNext(this.state.nextpage,'garagesize')}>
                                        1 Car
                                    </button>
                                    <button
                                        type='button' 
                                        class={this.getButtonActivity('2')}
                                        value='2'
                                        onClick={handleChangeNext(this.state.nextpage,'garagesize')}>
                                        2 Car
                                    </button>
                                    <button
                                        type='button' 
                                        class={this.getButtonActivity('3')}
                                        value='3'
                                        onClick={handleChangeNext(this.state.nextpage,'garagesize')}>
                                        3 Car
                                    </button>
                                    <button
                                        type='button' 
                                        class={this.getButtonActivity('3RV')}
                                        value='3RV'
                                        onClick={handleChangeNext(this.state.nextpage,'garagesize')}>
                                        3 Car + RV Storage
                                    </button>
                                </div>                               
                            </div>
                        </CSSTransition>
                        <FooterContact values_business={values_business} phone={values_customer.branch.phone}/>
                    </div>
                </div>
        )
    }
}

export default GarageSize;