import React, {Component} from 'react';
import {AboutModal} from '../questions/Modals/AboutModal.js';
import { CSSTransition } from 'react-transition-group';
import { FooterLogo } from './FooterLogo.js';
import Matomo from '../Matomo.js';
import { PreviewModal } from '../questions/Modals/PreviewModal.js';
import GPTModal from './modals/GPTmodal.js'
import AI_Assistant_Icon from '../general_questions/images/J.png'
import { FooterContact } from './FooterContact.js';
import { findObjectByProperty, sendToInpersons } from '../../Helpers.js';
import MultipleChoiceGPTButton from './components/MultipleChoiceGPTButton.js';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight, faImage} from '@fortawesome/free-solid-svg-icons';
import ButtonWithDescription from './components/ButtonWithDescription.js';
import ButtonWithIcon from './components/ButtonWithIcon.js';
import ButtonWithImageIcon from './components/ButtonWithImageIcon.js';
import StepCounter from './components/StepCounter.js';

//Incremental counter for a given state variable


export class StepCounterQuestion extends Component {
    constructor (props) {
        super(props);
        this.state = {
            showmodal : false,
            default_nextpage : props.default_next,
            mounted : false,
            err: null,
            local_step_number: 0,
            modal_gpt_prompts: props.gpt_prompts
        }
    }

    componentDidMount = () => {
        const {default_nextpage} = this.state;
        const {page_title, showpage} = this.props;

        /*-Matomo Analytics-*/
        const title = page_title;
        Matomo(title);
        /*----End Matomo----*/
        
        //Skip to next page if necessary
        console.log('showpage: ' + showpage)
        if(!showpage){
            console.log('skipping page: ' + title)
            this.props.nextPage(default_nextpage, true);
        }
        this.setState({ mounted: true });
    }

    showModal = (prompts, intro_query=null) => {
        document.body.style.overflow = 'hidden';
        this.setState({ showmodal: true, modal_gpt_prompts: prompts, modal_intro_query: intro_query});
    };
    
    hideModal = () => {
        document.body.style.overflow = 'auto';
        this.setState({ showmodal: false });
    };

    showPreviewModal = () => e =>{
        document.body.style.overflow = 'hidden';
        this.setState({ showpreviewmodal: e.currentTarget.id });
    };

    hidePreviewModal = () => {
        document.body.style.overflow = 'auto';
        this.setState({ showpreviewmodal: '' });
    };

    getPreviewModal = (choice) => {
        const {is_mobile_display} = this.props;
        if(!choice || !choice.preview){
            return;
        }
        const title = choice.title;
        const url = choice.preview;
        const content = {title: title, src :url};
        const show = (this.state.showpreviewmodal === title);
        return(
            <PreviewModal
                show={show} 
                handleClose={() => this.hidePreviewModal()} 
                info={content}
                is_mobile_display={is_mobile_display}>
            </PreviewModal>
        )
    }

    getAboutModal = (aboutthis) => {
        if(!aboutthis){
            return;
        }
        return <AboutModal show={this.state.showmodal} handleClose={this.hideModal} info={aboutthis}/>
    }

    handleIncrementClick = () => {
        const {handleChangeObject} = this.props;
        const {state_var, values_customer} = this.props;
        let new_val = values_customer[state_var];
        let new_local_val = this.state.local_step_number;
        if( typeof new_val !== 'number'){
            new_val = parseFloat(new_val);
        }
        new_val += 1;
        new_local_val += 1;
        this.setState({local_step_number: new_local_val}, handleChangeObject(state_var, new_local_val));
    }

    handleDecrementClick = () => {
        const {handleChangeObject} = this.props;
        const {state_var, values_customer} = this.props;
        let new_val = values_customer[state_var];
        let new_local_val = this.state.local_step_number;
        if( typeof new_val !== 'number'){
            new_val = parseFloat(new_val);
        }
        new_val = Math.max(0, new_val - 1); //prevent negative values
        new_local_val = Math.max(0, new_local_val - 1);
        this.setState({local_step_number: new_local_val}, handleChangeObject(state_var, new_val));
    }

    handleChange = () => e => {
        const {handleChangeObject} = this.props;
        const {state_var, values_customer} = this.props;
        console.log(e.target.value)
        let state_val = values_customer[state_var];
        //get previous value to see if value increased or decreased
        let prev_val = this.state.local_step_number;
        let new_val = parseFloat(e.target.value);
        if(isNaN(e.target.value) || !e.target.value){
            new_val = 0;
        }
        //undo last change, add new change
        new_val = Math.max(0, state_val - prev_val + new_val); //prevent negative values
        this.setState({local_step_number: e.target.value}, handleChangeObject(state_var, new_val));
    }




    render() { 
        const { handleChangeObjectNext} = this.props;
        const {state_var, header_question, values_business, values_customer, placeholder, gpt_prompts, modal_intro_query} = this.props;
        const { modal_gpt_prompts, local_step_number, all_choices } = this.state;
        
        if (this.state.err) throw this.state.err;
        return (
            <div className='surveywrapper'>
                <div className='multiplechoice-transitionbuffer'>
                {this.state.showpreviewmodal ? this.getPreviewModal(findObjectByProperty(all_choices, 'title', this.state.showpreviewmodal)) : null}
                {this.state.showmodal ? <GPTModal handleClose={this.hideModal} prompts={gpt_prompts} intro_query={modal_intro_query} displayclass={"modal display-block"} company_name={this.props.values_business.businessname} industry={values_business.industry}/> : null}
                    <CSSTransition
                        in={this.state.mounted}
                        timeout={1000}
                        classNames="fade"
                        unmountOnExit 
                        appear
                    >
                        <div className='multiplechoice'>
                            <div className='textwrapper'>
                                <h2 className='large'>{header_question}</h2>
                                {/*<AboutModal show={this.state.showmodal} handleClose={this.hideModal} info={aboutthis}/>*/}
                                <p className='clickable display-flex-row' onClick={this.showModal}><img className='assistant-icon' src={AI_Assistant_Icon} alt='chat icon'/>&nbsp;What counts as a stair or step?</p>
                            </div>
                            <StepCounter
                                handleIncrementClick={this.handleIncrementClick}
                                handleDecrementClick={this.handleDecrementClick}
                                handleChange={this.handleChange}
                                state_var={state_var}
                                state_val={local_step_number}
                                placeholder={placeholder}
                            />
                        </div>
                    </CSSTransition>
                    <FooterContact values_business={values_business} phone={values_customer.branch.phone} hasbuttonbelow={true}/>
                </div>
            </div>
        )
    }
}

export default StepCounterQuestion;