import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import { CSSTransition } from 'react-transition-group';
import Matomo from '../../Matomo';

const PreviewModal = ({ handleClose, show, children, info, ismobile }) => {

  const showHideClassName = show ? "modal display-block" : "modal display-none";

  /*-Matomo Analytics-*/
    if (show){
      const title = info.title;
      Matomo(title);
  }
  /*----End Matomo----*/

  const getHeader = (header) => {
    if (!ismobile) {
        return (
            <div className='preview-title'>
                <div className='display-flex-row'>
                    <div className='display-flex-column'>
                        <h4 className='medium no-margin'>{header}</h4>
                    </div>
                </div>
            </div>
        )
    } else {
        return(
            <div className='preview-title'>
                <div className='display-flex-column'>
                    <div className= 'display-flex-row justify-left'>
                        <h4 className='medium'>{header}</h4>
                    </div>
                </div>
            </div>
        )
    }
  }

  const scrollToCenterOfImage = (containerSelector, imageSelector) => {
    // Get the container and image elements
    var container = document.querySelector(containerSelector);
    var image = document.querySelector(imageSelector);
    console.log(container, image);
  
    if (container && image) {
      // Calculate the center position
      console.log(image.offsetWidth, container.offsetWidth);
      console.log(image.offsetHeight, container.offsetHeight);
      var centerX = (image.offsetWidth - container.offsetWidth) / 2;
      var centerY = (image.offsetWidth - container.offsetHeight) / 2;
        console.log(centerX, centerY);
      // Scroll the container to the center of the image
      container.scrollLeft = centerX;
      container.scrollTop = centerY;
    } else {
      console.error('Container or image not found');
    }
  }

  return (
    <div className={showHideClassName}>
      <CSSTransition in={true} timeout={300} classNames="fade-fast" unmountOnExit>
        <div className="modal display-flex-row">
            <section className="modal-preview-fullscreen no-margin">
                <div key={info.title} className='fullimagewrapper-modal'>
                    <div className='modal-header-row'>
                        {getHeader(info.title)}
                        <div className="close-modal-icon" onClick={handleClose}>
                            <FontAwesomeIcon 
                                className='large no-margin'
                                icon={faTimes}
                                color='black'
                            />
                        </div>
                    </div>
                    <div className='display-flex-row justify-none'>
                      <img
                          className='preview-photo non-square'
                          src={info.src}
                          alt={info.title}
                          onLoad={() => {
                              scrollToCenterOfImage('.fullimagewrapper-modal', '.preview-photo');
                          }}
                      />
                  </div>
                </div>
            </section>
        </div>
    </CSSTransition>
    </div>
  );
};

export {PreviewModal};