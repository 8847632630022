import React, {Component} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faWarehouse, faBuilding, faHome, faSwimmer, faHouseUser, faHandSparkles, faGem, faRoad, faUser, faTruckPickup, faHandshake, faCheck} from '@fortawesome/free-solid-svg-icons'
import Matomo from '../Matomo';
import { CSSTransition } from 'react-transition-group';
import { FooterLogo } from './FooterLogo';
import { values } from 'lodash';
import { FooterContact } from './FooterContact';
import GPTModal from './modals/GPTmodal'
import AI_Assistant_Icon from '../general_questions/images/J.png'
import MultipleChoiceText from './MultipleChoiceText';
import { findIndexByProperty, findObjectByProperty } from '../../Helpers';
import { text } from '@fortawesome/fontawesome-svg-core';

export class LongTextPage extends Component {

    constructor () {
        super();
        this.state = {
            mounted: false,
        }
    }

    componentDidMount = () => {
        const {page_title, show} = this.props
        /*-Matomo Analytics-*/
        Matomo(page_title);
        /*----End Matomo----*/

        //Skip to next page:
        if(show){
            const event = new Event("service");
            event.data=this.props.values_business.jobtype_options[0];
            this.continue(this.props.next, true)(event);
        }

        this.setState({ mounted: true });
    }


    render() { 
        const { header_text, values_business, values_customer, text_items, modal_gpt_prompts, modal_intro_query, hide_gpt, gpt_prompts} = this.props;
        console.log(text_items[0].header)
        return (
            
            <div className='surveywrapper' style={{overflow: 'auto'}}>
                <div className='itemizedlist'>
                {this.state.showmodal  ? <GPTModal handleClose={this.hideModal} prompts={modal_gpt_prompts} intro_query={modal_intro_query} displayclass={"modal display-block"} company_name={this.props.values_business.businessname} industry={values_business.industry}/> : null}
                    <CSSTransition
                            in={this.state.mounted}
                            timeout={1000}
                            classNames="fade"
                            unmountOnExit 
                            appear
                    >
                        <div>
                            
                            <div className='textwrapper'>
                                <h2 className='large'> {header_text} </h2>
                                {!hide_gpt && <p className='clickable display-flex-row' onClick={() => this.showModal(gpt_prompts)}><img className='assistant-icon' src={AI_Assistant_Icon} alt='chat icon'/>&nbsp;Ask anything about this</p>}
                            </div>
                            {text_items.map((item) => {
                                return (
                                    <div className='main'>
                                        <div className='item-header'>
                                            <h4 className='m-small no-margin'>{item.header}</h4>
                                        </div>
                                        {item.bullets && item.bullets.map((bullet) => {
                                            return (<div className='item-bullet'>
                                                        <FontAwesomeIcon icon={faCheck} className='icon'/>
                                                        <p className='small no-margin'>{bullet}</p>
                                                    </div>
                                            )
                                    })}
                                </div>
                            )})}
                            
                        </div>
                    </CSSTransition>
                    {/* <FooterContact values_business={values_business} phone={values_customer.branch.phone}/> */}
                </div>
            </div>
        )
    }
}

export default LongTextPage;