//Displays statement as a pulsing laod screen. Duration is calculated based on the length of the statement, or on "duration" prop if provided.

import React, {Component} from 'react';
import { CSSTransition } from 'react-transition-group';
import Matomo from '../Matomo.js';
import { findIndexByProperty } from '../../Helpers.js';
import RollUpItem from './components/animations/RollUpItem.js';

export class MakeMultiStatement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showmodal: false,
            nextpage: props.next,
            mounted: false,
            current_statement: props.statements[0],
            showskip: false,
        };
    }

    componentDidMount = () => {
        /*-Matomo Analytics-*/
        const title = this.props.page_title;
        Matomo(title);
        /*----End Matomo----*/

        // Skip to next page if necessary
        if (!this.props.showpage) {
            this.continue(true);
        } else {
            this.setState({ mounted: true });
            // console.log('setting timeout');
            // const duration = this.props.duration || this.props.header_statement.length * 40;
            // console.log(duration);
            // setTimeout(() => this.continue(), duration); // Use arrow function syntax to pass the function reference
        }
        this.setState({ mounted: true });
        this.setNextStatement();
        this.timeoutId = setTimeout(() => {this.setState({showskip: true})}, 2000);
    };

    componentWillUnmount() {
        clearTimeout(this.timeoutId);
        clearTimeout(this.timeoutId_other);
    }
    

    continue = (skip=false) => {
        this.props.nextPage(this.state.nextpage, skip);
    };

    setNextStatement = () => {
        console.log('setting next statement');
        const { statements } = this.props;
        const { current_statement } = this.state;
        const index = findIndexByProperty(statements, 'statement', current_statement.statement);
        const nextIndex = index + 1;
        console.log('index: ' + index);
        console.log('nextIndex: ' + nextIndex);
        const duration = current_statement.duration || current_statement.statement.length * 80; //wait duration of current statement before changing to next statement
        console.log('duration: ' + duration);
        if (nextIndex < statements.length && nextIndex !== -1) { //next index is in range and exists
            console.log('setting duration of ' + duration + ' for statement ' + index);
            this.timeoutId_other = setTimeout(() => {
                this.setState({ current_statement: statements[nextIndex] }, this.setNextStatement);
            }, duration);
        } else if (nextIndex === statements.length) {
            this.timeoutId_other = setTimeout(() => {
                this.continue();
            }, duration);
        } else {
            console.log('nextIndex is out of range');
            this.continue();
        }
    }

    getSkipButton = () => {
        if (this.state.showskip) {
            return (
                <p className='m-small no-margin right underline light' onClick={() => this.continue()}>Next</p>
            );
        } else{
            return (
                <p className='m-small no-margin right underline deselected'>Next</p>
            )
        }
    }
    

    render() {
        const { statements } = this.props; // statements is a list of objects with keys: statement, img_src, duration
        const statement_item = <p className='roll-up-text pulse-text large no-margin'>{this.state.current_statement.statement}</p>       
        const top_img = (this.state.current_statement.top_img_src) ? 
                <img src={this.state.current_statement.top_img_src} alt='' className='top-img' /> 
            : <div className='top-img'/>;
        const bottom_img_item = (this.state.current_statement.bottom_img_src) ?
                <img src={this.state.current_statement.bottom_img_src} alt='' className='bottom-img pulse-img' /> 
            : <div className='bottom-img'/>;
        const bottom_img = (this.state.current_statement.bottom_img_src) ? 
                <RollUpItem item={bottom_img_item} total_duration={(this.state.current_statement.duration || this.state.current_statement.statement.length * 80) - 2000} delay_time={2000}/>
            : <div className='bottom-img'/>;
            
        return (
            <div className='surveywrapper'>
                    <div className='column make-statement'>
                        <div className='pulse-text-fullscreen width-100' key={this.state.current_statement.statement}>
                            <div className='display-flex-column width-100'>
                                {top_img}
                                <RollUpItem item={statement_item} total_duration={this.state.current_statement.duration || this.state.current_statement.statement.length * 80} />
                                {bottom_img}
                            </div>
                            {this.getSkipButton()}
                        </div>
                        
                    </div>
            </div>
        );
    }
}

export default MakeMultiStatement;
