
import React, {Component} from 'react';
import {AboutModal} from '../questions/Modals/AboutModal.js';
import { CSSTransition } from 'react-transition-group';
import { FooterLogo } from './FooterLogo.js';
import Matomo from '../Matomo.js';
import { PreviewModal } from '../questions/Modals/PreviewModal.js';
import GPTModal from './modals/GPTmodal.js'
import { FooterContact } from './FooterContact.js';
import { findObjectByProperty, sendToInpersons } from '../../Helpers.js';


class AngleSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
        angle: 90, // Initial angle of 90 degrees
        };
    }

    componentDidMount = () => {
        const {default_nextpage, angle} = this.state;
        const {page_title, showpage, handleChangeObject, state_var} = this.props;

        /*-Matomo Analytics-*/
        const title = page_title;
        Matomo(title);
        /*----End Matomo----*/
        
        //Skip to next page if necessary
        console.log('showpage: ' + showpage)
        if(!showpage){
            console.log('skipping page: ' + title)
            this.props.nextPage(default_nextpage, true);
        }
        handleChangeObject(state_var, angle); //initialize value in parent component
        this.setState({ mounted: true });
    }

    showModal = (prompts, intro_query=null) => {
        document.body.style.overflow = 'hidden';
        this.setState({ showmodal: true, modal_gpt_prompts: prompts, modal_intro_query: intro_query});
    };

    hideModal = () => {
        document.body.style.overflow = 'auto';
        this.setState({ showmodal: false });
    };

    getAboutModal = (aboutthis) => {
        if(!aboutthis){
            return;
        }
        return <AboutModal show={this.state.showmodal} handleClose={this.hideModal} info={aboutthis}/>
    }

  handleAngleChange = (event) => {
    const {handleChangeObject, state_var} = this.props;
    this.setState({ angle: event.target.value }, handleChangeObject(state_var, event.target.value));
  };

  render() {
    const { angle } = this.state;
    const {state_var, header_question, values_business, values_customer, placeholder} = this.props;
    const { modal_intro_query, modal_gpt_prompts, local_step_number, all_choices } = this.state;
    // Calculate the second line's end point based on the angle
    // Assuming a fixed length of 100 units for both lines
    const lineLength = 75;
    // Convert angle to radians for JavaScript Math functions
    const angleRadians = ((180 - angle) * Math.PI) / 180;
    const x2 = 100 + lineLength * Math.sin(angleRadians); // Adjust for vertical base line
    const y2 = 100 - lineLength * Math.cos(angleRadians); // Adjust for vertical base line

    return (
        <div className='surveywrapper'>
            <div className='multiplechoice-transitionbuffer'>
            {this.state.showmodal ? <GPTModal handleClose={this.hideModal} prompts={modal_gpt_prompts} intro_query={modal_intro_query} displayclass={"modal display-block"} company_name={this.props.values_business.businessname} industry={values_business.industry}/> : null}
                <CSSTransition
                    in={this.state.mounted}
                    timeout={1000}
                    classNames="fade"
                    unmountOnExit 
                    appear
                >
                    <div className='multiplechoice'>
                        <div className='textwrapper'>
                            <h2 className='large'>{header_question}</h2>
                            <h4 className='small'>Drag the slider to adjust the approximate angle of your turn</h4>
                        </div>
                        <input
                            type="range"
                            min="0"
                            max="360"
                            value={angle}
                            step={10}
                            onChange={this.handleAngleChange}
                            style={{width: '90%', height: 'max-content', alignSelf: 'center'}}
                            />
                        <div className='column'>
                            <div>Angle: {angle}°</div>
                            <div>
                                <svg width="200" height="200" viewBox="0 0 200 200" style={{width: '90%', height: 'fit-content', maxHeight: '50vh'}}>
                                    {/* Base line (now vertical) */}
                                    <line x1="100" y1="100" x2="100" y2="175" className='angle-line'/>
                                    {/* Rotated line */}
                                    <line x1="100" y1="100" x2={x2} y2={y2} className='angle-line active'/>
                                    {/* Adding a dot at the origin */}
                                    <circle cx="100" cy="100" r="3" fill="blue" />
                                </svg>
                            </div>
                        </div>
                    </div>  
                </CSSTransition>
                <FooterContact values_business={values_business} phone={values_customer.branch.phone}/>
            </div>
        </div>
    );
  }
}

export default AngleSelector;


