import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// Helper function to reorder list
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const initDroppableCategories = (props) => {
    console.log("initDroppableCategories")
    console.log(props)
    const categories = props.drop_categories;
    const droppables = {};
    categories.forEach((category) => {
        droppables[category] = [];
    });
    console.log(droppables)
    return droppables;
}
  

export class DragDropOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: props.items, // Items to drag
      droppables: initDroppableCategories(props), // Categories to drop into
    };
  }

  onDragEnd = (result) => {
    const { source, destination } = result;
    const {handleAddItemToCategory, handleRemoveItemFromCategory} = this.props;
    console.log("onDragEnd")
    console.log(result)
    // Dropped outside any list
    if (!destination) {
      return;
    }
  
    // Moving within the same list
    if (source.droppableId === destination.droppableId) {
        const items = reorder(
            this.getList(source.droppableId),
            source.index,
            destination.index
        );
        
            // Update state according to source and destination
            if (source.droppableId === 'items') {
            this.setState({ items });
        } else {
            this.setState({
            droppables: {
                ...this.state.droppables,
                [source.droppableId]: items,
            },
            });
        }
    } else {
        // Moving between different lists - ensure no duplicates across the entire state
        const start = this.getList(source.droppableId);
        const finish = this.getList(destination.droppableId);
        const [removed] = start.splice(source.index, 1);
        console.log(removed)
        // Add to the new list
        finish.splice(destination.index, 0, removed);
    
        // Update state according to source and destination
        if (source.droppableId === 'items') {
            this.setState({
                items: start,
                droppables: {
                    ...this.state.droppables,
                    [destination.droppableId]: finish,
                },
            }, handleAddItemToCategory(destination.droppableId, removed));
        } else if (destination.droppableId === 'items') {
            this.setState({
                items: finish,
                droppables: {
                    ...this.state.droppables,
                    [source.droppableId]: start, // Update the original list without the removed item
                    // No need to update the destination here since it's the 'items' list
                },
            }, handleRemoveItemFromCategory(source.droppableId, removed));
        } else {
            this.setState({
                droppables: {
                    ...this.state.droppables,
                    [source.droppableId]: start,
                    [destination.droppableId]: finish,
                },
            }, handleAddItemToCategory(destination.droppableId, removed), handleRemoveItemFromCategory(source.droppableId, removed));
        }
    }
  }
  
  

  getList(id) {
    if (id === 'items') {
      return this.state.items;
    } else {
      // Ensure the droppableId always maps to an existing array
      return this.state.droppables[id] || [];
    }
  }
  

  render() {
    const { drop_categories, item_bank_name, header_question } = this.props;

    return (
        <div>
            <h2 className='center-horizontal large'>{header_question}</h2>
            <DragDropContext onDragEnd={this.onDragEnd}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Droppable droppableId="items">
                    {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        style={{ backgroundColor: snapshot.isDraggingOver ? 'lightblue' : 'lightgrey', padding: 8, width: '48%', borderRadius: 5}}
                        {...provided.droppableProps}
                    >
                        <div style={{ textAlign: 'center', padding: 4 }}>{item_bank_name}</div>
                        {this.state.items.map((item, index) => (
                        <Draggable key={item.key} draggableId={item.key} index={index}>
                            {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{ ...provided.draggableProps.style, margin: '0 0 8px 0', borderRadius: 5, backgroundColor: snapshot.isDragging ? 'lightgreen' : 'grey' }}
                            >
                                &nbsp;{item.title}
                            </div>
                            )}
                        </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                    )}
                </Droppable>
                <div style={{ width: '48%' }}>
                    {drop_categories.map((key) => (
                    <Droppable key={key} droppableId={key}>
                        {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={{ backgroundColor: snapshot.isDraggingOver ? 'lightblue' : 'lightgrey', padding: 8, minHeight: 100, marginBottom: 8, borderRadius: 5 }}
                        >
                            <div style={{ textAlign: 'center', padding: 4 }}>{key.replace(/([A-Z])/g, ' $1').trim()}</div>
                            {this.state.droppables[key].map((item, index) => (
                            <Draggable key={item.key} draggableId={item.key} index={index}>
                                {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{ ...provided.draggableProps.style, borderRadius: 5, margin: '0 0 8px 0', backgroundColor: snapshot.isDragging ? 'lightgreen' : 'grey' }}
                                >
                                    &nbsp;{item.title}
                                </div>
                                )}
                            </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                        )}
                    </Droppable>
                    ))}
                </div>
                </div>
            </DragDropContext>
        </div>
    );
  }
}

export default DragDropOptions;
