import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import { CSSTransition } from 'react-transition-group';
import Matomo from '../../Matomo';

const AboutModal = ({ handleClose, show, children, info }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  /*-Matomo Analytics-*/
  if (show){
    const title = 'about';
    Matomo(title);
  }
  /*----End Matomo----*/

  return (
    <div className={showHideClassName}>
      <section className="modal-auto-height">
        <div className="right">
          <div className="close-modal-icon" onClick={handleClose}>
              <FontAwesomeIcon 
                  className='large no-margin'
                  icon={faTimes}
                  color='black'
              />
          </div>
        </div>
        <CSSTransition
                    in={show}
                    timeout={150}
                    classNames="fade-fast"
                    unmountOnExit 
                    appear
                    //onEntered={}
                    //onExit={}
                >
            <div>
              {children}
              {info.map(({ title, text })  => (
                <div>
                  <h3 className='large'>{title}</h3>
                  {text}
                  <h3 className='large'>&nbsp;</h3>
                </div>
              ))}
            </div>
          </CSSTransition>
      </section>
    </div>
  );
};

export {AboutModal};