import React, {Component} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

//props: values_customer, state_var, choice, handleClick
export class StepCounter extends Component {

    constructor (props) {
        super(props);
        this.state = {
            hover_btn: '',
        }
    }

    
    render() { 
        const {placeholder, state_var, state_val, handleChange, handleIncrementClick, handleDecrementClick} = this.props;

        return (
            <div className='stepcounter'>
                <FontAwesomeIcon 
                    icon={faMinus} 
                    className='icon' 
                    onClick={handleDecrementClick}
                    disable={state_val === 0}
                />
                <input 
                    key = {state_var}
                    type="number" 
                    placeholder= {placeholder}
                    alt = 'number'
                    max = {999}
                    min={0}
                    onChange= {handleChange(state_var)}
                    value = {state_val}
                />
                <FontAwesomeIcon 
                    icon={faPlus} 
                    className='icon' 
                    onClick={handleIncrementClick}
                />
            </div>
        )
    }
}

export default StepCounter;