import React, {Component} from 'react';
import {AboutModal} from '../questions/Modals/AboutModal.js';
import { CSSTransition } from 'react-transition-group';
import Matomo from '../Matomo.js';
import GPTModal from './modals/GPTmodal.js'
import AI_Assistant_Icon from '../general_questions/images/J.png'
import { FooterContact } from './FooterContact.js';
import { findObjectByProperty, sendToInpersons } from '../../Helpers.js';

//REQUIRED PROPS: 


export class FreeFormTextInput extends Component {
    constructor (props) {
        super(props);
        this.state = {
            showmodal : false,
            default_nextpage : props.default_next,
            mounted : false,
            err: null,
        }
    }

    componentDidMount = () => {
        const {default_nextpage} = this.state;
        const {page_title, showpage} = this.props;

        /*-Matomo Analytics-*/
        const title = page_title;
        Matomo(title);
        /*----End Matomo----*/
        
        //Skip to next page if necessary
        console.log('showpage: ' + showpage)
        if(!showpage){
            console.log('skipping page: ' + title)
            this.props.nextPage(default_nextpage, true);
        }
        this.setState({ mounted: true });
        this.getTop().scrollIntoView();
    }

    getTop = () => {
        return document.getElementById('top');
    };

    handleOnChange = () => e => {
        console.log('handleOnChange')
        try {
            const {state_var, handleChangeObject} = this.props;
            const {value} = e.target;
            handleChangeObject(state_var, value);
        } catch (error) {
            console.log("error in FreeFormTextInput.handleOnChange")
            this.setState({err: error});
        }
    }

    render() { 
        const {hide_gpt, header_question, values_business, values_customer, header_statement, is_mobile_display} = this.props;
        
        if (this.state.err) throw this.state.err;
        return (
            <div className='surveywrapper' id='top'>
                <div className={'multiplechoice-transitionbuffer'}>
                    <CSSTransition
                            in={this.state.mounted}
                            timeout={1000}
                            classNames="fade"
                            unmountOnExit 
                            appear
                    >
                        <div className='multiplechoice'>
                            
                            <div className='textwrapper'>
                                <h2 className='large'> {header_question} </h2>
                                <h4 className='small'>{header_statement}</h4>
                            </div>
                            {/* CONTENT: Large input text box */}
                            <div className='textareawrapper'>
                                <textarea 
                                    type='text' 
                                    name='freeform' 
                                    id='freeform' 
                                    placeholder='Type here' 
                                    value={values_customer.other_info}
                                    onChange={e => this.handleOnChange()(e)}/>
                            </div>
                            {!is_mobile_display && <div className='textwrapper'>
                                <h2 className='large invis'>.</h2>
                                <h4 className='small invis'>.</h4>
                                <p className='small btntitle invis'>.</p>
                            </div>}
                            <div className="fillremainderflex"/>
                            {is_mobile_display && <FooterContact values_business={values_business} phone={values_customer.branch.phone} hasbuttonbelow={true}/>}
                        </div>
                    </CSSTransition>
                    {!is_mobile_display && <FooterContact values_business={values_business} phone={values_customer.branch.phone} hasbuttonbelow={true}/>}
                </div>
            </div>
        )
    }
}

export default FreeFormTextInput;